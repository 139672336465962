import React from "react";
import { useDrag } from "react-dnd";
const style = {
  border: "1px dashed gray",
  backgroundColor: "white",
  padding: "0.5rem 1rem",
  marginRight: "1.5rem",
  marginBottom: "1.5rem",
  cursor: "move",
  // float: "left"
};
export const Post = ({ different_campaign, name, type, isDropped, post, updateSearch, index, selected_post, t }) => {
  const [{ opacity }, drag] = useDrag({
    type: "CARD",
    item: { name, type, post },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1
    })
  });
  return (
    <React.Fragment>
      <span className={`position-relative`}>
        <span className={`text-background d-block m-1 cadre border border-2 border-secondary text-center position-relative ${different_campaign ? 'merge-campaign' : ''} ${selected_post ? 'selected' : ''}`}  ref={drag} style={{ ...style, opacity }}>
                <div><strong style={{textDecoration: 'underline'}}>{post.name}</strong></div>

                {(!post.type || post.type === 'image') && 
                  <img className="img img-thumbnail img-fluid " width src={"/api/images/" + post._id} g="img/post1.png" alt="" />
                }

                {(post.type === 'video') && 
                  <img className="img img-thumbnail img-fluid " width src={"/api/images/" + post._id} g="img/post1.png" alt="" />
                }

                {(post.type === 'album') && 
                  <img className="img img-thumbnail img-fluid " width src={"/api/images/" + post._id} g="img/post1.png" alt="" />
                }

                {(post.type === 'text-background') && 
                  <span className="text-background">
                     {post.post_content}
                  </span>
                }
                <br/>
                <span className="index" style={{
                  "top": "50%",
                  "position": "absolute",
                  "left": "5px",
                  "font-size": "22px",
                }}><strong>{index}</strong></span>
        </span>

        {<span onClick={updateSearch.bind(this, {path: [':lang', 'console','post', 'edit', post._id], post: post._id})} className="badge bg-info text-white rounded-pill position-absolute top-0 start-0">
            {t('Edit')}
        </span>}

      </span>

    </React.Fragment>
  );
};
