import React from 'react';
import PropTypes from 'prop-types';
import styles from './Contact.module.scss';

import {NotificationContainer, NotificationManager} from 'react-notifications';

class Contact extends React.Component {
    state = {
      contact: {
        name: '',
        phone: '',
        about: '',
      }
    }

    handleContactClicked(props) {
      fetch(`${process.env.NODE_ENV !== 'development' ? "" : ""}/contact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.state.contact)
      })
      .then(response => response.json())
      .then(data => {
        if (!data.success) {
          NotificationManager.error(data.message);
          return;
        }

        this.setState({contact: {
          name: '',
          phone: '',
          about: '',
        }})

        NotificationManager.success('Success Sent Message');
      })
      .catch(err => {
        NotificationManager.error(this.props.t('Error sending message'));
      })

    }

    update_data(props) {

    }

    refresh() {

    }

    update_data(props) {
    }

    componentWillReceiveProps(props) {
    }
    
    componentWillMount(props) {
    }

    componentWillUnmount(){
    }

    render() {
      const { t, i18n } = this.props;

      return (

      <div id="main-wrapper" className="page-wrapper">
        <div className="page-header section-padding style-dark full-height dc-two pb-0 dc-two pb-0">
          <div className="container">


        <div className="container">
          <div className="row clearfix align-items-center justify-content-center">
            <div className="col-lg-6">
              <div className="heading-wrapper with-separator">
                <span className="sub-title">{t("Want to hear more?")}</span>
                <h2>{t("Contact us")}</h2>
              </div>
              <div className="text-wrapper">
                <p>{t("In order to receive service and additional information about us and the features of the system and any other topic, leave details and we will be happy to get back to you and help!")}</p>
              </div>
              <div className="contact-form">
                  <form>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <input onChange={event => { this.state.contact.name = event.target.value; this.setState({ contact: this.state.contact })}} value={this.state.contact.name} type="text" className="form-control" id="FiratName" placeholder={t("Full name *")} required />
                      </div>
                    </div>
                    <div className="form-group">
                      <input type="text" onChange={event => { this.state.contact.phone = event.target.value; this.setState({ contact: this.state.contact })}} value={this.state.contact.phone} className="form-control" id="subject" placeholder={t("Phone Number *")} required />
                    </div>
                    <div className="form-group">
                      <textarea onChange={event => { this.state.contact.about = event.target.value; this.setState({ contact: this.state.contact })}} value={this.state.contact.about} className="form-control" name="about" placeholder={t("Tell us briefly about your line of work...")} defaultValue={""} />
                    </div>
                    <div className="form-group" style={{textAlign: 'center'}}>
                      <button type="button" onClick={this.handleContactClicked.bind(this)} className="btn btn-primary" defaultValue="" >{t("Send Message")}</button>
                    </div>
                  </form>
              </div>
            </div>
          </div>
        </div>

          </div>
        </div>
      </div>
      );
    }
}

Contact.propTypes = {};

Contact.defaultProps = {};

export default Contact;

