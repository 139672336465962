import React from 'react';
import PropTypes from 'prop-types';
import styles from './ProgramSettings.module.scss';
import fetch from 'node-fetch';
import fs from 'fs';
import Select from 'react-select'

import settings from '../../assets/icons/settings.svg';
import logo from '../../assets/images/logo.png';
import help from '../../assets/icons/help.svg';
import alert from '../../assets/icons/alert.svg';
import signin from '../../assets/icons/signin.svg';
import clock from '../../assets/icons/clock.svg';
import add from '../../assets/icons/add.svg';
import user1 from '../../assets/images/user1.png';
import user2 from '../../assets/images/user2.png';
import user3 from '../../assets/images/user3.png';
import user4 from '../../assets/images/user4.png';
import post1 from '../../assets/images/post1.png';
import post2 from '../../assets/images/post2.png';
import chat from '../../assets/icons/chat.svg';
import monitor from '../../assets/icons/monitor.svg';
import bin from '../../assets/icons/bin.svg';
import group1 from '../../assets/images/group1.png';
import account from '../../assets/icons/account.svg';
import password from '../../assets/icons/password.svg';
import like from '../../assets/icons/like.svg';
import follower from '../../assets/icons/follower.svg';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import Switch from "@material-ui/core/Switch";

import {Button} from 'react-bootstrap/Button';
import axios from 'axios';

import {DropdownButton, ButtonGroup, Dropdown} from 'react-bootstrap';

import {NotificationContainer, NotificationManager} from 'react-notifications';

import { useTranslation, initReactI18next, withTranslation } from 'react-i18next';

import { SketchPicker } from 'react-color';

import {FaWindows, FaAppStoreIos, FaQuestionCircle} from "react-icons/fa";

import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  withRouter,
  useParams,
  useLocation
} from "react-router-dom";

import queryString from 'query-string';

class ProgramSettings extends React.Component {

    state = {
    	city_options: [],
      jobs_options: [],
      master_users: [],
      "key": "",
      settings: null,
      notification_settings: null,
      // "sabbath_mode": true,
      "download_link": "",
    }

    refresh() {
    
    }

    componentWillReceiveProps() {
    
    }
    
    componentWillMount() {
    	this.update_data(this.props);
    }

    async download() {
      this.props.tutorialEvent({action: 'download-button-click'});

      var response = await axios({
          url: this.state.download_link,
          method: "GET",
          responseType: "blob", // important
          onDownloadProgress: (progressEvent) => {
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            
            this.setState({download_progress: percentCompleted})

            if (percentCompleted === 100) {
              percentCompleted = 99;
            }

            this.props.tutorialEvent({action: 'download-exe', progress: percentCompleted});
            
          }
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'fbzipper.exe'); //or any other extension
      document.body.appendChild(link);
      link.click();
      await (new Promise((resolve) => setTimeout(resolve, 3000)));
      this.props.tutorialEvent({action: 'download-exe', progress: 100});

    }


    save_settings(props) {

      var token = localStorage.getItem('user_token');
      var query = queryString.parse(this.props.location.search);

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/save-settings?access_token=${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({settings: this.state.settings, notification_settings: this.state.notification_settings})
        })
        .then(response => response.json())
        .then(data => {

          NotificationManager.success('success', 'saved_settings');

        })
        .catch(err => {

        });

    }

    update_data(props) {
    	var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/get-settings?access_token=${token}`, {})
        .then(response => response.json())
        .then(data => {

          // console.log(5555, data, data.settings.sabbath_mode);
        	this.setState({
        		settings: data.settings,
            notification_settings: data.notification_settings,
            selected: data._id,
        		key: data.key,
            download_link: data.download_link,
        		name: data.name,
        	});


          fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/get-cities?access_token=${token}`, {})
          .then(response => response.json())
          .then(_data => {
              var city_options = _data.map((a) => {
                return { value: a._id, label: a.city_name };
              });
              
              data.settings.custom_cities = data.settings.custom_cities || [];

              this.state.selected_cites = data.settings.custom_cities.map((city_id) => {
                return city_options.find((a) => a.value == city_id)
              }).filter((a) => a)

              this.setState({
                  city_options: city_options,
                  selected_cites: this.state.selected_cites,
              });
          })
          .catch(err => {

          });

          fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/get-jobs?access_token=${token}`, {})
          .then(response => response.json())
          .then(_data => {
              var jobs_options = _data.map((a) => {
                return { value: a._id, label: a.workplace_name };
              });
              
              data.settings.custom_jobs = data.settings.custom_jobs || [];

              this.state.selected_jobs = data.settings.custom_jobs.map((job_id) => {
                return jobs_options.find((a) => a.value == job_id)
              }).filter((a) => a)

              this.setState({
                  jobs_options: jobs_options,
                  selected_cites: this.state.selected_jobs,
              });
          })
          .catch(err => {

          });


        })
        .catch(err => {

        });

        fetch(`${process.env.NODE_ENV !== 'development' ? "/admin" : "/admin"}/master?access_token=${token}`, {})
          .then(response => response.json())
          .then(data => {
            this.setState({
              master_users: data,
            });
          })
          .catch(err => {

          });




    }

    handleDeleteCampaign() {
		    var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/delete-campaign?access_token=${token}`, {
  		    method: 'POST',
  		    headers: {
  		      'Content-Type': 'application/json'
  		    },
  		    body: JSON.stringify({
  		    	id: this.props.match.params.campaign_id,
  		    })
        })
        .then(response => response.json())
        .then(data => {
          NotificationManager.success('Deleted campaign successfully');
          this.props.updateSearch({path: [':lang', 'console'], "new-campaign": Math.random()})
        })
        .catch(err => {
        	NotificationManager.error('Error deleting campaign');

        	this.setState({
        		error: "Error deleting campaign",
        		success: "",
        		password: "",
        	});
        })
    }

    buttons = [
      {
        label: `Download Program`,
        tool_tip: 'Download Program',
        // class: 'login-button',
        action: () => {
          this.download();
          // window.open(`https://facebook.com/groups/${this.state.post.group.group_id}/posts/${this.state.post.submitted_post.facebook_post_id}`, "_blank");
        },
        should_display: () => {
          return true;
        }
      },
      {
        label: 'Copy Serial Key',
        tool_tip: 'Login',
        class: 'serial-key',
        action: () => {
          this.copy_key();
          
        },
        should_display: () => {
          return true;
        }
      },         
    ];

    copy_key = () => {
      navigator.clipboard.writeText(this.state.key);
      NotificationManager.success(this.props.t('Copied') + ' ' + this.state.key);
    }

    extra_options = [];

    close_path = [':lang', 'console'];
    tabs = [
      {
        label: 'Settings',
        active: true,
        should_display: () => {
          return true;
        },
        content: () => <div>

          <div className="container">
            <div className="row">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">{this.props.t('Sabbath Mode')}</label>
                &nbsp;<FaQuestionCircle data-tip={this.props.t('Posts will stop 1 hour before that start of the sabbath and will start again 1 hour after sabbath ends. during the sabbath we will not post or comment on posts')}/>
                <Switch
                  classes={this.props.classes}
                  checked={this.state.settings.sabbath_mode}
                  onClick={(e) => { this.state.settings.sabbath_mode = !this.state.settings.sabbath_mode; this.setState({
                    settings: this.state.settings,
                  }) }}
                />
                {/*<small id="emailHelp" className="form-text text-muted">{this.props.t(`Posts will stop 1 hour before that start of the sabbath and will start again 1 hour after sabbath ends. during the sabbath we will not post or comment on posts`)}</small>*/}
              </div>

            </div>
            {this.state.master_users.length > 0 && <div className="row">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">{this.props.t('Locked account')}</label>
                &nbsp;<FaQuestionCircle data-tip={this.props.t('only admins will be able to make changes')}/>
                <Switch
                  classes={this.props.classes}
                  checked={this.state.settings.admin_only}
                  onClick={(e) => { this.state.settings.admin_only = !this.state.settings.admin_only; this.setState({
                    settings: this.state.settings,
                  }) }}
                />
                {/*<small id="emailHelp" className="form-text text-muted">{this.props.t(`Posts will stop 1 hour before that start of the sabbath and will start again 1 hour after sabbath ends. during the sabbath we will not post or comment on posts`)}</small>*/}
              </div>

            </div>}

            <div className="row">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">{this.props.t('Notifications on whatsapp')}</label>
                &nbsp;<FaQuestionCircle data-tip={this.props.t('You will get important notifications straight to whatsapp')}/>
                <Switch
                  classes={this.props.classes}
                  checked={this.state.notification_settings.notifications_on_whatsapp}
                  onClick={(e) => { this.state.notification_settings.notifications_on_whatsapp = !this.state.notification_settings.notifications_on_whatsapp; this.setState({
                    settings: this.state.settings,
                  }) }}
                />
              </div>
              {this.state.notification_settings.notifications_on_whatsapp && <div className="form-group" style={{paddingRight: '51px'}}>
                <label htmlFor="exampleInputEmail1">{this.props.t('Whatsapp Phone Number')} <span style={{color: ''}}>({this.props.t('Required')})</span></label>
                <input
                  required={true}
                  type="text"
                  className="form-control"
                  placeholder={this.props.t('Phone')}
                  onChange={event => {
                    this.state.notification_settings.whatsapp_phone_number = event.target.value;
                    this.setState({ notification_settings: this.state.notification_settings });
                  }}
                  value={this.state.notification_settings.whatsapp_phone_number}
                />
              </div>}
            </div>





            {!this.props.user.personal_plan && <div className="row serial-key" onClick={this.copy_key.bind(this)}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">{this.props.t('Serial Key')}:</label>
                <input
                  disabled={true}
                  type="text"
                  className="form-control copy-input"
                  value={this.state.key}
                />
              </div>
            </div>}
            {!this.props.user.personal_plan && <div className="row">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">{this.props.t('Download Program')}:</label>
                {this.state.download_link && 
                  <div className="position-relative">
                    
                    <a data-tip={this.props.t('Download for windows')} style={{background: "#8ba6e3", width: '50%'}} target="_blank" className="btn btn-default download-button-windows" onClick={this.download.bind(this)}>
                      {this.props.t('Download for windows')} <FaWindows style={{
                        fontSize: '40px'
                      }}/> {this.state.download_progress ? `(${this.state.download_progress})%` : ''}
                    </a>

                    <a data-tip={this.props.t('coming soon')} disabled={true} style={{background: "#8ba6e3", width: '50%'}} target="_blank" className="btn btn-default download-button-ios">
                      {this.props.t('Download for mac')} <FaAppStoreIos style={{
                      fontSize: '40px'
                    }}/> {this.state.download_progress ? `(${this.state.download_progress})%` : ''}
                    </a>
                  </div>}
              </div>
            </div>}

            {!this.props.user.personal_plan && <div className="row">
              <div className="form-group dddd">
                <label htmlFor="exampleInputEmail1">{this.props.t('Account Cities')}:</label>
                  <Select value={this.state.selected_cites} options={this.state.city_options} isMulti onChange={(e) => {
                    this.state.settings.custom_cities = e.map((a) => a.value)
                    this.state.selected_cites = e;
                    this.setState({settings: this.state.settings, selected_cites: this.state.selected_cites});
                  }}/>
              </div>
            </div>}

            {!this.props.user.personal_plan && <div className="row">
              <div className="form-group dddd">
                <label htmlFor="exampleInputEmail1">{this.props.t('Account Jobs')}:</label>
                  <Select value={this.state.selected_jobs} options={this.state.jobs_options} isMulti onChange={(e) => {
                    this.state.settings.custom_jobs = e.map((a) => a.value)
                    this.state.selected_jobs = e;
                    this.setState({settings: this.state.settings, selected_jobs: this.state.selected_jobs});
                  }}/>
              </div>
            </div>}

            {this.state.settings.custom_jobs && this.state.settings.custom_jobs.length > 0 && <div className="row">
              <div className="form-group dddd">
                <label htmlFor="exampleInputEmail1">{this.props.t('Account Job Position')}:</label>
                  <input value={this.state.settings.custom_job_position} onChange={(e) => {
                    this.state.settings.custom_job_position = e.target.value;
                    this.setState({settings: this.state.settings});
                  }}/>
              </div>
            </div>}


          </div>
        </div>,
        footer: () => <div>
          <div className="container">
            <div className="row">
              <button onClick={this.save_settings.bind(this)} className="btn btn-warning">{this.props.t('Save Settings')}</button>
            </div>
          </div>
        </div>,
      },
    ];


    render() {
    	const { t, i18n } = this.props;

      this.props.sidePaneEvent({
        id: `new_user_${this.props.match.params.profile_id}`,
        buttons: this.buttons,
        extra_options: this.extra_options,
        close_path: this.close_path,
        tabs: this.tabs,
        name: 'create-user-popup',
        loading: this.state.settings ? false : true,
        title: 'Settings',
        // loading: false,
      });

      return (<div></div>);
      
        return (
		  <div className="overlay">
        <style>{'body {overflow: hidden;}'}</style>
              {this.state.settings && <div className="bg-primary p-5 text-white text-center rounded-3 " id="myForm">
                  <div className="pb-5 position-relative" id="signin_header">
                    <h2 className="fs-5 fw-bolder text-uppercase text-center">{this.props.edit ? t('Edit campaign') : t('Settings')}</h2>
                    <a type="button" className="btn text-white close_btn fs-2 fw-bolder" onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console']})}>×</a>
                  </div>

                  <div>
                      {this.props.t('Sabbath Mode')}
                      <Switch
                        classes={this.props.classes}
                        checked={this.state.settings.sabbath_mode}
                        onClick={(e) => { this.state.settings.sabbath_mode = !this.state.settings.sabbath_mode; this.setState({
                          settings: this.state.settings,
                        }) }}
                      />
                  </div>

                  <div >
                    <button onClick={this.save_settings.bind(this)} className="btn btn-warning">{this.props.t('Save')}</button>
                  </div>
                  <br/>
                  <br/>
                  <CopyToClipboard text={this.state.key}
                    onCopy={() => this.setState({copied: true})}>
                      <a style={{background: "rgb(196 199 206)", color: 'black'}}className="btn btn-default serial-key-label">{this.props.t('מספר סידורי')}</a>
                  </CopyToClipboard>
                  <CopyToClipboard text={this.state.key}
                    onCopy={() => this.setState({copied: true})}>

                      <div id="email" className="bg-blue py-3 my-3 rounded-3 d-flex serial-key">
                        <input disabled="disabled" className="m_input" type="text" placeholder={this.props.t('Key')} onChange={event => {this.setState({ name: event.target.value })}} value={this.state.key}/>
                      </div>
                  </CopyToClipboard>
                  {this.state.copied ? <span className={'serial-key-label'} style={{color: 'red'}}>{this.props.t('Copied.')}</span> : null}

                  {this.state.download_link && <div className="position-relative"><a style={{background: "#8ba6e3"}} target="_blank" className="btn btn-default download-button" onClick={this.download.bind(this)}>{this.props.t('Download')} {this.state.download_progress ? `(${this.state.download_progress})%` : ''}</a></div>}
              </div>}
		  </div>
        );
    }
}

ProgramSettings.propTypes = {};

ProgramSettings.defaultProps = {};

export default withRouter(ProgramSettings);









