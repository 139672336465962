import React from 'react';
import PropTypes from 'prop-types';
import styles from './Activate.module.scss';
import fetch from 'node-fetch';

import settings from '../../assets/icons/settings.svg';
import logo from '../../assets/images/logo.png';
import help from '../../assets/icons/help.svg';
import alert from '../../assets/icons/alert.svg';
import signin from '../../assets/icons/signin.svg';
import clock from '../../assets/icons/clock.svg';
import add from '../../assets/icons/add.svg';
import user1 from '../../assets/images/user1.png';
import user2 from '../../assets/images/user2.png';
import user3 from '../../assets/images/user3.png';
import user4 from '../../assets/images/user4.png';
import post1 from '../../assets/images/post1.png';
import post2 from '../../assets/images/post2.png';
import chat from '../../assets/icons/chat.svg';
import monitor from '../../assets/icons/monitor.svg';
import bin from '../../assets/icons/bin.svg';
import group1 from '../../assets/images/group1.png';
import account from '../../assets/icons/account.svg';
import password from '../../assets/icons/password.svg';
import like from '../../assets/icons/like.svg';
import follower from '../../assets/icons/follower.svg';

import {Button} from 'react-bootstrap/Button';
import {DropdownButton, ButtonGroup, Dropdown} from 'react-bootstrap';

import {NotificationContainer, NotificationManager} from 'react-notifications';

import { useTranslation, initReactI18next, withTranslation } from 'react-i18next';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  withRouter,
  useLocation
} from "react-router-dom";

import queryString from 'query-string';

class Activate extends React.Component {

    state = {
      email: this.props.user.email
    }

    refresh() {
    }
    componentWillReceiveProps() {
    }
    componentWillMount() {
    }

    handleResendEmail() {
      var token = localStorage.getItem('user_token');
      var request_url = `${process.env.NODE_ENV !== 'development' ? "/" : "http://localhost:8888/"}activate/send-email?access_token=${token}`;
      
      fetch(request_url, {})
      .then(response => response.json())
      .then(data => {
        NotificationManager.success(this.props.t(`Email has been sent`));
      })
      .catch(err => {
        // alert(request_url)
      });
    }

    handleActivateClicked() {
      var token = localStorage.getItem('user_token');
      var request_url = `${process.env.NODE_ENV !== 'development' ? "/" : "http://localhost:8888/"}activate/code?access_token=${token}&code=${this.state.activation_code}`;
      
      fetch(request_url, {})
      .then(response => response.json())
      .then(data => {
        if (!data.success) {
          return NotificationManager.error(this.props.t(data.message));
        }
        NotificationManager.success(this.props.t(`Success Activated`));
        this.props.checkLoggedIn();
      })
      .catch(err => {
        // alert(request_url)
      });
    }

    handleChangeEmail() {
      var token = localStorage.getItem('user_token');
      var request_url = `${process.env.NODE_ENV !== 'development' ? "/" : "http://localhost:8888/"}activate/change-email?access_token=${token}`;
      
      fetch(request_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          new_email: this.state.email
        })
      })
      .then(response => response.json())
      .then(data => {
        if (!data.success) {
          return NotificationManager.error(this.props.t(data.message));
        }
        NotificationManager.success(this.props.t(`Success changed email`));
        this.props.checkLoggedIn();
      })
      .catch(err => {
        // alert(request_url)
      });
    }
    render() {
    	const { t, i18n } = this.props;

        return (

      <div className="box-wrapper">
        {/* Main Wrapper */}
        <div id="main-wrapper" className="page-wrapper">
          <div className="dc-signin theme-two">
            <div className="signin-wrapper">
              <div className="intro-box">
                <div className="intro-content style-dark">
                  <img src="/images/d-code-logo-light.svg" className="logo" alt="DCode" />
                  <div className="heading-wrapper">
                    <h2 className="h1">{t("Wellcome")}</h2>
                  </div>
                  <div className="text-wrapper">
                    <p>{t('We sent you a email to validate your account')}</p>
                  </div>
                  <div className="btn-wrapper">
                    <a className="btn btn-primary btn-light" onClick={this.handleResendEmail.bind(this)}>{t('Resend Email')}</a>
                  </div>
                </div>
                <div className="st-tab-btn">
                  <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                      <a className={`nav-link ${this.props.page === 'activate' && 'active'}`} data-toggle="tab" role="tab" to={`/${this.props.match.params.lang}/activate`}>{t('Activate Account')}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="form-box">
                <div className="st-tab-content">
                  <div className="tab-content">
                    <div className={`tab-pane ${this.props.page === 'activate' && 'active'}`} id="SignIn" role="tabpanel">
                        <div className="form-group">
                          <input onChange={event => {this.setState({ activation_code: event.target.value })}} value={this.state.activation_code} type="text" name="username" className="form-control" placeholder={t('Activation Code')} />
                        </div>
                        <div className="form-group">
                          <button onClick={this.handleActivateClicked.bind(this)} className="btn btn-primary btn-full">{t('Activate')}</button>
                        </div>



                        <div className="form-group">
                          <input onChange={event => {this.setState({ email: event.target.value })}} value={this.state.email} type="text" name="username" className="form-control" placeholder={t('Email')} />
                        </div>
                        <div className="form-group">
                          <button onClick={this.handleChangeEmail.bind(this)} className="btn btn-primary btn-full">{t('Change Email')}</button>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Page Header */}
        </div>
      </div>



		  // <div className="overlay">
    //           <div className="bg-primary p-5 text-white text-center rounded-3 " id="myForm">
    //               <div className="pb-5 position-relative" id="signin_header">
    //                 <h2 className="fs-5 fw-bolder text-uppercase text-center">{t('Sign in to account')}</h2>
    //                 <a type="button" className="btn text-white close_btn fs-2 fw-bolder" to={`/${this.props.match.params.lang}`}>×</a>
    //               </div>

    //               <div className="error">{t(this.state.error)}</div>
    //               <div className="success">{t(this.state.success)}</div>

    //               <div id="email" className="bg-blue py-3 my-3 rounded-3 d-flex">
    //                 <img className="mx-4" width="35px" src={account} g="icons/account.svg" />
    //                 <input className="m_input" type="text" placeholder={t('username')} onChange={event => {this.setState({ username: event.target.value })}} value={this.state.username}/>
    //               </div>
    //               <div id="password" className="bg-blue py-3 my-3 rounded-3 d-flex">
    //                 <img className="mx-4" width="35px" src={password} g="icons/password.svg" />
    //                 <input className="m_input" type="password" placeholder={t('password')}  onChange={event => {this.setState({ password: event.target.value })}} value={this.state.password} />
    //               </div>
    //               <p className="small text-white-50">{t('Forget login or password?')} <a className="text-info text-decoration-none" href="#">{t('Click here to reset')}</a></p>
    //               <button onClick={this.handleActivateClicked.bind(this)} type="submit" className="btn w-75 fw-bold bg-white py-3 px-5 mt-4 text-primary rounded rounded-pill">{t('Sign In')}</button>
    //               <p className="my-1 small text-white-50">{t('Dont have an account?')} <a className="text-info text-decoration-none" href="#">{t('Register now.')}</a></p>
    //           </div>
		  // </div>
        );
    }
}

Activate.propTypes = {};

Activate.defaultProps = {};

export default Activate;



