import React from 'react';
import { useDrop } from 'react-dnd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComment, faThumbsUp, faInfoCircle, faTrashAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import ReactTooltip from 'react-tooltip';

import like from '../../assets/icons/like.svg';
import chat from '../../assets/icons/chat.svg';
import settings from '../../assets/icons/settings.svg';
import monitor from '../../assets/icons/monitor.svg';
import bin from '../../assets/icons/bin.svg';
import empty_profile from '../../assets/images/empty_profile.jpg';

import {FaBan, FaExclamationTriangle, FaLock, FaClock, FaBell, FaBox, FaUsers, FaPlus, FaTrash, FaEdit, FaComment, FaHandshake } from "react-icons/fa";


const style = {
    minHeight: '100px',
    width: '100%',
    color: 'white',
    // textAlign: 'center',
    fontSize: '1rem',
    lineHeight: 'normal',
    float: 'left',
};

var getColumnPosts = (time_slot) => {
  var results = [];
  
  var amount = Object.keys(time_slot.group_map).reduce((amount, group_id) => {
    if (time_slot.time && time_slot.group_map[group_id]) {
      if (time_slot.group_map[group_id].active) {
        var amount = parseInt(time_slot.group_map[group_id].post_amount) || 1;
        for (var i = 0; i < amount; i++) {
          results.push(time_slot.group_map[group_id])
        }

      }
    }
    return results;
  }, 0);

  return amount;
}

export const Dropbox = ({ query, accept, multi_select, lastDroppedItem, onDrop, day, slot, groups, _delete, props, set_selected, set_selected_group, selected, that, remove, removeCampaign, updateSearch, t, automatic, multi_group_slots, interval, live_events}) => {
    const [{ isOver, canDrop }, drop] = useDrop({
        accept,
        type: "CARD",
        drop: onDrop.bind(this, {day,slot}),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    const isActive = isOver && canDrop;
    let backgroundColor = 'rgb(250 250 250)';
    if (isActive) {
        backgroundColor = 'green';
    }
    else if (canDrop) {
        backgroundColor = 'rgb(150 150 150)';
    }

    return (<div ref={drop} style={{ ...style, backgroundColor }}>
        {<React.Fragment>

{isActive && <span style={{color: 'black', width: '100%', display: 'block'}}>'Release to drop'</span>}

{/*<div className="task-container">
  <img style={{
    position: 'absolute',
    left: '4px',
    top: '4px',
  }} width="34px" className="rounded rounded-circle " src="https://images.fbzipper.com/api/users/profile/614fab8f0b5f45c088795d47" g="img/user1.png" alt=""/>
  <div className="button-border working"></div>
</div>*/}




      
            {multi_group_slots && multi_group_slots.map((multi_slot) => (<React.Fragment>
              {(multi_slot.times || []).filter((times) => {
                return slot.slot == times.time;
              }).map((multi_slot_time) => (<React.Fragment>
                {(getColumnPosts(multi_slot_time) || []).filter((group) => group.group_name).map((group) => (<a  onClick={ updateSearch.bind(this, {"multiple-group-camaign": multi_slot._id, "interval": interval, "day": day}) } style={{position: 'relative'}} className >
                  <span style={{
                    opacity: query['multiple-group-camaign'] ? (query['multiple-group-camaign'] === multi_slot._id ? 1 : 0.1) : 1,
                  }}  data-tip={ `${multi_slot.campaign && multi_slot.campaign.name} = ${group.group_name}` }>
                      <span className="position-relative" style={{
                        width: '40px',
                        height: '40px',
                        background: multi_slot.campaign && multi_slot.campaign.color,
                        display: 'inline-block',
                        borderRadius: '50%',
                      }}>
                      </span>
                  </span>
                </a>))}
              </React.Fragment>))}
            </React.Fragment>))}

            {slot.campaigns.map((post) => (
                <React.Fragment>


                {post.group && (!post.campaign || post.campaign.enabled !== false) && <a style={{position: 'relative'}} className onClick={set_selected.bind(this, post._id)}>
                  <span >
                      <span className="position-relative">

                        <div className={(multi_select.indexOf(post._id) > -1) ? "multi-selected" : ""} data-tip={ post.group.name } style={{color: 'black', lineHeight: '41px', textAlign: 'center', width: '100%', height: '40px', background: `linear-gradient(to left, ${post.campaign && post.campaign.color} 50%, ${(post.campaign && post.settings && post.secondary_color && post.secondary_color) || (post.campaign && post.campaign.color)} 50%)`, borderRadius: '50px'}}>

                          {post.campaign && post.campaign.name}

                          <img className="d-inline mx-1 h-icon" width="5px" src="/static/media/account.9ec423dc.svg" g="icons/account.svg"/> {post.users.length}
                        </div>

                    {(post._id === selected) && <aside id="secondary_menu" className="px-1 py-1 rounded-3 small text-white position-absolute start-50 d-flex">
                      <div className="d-flex px-1">
                        <a className="mx-1" onClick={updateSearch.bind(this, {'edit-scheduled-camaign': post._id })}><img width="15px" src={monitor} g="icons/monitor.svg" alt="" /></a>
                        <a className="mx-1" onClick={removeCampaign.bind(this, post._id)} data-tip={"Remove"}><img width="15px" src={bin} g="icons/bin.svg" alt="" /></a>
                      </div>
                    </aside>}

                      </span>
                  </span>
                </a>}
                </React.Fragment>
            ))}

            {slot.posts.map((post) => (
                <React.Fragment>
                {((post.user && post.is_in_past && post.submitted_post) || ((post.user && !post.is_in_past && !post.user.temporary_block && !post.user.locked_account) ) ) && <React.Fragment>
                

<div style={{
  opacity: (post.submitted_post ? 1 : 0.5),
}} 
data-tip={((post.post_type === 'new' || post.post_type === 'profile-picture') && post.user) ? post.user.first_name + " " + post.user.last_name + " - " + (post.campaign && post.campaign.name) + " --- " + (post.group && post.group.name) : 'Existing Post' }
className={`${query.group ? ( post.group && query.group === post.group._id ? 'selected-post-group' : 'not-selected-post-group' ) : ''} task-container ${post._id === selected ? 'selected' : ''}`} onClick={set_selected.bind(this, post._id)}>
  
  {post.post_type === 'existing' && <React.Fragment>
    <img style={{
      position: 'absolute',
      left: '4px',
      top: '4px',
    }} width="34px" className="rounded rounded-circle " src={empty_profile} g="img/user1.png" alt=""/>
  </React.Fragment>}

  <div style={{
    borderColor: post.campaign && post.campaign.color,
  }} className={`button-border ${live_events && live_events.indexOf(post._id) > -1 ? 'working' : ''} `}></div>


  {post.user && (post.post_type === 'new' || post.post_type === 'profile-picture' || post.post_type === 'cover-photo') && <React.Fragment>
    <img style={{
      position: 'absolute',
      left: '4px',
      top: '4px',
      
    }}
    width="34px" className="rounded rounded-circle " src={'https://images.fbzipper.com/api/users/profile/' + post.user._id} g="img/user1.png" alt=""/>
      
                          {post.submitted_post && post.submitted_post._errors && post.submitted_post._errors.length > 0 && <FaExclamationTriangle style={{
                            color: 'red',
                            fontSize: '22px',
                            lineHeight: '41px',
                            position: 'absolute',
                            top: '9px',
                            left: '9px',
                          }}/>}

                          {!post.post && <FaExclamationTriangle style={{
                            color: 'purple',
                            fontSize: '22px',
                            lineHeight: '41px',
                            position: 'absolute',
                            top: '9px',
                            left: '9px',
                          }}/>}

                          {post.user.locked_account && <FaLock style={{
                            color: 'red',
                            fontSize: '22px',
                            lineHeight: '41px',
                            position: 'absolute',
                            top: '9px',
                            left: '9px',
                          }}/>}

                          {/*post.user.temporary_block && <FaLock style={{
                            color: 'blue',
                            fontSize: '22px',
                            lineHeight: '41px',
                            position: 'absolute',
                            top: '9px',
                            left: '9px',
                          }}/>*/}

                          {post.user.login_try_count > 0 && <FaBell style={{
                            color: 'red',
                            fontSize: '22px',
                            lineHeight: '41px',
                            position: 'absolute',
                            top: '9px',
                            left: '9px',
                          }}/>}

                          {post.submitted_post && post.submitted_post.status === 'pending' && <FaClock style={{
                            color: 'yellow',
                            fontSize: '22px',
                            lineHeight: '41px',
                            position: 'absolute',
                            top: '9px',
                            left: '9px',
                          }}/>}

                          {post.submitted_post && post.submitted_post.status === 'pending_participation' && <FaClock style={{
                            color: 'pink',
                            fontSize: '22px',
                            lineHeight: '41px',
                            position: 'absolute',
                            top: '9px',
                            left: '9px',
                          }}/>}

                          {post.submitted_post && post.submitted_post.status === 'deleted' && <FaTrash style={{
                            color: 'red',
                            fontSize: '22px',
                            lineHeight: '41px',
                            position: 'absolute',
                            top: '9px',
                            left: '9px',
                          }}/>}

                          {post.submitted_post && post.submitted_post.status === 'auto-deleted' && <FaTrash style={{
                            color: 'green',
                            fontSize: '22px',
                            lineHeight: '41px',
                            position: 'absolute',
                            top: '9px',
                            left: '9px',
                          }}/>}

                          {post.settings && post.settings.should_edit && !post.edited && post.submitted_post && post.submitted_post.status === 'published' && <FaEdit style={{
                            color: 'green',
                            fontSize: '22px',
                            lineHeight: '41px',
                            position: 'absolute',
                            top: '9px',
                            left: '9px',
                          }}/>}

                          {post.submitted_post && post.submitted_post.status === 'no box' && <FaBox style={{
                            color: 'orange',
                            fontSize: '22px',
                            lineHeight: '41px',
                            position: 'absolute',
                            top: '9px',
                            left: '9px',
                          }}/>}

                          {post.submitted_post && post.submitted_post.status === 'error' && <FaExclamationTriangle style={{
                            color: 'red',
                            fontSize: '22px',
                            lineHeight: '41px',
                            position: 'absolute',
                            top: '9px',
                            left: '9px',
                          }}/>}

                          {post.submitted_post && post.submitted_post.status === 'not in group' && <FaUsers style={{
                            color: 'red',
                            fontSize: '22px',
                            lineHeight: '41px',
                            position: 'absolute',
                            top: '9px',
                            left: '9px',
                          }}/>}

                          {post.submitted_post && post.submitted_post.locked_comment && <FaLock style={{
                            color: 'orange',
                            fontSize: '22px',
                            lineHeight: '41px',
                            position: 'absolute',
                            top: '9px',
                            left: '9px',
                          }}/>}

                          {post.real_reply_count > 0 && <span style={{
                            background: 'red',
                            fontSize: '16px',
                            width: '20px',
                            height: '20px',
                            lineHeight: '20px',
                            textAlign: 'center',
                            opacity: '1',
                            position: 'absolute',
                            top: '25px',
                            left: '22px',
                            border: '1px solid blue',
                            borderRadius: '50%',
                          }}>{post.real_reply_count}</span>}
                          
                          {false && !post.user.locked_account && !post.user.temporary_block && post.user.temporary_block_comment && <FaHandshake style={{
                            color: 'orange',
                            fontSize: '22px',
                            lineHeight: '41px',
                            position: 'absolute',
                            top: '9px',
                            left: '9px',
                          }}/>}
                          {false && !post.user.locked_account && !post.user.temporary_block && post.user.temporary_block_comment_link && <FaHandshake style={{
                            color: 'pink',
                            fontSize: '22px',
                            lineHeight: '41px',
                            position: 'absolute',
                            top: '9px',
                            left: '9px',
                          }}/>}

                          {post.user.login_try_count > 0 && <FaBell style={{
                            color: 'red',
                            fontSize: '22px',
                            lineHeight: '41px',
                            position: 'absolute',
                            top: '9px',
                            left: '9px',
                          }}/>}




  </React.Fragment>}


</div>


                </React.Fragment>}
                </React.Fragment>
            ))}




            {query.show_groups && slot.groups.map((post) => (
                <React.Fragment>

                {post.user && post.group && <div style={{
                  opacity: (post.triggerd ? 1 : 0.5),
                }} className={`${query.group ? ( post.group && query.group === post.group._id ? 'selected-post-group' : 'not-selected-post-group' ) : ''} task-container ${post._id === selected ? 'selected' : ''}`} data-tip={post.user.first_name + " " + post.user.last_name + " --- " + post.group.name } onClick={set_selected_group.bind(this, post._id)}>
                  <img style={{
                    position: 'absolute',
                    left: '4px',
                    top: '4px',
                  }} width="34px" className="rounded rounded-circle " src={'https://images.fbzipper.com/api/users/profile/' + post.user._id} g="img/user1.png" alt=""/>
                  

                          {/*<FaUsers style={{
                            color: 'green',
                            fontSize: '22px',
                            lineHeight: '41px',
                            position: 'absolute',
                            top: '9px',
                            left: '9px',
                          }}/>*/}
                          {<FaPlus style={{
                            color: 'yellow',
                            fontSize: '22px',
                            lineHeight: '41px',
                            position: 'absolute',
                            opacity: 1,
                            top: '9px',
                            left: '9px',
                          }}/>}
                          {post.user.locked_account && <FaLock style={{
                            color: 'red',
                            fontSize: '22px',
                            lineHeight: '41px',
                            position: 'absolute',
                            top: '9px',
                            left: '9px',
                          }}/>}
                          {post.user.temporary_block && <FaLock style={{
                            color: 'blue',
                            fontSize: '22px',
                            lineHeight: '41px',
                            position: 'absolute',
                            top: '9px',
                            left: '9px',
                          }}/>}



                  <div className={`button-border ${live_events && live_events.indexOf(post._id) > -1 ? 'working' : ''} `}></div>
                </div>}



                </React.Fragment>
            ))}


                <React.Fragment>


                  {!automatic && <div onClick={ updateSearch.bind(this, {"schedule-new-post": '', "slot": slot.slot, "day": day}) } className="task-container" data-tip={t('New Scheduled Post')}>
                    <img style={{
                      position: 'absolute',
                      left: '4px',
                      top: '4px',
                    }} width="34px" className="rounded rounded-circle " src={empty_profile} g="img/user1.png" alt=""/>
                    
                      {<FaPlus style={{
                        color: '#222222',
                        fontSize: '22px',
                        lineHeight: '41px',
                        position: 'absolute',
                        top: '9px',
                        left: '9px',
                      }}/>}


                    <div className="button-border" style={{border: 'none'}}></div>
                  </div>}

                </React.Fragment>





        </React.Fragment>}

      {lastDroppedItem && (<p>Last dropped: {JSON.stringify(lastDroppedItem)}</p>)}


    </div>);
};
