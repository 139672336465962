import React from 'react';
import PropTypes from 'prop-types';
import styles from './GroupSettingsPopup.module.scss';
import fetch from 'node-fetch';
import { confirmAlert } from 'react-confirm-alert'; // Import
// import MultipleValueTextInput from 'react-multivalue-text-input';
import Select from 'react-select';

import settings from '../../assets/icons/settings.svg';
import logo from '../../assets/images/logo.png';
import help from '../../assets/icons/help.svg';
import alert from '../../assets/icons/alert.svg';
import signin from '../../assets/icons/signin.svg';
import clock from '../../assets/icons/clock.svg';
import add from '../../assets/icons/add.svg';
import user1 from '../../assets/images/user1.png';
import user2 from '../../assets/images/user2.png';
import user3 from '../../assets/images/user3.png';
import user4 from '../../assets/images/user4.png';
import post1 from '../../assets/images/post1.png';
import post2 from '../../assets/images/post2.png';
import chat from '../../assets/icons/chat.svg';
import monitor from '../../assets/icons/monitor.svg';
import bin from '../../assets/icons/bin.svg';
import group1 from '../../assets/images/group1.png';
import account from '../../assets/icons/account.svg';
import password from '../../assets/icons/password.svg';
import like from '../../assets/icons/like.svg';
import follower from '../../assets/icons/follower.svg';

import {Button} from 'react-bootstrap/Button';
import {DropdownButton, ButtonGroup, Dropdown} from 'react-bootstrap';

import {NotificationContainer, NotificationManager} from 'react-notifications';

import { useTranslation, initReactI18next, withTranslation } from 'react-i18next';

import { SliderPicker, AlphaPicker, CirclePicker, ChromePicker } from 'react-color'

import { FaQuestionCircle } from "react-icons/fa";


import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  withRouter,
  useParams,
  useLocation
} from "react-router-dom";

import queryString from 'query-string';

import Switch from "@material-ui/core/Switch";


class GroupSettingsPopup extends React.Component {

    state = {
    	"groups": [],
      "search": {},
      "posts": [],
      "master_users": [],
      "_id": null,
      "name": "",
      "new_name": "",
      "new_permission": "",
    	"color": "#ff0000",
    	"error": "",
    	"success": "",
      "allow_permissions": [],
      "campaigns": [],
    	"selected": null,
      "immediate_pump": false,
      "enabled": true,
      "max_comments": 10,
      "max_daily_posts": 300,
      "max_immediate_comment_count": 2,
      "comment_duration_hours": 8,
    }

    refresh() {
    }
    componentWillReceiveProps(props) {
      if (props.match.params.group_id !== this.props.match.params.group_id) {
        this.setState({
          _id: null,
          "logs": [],
          "log_stats": {},
          "profiles": [],
        });
        setTimeout(() => {
          this.update_data(this.props);
        }, 0)
      }
    }
    componentWillMount() {
      this.props.tutorialEvent({action: 'add_campaign_button_click'})
      
    	this.update_data(this.props);
    }


    update_data(props) {
    	var token = localStorage.getItem('user_token');

        // fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/get-group?access_token=${token}&id=${this.props.match.params.group_id}`, {})
        // .then(response => response.json())
        // .then(data => {
        //   if (data.success === false) {
        //     return;
        //   }
        //   data.groups_participation_answers = data.groups_participation_answers || {};
        //   data.membership_answers = data.membership_answers || {};
        	
        //   if (data.membership_questions) {
        //     data.membership_questions.map((q) => {
        //       data.membership_answers[q.id] = data.membership_answers[q.id] || [{
        //         strength: 1,
        //         answer: '',
        //         answers: '',
        //       }];
        //     })
        //   }
        //   if (data.groups_participation_questions) {
        //     data.groups_participation_questions.map((q) => {
        //       data.groups_participation_answers[q.id] = data.groups_participation_answers[q.id] || [{
        //         strength: 1,
        //         answer: '',
        //         answers: '',
        //       }];
        //     })
        //   }

        //   this.setState({
        // 		_id: data._id,
        //     group: data,
        // 	});
        // })
        // .catch(err => {

        // });
        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/get-groups?access_token=${token}&id=${this.props.match.params.group_id}`, {})
        .then(response => response.json())
        .then(groups => {
          if (groups.success === false) {
            return;
          }

          for (var i = 0; i < groups.length; i++) {
            var data = groups[i];
            // console.log(data);
            data.groups_participation_answers = data.groups_participation_answers || {};
            data.membership_answers = data.membership_answers || {};
            
            if (data.membership_questions) {
              data.membership_questions.map((q) => {
                data.membership_answers[q.id] = data.membership_answers[q.id] || [{
                  strength: 1,
                  answer: '',
                  answers: '',
                }];
              })
            }
            if (data.groups_participation_questions) {
              data.groups_participation_questions.map((q) => {
                data.groups_participation_answers[q.id] = data.groups_participation_answers[q.id] || [{
                  strength: 1,
                  answer: '',
                  answers: '',
                }];
              })
            }
          }

          this.setState({
            _id: 'aaa',
            groups: groups,
          });
        })
        .catch(err => {

        });
    }

    handleSaveReplace() {

    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
    var token = localStorage.getItem('user_token');
        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/save-replcae-posts?access_token=${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: this.props.match.params.group_id,
            posts: this.state.posts,
          })
        })
        .then(response => response.json())
        .then(data => {
          NotificationManager.success('success replace');
        })
        .catch(err => {
          NotificationManager.error('Error deleting campaign');

          this.setState({
            error: "Error deplicating campaign",
            success: "",
            password: "",
          });
        })

          }
        },
        {
          label: 'No',
          onClick: () => {

          }
        }
      ]
    });


    }

    handleMergeCampaignClicked() {

    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
    var token = localStorage.getItem('user_token');
        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/merge-campaign?access_token=${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: this.props.match.params.group_id,
            new_name: this.state.new_name,
            merge_campaign: this.state.merge_campaign.value,
          })
        })
        .then(response => response.json())
        .then(data => {
          NotificationManager.success('Deplicate campaign successfully');
          this.props.updateSearch({path: [':lang', 'console'], "new-campaign": Math.random()})
        })
        .catch(err => {
          NotificationManager.error('Error deleting campaign');

          this.setState({
            error: "Error deplicating campaign",
            success: "",
            password: "",
          });
        })

          }
        },
        {
          label: 'No',
          onClick: () => {

          }
        }
      ]
    });



    }
    handleGroupSettingsPopupClicked() {
		  var token = localStorage.getItem('user_token');

      var groups = this.state.groups;


  // for (var i = 0; i < groups.length; i++) {
  //     var b = groups[i];

  //     b.groups_participation_questions = b.groups_participation_questions || [];
  //     b.membership_questions = b.membership_questions || [];
      
  //     var membership_answers = b.membership_answers = b.membership_answers || {};
  //     for (var answer_id in membership_answers) {
  //         var has_answer = membership_answers[answer_id].filter((c) => !c.answer)
  //         if (has_answer) {
  //             var q = b.membership_questions.find((c) => c.id == answer_id);
  //             b.membership_questions.splice(b.membership_questions.indexOf(q), 1)
  //         }
  //     }

  //     var groups_participation_answers = b.groups_participation_answers = b.groups_participation_answers || {};
  //     for (var answer_id in groups_participation_answers) {
  //         var has_answer = groups_participation_answers[answer_id].filter((c) => !c.answer)
  //         if (has_answer) {
  //             var q = b.groups_participation_questions.find((c) => c.id == answer_id);
  //             b.groups_participation_questions.splice(b.groups_participation_questions.indexOf(q), 1)
  //         }
  //     }
  // }

// b.membership_answers
// b.groups_participation_answers

  groups = groups.filter((b) => {
    return Object.keys(b.membership_answers).filter((a) => {
      return b.membership_answers[a].find((c) => c.answer);
    }).length || Object.keys(b.groups_participation_answers).filter((a) => {
      return b.groups_participation_answers[a].find((c) => c.answer);
    }).length
  });



        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/save-group-answers?access_token=${token}`, {
  		    method: 'POST',
  		    headers: {
  		      'Content-Type': 'application/json'
  		    },
  		    body: JSON.stringify({
            groups: groups,
          })
        })
        .then(response => response.json())
        .then(data => {
          NotificationManager.success('Added campaign successfully');

          // this.props.tutorialEvent({action: 'success_create_campaign'})

          // this.props.updateSearch({path: [':lang', 'console'], campaign: data._id, "new-campaign": Math.random()})
        })
        .catch(err => {
        	NotificationManager.error('Error adding campaign');

        	this.setState({
        		error: "Error adding campaign",
        		success: "",
        		password: "",
        	});
        })
    }

    handleDeleteCampaign() {

    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
    var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/delete-campaign?access_token=${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: this.props.match.params.group_id,
          })
        })
        .then(response => response.json())
        .then(data => {
          NotificationManager.success('Deleted campaign successfully');
          this.props.updateSearch({path: [':lang', 'console'], "new-campaign": Math.random()})
        })
        .catch(err => {
          NotificationManager.error('Error deleting campaign');

          this.setState({
            error: "Error deleting campaign",
            success: "",
            password: "",
          });
        })

          }
        },
        {
          label: 'No',
          onClick: () => {

          }
        }
      ]
    });


    }


    handleDeplicateCampaign() {

    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
    var token = localStorage.getItem('user_token');
        let new_name = prompt("What do you want to call the new campaign?", "aaa");
        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/deplicate-campaign?access_token=${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: this.props.match.params.group_id,
            new_name
          })
        })
        .then(response => response.json())
        .then(data => {
          NotificationManager.success('Deplicate campaign successfully');
          this.props.updateSearch({path: [':lang', 'console'], "new-campaign": Math.random()})
        })
        .catch(err => {
          NotificationManager.error('Error deleting campaign');

          this.setState({
            error: "Error deplicating campaign",
            success: "",
            password: "",
          });
        })

          }
        },
        {
          label: 'No',
          onClick: () => {

          }
        }
      ]
    });


    }


    buttons = [
    ];

    extra_options = [
      // {
      //   label: 'Stop Profile Activity',
      //   tool_tip: 'Stop Profile Activity',
      //   action: () => {
      //     this.stopProfileActivity();
      //   },
      //   should_display: () => {
      //     return !this.state.locked_account && this.props.match.params.profile_id;
      //   }
      // },
    ];

    count_replace (text, replace_with, should_replace = false) {
      if (!text) {
        return 0;
      }
      // var search = new RegExp(text, "g");

      var str = JSON.stringify(this.state.posts)

      var count = str.split(text).length - 1;

      // window.alert(count);

      if (should_replace) {
        this.state.posts = JSON.parse(str.split(text).join(replace_with));
        
        this.setState({
          posts: this.state.posts,
        })

        NotificationManager.success(this.props.t('replace done'));
      }

      return count;

      // window.alert(JSON.stringify(this.getCommentgroup().advanced_comments));
    }


    close_path = [':lang', 'console'];
    tabs = [
      {
        label: 'Settings',
        active: true,
        should_display: () => {
          return true;
        },
        content: () => <div>
          <form className={`needs-validation ${this.state.validate_form ? 'was-validated' : ''}`} noValidate="">
            {this.state.groups.length}
            {this.state.groups.map((group) => (<div className="container">
              <h1><a target="_blank" href={`https://facebook.com/${group.group_id}`}>{group.name}</a></h1>
              <h3>{this.props.t('Membership Questions')}</h3>
              {group.membership_questions && group.membership_questions.map((question, question_index) => (<div className="row">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">{question.question}</label>

                  {group.membership_answers[question.id].map((a, index) => (<div>
                    
                    {group.membership_answers[question.id].length > 1 && <button onClick={() => {
                      group.membership_answers[question.id].splice(index, 1);
                      this.setState({ group: group })
                    }} type="button">x</button>}

                    {group.membership_answers[question.id].length > 1 && <div>
                      <label>{this.props.t('Strength')}</label>
                      <input
                        type="number"
                        className="form-control"
                        onChange={event => {group.membership_answers[question.id][index].strength = event.target.value;   this.setState({ group: group })}}
                        value={group.membership_answers[question.id][index].strength}
                      />
                    </div>}

                    {question.question_type === 'PARAGRAPH' && <div>
                      <label>{this.props.t('Answer')}</label>
                      <textarea
                        type="text"
                        style={{height: '100px'}}
                        required={true}
                        className="form-control"
                        placeholder={question.question}
                        onChange={event => {group.membership_answers[question.id][index].answer = event.target.value;   this.setState({ group: group })}}
                        value={group.membership_answers[question.id][index].answer}
                      />
                    </div>}

                    {question.question_type === 'MULTIPLE_CHOICE' && <div>
                      <ul>
                        {question.question_options.map((q) => (<li>
                          <label><input
                                    type="checkbox"
                                    onChange={event => {
                                      
                                      if (group.membership_answers[question.id][index].answer.indexOf(q.id) > -1) {
                                        group.membership_answers[question.id][index].answer = group.membership_answers[question.id][index].answer.split(',');
                                        group.membership_answers[question.id][index].answer.splice(group.membership_answers[question.id][index].answer.indexOf(q.id), 1);
                                        group.membership_answers[question.id][index].answer = group.membership_answers[question.id][index].answer.join(',');
                                      } else {
                                        group.membership_answers[question.id][index].answer = group.membership_answers[question.id][index].answer.split(',');
                                        group.membership_answers[question.id][index].answer.push(event.target.value);
                                        group.membership_answers[question.id][index].answer = group.membership_answers[question.id][index].answer.join(',');
                                      }

                                      this.setState({ group: group })
                                    }}
                                    value={q.id}

                                    checked={group.membership_answers[question.id][index].answer.indexOf(q.id) > -1}
                                    name={q.id}/>{q.question_option}</label>
                        </li>))}
                      </ul>
                    </div>}

                    {question.question_type === 'CHECKBOXES' && <div>
                      <ul>
                        {question.question_options.map((q) => (<li>
                          <label><input
                                    type="checkbox"
                                    onChange={event => {
                                      
                                      if (group.membership_answers[question.id][index].answer.indexOf(q.id) > -1) {
                                        group.membership_answers[question.id][index].answer = group.membership_answers[question.id][index].answer.split(',');
                                        group.membership_answers[question.id][index].answer.splice(group.membership_answers[question.id][index].answer.indexOf(q.id), 1);
                                        group.membership_answers[question.id][index].answer = group.membership_answers[question.id][index].answer.join(',');
                                      } else {
                                        group.membership_answers[question.id][index].answer = group.membership_answers[question.id][index].answer.split(',');
                                        group.membership_answers[question.id][index].answer.push(event.target.value);
                                        group.membership_answers[question.id][index].answer = group.membership_answers[question.id][index].answer.join(',');
                                      }

                                      this.setState({ group: group })
                                    }}
                                    value={q.id}

                                    checked={group.membership_answers[question.id][index].answer.indexOf(q.id) > -1}
                                    name={q.id}/>{q.question_option}</label>
                        </li>))}
                      </ul>
                    </div>}


                  </div>))}
                </div>

                <button className="form-control btn-primary" onClick={() => {
                  group.membership_answers[question.id].push({strength: 1, answer: '', answers: []});
                  this.setState({ group: group })
                }} type="button">{this.props.t('Add Option')}</button>

              </div>))}
              

              <h3>{this.props.t('Participation Questions')}</h3>
              {group.groups_participation_questions && group.groups_participation_questions.map((question, question_index) => (<div className="row">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">{question.participation_question}</label>

                  {group.groups_participation_answers[question.id].map((a, index) => (<div>
                    
                    {group.groups_participation_answers[question.id].length > 1 && <button onClick={() => {
                      group.groups_participation_answers[question.id].splice(index, 1);
                      this.setState({ group: group })
                    }} type="button">x</button>}

                    {group.groups_participation_answers[question.id].length > 1 && <div>
                      <label>{this.props.t('Strength')}</label>
                      <input
                        type="number"
                        className="form-control"
                        onChange={event => {group.groups_participation_answers[question.id][index].strength = event.target.value;   this.setState({ group: group })}}
                        value={group.groups_participation_answers[question.id][index].strength}
                      />
                    </div>}

                    {question.participation_question_type === 'PARAGRAPH' && <div>
                      <label>{this.props.t('Answer')}</label>
                      <textarea
                        type="text"
                        style={{height: '100px'}}
                        required={true}
                        className="form-control"
                        placeholder={question.participation_question}
                        onChange={event => {group.groups_participation_answers[question.id][index].answer = event.target.value;   this.setState({ group: group })}}
                        value={group.groups_participation_answers[question.id][index].answer}
                      />
                    </div>}

                    {question.participation_question_type === 'MULTIPLE_CHOICE' && <div>
                      <ul>
                        {question.participation_question_options.edges.map((q) => (<li>
                          <label><input
                                    type="checkbox"
                                    onChange={event => {
                                      
                                      if (group.groups_participation_answers[question.id][index].answer.indexOf(q.node.id) > -1) {
                                        group.groups_participation_answers[question.id][index].answer = group.groups_participation_answers[question.id][index].answer.split(',');
                                        group.groups_participation_answers[question.id][index].answer.splice(group.groups_participation_answers[question.id][index].answer.indexOf(q.node.id), 1);
                                        group.groups_participation_answers[question.id][index].answer = group.groups_participation_answers[question.id][index].answer.join(',');
                                      } else {
                                        group.groups_participation_answers[question.id][index].answer = group.groups_participation_answers[question.id][index].answer.split(',');
                                        group.groups_participation_answers[question.id][index].answer.push(event.target.value);
                                        group.groups_participation_answers[question.id][index].answer = group.groups_participation_answers[question.id][index].answer.join(',');
                                      }

                                      this.setState({ group: group })
                                    }}
                                    value={q.node.id}

                                    checked={group.groups_participation_answers[question.id][index].answer.indexOf(q.node.id) > -1}
                                    name={q.node.id}/>{q.node.membership_question_option}</label>
                        </li>))}
                      </ul>
                    </div>}
                  </div>))}
                </div>

                <button className="form-control btn-primary" onClick={() => {
                  group.groups_participation_answers[question.id].push({strength: 1, answer: '', answers: []});
                  this.setState({ group: group })
                }} type="button">{this.props.t('Add Option')}</button>

              </div>))}
            </div>))}
          </form>
        </div>,
        footer: () => <div>
          <div className="container">
            <div className="row">
              <button 
                type="submit"
                className="btn btn-warning"
                onClick={this.handleGroupSettingsPopupClicked.bind(this)}>
                  {this.props.t('Save')}
              </button>
            </div>
          </div>
        </div>,
      },
      {
        label: 'Merge',
        active: true,
        should_display: () => {
          return this.props.edit && this.state.master_users.length > 0;
        },
        content: () => <div>
          <form className={`needs-validation ${this.state.validate_form ? 'was-validated' : ''}`} noValidate="">

            <div className="container">
              <div className="row">

                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">{this.props.t('New Campaign Name')} ({this.props.t('Required')})</label>
                  <input
                    type="text"
                    required={true}
                    className="form-control"
                    placeholder={this.props.t('New Campaign Name')}
                    onChange={event => {this.setState({ new_name: event.target.value })}}
                    value={this.state.new_name}
                  />
                </div>
              </div>
              
              {<div className="row">
                <div className="form-group dddd">
                  <label htmlFor="exampleInputEmail1">{this.props.t('Merge with campaign')}</label>
                  <Select
                   className="my-react-select-container"
                   classNamePrefix="my-react-select"
                    name="colors"
                    value={this.state.merge_campaign}
                    options={this.state.campaigns.map((a) => { return {label: a.name, value: a._id}; })}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => { this.props.updateSearch({"merge-campaign": e.value}); this.setState({merge_campaign: {label: this.state.campaigns.find((a) => a._id == e.value).name, value: e.value}  }) }}
                  />
                </div>
              </div>}

            </div>
          </form>
        </div>,
        footer: () => <div>
          <div className="container">
            <div className="row">
              <button 
                type="submit"
                className="btn btn-warning"
                onClick={this.handleMergeCampaignClicked.bind(this)}>
                  {this.props.edit ? this.props.t('Merge') : this.props.t('Merge')}
              </button>
            </div>
          </div>
        </div>,
      },
      {
        label: 'Posts',
        active: true,
        should_display: () => {
          return this.props.edit && this.state.master_users.length > 0;
        },
        content: () => <div>
          <form className={`needs-validation ${this.state.validate_form ? 'was-validated' : ''}`} noValidate="">

            <div className="container">
              {this.state.posts.map((post) => (<div className="row">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">{post.name || post._id}</label>
                  <textarea
                    type="text"
                    required={true}
                    className="form-control"
                    style={{height: '100px'}}
                    placeholder={this.props.t('New Campaign Name')}
                    onChange={event => {post.post_content = event.target.value; this.setState({ posts: this.state.posts })}}
                    value={post.post_content}
                  />
                </div>
              </div>))}
              
            </div>
          </form>
        </div>,
        footer: () => <div>
          <div className="container">
            <div className="row">

                            <div className="form-group">
                              <label style={{color: '#000'}} htmlFor="exampleInputEmail1">{this.props.t('Search')} <span style={{color: 'red'}}>({this.count_replace(this.state.search.search)} matches)</span></label>
                              <input style={{
                                border: '1px solid #ccc'
                              }} className="form-control" placeholder={this.props.t('Search')} value={this.state.search.search} required={true} onChange={event => {this.state.search.search = event.target.value;this.setState({ search: this.state.search })}} id="exampleFormControlTextarea1"/>
                            </div>
                            <div className="form-group">
                              <label style={{color: '#000'}} htmlFor="exampleInputEmail1">{this.props.t('Replace')}</label>
                              <input style={{
                                border: '1px solid #ccc'
                              }} className="form-control" placeholder={this.props.t('Search')} value={this.state.search.replace} required={true} onChange={event => {this.state.search.replace = event.target.value;this.setState({ search: this.state.search })}} id="exampleFormControlTextarea1"/>
                            </div>


                          <div className="btn-group" role="group" aria-label="Basic example">
                          </div>
                          
                          <button className="btn btn-danger" type="button" onClick={this.count_replace.bind(this, this.state.search.search, this.state.search.replace, true)}>{this.props.t('Replace')} ({this.count_replace(this.state.search.search)})</button>

              <button 
                type="submit"
                className="btn btn-warning"
                onClick={this.handleSaveReplace.bind(this)}>
                  {this.props.edit ? this.props.t('Save Replacement') : this.props.t('Save Replacement')}
              </button>



            </div>
          </div>
        </div>,
      }
    ];



    render() {
    	const { t, i18n } = this.props;

      this.props.sidePaneEvent({
        id: `new_campaign_${this.props.match.params.group_id}`,
        buttons: this.buttons,
        extra_options: this.extra_options,
        close_path: this.close_path,
        tabs: this.tabs,
        name: 'create-campaign-popup',
        loading: this.props.match.params.group_id ? !(this.state._id) : false,
        title: this.props.edit ? 'Edit Campaign' : 'Create Campaign',
        //loading: false,
      });

      return (<div></div>);

        return (
		  <div className="overlay">
        <style>{'body {overflow: hidden;}'}</style>
		  </div>
        );
    }
}

GroupSettingsPopup.propTypes = {};

GroupSettingsPopup.defaultProps = {};

export default withRouter(GroupSettingsPopup);









