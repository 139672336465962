import React from 'react';
import PropTypes from 'prop-types';
import styles from './SettingsPopup.module.scss';
import fetch from 'node-fetch';

import settings from '../../assets/icons/settings.svg';
import logo from '../../assets/images/logo.png';
import help from '../../assets/icons/help.svg';
import alert from '../../assets/icons/alert.svg';
import signin from '../../assets/icons/signin.svg';
import clock from '../../assets/icons/clock.svg';
import add from '../../assets/icons/add.svg';
import user1 from '../../assets/images/user1.png';
import user2 from '../../assets/images/user2.png';
import user3 from '../../assets/images/user3.png';
import user4 from '../../assets/images/user4.png';
import post1 from '../../assets/images/post1.png';
import post2 from '../../assets/images/post2.png';
import chat from '../../assets/icons/chat.svg';
import monitor from '../../assets/icons/monitor.svg';
import bin from '../../assets/icons/bin.svg';
import group1 from '../../assets/images/group1.png';
import account from '../../assets/icons/account.svg';
import password from '../../assets/icons/password.svg';
import like from '../../assets/icons/like.svg';
import follower from '../../assets/icons/follower.svg';

import Switch from "@material-ui/core/Switch";

import {Button} from 'react-bootstrap/Button';
import {DropdownButton, ButtonGroup, Dropdown} from 'react-bootstrap';

import {NotificationContainer, NotificationManager} from 'react-notifications';

import { useTranslation, initReactI18next, withTranslation } from 'react-i18next';

import { SketchPicker } from 'react-color';

import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  withRouter,
  useParams,
  useLocation
} from "react-router-dom";

import queryString from 'query-string';

class SettingsPopup extends React.Component {

    state = {
    	"key": "",
      settings: null,
      health_check: {},
      // "sabbath_mode": true,
      "download_link": "",
    }

    refresh() {
    }
    componentWillReceiveProps() {
    }
    componentWillMount() {
    	this.update_data(this.props);
    }


    save_settings(props) {

      var token = localStorage.getItem('user_token');
      var query = queryString.parse(this.props.location.search);

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/save-settings?access_token=${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(this.state.settings)
        })
        .then(response => response.json())
        .then(data => {

          NotificationManager.success('success', 'saved_settings');

        })
        .catch(err => {

        });

    }

    update_data(props) {
      var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/health-check?access_token=${token}`, {})
        .then(response => response.json())
        .then(data => {
          this.setState({
            health_check: data,
          });
        })
        .catch(err => {

        });;
    }

    set_program_settings(props) {
      var token = localStorage.getItem('user_token');
        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/set-program-settings?access_token=${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            setttings_object: this.state.health_check,
          })
        })
        .then(response => response.json())
        .then(data => {
          NotificationManager.success('Updated Settings');
        })
        .catch(err => {
          NotificationManager.error('Error Updated Settings');
        })


    }

    handleDeleteCampaign() {
		    var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/delete-campaign?access_token=${token}`, {
  		    method: 'POST',
  		    headers: {
  		      'Content-Type': 'application/json'
  		    },
  		    body: JSON.stringify({
  		    	id: this.props.match.params.campaign_id,
  		    })
        })
        .then(response => response.json())
        .then(data => {
          NotificationManager.success('Deleted campaign successfully');
          this.props.updateSearch({path: [':lang', 'console'], "new-campaign": Math.random()})
        })
        .catch(err => {
        	NotificationManager.error('Error deleting campaign');

        	this.setState({
        		error: "Error deleting campaign",
        		success: "",
        		password: "",
        	});
        })
    }

    render() {
    	const { t, i18n } = this.props;

        return (
		  <div className="overlay">
        <style>{'body {overflow: hidden;}'}</style>
              {<div className="bg-primary p-5 text-white text-center rounded-3 " id="myForm">
                  <div className="pb-5 position-relative" id="signin_header">
                    <h2 className="fs-5 fw-bolder text-uppercase text-center">{t('Program Settings')} - {this.state.health_check.version}</h2>
                    <a type="button" className="btn text-white close_btn fs-2 fw-bolder" onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console']})}>×</a>
                  </div>









<div>

      <div className="row">
        <div className="col-lg mb-4 col-sm-6 col-md-6">
          <div className="stats-small stats-small--1 card card-small">
            <div className="p-0 d-flex card-body">
              <div className="chartjs-size-monitor">
                <div className="chartjs-size-monitor-expand">
                  <div className />
                </div>
                <div className="chartjs-size-monitor-shrink">
                  <div className />
                </div>
              </div>
              <div className="d-flex flex-column m-auto">
                <div className="stats-small__data text-center application-status">
                  <span className="stats-small__label text-uppercase" style={{color: 'red'}}>{t('Status')}</span>

                  <hr/>

                  {this.state.health_check && !this.state.health_check.started && <button onClick={() => {
                    this.state.health_check.started = !this.state.health_check.started;
                    this.setState({health_check: this.state.health_check});
                    this.set_program_settings();
                  }} type="button" className="btn btn-success">
                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-play" viewBox="0 0 16 16">
                      <path d="M10.804 8 5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z" />
                    </svg>
                    התחל
                  </button>}

                  {this.state.health_check && this.state.health_check.started && <button onClick={() => {
                    this.state.health_check.started = !this.state.health_check.started;
                    this.setState({health_check: this.state.health_check});
                    this.set_program_settings();
                  }} type="button" className="btn btn-danger">
                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-pause" viewBox="0 0 16 16">
                      <path d="M6 3.5a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5zm4 0a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5z" />
                    </svg>
                    הפסק
                  </button>}


                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg mb-4 col-sm-6 col-md-6">
          <div className="stats-small stats-small--1 card card-small">
            <div className="p-0 d-flex card-body">
              <div className="chartjs-size-monitor">
                <div className="chartjs-size-monitor-expand">
                  <div className />
                </div>
                <div className="chartjs-size-monitor-shrink">
                  <div className />
                </div>
              </div>
              <div className="d-flex flex-column m-auto define-browsers">
                {this.state.health_check && <div className="stats-small__data text-center">
                  <span className="stats-small__label text-uppercase" style={{color: 'red'}}>{t('Browsers')}</span>

                  <hr/>

                  <h6 className="stats-small__value count my-3" style={{color: 'blue', 'font-size': '30px'}}>{this.state.health_check.simultaneous}</h6>

                  <button onClick={() => {
                    this.state.health_check.simultaneous++;
                    this.setState({health_check: this.state.health_check});
                    this.set_program_settings();
                  }} disabled={this.state.health_check.started ? 'disabled': false} type="button" className="btn btn-success">+</button>
                  <button onClick={() => {
                    this.state.health_check.simultaneous--;
                    this.state.health_check.simultaneous = Math.max(1, this.state.health_check.simultaneous);
                    this.setState({health_check: this.state.health_check});
                    this.set_program_settings();
                  }} disabled={this.state.health_check.started ? 'disabled': false} type="button" className="btn btn-danger">-</button>

                </div>}
              </div>
            </div>
          </div>
        </div>
        </div>

</div>



















              </div>}

		  </div>
        );
    }
}

SettingsPopup.propTypes = {};

SettingsPopup.defaultProps = {};

export default withRouter(SettingsPopup);









