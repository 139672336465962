import React from 'react';
import PropTypes from 'prop-types';
import styles from './ManageGroupLibraries.module.scss';
import fetch from 'node-fetch';
import _ from 'lodash';

import settings from '../../assets/icons/settings.svg';
import logo from '../../assets/images/logo.png';
import help from '../../assets/icons/help.svg';
import alert from '../../assets/icons/alert.svg';
import signin from '../../assets/icons/signin.svg';
import clock from '../../assets/icons/clock.svg';
import add from '../../assets/icons/add.svg';
import user1 from '../../assets/images/user1.png';
import user2 from '../../assets/images/user2.png';
import user3 from '../../assets/images/user3.png';
import user4 from '../../assets/images/user4.png';
import post1 from '../../assets/images/post1.png';
import post2 from '../../assets/images/post2.png';
import chat from '../../assets/icons/chat.svg';
import monitor from '../../assets/icons/monitor.svg';
import bin from '../../assets/icons/bin.svg';
import group1 from '../../assets/images/group1.png';
import account from '../../assets/icons/account.svg';
import password from '../../assets/icons/password.svg';
import like from '../../assets/icons/like.svg';
import follower from '../../assets/icons/follower.svg';

import {Button} from 'react-bootstrap/Button';
import {DropdownButton, ButtonGroup, Dropdown} from 'react-bootstrap';

import {NotificationContainer, NotificationManager} from 'react-notifications';

import { useTranslation, initReactI18next, withTranslation } from 'react-i18next';

import { SketchPicker } from 'react-color'

import {FaGlobe, FaTools} from "react-icons/fa";

import { confirmAlert } from 'react-confirm-alert'; // Import

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  withRouter,
  useParams,
  useLocation
} from "react-router-dom";

import queryString from 'query-string';

function nFormatter(num, digits) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function(item) {
    return num >= item.value;
  });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}


class ManageGroupLibraries extends React.Component {

    state = {
      "groups": [],
      "public_libraries": [],
      "selected": null,
      "amount_to_display": 300,
      "search_text": '',
      // "sort_by": 'follow_count',
      "sort_by": 'users_in_group_count',
      "profiles": [],
      "selected_campaign": queryString.parse(this.props.location.search).campaign,
      "profile_group": {},
      "loading": true,
      "filter_query": {
        by_selected: {
          value: 'all',
          query: {},
        },
      },
      "campaigns": null,
    }

    refresh() {
    }
    componentWillReceiveProps() {
    }
    componentWillMount() {
      this.props.tutorialEvent({action: 'open_manage_group_library_popup'})

      this.update_data(this.props);
    }


    update_data(props) {
    	var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/group-libraries?all=true&access_token=${token}`, {})
        .then(response => response.json())
        .then(data => {
        	this.setState({
        		libraries: data,
        	});
            this._sort();
        })
        .catch(err => {

        });

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/group-libraries?public=true&access_token=${token}`, {})
        .then(response => response.json())
        .then(data => {
          this.setState({
            public_libraries: data,
          });
            this._sort();
        })
        .catch(err => {

        });

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/campaigns?access_token=${token}`, {})
        .then(response => response.json())
        .then(data => {


          this.setState({
            campaigns: data,
          });
        })
        .catch(err => {

        });

      fetch(`${process.env.NODE_ENV !== 'development' ? "/admin" : "/admin"}/master/is-admin?access_token=${token}`, {})
        .then(response => response.json())
        .then(data => {
          this.setState({
            is_admin: data.success,
          });
        })
        .catch(err => {

        });



    }

    duplicateGlobalLibrary(lib) {
      window.alert(1234);
    }

    removeGlobalLibrary(lib) {

        confirmAlert({
          title: 'Confirm to remove global library',
          message: 'Are you sure to do this.',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                var token = localStorage.getItem('user_token');
                
                fetch(`${process.env.NODE_ENV !== 'development' ? "/admin" : "/admin"}/master/remove-global-library?access_token=${token}&lib-id=${lib._id}`, {})
                  .then(response => response.json())
                  .then(data => {
                    NotificationManager.success('remove global group successfully');
                    this.update_data();
                  })
                  .catch(err => {

                  });

              }
            },
            {
              label: 'No',
              onClick: () => {

              }
            }
          ]
        });




    }
    selectPublicLibrary(lib) {
        var token = localStorage.getItem('user_token');
        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/select-global-group-library?all=true&access_token=${token}&lib-id=${lib._id}`, {})
        .then(response => response.json())
        .then(data => {
          NotificationManager.success('Success Selecting global library');
          lib.selected = true;
          this.setState({})
        })
        .catch(err => {
          NotificationManager.error('Error Selecting global library');
        });
    }
    unselectPublicLibrary(lib) {
        var token = localStorage.getItem('user_token');
        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/unselect-global-group-library?all=true&access_token=${token}&lib-id=${lib._id}`, {})
        .then(response => response.json())
        .then(data => {
          NotificationManager.success('Success Selecting global library');
          lib.selected = false;
          this.setState({})
        })
        .catch(err => {
          NotificationManager.error('Error Selecting global library');
        });
    }
    createGlobalLibrary(lib) {

        confirmAlert({
          title: 'Confirm to create global library',
          message: 'Are you sure to do this.',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                var token = localStorage.getItem('user_token');
                
                fetch(`${process.env.NODE_ENV !== 'development' ? "/admin" : "/admin"}/master/create-global-library?access_token=${token}&lib-id=${lib._id}`, {})
                  .then(response => response.json())
                  .then(data => {
                    NotificationManager.success('Added group successfully');
                    this.update_data();
                  })
                  .catch(err => {

                  });

              }
            },
            {
              label: 'No',
              onClick: () => {

              }
            }
          ]
        });




    }

    getDisplayComments(libs = [], sort_by, search_text, amount_to_display, filter_by_profile, filter_query, selected) {

      search_text = search_text || '';

      libs.map((g) => {
        g.follow_count = g.follow_count | 0;
      })

      var groups = _.orderBy(libs, [sort_by, 'follow_count', 'today_slot_count', 'users_in_group_count'],['desc'])

      return groups.filter((group) => {
        group.name = group.name || "";
          return group.name.toLowerCase().indexOf(search_text.toLowerCase()) > -1;
        })
        .slice(0,amount_to_display)

    }

    buttons = [
      // {
      //   label: 'Add New',
      //   tool_tip: 'Add New',
      //   class: 'add-new-group-library',
      //   action: () => {
      //     this.props.updateSearch({path: [':lang', 'console','group-library', 'add']});
      //   },
      //   should_display: () => {
      //     return true;
      //   }
      // },       
    ];

    extra_options = [
      // {
      //   label: 'Stop Profile Activity',
      //   tool_tip: 'Stop Profile Activity',
      //   action: () => {
      //     this.stopProfileActivity();
      //   },
      //   should_display: () => {
      //     return !this.state.locked_account && this.props.match.params.profile_id;
      //   }
      // },
    ];

    close_path = [':lang', 'console'];
    tabs = [
      {
        label: 'Settings',
        active: true,
        should_display: () => {
          return true;
        },
        content: () => <div>
          <div className="container">
            <div className="row">
                <h4>{this.props.t('Custom Libraries')} <FaTools/></h4>
                  <div className="search-section">
                    <input 
                      placeholder={this.props.t('Search Libraries')} 
                      type="text" 
                      value={this.state.search_text} 
                      onChange={(e) => {this.setState({search_text: e.target.value})}}
                    />
                  </div>

                  <div className="group-info"><strong>{this.props.t(`Showing`)}:</strong> {this.state.libraries.filter((group) => {group.name = group.name || ''; return group.name.toLowerCase().indexOf(this.state.search_text.toLowerCase()) > -1;}).length} {this.props.t(`groups`)} {this.props.t('of')} {this.state.libraries && this.state.libraries.length}</div>

                  {this.getDisplayComments(this.state.libraries, this.state.sort_by, this.state.search_text, this.state.amount_to_display, this.state.filter_by_profile, this.state.filter_query, this.state.selected).map((group) => (<div className={`text-center ${queryString.parse(this.props.location.search)['added-library'] === group._id ? 'saved-group-library' : ''}`} style={{padding: '13px', width: '12.5%', float: 'right'}}>

                  <div className="icon-container">
                  <span dir="ltr" className={`text-decoration-none my-1 d-block group text-center` } style={{paddingBottom: '0', overflow: 'inherit'}}>
                      <div style={{'height': '80px', position: 'relative'}}>
                        {[group.group_pictures[0] || 'sss'].map((g, index) => (
                          <img data-tip={g.name} style={{margin: 'auto', width: '80px', height: '80px'}} className="group-item-pick group-image img img-thumbnail img-fluid rounded rounded-circle " src={g.image_url} g="img/group1.png" alt=""/>))}
                      </div>
                  </span>

                    <div style={{height: '49px'}}>
                      {group.name} ({nFormatter(group.total_group_count, 2)})
                    </div>
                  </div>

                    <button className={`btn btn-primary`} onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console','group-library', 'edit', group._id]})} >{this.props.t('Edit')}</button>
                    

                    {/*this.state.is_admin && <button className={`btn btn-warning`} onClick={this.duplicateGlobalLibrary.bind(this)} >{this.props.t('Duplicate')}</button>*/}





                    {this.state.is_admin && !group.is_public && <button className={``} onClick={this.createGlobalLibrary.bind(this, group)} >{this.props.t('Create Global Library')}</button>}

                  </div>))}
            </div>

            <hr/>

            <div className="row">
                <h4>{this.props.t('Global Libraries')} <FaGlobe/></h4>
                  <div className="search-section">
                    <input 
                      placeholder={this.props.t('Search Libraries')} 
                      type="text" 
                      value={this.state.search_text} 
                      onChange={(e) => {this.setState({search_text: e.target.value})}}
                    />
                  </div>

                  <div className="group-info"><strong>{this.props.t(`Showing`)}:</strong> {this.state.libraries.filter((group) => {group.name = group.name || ''; return group.name.toLowerCase().indexOf(this.state.search_text.toLowerCase()) > -1;}).length} {this.props.t(`groups`)} {this.props.t('of')} {this.state.libraries && this.state.libraries.length}</div>

                  {this.getDisplayComments(this.state.public_libraries, this.state.sort_by, this.state.search_text, this.state.amount_to_display, this.state.filter_by_profile, this.state.filter_query, this.state.selected).map((group) => (<div className={`text-center ${queryString.parse(this.props.location.search)['added-library'] === group._id ? 'saved-group-library' : ''}`} style={{padding: '13px', width: '12.5%', float: 'right'}}>

                  <span dir="ltr" className={`text-decoration-none my-1 d-block group text-center` } style={{paddingBottom: '0', overflow: 'inherit'}}>
                      <div style={{'height': '80px', position: 'relative'}}>
                        {[group.group_pictures[0] || 'sss'].map((g, index) => (
                          <img data-tip={g.name} style={{margin: 'auto', width: '80px', height: '80px'}} className="group-item-pick group-image img img-thumbnail img-fluid rounded rounded-circle " src={g.image_url} g="img/group1.png" alt=""/>))}
                      </div>
                    {/*<div className="">
                      <span className="position-absolute top-50 start-50 translate-middle bg-dark-75 w-75 h-75">
                        <p className=" position-absolute top-50 start-50 translate-middle w-100 text-center text-white fw-bolder">{group.name}</p>
                      </span>
                    </div>
                    <span className="badge bg-info px-2 py-2 small text-white rounded-pill position-25">
                      <img className="d-inline mx-1 h-icon" width="5px" src="/static/media/follower.e4d49cda.svg" g="icons/follower.svg" />{nFormatter(group.facebook_groups.length, 2)}
                    </span>*/}
                    </span>
                    <div style={{height: '49px'}}>
                      {group.name} ({nFormatter(group.total_group_count, 2)})
                    </div>

                    {!group.selected && <button className={`select-button btn btn-primary`} onClick={this.selectPublicLibrary.bind(this, group)} >{this.props.t('Select')}</button>}
                    {group.selected && <button style={{background: 'red'}} className={`btn btn-primary`} onClick={this.unselectPublicLibrary.bind(this, group)} >{this.props.t('Unselect')}</button>}
                    
                    {this.state.is_admin && <button className={``} onClick={this.removeGlobalLibrary.bind(this, group)} >{this.props.t('Remove Global Library')}</button>}

                  </div>))}
            </div>
          </div>
        </div>,
        footer: () => <div>

  

          <div className="container">
            <div className="row add-new-group-library">

              {<button onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console','group-library', 'add']})} type="submit" className="btn btn-warning">{this.props.t('Add New')}</button>}

            </div>
          </div>


        </div>,
      },
    ];



    render() {
      const { t, i18n } = this.props;

      this.props.sidePaneEvent({
        id: `manage_group_libraries`,
        buttons: this.buttons,
        extra_options: this.extra_options,
        close_path: this.close_path,
        tabs: this.tabs,
        name: 'create-user-popup',
        loading: !this.state.libraries || !this.state.libraries,
        title: 'Manage Group Libraries',
        sub_title: null,
        // loading: false,
      });
      return (<div></div>);
    }
}

ManageGroupLibraries.propTypes = {};

ManageGroupLibraries.defaultProps = {};

export default withRouter(ManageGroupLibraries);









