import React from 'react';
import PropTypes from 'prop-types';
import styles from './Footer.module.scss';


class Footer extends React.Component {
    state = {
      selected_question: {
        section_index: null,
        question_index: null,
      },
      sections: [
        {
          title: 'Section 1',
          questions: [
            {
              q: 'how can i do that?',
              a: 'Very easy just click here then here'
            },
            {
              q: 'how can i do that?',
              a: 'Very easy just click here then here'
            },
            {
              q: 'how can i do that?',
              a: 'Very easy just click here then here'
            },
            {
              q: 'how can i do that?',
              a: 'Very easy just click here then here'
            },
          ]
        },
        {
          title: 'Section 2',
          questions: [
            {
              q: 'how can i do that?',
              a: 'Very easy just click here then here'
            },
            {
              q: 'how can i do that?',
              a: 'Very easy just click here then here'
            },
            {
              q: 'how can i do that?',
              a: 'Very easy just click here then here'
            },
            {
              q: 'how can i do that?',
              a: 'Very easy just click here then here'
            },
          ]
        },
        {
          title: 'Section 3',
          questions: [
            {
              q: 'how can i do that?',
              a: 'Very easy just click here then here'
            },
            {
              q: 'how can i do that?',
              a: 'Very easy just click here then here'
            },
            {
              q: 'how can i do that?',
              a: 'Very easy just click here then here'
            },
            {
              q: 'how can i do that?',
              a: 'Very easy just click here then here'
            },
          ]
        },
      ],
    }

    update_data(props) {

    }

    refresh() {

    }

    update_data(props) {
    }

    componentWillReceiveProps(props) {
    }
    
    componentWillMount(props) {
    }

    componentWillUnmount(){
    }

    render() {
      const { t, i18n } = this.props;

      return (

          <footer className="site-footer footer-theme-two">
            <div className="container">
              <div className="main-footer style-dark">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="widget">
                      <div className="text-widget">
                        <div className="about-info">
                          <div className="image-wrapper">
                            <img src="/images/d-code-logo-light.svg" alt="" />
                          </div>
                          <p>{t("FBzipper is the best automatic software")} <br /> {t("In the world of advertising on Facebook that will close your corner!")}</p>
                        </div>
                        {/*                                         <div class="playstore-widget">
                                            <h3>הורידו את האפליקציה</h3>
                                            <ul>
                                                <li class="google"><a href="#">Google Play</a></li>
                                                <li class="apple"><a href="#">App Store</a></li>
                                            </ul>
                                        </div>
 */}                                  </div>
                    </div>{/* End Widget */}
                  </div>{/* End Col */}
                  <div className="col-lg-2">
                    <div className="widget">
                      <div className="widget-title">
                        <h3 className="title">{t("Useful Links")}</h3>
                      </div>
                      <div className="text-widget">
                        <div className="footer-nav">
                          <ul>
                            <li><a onClick={this.props.updateSearch.bind(this, {path: [':lang', 'contact']})}>{t("Contact Us")}</a></li>
                            <li><a onClick={this.props.updateSearch.bind(this, {path: [':lang', 'terms-of-service']})}>{t("Regulations and terms of use")}</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>{/* End Widget */}
                  </div>{/* End Col */}
                  <div className="col-lg-2">
                    <div className="widget">
                      <div className="widget-title">
                        <h3 className="title">{t("support")}</h3>
                      </div>
                      <div className="text-widget">
                        <div className="footer-nav">
                          <ul>
                            {/*<li><a href="#contact">מדריכים</a></li>*/}
                            <li><a onClick={this.props.updateSearch.bind(this, {path: [':lang', 'faq']})}>{t("Common questions")}</a></li>
                            <li><a onClick={this.props.updateSearch.bind(this, {path: [':lang', 'plans']})}>{t("to purchase the software")}</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>{/* End Widget */}
                  </div>{/* End Col */}
                  <div className="col-lg-4">
                    <div className="widget">
                      <div className="widget-title">
                        <h3 className="title">{t("Stay tuned")}</h3>
                      </div>
                      <div className="text-widget">
                        <div className="contact-info">
                          <ul>
                            <li className="email-field">support@fbzipper.com</li>
                            <li className="phone-field"><strong>{t("management board -")} </strong> <a href="tel:0722210190">072-2210190</a></li>
                            <li className="phone-field"><strong>{t("Customer Service -")} </strong> <a href="tel:0722210190">072-2210190</a></li>
                          </ul>
                        </div>
                        {/*                                         <div class="social-media-links">
                                            <ul>
                                                <li><a target="_blank" href="https://www.facebook.com/sacredthemes/"><i class="fab fa-facebook-f"></i></a></li>
                                                <li><a target="_blank" href="https://www.linkedin.com/company/sacredthemes/"><i class="fab fa-linkedin-in"></i></a></li>
                                                <li><a target="_blank" href="https://twitter.com/SacredThemes"><i class="fab fa-twitter"></i></a></li>
                                            </ul>
                                        </div>
 */}                                  </div>
                    </div>{/* End Widget */}
                  </div>{/* End Col */}
                </div>{/* End Row */}
              </div>
              <div className="copyright-bar style-dark">
                <div className="copyright-text text-center" style={{direction: 'ltr'}}>
                  © Copyright FBzipper 2022. Made with <i className="fas fa-heart" /> by <strong>FBzipper group</strong>.
                </div>
              </div>
            </div>
          </footer>

      );
    }
}

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;