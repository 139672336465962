import React from 'react';
import PropTypes from 'prop-types';
import styles from './Posts.module.scss';
import fetch from 'node-fetch';

import settings from '../../assets/icons/settings.svg';
import logo from '../../assets/images/logo.png';
import help from '../../assets/icons/help.svg';
import alert from '../../assets/icons/alert.svg';
import signin from '../../assets/icons/signin.svg';
import clock from '../../assets/icons/clock.svg';
import add from '../../assets/icons/add.svg';
import user1 from '../../assets/images/user1.png';
import user2 from '../../assets/images/user2.png';
import user3 from '../../assets/images/user3.png';
import user4 from '../../assets/images/user4.png';
import post1 from '../../assets/images/post1.png';
import post2 from '../../assets/images/post2.png';
import chat from '../../assets/icons/chat.svg';
import monitor from '../../assets/icons/monitor.svg';
import bin from '../../assets/icons/bin.svg';
import group1 from '../../assets/images/group1.png';
import account from '../../assets/icons/account.svg';
import password from '../../assets/icons/password.svg';
import like from '../../assets/icons/like.svg';
import follower from '../../assets/icons/follower.svg';

import {Button} from 'react-bootstrap/Button';
import {DropdownButton, ButtonGroup, Dropdown} from 'react-bootstrap';

import { useTranslation, initReactI18next, withTranslation } from 'react-i18next';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  withRouter,
  useLocation
} from "react-router-dom";

import queryString from 'query-string';

import {FaComment } from "react-icons/fa";

import { Post } from "./Post";

class Posts extends React.Component {

    state = {
    	posts: [],
    	selected: null,
    	latest_new: null,
    }

    refresh() {

    }

    update_data(props) {
    	var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/posts?access_token=${token}&campaign=${this.state.selected}&merge=${this.state.latest_new_merge || ''}`, {})
        .then(response => response.json())
        .then(data => {
        	this.setState({
        		posts: data,
        	});
        })
        .catch(err => {

        });
    }

    componentWillReceiveProps(props) {

        var query = queryString.parse(props.location.search);
        var old_query = queryString.parse(this.props.location.search);
    	this.setState({
    		selected: query.campaign,
    	});

    	setTimeout(() => {		
	    	if (old_query.campaign !== query.campaign || (query.campaign && !this.state.selected)) {
	    		this.update_data(props);
	    	}

	        if (query['new-post'] && this.state.latest_new !== query['new-post']) {
	            this.setState({
	                latest_new: query['new-post'],
	            });

	            this.update_data(props);
	        }
          if (query['merge-campaign'] && this.state.latest_new_merge !== query['merge-campaign']) {
              this.setState({
                  latest_new_merge: query['merge-campaign'],
              });

              this.update_data(props);
          }
            this.setState({
                selected_post: query['post'],
            });


    	}, 0)
    }

    componentWillMount(props) {
        var query = queryString.parse(this.props.location.search);
      	
        this.setState({
      		selected: query.campaign,
      	});
		
  		  setTimeout(() => {	
  			 this.update_data(this.props);	
      	}, 0)
    }

    render() {
    	const { t, i18n } = this.props;
        return (
			<React.Fragment>

              <div className="btn-group d-flex" role="group">
                {/*<button disabled={!this.state.selected && "disabled"} onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console','comment-library']})} type="button" className="btn btn-secondary" style={{padding: '0px 10px'}}><FaComment/></button>*/}
                {this.state.selected && <span className="create-new-post manage-posts-button w-100" onClick={this.props.tutorialEvent.bind(this, {action: 'add_post_button_click'})}>
                  <button disabled={!this.state.selected && "disabled"} style={{padding: '0px 0px', marginRight: '0px'}} onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console','post', 'add']})} className="btn btn-primary w-100">{t('Add New Post')}</button>
                </span>}
              </div>

              <div className="btn-group d-flex" role="group">
                {<span className="create-new-multi-schedule manage-posts-button w-100" onClick={this.props.tutorialEvent.bind(this, {action: 'add_multi_schedule'})}>
                  <button style={{padding: '0px 0px', marginRight: '0px', 'background': '#027c02','marginTop': '16px'}} onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console','multiple-group-camaign']})} className="btn btn-primary w-100">{t('Schedule posts now')}</button>
                </span>}
              </div>



              {!this.state.selected && <div className="error">
                  {t('You must choose a campaign')}
              </div>}

              {/*<select value={this.state.sort_by} onChange={(e) => { this.setState({sort_by: e.target.value}); setTimeout(() => this._sort(), 1); }} className="form-select bg-primary  rounded-3 text-white py-3 mb-4 px-1 group" aria-label="Default select example">
                  <option>{t('Sort By')}</option>
                    <option value={'today_slot_count'}>{t('Slot Count')}</option>
                    <option value={'users_in_group_count'}>{t('User in group')}</option>
                  <option value={'follow_count'}>{t('Followers')}</option>
                  <option value={'pending_users_in_group_count'}>{t('Pending')}</option>
                  <option value={'pending_participation_users_in_group_count'}>{t('Pending Participation')}</option>
                  <option value={'blocked_users_in_group_count'}>{t('Blocked')}</option>
              </select>*/}
              
              {/*<div className="search-section">
                <input style={{maxWidth: '100%'}} placeholder={t('Search Posts')} type="text" value={this.state.search_text} onChange={(e) => {this.setState({search_text: e.target.value})}}/>
              </div>*/}

              {this.state.selected && <div className="group-info"><strong>{t(`Showing`)}:</strong> {this.state.posts.length} {t(`posts`)} {t('of')} {this.state.posts && this.state.posts.length}</div>}



				{/*<a className="d-block m-1 cadre border border-2 border-secondary text-center position-relative" onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console','post', 'add']})}>
					<span className="text-decoration-none text-secondary position-absolute top-50 start-50 w-100 translate-middle">
					<img className="m-1" width="25px" src={add} g="icons/add.svg" alt="" /> {t('Add New Post')}
					</span>
				</a>*/}
              <div dir="rtl" className="posts-container">
                  <div className="px-2 mh-75 overflow-auto">
            				{this.state.posts.map((post, index) => (
            					<React.Fragment>
            						<Post
                            t={t}
                            name={"hello"}
                            different_campaign={post && post.category && this.state.selected != post.category._id}
                            index={index + 1}
                            type={"post"}
                            key={"ggggggggg"}
                            post={post}
                            selected_post={this.state.selected_post === post._id}
                            updateSearch={this.props.updateSearch}
                            	    />
                      }

            					</React.Fragment>
            				))}
                  </div>
              </div>
			</React.Fragment>
        );
    }
}

Posts.propTypes = {};

Posts.defaultProps = {};

export default Posts;










