import React from 'react';
import PropTypes from 'prop-types';
import styles from './CreateGroupLibrary.module.scss';
import fetch from 'node-fetch';
import { confirmAlert } from 'react-confirm-alert'; // Import

import settings from '../../assets/icons/settings.svg';
import logo from '../../assets/images/logo.png';
import help from '../../assets/icons/help.svg';
import alert from '../../assets/icons/alert.svg';
import signin from '../../assets/icons/signin.svg';
import clock from '../../assets/icons/clock.svg';
import add from '../../assets/icons/add.svg';
import user1 from '../../assets/images/user1.png';
import user2 from '../../assets/images/user2.png';
import user3 from '../../assets/images/user3.png';
import user4 from '../../assets/images/user4.png';
import post1 from '../../assets/images/post1.png';
import post2 from '../../assets/images/post2.png';
import chat from '../../assets/icons/chat.svg';
import monitor from '../../assets/icons/monitor.svg';
import bin from '../../assets/icons/bin.svg';
import group1 from '../../assets/images/group1.png';
import account from '../../assets/icons/account.svg';
import password from '../../assets/icons/password.svg';
import like from '../../assets/icons/like.svg';
import follower from '../../assets/icons/follower.svg';
import Multiselect from 'multiselect-react-dropdown';

import {Button} from 'react-bootstrap/Button';
import {DropdownButton, ButtonGroup, Dropdown} from 'react-bootstrap';

import {NotificationContainer, NotificationManager} from 'react-notifications';
import { useTranslation, initReactI18next, withTranslation } from 'react-i18next';

import { SketchPicker } from 'react-color';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  withRouter,
  useParams,
  useLocation
} from "react-router-dom";

import queryString from 'query-string';

import { FaImage, FaImages, FaRegNewspaper, FaVideo, FaExternalLinkSquareAlt, FaEquals, FaCube, FaDollarSign, FaFemale, FaMale, FaUsers } from "react-icons/fa";

import Dropzone from 'react-dropzone-uploader'

import 'react-dropzone-uploader/dist/styles.css'

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

const PrettoSlider = withStyles({
  root: {
    color: '#52af77',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const useStyles = makeStyles({
  root: {
    width: 300,
  },
});

function valuetext(value) {
  return `${value}°C`;
}

class CreateGroupLibrary extends React.Component {

    state = {
      "error": "",
      "success": "",
      "selected": null,
      "post": null,
      "_comments": [],
      "test": [],
      "images": [],
      "autoUpload": false,
      "view_state": '',
      "import": '[]',
      "campaigns": null,
    }

    refresh() {
    }
    componentWillReceiveProps() {
    }
    componentWillMount() {
      this.update_data(this.props);

      this.test()
    }

    async test() {
      var result = await fetch('https://abazol.ams3.digitaloceanspaces.com/zipper/1624305001228');

      // console.log(325235, new File([await result.arrayBuffer()], 'image_data_url.jpg', { type: 'image/jpeg' }) )

      this.setState({test: [new File([await result.arrayBuffer()], 'image_data_url.jpg', { type: 'image/jpeg' })]});

      // await new Promise(() => {
      //  console.log(4444)
      //  setTimeout(() => {
      //    this.setState({autoUpload: true});
      //  }, 3000)
      // })
    }


    add_comment(array) {
      (array || this.state.post.advanced_comments).push({
        text: "",
        gender: "ALL",
        reactions: {
          like: 0,
          love: 0,
          care: 0,
          haha: 0,
          wow: 0,
          sad: 0,
          angry: 0,
        },
        reply: []
      })

      this.setState({
        post: this.state.post,
      })
    }
    remove_comment(array, item) {

      array.splice(array.indexOf(item), 1);
      this.setState({
        post: this.state.post,
      })

    }
    reaction (reactiom_obj, type, e) {

      if (e.nativeEvent && e.nativeEvent.which === 3) {

        reactiom_obj[type] -= 1;

        if (reactiom_obj[type] < 0) {
          reactiom_obj[type] = 0;
        }
        this.setState({
          post: this.state.post,
        });


        console.log('Right click');
        e.preventDefault()
      } else {
        reactiom_obj[type] += 1;
        this.setState({
          post: this.state.post,
        });
        console.log('Left click');
      }


    }

    update_data(props) {
      var token = localStorage.getItem('user_token');

        (async () => {
          var response = await fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/campaigns?access_token=${token}`, {});
          var campaigns = await response.json();

          if (!this.props.edit) {

            await this.setState({
              post: {
                name: '',
                advanced_comments: [{
                  text: "",
                  gender: "ALL",
                  reactions: {
                    like: 0,
                    love: 0,
                    care: 0,
                    haha: 0,
                    wow: 0,
                    sad: 0,
                    angry: 0,
                  },
                  reply: []
                }],
              },
            });

            this.state.post.campaigns = campaigns.map( (campaign) => {
              return {
                name: `${campaign.name}`,
                id: campaign._id,
              }
            } ).filter((campaign) => {
              return campaign.id == queryString.parse(this.props.location.search).campaign
            });
          }


          this.setState({
            campaigns: campaigns,
            post: this.state.post,
          });


          if (this.props.edit) {
            fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/get-comment-group?access_token=${token}&id=${this.props.match.params.comment_id}`, {})
            .then(response => response.json())
            .then(data => {
              data.campaigns = campaigns.map( (campaign) => {
                return {
                  name: `${campaign.name}`,
                  id: campaign._id,
                }
              } ).filter((campaign) => {
                return data.campaigns.indexOf(campaign.id) > -1;
                // return true;
              });

              this.setState({
                post: data,
              });
            })
            .catch(err => {

            });
          }


        })();

        


    }

    handleCreateGroupLibraryClicked() {

      var token = localStorage.getItem('user_token');
      var query = queryString.parse(this.props.location.search);

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/create-comment-group?access_token=${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(this.state.post)
        })
        .then(response => response.json())
        .then(data => {
          NotificationManager.success('Added comment group successfully');
          this.props.updateSearch({path: [':lang', 'console', 'group-library'], "new-post": Math.random()})
        })
        .catch(err => {
          NotificationManager.error('Error adding comment group');

          this.setState({
            error: "Error adding campaign",
            success: "",
            password: "",
          });
        })
    }

    handleDeleteCampaign() {

    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            var token = localStorage.getItem('user_token');

            fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/delete-comment-group?access_token=${token}`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                id: this.props.match.params.comment_id,
              })
            })
            .then(response => response.json())
            .then(data => {
              NotificationManager.success('Deleted post successfully');
              this.props.updateSearch({path: [':lang', 'console', 'group-library'], "new-post": Math.random()})
            })
            .catch(err => {
              NotificationManager.error('Error deleting post');

              this.setState({
                error: "Error deleting post",
                success: "",
                password: "",
              });
            })
          }
        },
        {
          label: 'No',
          onClick: () => {
            
          }
        }
      ]
    });

      
    }

  Preview = ({ meta }) => {
    const { name, percent, status } = meta
    return (
      <span style={{ alignSelf: 'flex-start', margin: '10px 3%', fontFamily: 'Helvetica' }}>
        {name}, {Math.round(percent)}%, {status}
      </span>
    )
  }

  getUploadParams = () => ({ url: `${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/upload-photo?access_token=${localStorage.getItem('user_token')}` })
  // getUploadParams = () => ({ url: `https://httpbin.org/post` })

  handleSubmit = (files, allFiles) => {
    console.log(2222, files, allFiles)
    allFiles.forEach(f => f.remove())
  }
  handleChangeStatus = (file, status) => {
    if (file.xhr && status === 'done') {
      var _file = JSON.parse(file.xhr.response);
      file.location = _file.location;

      this.state.images.push(file.location);

      this.setState({images: this.state.images});

      file.remove();
    } else {
      console.log(file, status)
    }
  }
  comment = (t, type, comments, comment) => (<React.Fragment>

            <div className="comment-area">
              <div className="comment-section">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span onClick={() => { comment.gender = 'ALL'; this.setState({post: this.state.post}) }} data-tip={t("Both Male And Female")} className="input-group-text" id="basic-addon1" style={{
                      padding: 0,
                      margin: 0,
                      color: comment.gender === 'ALL' ? 'rgb(35 151 66)' : '#000',
                      fontSize: '32px',

                    }}><FaUsers/></span>
                    <span onClick={() => { comment.gender = 'FEMALE'; this.setState({post: this.state.post}) }} data-tip={t("Female")} className="input-group-text" id="basic-addon1" style={{
                      padding: 0,
                      margin: 0,
                      color: comment.gender === 'FEMALE' ? 'rgb(35 151 66)' : '#000',
                      fontSize: '32px',
                    }}><FaFemale/></span>
                    <span onClick={() => { comment.gender = 'MALE'; this.setState({post: this.state.post}) }} data-tip={t("Male")} className="input-group-text" id="basic-addon1" style={{
                      padding: 0,
                      margin: 0,
                      color: comment.gender === 'MALE' ? 'rgb(35 151 66)' : '#000',
                      fontSize: '32px',
                    }}><FaMale/></span>
                  </div>
                  <input type={"text"} className="form-control" id="exampleFormControlTextarea1" rows={3} defaultValue={""} onChange={event => {comment.text = event.target.value; this.setState({ post: this.state.post })}} value={comment.text}/>
                </div>
              </div>



              <div className="action-section">
                {type !== 'reply' && <button className="btn btn-primary bg-blue" onClick={this.add_comment.bind(this, comment.reply)}>{t('Add Reply')}</button>}
                {comments.length > 0 && <button className="btn bg-danger" onClick={this.remove_comment.bind(this, comments, comment)}>{t('Delete')}</button>}
                {comment.gender}
              </div>
              {type !== 'reply' && <div className="copy-commands-section">
                <span className="code">@[[RANDOM_PROFILE]]</span>
                <br/>
                <span className="code">@[[NAME,ID]]</span>
              </div>}
              <div className="emoji-section">
                <div class="emoji-container">
                    <div class="emoji like">
                        <div onClick={this.reaction.bind(this, comment.reactions, 'like')} onContextMenu={this.reaction.bind(this, comment.reactions, 'like')} class="icon" data-title="Like"></div> <span>{comment.reactions.like || 0}</span>
                    </div>
                    <div class="emoji love">
                        <div onClick={this.reaction.bind(this, comment.reactions, 'love')} onContextMenu={this.reaction.bind(this, comment.reactions, 'love')}  class="icon" data-title="love"></div> <span>{comment.reactions.love || 0}</span>
                    </div>
                    <div class="emoji haha">
                        <div onClick={this.reaction.bind(this, comment.reactions, 'haha')} onContextMenu={this.reaction.bind(this, comment.reactions, 'haha')}  class="icon" data-title="haha"></div> <span>{comment.reactions.haha || 0}</span>
                    </div>
                    <div class="emoji wow">
                        <div onClick={this.reaction.bind(this, comment.reactions, 'wow')} onContextMenu={this.reaction.bind(this, comment.reactions, 'wow')}  class="icon" data-title="wow"></div> <span>{comment.reactions.wow || 0}</span>
                    </div>
                    <div class="emoji sad">
                        <div onClick={this.reaction.bind(this, comment.reactions, 'sad')} onContextMenu={this.reaction.bind(this, comment.reactions, 'sad')}  class="icon" data-title="sad"></div> <span>{comment.reactions.sad || 0}</span>
                    </div>
                    <div class="emoji angry">
                        <div onClick={this.reaction.bind(this, comment.reactions, 'angry')} onContextMenu={this.reaction.bind(this, comment.reactions, 'angry')}  class="icon" data-title="angry"></div> <span>{comment.reactions.angry || 0}</span>
                    </div>
                </div>
              </div>
            </div>

            </React.Fragment>)

    selectdCampaigns (selected_list) {
      this.state.post.campaigns = selected_list;
      this.setState({post: this.state.post});
    }


    buttons = [
      // {
      //   label: 'Add New',
      //   tool_tip: 'Add New',
      //   class: 'add',
      //   action: () => {
      //     this.props.updateSearch({path: [':lang', 'console','group-library', 'add']});
      //   },
      //   should_display: () => {
      //     return true;
      //   }
      // },     



    ];

    extra_options = [
      // {
      //   label: 'Stop Profile Activity',
      //   tool_tip: 'Stop Profile Activity',
      //   action: () => {
      //     this.stopProfileActivity();
      //   },
      //   should_display: () => {
      //     return !this.state.locked_account && this.props.match.params.profile_id;
      //   }
      // },
    ];

    close_path = [':lang', 'console'];
    tabs = [
      {
        label: 'Settings',
        active: true,
        should_display: () => {
          return true;
        },
        content: () => <div>
          <div className="container">
            <div className="row">


            </div>
          </div>
        </div>,
        footer: () => <div>
        </div>,
      },
    ];

    render() {
      const { t, i18n } = this.props;

      this.props.sidePaneEvent({
        id: `new_user_${this.props.match.params.profile_id}`,
        buttons: this.buttons,
        extra_options: this.extra_options,
        close_path: this.close_path,
        tabs: this.tabs,
        name: 'create-user-popup',
        loading: false,
        title: 'Manage Group Libraries',
        sub_title: null,
        // loading: false,
      });
      return (<div></div>);


        return (
      <div className="overlay" >
              {(this.state.campaigns && this.state.post ? <div style={{minWidth: '500px'}} className="bg-primary p-5 text-white text-center rounded-3 " id="myForm">
                  <div className="pb-5 position-relative" id="signin_header">
                    <h2 className="fs-5 fw-bolder text-uppercase text-center">{this.props.edit ? t('Edit comment group') : t('Create group library')}</h2>
                    <a type="button" className="btn text-white close_btn fs-2 fw-bolder" onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console', 'group-library']})}>×</a>
                  </div>

                  <div className="error">{t(this.state.error)}</div>
                  <div className="success">{t(this.state.success)}</div>

                  <div className="form-group">
                    <h4 for="exampleFormControlTextarea1">{t('Group name')}</h4>
                    <input className="form-control" value={this.state.post.name} onChange={event => {this.state.post.name = event.target.value;this.setState({ post: this.state.post })}} id="exampleFormControlTextarea1"/>
                  </div>





                  <button disabled={!this.state.post.name || this.state.post.advanced_comments.length == 0} onClick={this.handleCreateGroupLibraryClicked.bind(this)} type="submit" className="btn w-75 fw-bold bg-white py-3 px-5 mt-4 text-primary rounded rounded-pill">{this.props.edit ? t('Update') : t('Create')}</button>
                  
                  {this.props.edit && <button onClick={this.handleDeleteCampaign.bind(this)} type="submit" className="btn w-75 fw-bold bg-danger py-3 px-5 mt-4 text-primary rounded rounded-pill">{t('Delete')}</button>}
              </div> : <div className="loader">
                  <svg width="300px" version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                    <circle fill="none" stroke="#fff" strokeWidth={4} cx={50} cy={50} r={44} style={{opacity: '0.5'}} />
                    <circle fill="#fff" stroke="#e74c3c" strokeWidth={3} cx={8} cy={54} r={6}>
                      <animateTransform attributeName="transform" dur="2s" type="rotate" from="0 50 48" to="360 50 52" repeatCount="indefinite" />
                    </circle>
                  </svg>
                 </div>)}
      </div> 
        );
    }
}

CreateGroupLibrary.propTypes = {};

CreateGroupLibrary.defaultProps = {};

export default withRouter(CreateGroupLibrary);
