import React from 'react';
import PropTypes from 'prop-types';
import styles from './CreateCampaignPopup.module.scss';
import fetch from 'node-fetch';
import { confirmAlert } from 'react-confirm-alert'; // Import
// import MultipleValueTextInput from 'react-multivalue-text-input';
import Select from 'react-select';

import settings from '../../assets/icons/settings.svg';
import logo from '../../assets/images/logo.png';
import help from '../../assets/icons/help.svg';
import alert from '../../assets/icons/alert.svg';
import signin from '../../assets/icons/signin.svg';
import clock from '../../assets/icons/clock.svg';
import add from '../../assets/icons/add.svg';
import user1 from '../../assets/images/user1.png';
import user2 from '../../assets/images/user2.png';
import user3 from '../../assets/images/user3.png';
import user4 from '../../assets/images/user4.png';
import post1 from '../../assets/images/post1.png';
import post2 from '../../assets/images/post2.png';
import chat from '../../assets/icons/chat.svg';
import monitor from '../../assets/icons/monitor.svg';
import bin from '../../assets/icons/bin.svg';
import group1 from '../../assets/images/group1.png';
import account from '../../assets/icons/account.svg';
import password from '../../assets/icons/password.svg';
import like from '../../assets/icons/like.svg';
import follower from '../../assets/icons/follower.svg';

import {Button} from 'react-bootstrap/Button';
import {DropdownButton, ButtonGroup, Dropdown} from 'react-bootstrap';

import {NotificationContainer, NotificationManager} from 'react-notifications';

import { useTranslation, initReactI18next, withTranslation } from 'react-i18next';

import { SliderPicker, AlphaPicker, CirclePicker, ChromePickerm, SwatchesPicker } from 'react-color'

import { FaQuestionCircle, FaLock,FaSpinner } from "react-icons/fa";


import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  withRouter,
  useParams,
  useLocation
} from "react-router-dom";

import queryString from 'query-string';

import Switch from "@material-ui/core/Switch";
import Slider from '@material-ui/core/Slider';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const PrettoSlider = withStyles({
  root: {
    color: '#52af77',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);


class CreateCampaignPopup extends React.Component {

    state = {
    	"generate_post_amount": 3,
      "search": {},
      "posts": [],
      "master_users": [],
      "generated_posts": [],
      "_id": null,
      "name": "",
      "new_name": "",
      "new_permission": "",
    	"color": "#ff0000",
    	"error": "",
    	"success": "",
      "allow_permissions": [],
      "campaigns": [],
    	"selected": null,
      "immediate_pump": false,
      "enabled": true,
      "max_comments": 10,
      "max_daily_posts": 300,
      "real_daily_posts": 0,
      "max_immediate_comment_count": 2,
      "comment_duration_hours": 8,
      "default_signature": "",
    }

    refresh() {
    }
    componentWillReceiveProps(props) {
      if (props.match.params.campaign_id !== this.props.match.params.campaign_id) {
        this.setState({
          _id: null,
          "logs": [],
          "log_stats": {},
          "profiles": [],
        });
        setTimeout(() => {
          this.update_data(this.props);
        }, 0)
      }
    }
    componentWillMount() {
      this.props.tutorialEvent({action: 'add_campaign_button_click'})
      
    	this.update_data(this.props);
    }


    update_data(props) {
    	var token = localStorage.getItem('user_token');
      if (this.props.match.params.campaign_id) {

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/get-campaign?access_token=${token}&id=${this.props.match.params.campaign_id}`, {})
        .then(response => response.json())
        .then(data => {
          if (data.success === false) {
            return;
          }
        	this.setState({
        		_id: data._id,
            selected: data._id,
            account: data.account,
        		color: data.color,
        		name: data.name,
            new_name: data.name + ' (חדש)',
            allow_permissions: data.allow_permissions,
            immediate_pump: data.immediate_pump,
            enabled: data.enabled,
            max_comments: data.max_comments || 50,
            max_daily_posts: data.max_daily_posts || 300,
            real_daily_posts: data.real_daily_posts || 0,
            max_immediate_comment_count: data.max_immediate_comment_count || 2,
            comment_duration_hours: data.comment_duration_hours || 8,
            default_signature: data.default_signature || "",
        	});
        })
        .catch(err => {

        });


        var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/comment-groups?access_token=${token}&campaign=${this.props.match.params.campaign_id}`, {})
        .then(response => response.json())
        .then(data => {
          var q = {};
          q.comment_groups = data;
          this.setState(q);
        })
        .catch(err => {

        });





      }

        fetch(`${process.env.NODE_ENV !== 'development' ? "/admin" : "/admin"}/master?access_token=${token}`, {})
          .then(response => response.json())
          .then(data => {
            this.setState({
              master_users: data,
            });
          })
          .catch(err => {

          });


        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/campaigns?access_token=${token}`, {})
        .then(response => response.json())
        .then(data => {
          this.setState({
            campaigns: data,
          });
        })
        .catch(err => {

        });

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/posts?access_token=${token}&campaign=${this.props.match.params.campaign_id}`, {})
        .then(response => response.json())
        .then(data => {

          data.map((post) => {
              post._comment_group = {name: 'fff', value: post.comment_group};
          })
          

          this.setState({
            posts: data,
          });
        })
        .catch(err => {

        });


    }

    saveGeneratedPosts() {

      var token = localStorage.getItem('user_token');
        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/save-generated-posts?access_token=${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            posts: this.state.generated_posts.filter((a) => a.selected),
            campaign: this.props.match.params.campaign_id,
          })
        })
        .then(response => response.json())
        .then(data => {
          NotificationManager.success('success added posts');
          this.props.updateSearch({path: [':lang', 'console'], campaign: data._id, "new-campaign": Math.random()})
        })
        .catch(err => {
          NotificationManager.error('Error adding posts');
        })
    }

    generatedPosts() {

      // generate-posts

      // window.alert(this.state.post_generation_text);
      this.setState({
        loading_generate_posts: true,
      });


      var token = localStorage.getItem('user_token');
        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/generate-posts?access_token=${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            content: this.state.post_generation_text,
            amount: this.state.generate_post_amount,
          })
        })
        .then(response => response.json())
        .then(data => {
          NotificationManager.success('success generate');

          this.setState({
            loading_generate_posts: false,
            generated_posts: data.posts,
          });

          console.log(data.posts);

        })
        .catch(err => {
          NotificationManager.error('Error deleting campaign');
          
          this.setState({
            loading_generate_posts: false,
            //generated_posts: data.posts,
          });

          this.setState({
            error: "Error deplicating campaign",
            success: "",
            password: "",
          });
        })





    }

    handleSaveReplace() {

    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
    var token = localStorage.getItem('user_token');
        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/save-replcae-posts?access_token=${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: this.props.match.params.campaign_id,
            posts: this.state.posts,
          })
        })
        .then(response => response.json())
        .then(data => {
          NotificationManager.success('success replace');
        })
        .catch(err => {
          NotificationManager.error('Error deleting campaign');

          this.setState({
            error: "Error deplicating campaign",
            success: "",
            password: "",
          });
        })

          }
        },
        {
          label: 'No',
          onClick: () => {

          }
        }
      ]
    });


    }

    handleMergeCampaignClicked() {

    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
    var token = localStorage.getItem('user_token');
        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/merge-campaign?access_token=${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: this.props.match.params.campaign_id,
            new_name: this.state.new_name,
            merge_campaign: this.state.merge_campaign.value,
          })
        })
        .then(response => response.json())
        .then(data => {
          NotificationManager.success('Deplicate campaign successfully');
          this.props.updateSearch({path: [':lang', 'console'], "new-campaign": Math.random()})
        })
        .catch(err => {
          NotificationManager.error('Error deleting campaign');

          this.setState({
            error: "Error deplicating campaign",
            success: "",
            password: "",
          });
        })

          }
        },
        {
          label: 'No',
          onClick: () => {

          }
        }
      ]
    });



    }
    handleCreateCampaignPopupClicked() {
		var token = localStorage.getItem('user_token');

        if (!this.state.name || !this.state.color) {
          // this.state.validate_form = true;
          this.setState({validate_form: true});
          return 'not valid';
        }

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/create-campaign?access_token=${token}`, {
  		    method: 'POST',
  		    headers: {
  		      'Content-Type': 'application/json'
  		    },
  		    body: JSON.stringify({
  		    	name: this.state.name,
            account: this.state.account,
  		    	color: this.state.color,
            allow_permissions: this.state.allow_permissions,
            immediate_pump: this.state.immediate_pump,
            enabled: this.state.enabled,
            max_comments: this.state.max_comments,
            max_daily_posts: this.state.max_daily_posts,
            real_daily_posts: this.state.real_daily_posts,
            max_immediate_comment_count: this.state.max_immediate_comment_count,
            comment_duration_hours: this.state.comment_duration_hours,
            default_signature: this.state.default_signature,
  		    	id: this.props.match.params.campaign_id,
  		    })
        })
        .then(response => response.json())
        .then(data => {
          NotificationManager.success('Added campaign successfully');

          this.props.tutorialEvent({action: 'success_create_campaign'})

          this.props.updateSearch({path: [':lang', 'console'], campaign: data._id, "new-campaign": Math.random()})
        })
        .catch(err => {
        	NotificationManager.error('Error adding campaign');

        	this.setState({
        		error: "Error adding campaign",
        		success: "",
        		password: "",
        	});
        })
    }

    handleDeleteCampaign() {

    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
    var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/delete-campaign?access_token=${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: this.props.match.params.campaign_id,
          })
        })
        .then(response => response.json())
        .then(data => {
          NotificationManager.success('Deleted campaign successfully');
          this.props.updateSearch({path: [':lang', 'console'], "new-campaign": Math.random()})
        })
        .catch(err => {
          NotificationManager.error('Error deleting campaign');

          this.setState({
            error: "Error deleting campaign",
            success: "",
            password: "",
          });
        })

          }
        },
        {
          label: 'No',
          onClick: () => {

          }
        }
      ]
    });


    }


    handleDeplicateCampaign() {

    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
    var token = localStorage.getItem('user_token');
        let new_name = prompt("What do you want to call the new campaign?", "aaa");
        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/deplicate-campaign?access_token=${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: this.props.match.params.campaign_id,
            new_name
          })
        })
        .then(response => response.json())
        .then(data => {
          NotificationManager.success('Deplicate campaign successfully');
          this.props.updateSearch({path: [':lang', 'console'], "new-campaign": Math.random()})
        })
        .catch(err => {
          NotificationManager.error('Error deleting campaign');

          this.setState({
            error: "Error deplicating campaign",
            success: "",
            password: "",
          });
        })

          }
        },
        {
          label: 'No',
          onClick: () => {

          }
        }
      ]
    });


    }


    buttons = [
      // {
      //   label: 'Login',
      //   tool_tip: 'Login',
      //   class: 'login-button',
      //   action: () => {
      //     this.handleLogin();
      //   },
      //   should_display: () => {
      //     return this.props.match.params.profile_id;
      //   }
      // },
      // {
      //   label: 'Open Browser',
      //   tool_tip: 'Open Browser',
      //   class: 'open-browser-button',
      //   action: () => {
      //     this.openProfile();
      //   },
      //   should_display: () => {
      //     return this.props.match.params.profile_id;
      //   }
      // },
      {
        label: 'Delete',
        tool_tip: 'Delete',
        class: 'delete-button',
        action: () => {
          this.handleDeleteCampaign();
        },
        should_display: () => {
          return this.props.match.params.campaign_id;
        },
        class: 'danger'
      },          
      {
        label: 'Duplicate',
        tool_tip: 'Duplicate',
        class: 'duplicate-button',
        action: () => {
          this.handleDeplicateCampaign();
        },
        should_display: () => {
          return this.props.match.params.campaign_id;
        },
        class: 'danger'
      },          
    ];

    extra_options = [
      // {
      //   label: 'Stop Profile Activity',
      //   tool_tip: 'Stop Profile Activity',
      //   action: () => {
      //     this.stopProfileActivity();
      //   },
      //   should_display: () => {
      //     return !this.state.locked_account && this.props.match.params.profile_id;
      //   }
      // },
    ];

    count_replace (text, replace_with, should_replace = false) {
      if (!text) {
        return 0;
      }
      // var search = new RegExp(text, "g");

      var str = JSON.stringify(this.state.posts)

      var count = str.split(text).length - 1;

      // window.alert(count);

      if (should_replace) {
        this.state.posts = JSON.parse(str.split(text).join(replace_with));
        
        this.setState({
          posts: this.state.posts,
        })

        NotificationManager.success(this.props.t('replace done'));
      }

      return count;

      // window.alert(JSON.stringify(this.getCommentgroup().advanced_comments));
    }


    close_path = [':lang', 'console'];
    tabs = [
      {
        label: 'Settings',
        active: true,
        should_display: () => {
          return true;
        },
        content: () => <div>
          <form className={`needs-validation ${this.state.validate_form ? 'was-validated' : ''}`} noValidate="">

            <div className="container">
              <div className="row">

                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">{this.props.t('Campaign name')} ({this.props.t('Required')})</label>
                  <input
                    type="text"
                    required={true}
                    className="form-control"
                    placeholder={this.props.t('Campaign name')}
                    onChange={event => {this.setState({ name: event.target.value })}}
                    value={this.state.name}
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group" >
                  <label htmlFor="exampleInputEmail1">{this.props.t('Color')} ({this.props.t('Required')})</label>
                  <SwatchesPicker color={ this.state.color } onChangeComplete={color => {this.setState({ color: color.hex })}}/>
                </div>
              </div>
              
              {this.state.master_users.length > 0 && this.state.allow_permissions && <div className="row">
                <div className="form-group dddd">
                  <label htmlFor="exampleInputEmail1">{this.props.t('Permissions')}</label>
                  <Select
                   className="my-react-select-container"
                   classNamePrefix="my-react-select"


                    defaultValue={(this.state.allow_permissions || []).map((a) => { return {label: a, value: a}; })}
                    isMulti
                    name="colors"
                    options={this.state.master_users.map((a) => { return {label: a.username, value: a.username}; })}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => { this.setState({allow_permissions: e.map((a) => a.value)}) }}
                  />
                </div>
                <div className="form-group dddd">
                  <label htmlFor="exampleInputEmail1">{this.props.t('Account')}</label>
                  <Select
                   className="my-react-select-container"
                   classNamePrefix="my-react-select"


                    defaultValue={this.state.master_users.map((a) => { return {label: a.username, value: a._id}; }).find((a) => a.value == this.state.account)}
                    name="colors"
                    options={this.state.master_users.map((a) => { return {label: a.username, value: a._id}; })}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => { this.setState({account: e.value}) }}
                  />
                </div>
              </div>}




              {/*<div className="row">
                <div className="form-group">
                  <MultipleValueTextInput
                    onItemAdded={(item, allItems) => this.setState({ allow_permissions: allItems, permissions: allItems})}
                    onItemDeleted={(item, allItems) => this.setState({ allow_permissions: allItems, permissions: allItems})}
                    label={this.props.t('Permissions')}
                    name="item-input"
                    className="form-control"
                    placeholder={this.props.t('Enter a username')}
                    values={this.state.allow_permissions}
                  />
                </div>
              </div>*/}

              <div className="row">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">{this.props.t('Enabled')}</label>
                  <Switch
                    checked={this.state.enabled}
                    onClick={(e) => { this.state.enabled = !this.state.enabled; this.setState({
                      post: this.state.post,
                    }) }}
                  />
                </div>
              </div>

                {<div className="row" >
                  <div className="form-group" style={{
                  }}>
                    <label htmlFor="exampleInputEmail1">{this.props.t('Default Signature')} ({this.props.t('Should place virtual number here')})</label>
                    <textarea
                      className="form-control"
                      placeholder={this.props.t('Default Signature')}
                      onChange={event => {this.setState({ default_signature: event.target.value })}}
                      value={this.state.default_signature}
                      disabled={this.props.user.personal_plan}
                      style={{height: '100px'}}
                    />
                  </div>
                </div>}

              {this.state.enabled && <React.Fragment>
                {<div className="row">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{this.props.t('Maximum amount of posts you want to post each day from this campaign')}</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder={this.props.t('max_daily_posts')}
                      onChange={event => {this.setState({ max_daily_posts: event.target.value })}}
                      value={this.state.max_daily_posts}
                    />
                  </div>
                </div>}
                {this.state.master_users.length > 0 && <div className="row">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{this.props.t('real_daily_posts')}</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder={this.props.t('real_daily_posts')}
                      onChange={event => {this.setState({ real_daily_posts: event.target.value })}}
                      value={this.state.real_daily_posts}
                    />
                  </div>
                </div>}




                {/*<div>
                    <label htmlFor="exampleInputEmail1" style={{
                    opacity: this.props.user.personal_plan ? 0.7 : 1,
                  }}>

                    <Switch
                      classes={this.props.classes}
                      checked={!this.state.block_comment}
                      onClick={(e) => { this.state.block_comment = !this.state.block_comment; this.setState({
                        post: this.state.post,
                      }) }}
                      disabled={this.props.user.personal_plan}
                    />
                    {this.props.user.personal_plan && <FaLock/>}
                    {this.props.t('Allow User To Comment On Posts')}
                    <FaQuestionCircle data-tip={this.props.t('For higher porformance turn on automatic comments')}/>
                  </label>

                    {this.props.user.personal_plan && <button type="button" className="bigger-package-button" onClick={this.props.updateSearch.bind(this, {path: [':lang', 'plans']})}>{this.props.t('Purchase Package with profiles')}</button>}
                </div>*/}



                {this.state.immediate_pump && <div className="row" >
                  <div className="form-group" style={{
                    opacity: this.props.user.personal_plan ? 0.7 : 1,
                  }}>
                    <label htmlFor="exampleInputEmail1">{this.props.t('Do you want to get all the comments the moment the post gets published?')} {this.props.user.personal_plan && <FaLock/>}</label>
                    <Switch
                      checked={this.state.immediate_pump}
                      onClick={(e) => { this.state.immediate_pump = !this.state.immediate_pump; this.setState({
                        post: this.state.post,
                      }) }}
                      disabled={this.props.user.personal_plan}
                    />
                  </div>

                  {this.props.user.personal_plan && <button type="button" className="bigger-package-button" onClick={this.props.updateSearch.bind(this, {path: [':lang', 'plans']})}>{this.props.t('Purchase Package with profiles')}</button>}
                </div>}



                {!this.state.immediate_pump && <div className="row" >
                  <div className="form-group">


              {<div className="row" style={{
                    opacity: this.props.user && this.props.user.personal_plan ? 0.7 : 1,
                  }}>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">{this.props.t('Maximum amount of comments you want to post on each post')} {this.props.user && this.props.user.personal_plan && <FaLock/>}</label>
                  <PrettoSlider disabled={this.props.user && this.props.user.personal_plan} min={2} max={30} step="1" valueLabelDisplay="auto" aria-label="pretto slider" value={this.state.max_comments} onChange={(e, value) => {this.setState({ max_comments: value })}}/>
                </div>
              </div>}


                  {this.props.user.personal_plan && <button type="button" className="bigger-package-button" onClick={this.props.updateSearch.bind(this, {path: [':lang', 'plans']})}>{this.props.t('Purchase Package with profiles')}</button>}

                  </div>
                </div>}


                {!this.state.immediate_pump && <div className="row" >
                  <div className="form-group" style={{
                    opacity: this.props.user.personal_plan ? 0.7 : 1,
                  }}>
                    <label htmlFor="exampleInputEmail1">{this.props.t('Max Immediate Comment Count')} <FaQuestionCircle data-tip={this.props.t('the amount of comments to publiush in the first few minutes the post has been published')}/> {this.props.user.personal_plan && <FaLock/>}</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder={this.props.t('max_immediate_comment_count')}
                      onChange={event => {this.setState({ max_immediate_comment_count: event.target.value })}}
                      value={this.state.max_immediate_comment_count}
                      disabled={this.props.user.personal_plan}
                    />
                  </div>



                </div>}
                  {this.props.user.personal_plan && <button type="button" className="bigger-package-button" onClick={this.props.updateSearch.bind(this, {path: [':lang', 'plans']})}>{this.props.t('Purchase Package with profiles')}</button>}

                {!this.state.immediate_pump && <div className="row" >
                  <div className="form-group" style={{
                    opacity: this.props.user.personal_plan ? 0.7 : 1,
                  }}>
                    <label htmlFor="exampleInputEmail1">{this.props.t('to how many hours should we devide the remaining comments')}? ({this.props.t('Max')} {(this.state.max_comments-this.state.max_immediate_comment_count)}) {this.props.user.personal_plan && <FaLock/>}</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder={this.props.t('comment_duration_hours')}
                      onChange={event => {this.setState({ comment_duration_hours: event.target.value })}}
                      value={this.state.comment_duration_hours}
                      disabled={this.props.user.personal_plan}
                    />
                   <small id="emailHelp" className="form-text text-muted">{this.props.t(`According to information you have submitted you will have a comment every`)} - <strong>{(this.state.comment_duration_hours/ (this.state.max_comments-this.state.max_immediate_comment_count)) * 60} {this.props.t(`minutes`)}</strong> {this.props.t(`on posts from this campaign (if you have enough profiles in the specified group)`)}
                    <FaQuestionCircle data-tip={this.props.t('Final calculation will be made by the amount of profiles you have in the groups')}/>
                   </small>
                  </div>
                </div>}


                  {this.props.user.personal_plan && <button type="button" className="bigger-package-button" onClick={this.props.updateSearch.bind(this, {path: [':lang', 'plans']})}>{this.props.t('Purchase Package with profiles')}</button>}
              </React.Fragment>}
            </div>
          </form>
        </div>,
        footer: () => <div>
          <div className="container">
            <div className="row">
              <button 
                type="submit"
                className="btn btn-warning"
                onClick={this.handleCreateCampaignPopupClicked.bind(this)}>
                  {this.props.edit ? this.props.t('Update') : this.props.t('Create')}
              </button>
            </div>
          </div>
        </div>,
      },
      {
        label: 'Merge',
        active: true,
        should_display: () => {
          return this.props.edit && this.state.master_users.length > 0;
        },
        content: () => <div>
          <form className={`needs-validation ${this.state.validate_form ? 'was-validated' : ''}`} noValidate="">

            <div className="container">
              <div className="row">

                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">{this.props.t('New Campaign Name')} ({this.props.t('Required')})</label>
                  <input
                    type="text"
                    required={true}
                    className="form-control"
                    placeholder={this.props.t('New Campaign Name')}
                    onChange={event => {this.setState({ new_name: event.target.value })}}
                    value={this.state.new_name}
                  />
                </div>
              </div>
              
              {<div className="row">
                <div className="form-group dddd">
                  <label htmlFor="exampleInputEmail1">{this.props.t('Merge with campaign')}</label>
                  <Select
                   className="my-react-select-container"
                   classNamePrefix="my-react-select"
                    name="colors"
                    value={this.state.merge_campaign}
                    options={this.state.campaigns.map((a) => { return {label: a.name, value: a._id}; })}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => { this.props.updateSearch({"merge-campaign": e.value}); this.setState({merge_campaign: {label: this.state.campaigns.find((a) => a._id == e.value).name, value: e.value}  }) }}
                  />
                </div>
              </div>}

            </div>
          </form>
        </div>,
        footer: () => <div>
          <div className="container">
            <div className="row">
              <button 
                type="submit"
                className="btn btn-warning"
                onClick={this.handleMergeCampaignClicked.bind(this)}>
                  {this.props.edit ? this.props.t('Merge') : this.props.t('Merge')}
              </button>
            </div>
          </div>
        </div>,
      },
      {
        label: 'Posts',
        active: true,
        should_display: () => {
          return this.props.edit && this.state.master_users.length > 0;
        },
        content: () => <div>

          <label htmlFor="exampleInputEmail1">תגובות לכל הפוסטים</label>
          <Select
           className="my-react-select-container"
           classNamePrefix="my-react-select"

            name="comment_group"
            options={(this.state.comment_groups || []).map((a) => { return {label: a.name, value: a._id}; })}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(e) => { this.state.posts.map((post) => {
              post.comment_group = e.value;
              post._comment_group = {label: this.state.comment_groups.find((a) => a._id == post.comment_group)?.name,value: this.state.comment_groups.find((a) => a._id == post.comment_group)?._id}
            }); this.setState({posts: this.state.posts}) }}
          />

          <br/>
          <br/>
          <br/>
          <br/>
          <form className={`needs-validation ${this.state.validate_form ? 'was-validated' : ''}`} noValidate="">

            <div className="container">
              {this.state.posts.map((post) => (<div className="row">
                <div className="form-group dddd">
                  <label htmlFor="exampleInputEmail1">{post.name || post._id}</label>
                  <textarea
                    type="text"
                    required={true}
                    className="form-control"
                    style={{height: '100px'}}
                    placeholder={this.props.t('New Campaign Name')}
                    onChange={event => {post.post_content = event.target.value; this.setState({ posts: this.state.posts })}}
                    value={post.post_content}
                  />
                  <label htmlFor="exampleInputEmail1">תגובות</label>
                  {this.state.comment_groups && <Select
                   className="my-react-select-container"
                   classNamePrefix="my-react-select"
                    defaultValue={post._comment_group = {label: this.state.comment_groups.find((a) => a._id == post.comment_group)?.name,value: this.state.comment_groups.find((a) => a._id == post.comment_group)?._id} }
                    value={post._comment_group}
                    name="comment_group"
                    options={(this.state.comment_groups || []).map((a) => { return {label: a.name, value: a._id}; })}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => { post.comment_group = e.value; post._comment_group = {label: this.state.comment_groups.find((a) => a._id == post.comment_group)?.name,value: this.state.comment_groups.find((a) => a._id == post.comment_group)?._id}; this.setState({posts: this.state.posts}) }}
                  />}
                </div>
              </div>))}
              
            </div>
          </form>
        </div>,
        footer: () => <div>
          <div className="container">
            <div className="row">

                            <div className="form-group">
                              <label style={{color: '#000'}} htmlFor="exampleInputEmail1">{this.props.t('Search')} <span style={{color: 'red'}}>({this.count_replace(this.state.search.search)} matches)</span></label>
                              <input style={{
                                border: '1px solid #ccc'
                              }} className="form-control" placeholder={this.props.t('Search')} value={this.state.search.search} required={true} onChange={event => {this.state.search.search = event.target.value;this.setState({ search: this.state.search })}} id="exampleFormControlTextarea1"/>
                            </div>
                            <div className="form-group">
                              <label style={{color: '#000'}} htmlFor="exampleInputEmail1">{this.props.t('Replace')}</label>
                              <input style={{
                                border: '1px solid #ccc'
                              }} className="form-control" placeholder={this.props.t('Search')} value={this.state.search.replace} required={true} onChange={event => {this.state.search.replace = event.target.value;this.setState({ search: this.state.search })}} id="exampleFormControlTextarea1"/>
                            </div>


                          <div className="btn-group" role="group" aria-label="Basic example">
                          </div>
                          
                          <button className="btn btn-danger" type="button" onClick={this.count_replace.bind(this, this.state.search.search, this.state.search.replace, true)}>{this.props.t('Replace')} ({this.count_replace(this.state.search.search)})</button>

              <button 
                type="submit"
                className="btn btn-warning"
                onClick={this.handleSaveReplace.bind(this)}>
                  {this.props.edit ? this.props.t('Save Replacement') : this.props.t('Save Replacement')}
              </button>



            </div>
          </div>
        </div>,
      },
      {
        label: 'Generate Posts',
        active: true,
        should_display: () => {
          return this.props.edit && this.state.master_users.length > 0;
        },
        content: () => <div>

            <div className="container">
                <div className="form-group dddd">
                  <label htmlFor="exampleInputEmail1">{this.props.t('What posts do you want to create?')}</label>
                  <textarea
                    type="text"
                    required={true}
                    className="form-control"
                    style={{height: '100px'}}
                    placeholder={this.props.t('What posts do you want to create? tell us a little about it')}
                    onChange={event => {this.setState({ post_generation_text: event.target.value })}}
                    value={this.state.post_generation_text}
                  />
                </div>

              <button 
                type="submit"
                className="btn btn-warning"
                onClick={!this.state.loading_generate_posts ? this.generatedPosts.bind(this) : () => {}}
                >
                {!this.state.loading_generate_posts ? this.props.t('Generate') : <FaSpinner/>}
              </button>

              <button 
                type="submit"
                className={`btn ${this.state.generate_post_amount === 3 ? 'btn-success' : 'btn-warning'}`}
                onClick={() => {this.setState({ generate_post_amount: 3 }) }}
                >
                3
              </button>
              <button 
                type="submit"
                className={`btn ${this.state.generate_post_amount === 5 ? 'btn-success' : 'btn-warning'}`}
                onClick={() => {this.setState({ generate_post_amount: 5 }) }}
                >
                5
              </button>
              <button 
                type="submit"
                className={`btn ${this.state.generate_post_amount === 10 ? 'btn-success' : 'btn-warning'}`}
                onClick={() => {this.setState({ generate_post_amount: 10 }) }}
                >
                10
              </button>


            </div>

          <form className={`needs-validation ${this.state.validate_form ? 'was-validated' : ''}`} noValidate="">

            <div className="container">
              {this.state.generated_posts.map((post, i) => (<div className="row">
                <div className="form-group dddd">
                  <label htmlFor="exampleInputEmail1">{post.name || post._id}</label>

                  <Switch
                    checked={post.selected}
                    onClick={(e) => { post.selected = !post.selected; this.setState({ generated_posts: this.state.generated_posts }) }}
                  />


                  <textarea
                    type="text"
                    required={true}
                    className="form-control"
                    style={{height: '100px'}}
                    placeholder={this.props.t('New Campaign Name')}
                    onChange={event => {post.post_content = event.target.value; this.setState({ generated_posts: this.state.generated_posts })}}
                    value={post.post_content}
                  />
                </div>

              </div>))}
              
            </div>
          </form>
        </div>,
        footer: () => <div>
          <div className="container">
            <div className="row">


              {this.state.generated_posts.filter((a) => a.selected).length && <button 
                type="submit"
                className="btn btn-warning"
                onClick={this.saveGeneratedPosts.bind(this)}
                >
                  {this.props.t('Save Posts')}
              </button>}



            </div>
          </div>
        </div>,
      }
    ];



    render() {
    	const { t, i18n } = this.props;

      this.props.sidePaneEvent({
        id: `new_campaign_${this.props.match.params.campaign_id}`,
        buttons: this.buttons,
        extra_options: this.extra_options,
        close_path: this.close_path,
        tabs: this.tabs,
        name: 'create-campaign-popup',
        loading: this.props.match.params.campaign_id ? !(this.state._id) : false,
        title: this.props.edit ? 'Edit Campaign' : 'Create Campaign',
        // loading: false,
      });

      return (<div></div>);

        return (
		  <div className="overlay">
        <style>{'body {overflow: hidden;}'}</style>
		  </div>
        );
    }
}

CreateCampaignPopup.propTypes = {};

CreateCampaignPopup.defaultProps = {};

export default withRouter(CreateCampaignPopup);









