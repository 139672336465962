import React from 'react';
import PropTypes from 'prop-types';
import styles from './SidePane.module.scss';
import PlaceholderLoading from 'react-placeholder-loading'

import LoadingIcons from 'react-loading-icons';

import CreateUserPopup from '../../components/CreateUserPopup/CreateUserPopup.js';

import sidePaneEventBus from "../../sidePaneEventBus";

import queryString from 'query-string';

import {
  Route,
} from "react-router-dom";


// baloon aniamtion -> https://codepen.io/Jemimaabu/pen/vYEYdOy

class SidePane extends React.Component {

    state = {

      loading: true,
      buttons: [],
      extra_options: [],
      close_path: [],
      tabs: [],

      // buttons: [
      //   {
      //     label: 'Login',
      //     tool_tip: '123',
      //     action: () => {
      //       window.alert(123)
      //     },
      //   },
      //   {
      //     label: 'Some Other Action',
      //     tool_tip: '1234567',
      //     action: () => {
      //       window.alert(123456)
      //     },
      //   },
      // ],
      // extra_options: [
      //   {
      //     label: 'Login',
      //     tool_tip: '123',
      //     action: () => {
      //       window.alert(123)
      //     },
      //   },
      //   {
      //     label: 'Some Other Action',
      //     tool_tip: '1234567',
      //     action: () => {
      //       window.alert(123456)
      //     },
      //   },
      // ],
      // close_path: [':lang', 'console'],
      // tabs: [
      //   {
      //     label: 'Settings',
      //     active: true,
      //     content: <div>Settings</div>,
      //   },
      //   {
      //     label: 'Logs',
      //     active: true,
      //     content: <div>Logs</div>,
      //   },
      //   {
      //     label: 'Friends',
      //     active: true,
      //     content: <div>Friends</div>,
      //   },
      //   {
      //     label: 'Tasks',
      //     active: true,
      //     content: <div>Tasks</div>,
      //   },
      // ],
      active_tab: null,
      show_extra_options: false,
    }

    componentDidMount() {
      // this.setSelectedTutorial(this.state.tutorials[1]);

      sidePaneEventBus.on("side_pane_change", (changes) => {
        // console.log(111, Object.keys(changes))
        this.setState(changes);

        if (changes.loading === undefined) {
          changes.loading = false;
        }

        if (this.state.id !== changes.id || !this.state.active_tab) {
          setTimeout(() => {
            this.setState({
              active_tab: changes.tabs.indexOf(changes.tabs.find((a) => {
                return !a.should_display || a.should_display();
              })),
            });
          }, 0)
        }

        // setTimeout(() => {
        //   if (this.state.tabs) {
        //     if (this.state.active_tab && this.state.tabs.indexOf(this.state.active_tab) > -1) {
              
        //     } else {
        //       this.setSelectedTab(0);
        //     }
        //   }
        // }, 0)


      });
    }

    componentWillUnmount() {
      sidePaneEventBus.remove("side_pane_change");
    }


    refresh() {
    }

    update_data(props) {
    }

    componentWillReceiveProps(props) {
      var query = queryString.parse(this.props.location.search);

      this.setState({
        style: query.tutorial && query.admin ? `
              .full-page {
                width: calc(70% - 240px) !important;
                loat: left;
              }
              .ffffff {
                  margin-right: 240px;
                  overflow: inherit;
              }
            `: `
              .full-page{
                width: 70% !important;
                float: left;
              }
            `,
        style_half_page: query.tutorial ? `
              .full-page{
                width: calc(50% - 240px) !important;
              }
              .ffffff {
                  width: 50% !important;
              }
            ` : `
              .full-page{
                width: 50% !important;
              }
              .ffffff {
                  width: 50% !important;
              }
            `
      })

    }
    
    componentWillMount(props) {

    }

    setSelectedTab(index) {
      this.setState({
        active_tab: index
      });
    }

    toggleExtraOptions() {
      this.setState({
        show_extra_options: !this.state.show_extra_options
      });
    }

    getCurrentTab() {
      var current_tab = this.state.tabs[this.state.active_tab];
      if (!current_tab) {
        // this.setSelectedTab(0);
      }
      return current_tab;
    }

    render() {
      const { t, i18n } = this.props;

      return (
          <div className={styles.popupOverlay + ' sidepane ffffff '}>
            <div className={styles.popup + ' ' + this.state.name}>
              <div className={styles.header + ' flex-fixed '}>
                <div className={styles.SingleTaskPaneSpreadsheetHeader}>
                  <div
                    className={styles.ThemeableIconButton}
                    role="button"
                    aria-label="Collapse task pane"
                    tabIndex={0}
                    style={{
                      height: '28px',
                      width: '28px',
                    }}
                    onClick={this.props.updateSearch.bind(this, Object.assign({},{path: this.state.close_path},this.state.close_query || {}) )}
                  >
                    <svg
                      className="Icon XIcon"
                      viewBox="0 0 32 32"
                      aria-hidden="true"
                      focusable="false"
                    >
                      <path d="M18.1,16L27,7.1c0.6-0.6,0.6-1.5,0-2.1s-1.5-0.6-2.1,0L16,13.9l-8.9-9C6.5,4.3,5.6,4.3,5,4.9S4.4,6.4,5,7l8.9,8.9L5,24.8c-0.6,0.6-0.6,1.5,0,2.1c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4l8.9-8.9l8.9,8.9c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4c0.6-0.6,0.6-1.5,0-2.1L18.1,16z" />
                    </svg>
                  </div>


                  {!this.state.loading && this.state.extra_options.filter((button) => {
                          return (!button.should_display || button.should_display());
                        }).length > 0 && <div
                    id="lui_170"
                    className={styles.ThemeableIconButton}
                    role="button"
                    aria-disabled="false"
                    aria-label="More actions for this task"
                    aria-expanded="false"
                    tabIndex={0}


                      style={{
                        height: '28px',
                        width: '28px',
                      }}
                      onClick={this.toggleExtraOptions.bind(this)}

                  >
                    <svg
                      className="Icon MoreIcon"
                      viewBox="0 0 32 32"
                      aria-hidden="true"
                      focusable="false"


                    >
                      <path d="M16,13c1.7,0,3,1.3,3,3s-1.3,3-3,3s-3-1.3-3-3S14.3,13,16,13z M3,13c1.7,0,3,1.3,3,3s-1.3,3-3,3s-3-1.3-3-3S1.3,13,3,13z M29,13c1.7,0,3,1.3,3,3s-1.3,3-3,3s-3-1.3-3-3S27.3,13,29,13z" />
                    </svg>

                    {this.state.show_extra_options && <div style={{ position: "absolute" }}>
                      <div className={styles.dropdown}>
                        {this.state.extra_options.filter((button) => {
                          return (!button.should_display || button.should_display());
                        }).map((button) => <ul className="">
                          {(!button.should_display || button.should_display()) && <li onClick={button.action.bind(this)}>{t(button.label)}</li>}
                        </ul>)}
                      </div>
                    </div>}
                  </div>}

                  <div className="SingleTaskPaneToolbarAnimation" style={{
                    marginRight: 'auto',

                    minWidth: '1px',
                    height: '56px',


                  }}>
                    <div className="SingleTaskPaneToolbarAnimation-container">
                      <div className="SingleTaskPaneToolbarAnimation-row"
                        style={{
                          alignItems: 'center',
                          boxSizing: 'border-box',
                          display: 'flex',
                          height: '56px',
                          maxWidth: '10000px',
                          opacity: '1',
                          transition: 'opacity 200ms ease-in',
                          flexDirection: 'rowReverse',
                        }}
                      >



                        {this.state.loading && [1,2,3].map(() => <div
                          className={`ThemeableRectangularButton--isEnabled ThemeableRectangularButton ThemeableRectangularButton--medium TaskCompletionToggleButton--isNotPressed TaskCompletionToggleButton`}
                          role="button"
                          aria-pressed="false"
                          tabIndex={0}
                          style={{
                            padding: '0 8px',
                            marginLeft: '8px',
                          }}
                        >
                          <PlaceholderLoading shape="rect" width={104} height={27} />
                        </div>)}

                        {!this.state.loading && this.state.buttons.map((button) => (!button.should_display || button.should_display()) && <div
                          className={`ThemeableRectangularButton--isEnabled ThemeableRectangularButton ThemeableRectangularButton--medium TaskCompletionToggleButton--isNotPressed TaskCompletionToggleButton ${button.class}`}
                          role="button"
                          aria-pressed="false"
                          tabIndex={0}
                          onClick={button.action.bind(this)}
                          data-tip={button.action.tool_tip}
                          style={{
                            background: '#fff',
                            borderColor: '#cfcbcb',
                            color: '#1e1f21',
                            fill: '#6d6e6f',

                            fontSize: '12px',
                            height: '28px',
                            lineHeight: '28px',
                            padding: '0 8px',
                            marginLeft: '8px',

                          }}
                        >
                          <svg
                            className="MiniIcon ThemeableRectangularButton-leftIcon CheckMiniIcon"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                            focusable="false"

                            style={{
                              height: '12px',
                              width: '12px',
                            }}
                          >
                            <path d="M9.2,20c-0.5,0.5-1.3,0.5-1.9,0l-5.1-5.1c-0.4-0.5-0.4-1.3,0-1.9c0.4-0.5,1.3-0.5,1.9,0l4.1,4.1L19.7,5.7 c0.5-0.5,1.3-0.5,1.9,0s0.5,1.3,0,1.9L9.2,20z" />
                          </svg>
                          {t(button.label)}
                        </div>)}
                      </div>
                    </div>
                  </div>


                </div>
              </div>
              <div className={styles.tabs + ' flex-fixed'}>
                <ul className="nav nav-tabs">
                  {this.state.loading && [1,2,3].map((tab, tab_index) => (<li className="nav-item">
                    <a className={`nav-link ${tab_index === this.state.active_tab ? 'not-active' : 'not-active'}`}>
                      <PlaceholderLoading shape="rect" width={60} height={40} />
                    </a>
                  </li>))}
                  {!this.state.loading && this.state.tabs.map((tab, tab_index) => ((!tab.should_display || tab.should_display()) && <li className="nav-item">
                    <a className={`nav-link ${tab_index === this.state.active_tab ? ('actives ' + styles.activeTab) : 'not-active'}`} onClick={this.setSelectedTab.bind(this, tab_index)}>
                      {t(tab.label)}
                    </a>
                  </li>))}
                </ul>
              </div>
              {this.state.loading && <div className={styles.body  + ` flex-fill list-container `}>
                <div>
                  <PlaceholderLoading shape="rect" width={'100%'} height={10} /><br/>
                  <PlaceholderLoading shape="rect" width={'100%'} height={10} /><br/>
                  <PlaceholderLoading shape="rect" width={'100%'} height={10} /><br/>
                  <PlaceholderLoading shape="rect" width={'100%'} height={10} /><br/>
                  <PlaceholderLoading shape="rect" width={'100%'} height={10} /><br/>
                  <PlaceholderLoading shape="rect" width={'100%'} height={10} /><br/>
                  <PlaceholderLoading shape="rect" width={'100%'} height={10} /><br/>
                  <PlaceholderLoading shape="rect" width={'100%'} height={10} /><br/>
                </div>
              </div>}
              {!this.state.loading && this.getCurrentTab() && <div className={styles.title + ' side-pane-title'}>

                  {this.state.title && <h7 style={{color: '#478cec !important'}}>
                      {this.props.t(this.state.title)}

                      {this.state.sub_title && <span>
                        <strong>-</strong>
                       {this.props.t(this.state.sub_title)}
                      </span>}
                  </h7>}
                   
              </div>}

              {!this.state.loading && this.getCurrentTab() && this.getCurrentTab().sticky_header && <div className={styles.title + ' side-pane-title'}>
                  <div>{this.getCurrentTab().sticky_header()}</div>
              </div>}

              {!this.state.loading && this.getCurrentTab() && <div className={styles.body  + ` flex-fill list-container ${this.state.name}-body`}>
                <div className={styles.settings}>
                  <div className={styles.settings}>{this.getCurrentTab().content()}</div>
                </div>
              </div>}
              
              {this.state.loading_footer_action && !this.state.loading && this.getCurrentTab() && this.getCurrentTab().footer && <div className={styles.footer  + ` flex-fixed ${this.state.name}-footer`}>
                <div className={styles.settings}>
                  <div className="container">
                    <div className="row">

                      {/*<button className="btn btn-warning">Loading</button>*/}
                      <button disabled={true} className="btn btn-warning">{this.props.t('Loading')}...</button>

                    </div>
                  </div>

                </div>
              </div>}

              {!this.state.loading_footer_action && !this.state.loading && this.getCurrentTab() && this.getCurrentTab().footer && <div className={styles.footer  + ` flex-fixed ${this.state.name}-footer`}>
                <div className={styles.settings}>
                  <div className={styles.settings}>{this.getCurrentTab().footer()}</div>
                </div>
              </div>}

              <Route path='/:lang/console/profile/add'>
                <style>{`#root{overflow-y: scroll; height: 100vh;} .ffffff { width: 30%;  }`}</style>
                <style>{this.state.style}</style>
              </Route>
              
              <Route path='/:lang/console/profile/edit/:profile_id'>
                <style>{`#root{overflow-y: scroll; height: 100vh;} .ffffff { width: 30%;  }`}</style>
                <style>{this.state.style}</style>
              </Route>

              <Route path='/:lang/console/campaign/add'>
                <style>{`#root{overflow-y: scroll; height: 100vh;} .ffffff { width: 30%;  }`}</style>
                <style>{this.state.style}</style>
              </Route>
              <Route path='/:lang/console/groups/:group_id'>
                <style>{`#root{overflow-y: scroll; height: 100vh;} .ffffff { width: 30%;  }`}</style>
                <style>{this.state.style}</style>
              </Route>
              
              <Route path='/:lang/console/campaign/edit/:campaign_id'>
                <style>{`#root{overflow-y: scroll; height: 100vh;} .ffffff { width: 30%;  }`}</style>
                <style>{this.state.style}</style>
              </Route>
              
              <Route path='/:lang/console/slot/:post_id' exact>
                <style>{`#root{overflow-y: scroll; height: 100vh;} .ffffff { width: 30%;  }`}</style>
                <style>{this.state.style}</style>
              </Route>
              
              <Route path='/:lang/console/join-group/:post_id' exact>
                <style>{`#root{overflow-y: scroll; height: 100vh;} .ffffff { width: 30%;  }`}</style>
                <style>{this.state.style}</style>
              </Route>

              <Route path='/:lang/console/settings' exact>
                <style>{`#root{overflow-y: scroll; height: 100vh;} .ffffff { width: 30%;  }`}</style>
                <style>{this.state.style}</style>
              </Route>

              <Route path='/:lang/console/post/add' exact>
                <style>{`#root{overflow-y: scroll; height: 100vh;} .ffffff { width: 30%;  }`}</style>
                <style>{this.state.style}</style>
              </Route>
              <Route path='/:lang/console/post/edit/:post_id' exact>
                <style>{`#root{overflow-y: scroll; height: 100vh;} .ffffff { width: 30%;  }`}</style>
                <style>{this.state.style}</style>
              </Route>
              <Route path='/:lang/console/group-library' exact>
                <style>{`#root{overflow-y: scroll; height: 100vh;} .ffffff { width: 30%;  }`}</style>
                <style>{this.state.style_half_page}</style>
              </Route>
              <Route path='/:lang/console/group-library/edit/:library_id'>
                <style>{`#root{overflow-y: scroll; height: 100vh;} .ffffff { width: 30%;  }`}</style>
                <style>{this.state.style}</style>
                <style>{this.state.style_half_page}</style>
              </Route>
              <Route path='/:lang/console/group/manage'>
                <style>{`#root{overflow-y: scroll; height: 100vh;} .ffffff { width: 30%;  }`}</style>
                <style>{this.state.style}</style>
                <style>{this.state.style_half_page}</style>
              </Route>
              <Route path='/:lang/console/group-library/add'>
                <style>{`#root{overflow-y: scroll; height: 100vh;} .ffffff { width: 30%;  }`}</style>
                <style>{this.state.style}</style>
                <style>{this.state.style_half_page}</style>
              </Route>
              <Route path='/:lang/console/multiple-group-camaign/:multiple_group_camaign_id?' exact>
                {<div>
                  <style>{`#root{overflow-y: scroll; height: 100vh;} .ffffff { width: 30%;  }`}</style>
                  <style>{this.state.style}</style>
                  <style>{this.state.style_half_page}</style>
                </div>}
              </Route>

            </div>
        </div>
      );
    }
}


SidePane.propTypes = {};

SidePane.defaultProps = {};

export default SidePane;
