import React, { useState } from "react";
import moment from "moment";

import PropTypes from 'prop-types';
import styles from './Calendar.module.scss';
import fetch from 'node-fetch';
import _ from 'lodash';
import $ from 'jquery';

import { confirmAlert } from 'react-confirm-alert'; // Import

import settings from '../../assets/icons/settings.svg';
import logo from '../../assets/images/logo.png';
import help from '../../assets/icons/help.svg';
import alert from '../../assets/icons/alert.svg';
import signin from '../../assets/icons/signin.svg';
import clock from '../../assets/icons/clock.svg';
import add from '../../assets/icons/add.svg';
import user1 from '../../assets/images/user1.png';
import user2 from '../../assets/images/user2.png';
import user3 from '../../assets/images/user3.png';
import user4 from '../../assets/images/user4.png';
import post1 from '../../assets/images/post1.png';
import post2 from '../../assets/images/post2.png';
import chat from '../../assets/icons/chat.svg';
import monitor from '../../assets/icons/monitor.svg';
import bin from '../../assets/icons/bin.svg';
import group1 from '../../assets/images/group1.png';
import account from '../../assets/icons/account.svg';
import password from '../../assets/icons/password.svg';
import like from '../../assets/icons/like.svg';
import follower from '../../assets/icons/follower.svg';
import tutorialEventBus from "../../tutorialEventBus";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ReactTooltip from 'react-tooltip';
import {Checkbox, RadioGroup} from "@material-ui/core";


import {Button} from 'react-bootstrap/Button';
import {DropdownButton, ButtonGroup, Dropdown} from 'react-bootstrap';

import { useTranslation, initReactI18next, withTranslation } from 'react-i18next';
import {FaBan, FaExclamationTriangle, FaLock, FaClock, FaBell, FaBox, FaUsers, FaPlus,FaQuestionCircle, FaTrash, FaEdit, FaComment, FaHandshake } from "react-icons/fa";


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  withRouter,
  useLocation
} from "react-router-dom";

import queryString from 'query-string';

import { Dropbox } from "./Dropbox.js";

import {NotificationContainer, NotificationManager} from 'react-notifications';

if (![].chunk_inefficient) {
    Object.defineProperty(Array.prototype, 'chunk_inefficient', {
      value: function(chunkSize) {
        var array = this;
        return [].concat.apply([],
          array.map(function(elem, i) {
            return i % chunkSize ? [] : [array.slice(i, i + chunkSize)];
          })
        );
      }
    });
}


class Calendar extends React.Component {

    state = {
    	live_events: [],
        slots: [
    		"0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
    		"7",
    		"8",
    		"9",
    		"10",
    		"11",
    		"12",
    		"13",
    		"14",
    		"15",
    		"16",
    		"17",
    		"18",
    		"19",
    		"20",
    		"21",
    		"22",
    		"23"
    	],
    	selected: null,
    	selected_group: null,
        multi_select: [],
    	start_date: new Date(),
    	days: [],
    	calendar: {},
        current: {
            day: moment().format('L'),
            hour: new Date().getHours().toString(),
        },
        debug: false,
    }

    addOrRemoveArray(array, value) {
        var index = array.indexOf(value);

        if (index === -1) {
            array.push(value);
        } else {
            array.splice(index, 1);
        }
    }
    componentWillReceiveProps() {
        // .current-hour.current-day
        

    }
    componentDidMount() {
      tutorialEventBus.on("live_events", (events) => {
        this.setState({live_events: events})
      });

      tutorialEventBus.on("trigger_join_group_now", (action) => {
        // this.setState({live_events: events})
        // ddddddddddd
        // window.alert(123);
        // window.alert(this.state.current.day);
        this.handleDrop({
            "day": this.state.current.day,
            "slot": {
                "slot": new Date().getHours().toString(),
                "day": this.state.current.day,
                "groups": [],
                "campaigns": [],
                "posts": []
            },
            group: action.group,
        }, {
            "name": "profile",
            "type": "profile",
            profile: {_id: 'aaaaa'}
        })
      });
      tutorialEventBus.on("move_to_position", (action) => {
            this.move_to_position();
      });
    }
    componentWillUnmount() {
      tutorialEventBus.remove("select_group");
      tutorialEventBus.remove("trigger_join_group");
      clearInterval(this.state.intervalId);
      clearInterval(this.state.interval_auto_update);
    }

    set_selected(id) {

        this.props.updateSearch({path: [':lang', 'console','slot', id]});

        if (window.event.ctrlKey) {
            
            this.addOrRemoveArray(this.state.multi_select, id);

            this.setState({
                selected: null,
                multi_select: this.state.multi_select,
            });

            return;
        }

    	this.setState({
    		selected: id,
            multi_select: [],
    	})
    }

    set_selected_group(id) {

        this.props.updateSearch({path: [':lang', 'console','join-group', id]});

        if (window.event.ctrlKey) {
            
            this.addOrRemoveArray(this.state.multi_select, id);

            this.setState({
                selected: null,
                multi_select: this.state.multi_select,
            });

            return;
        }

        this.setState({
            selected: id,
            multi_select: [],
        })
    }

    remove(slot, day, id) {

        confirmAlert({
          title: 'Confirm to remove slot',
          message: 'Are you sure to do this.',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                var token = localStorage.getItem('user_token');
                var query = queryString.parse(this.props.location.search);
                query.interval = query.interval || 'day';
                if (!this.props.automatic) {
                    query.interval = undefined;
                    query.period = undefined;
                }

                var slot_area = this.state.calendar.slots[[day, slot].toString()]; // array

                fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/remove-post?access_token=${token}`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        id: id,
                    })
                })
                .then(response => response.json())
                .then(data => {
                    _.remove(slot_area, {
                        _id: id
                    });


                    this.setState({
                        calendar: this.state.calendar,
                    });

                    NotificationManager.success('success', 'removed post');

                })
                .catch(err => {

                });

              }
            },
            {
              label: 'No',
              onClick: () => {

              }
            }
          ]
        });
    }
    removeCampaign(slot, day, id) {
        confirmAlert({
          title: 'Confirm to submit',
          message: 'Are you sure to do this.',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
        var token = localStorage.getItem('user_token');
        var query = queryString.parse(this.props.location.search);
        query.interval = query.interval || 'day';
                if (!this.props.automatic) {
                    query.interval = undefined;
                    query.period = undefined;
                }

        var slot_area = this.state.calendar.campaigns[[day, slot].toString()]; // array

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/remove-campaign-post?access_token=${token}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: id,
            })
        })
        .then(response => response.json())
        .then(data => {
            _.remove(slot_area, {
                _id: id
            });


            this.setState({
                calendar: this.state.calendar,
            });

            NotificationManager.success('success', 'removed post');

        })
        .catch(err => {

        });

              }
            },
            {
              label: 'No',
              onClick: () => {
                  
              }
            }
          ]
        });



    }

    refresh() {

    }
    mass_delete_campaign_slots() {


        confirmAlert({
          title: 'Confirm to submit',
          message: 'Are you sure to do this.',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                var token = localStorage.getItem('user_token');
                var query = queryString.parse(this.props.location.search);
                query.interval = query.interval || 'day';
                if (!this.props.automatic) {
                    query.interval = undefined;
                    query.period = undefined;
                }

                fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/mass-delete-campaign-slots?access_token=${token}`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        ids: this.state.multi_select,
                    })
                })
                .then(response => response.json())
                .then(data => {

                    this.setState({
                        calendar: this.state.calendar,
                    });

                    NotificationManager.success('success', 'mass delete success');

                })
                .catch(err => {

                });

              }
            },
            {
              label: 'No',
              onClick: () => {

              }
            },



          ]
        });


    }

    get_groups(props) {
        var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/groups?access_token=${token}`, {})
        .then(response => response.json())
        .then(data => {
            this.setState({
                groups: data,
            });
            this._sort();
            this.move_to_selected();

        })
        .catch(err => {

        });

    }
    async update_data(props, is_live) {
        new Promise((resolve) => {
            (async () => {
                var token = localStorage.getItem('user_token');
                var query = queryString.parse(this.props.location.search);
                query.interval = query.interval || 'day';
                if (!this.props.automatic) {
                    query.interval = undefined;
                    query.period = undefined;
                }

                this.setState({old_query: query})

                var days = [0,1,2,3,4,5,6];

                if (query.interval === 'day') {
                    days = [0];
                } else if (query.interval === 'week') {
                    days = [
                        'Sunday',
                        'Monday',
                        'Tuesday',
                        'Wednesday',
                        'Thursday',
                        'Friday',
                        'Saturday'
                    ];
                } else if (query.interval === 'month') {
                    
                    var week = parseInt(query.period.replace( /^\D+/g, '')) - 1;
                    days = [
                        '01',
                        '02',
                        '03',
                        '04',
                        '05',
                        '06',
                        '07',
                        '08',
                        '09',
                        '10',
                        '11',
                        '12',
                        '13',
                        '14',
                        '15',
                        '16',
                        '17',
                        '18',
                        '19',
                        '20',
                        '21',
                        '22',
                        '23',
                        '24',
                        '25',
                        '26',
                        '27',
                        '28',
                        '29',
                        '30',
                        '31'
                    ].chunk_inefficient(7)[week];
                }
                
                if (!this.props.automatic) {        
                    this.setState({
                        days: days.map((days) => {
                            return moment(this.state.start_date).add(days, 'days').format('L');
                        }),
                        display_days: days.map((days) => {
                            return moment(this.state.start_date).add(days, 'days').format('DD/MM/YYYY');
                        }),
                    });
                } else if (query.interval === 'day') {
                    this.setState({
                        days: days.map((days) => {
                            return 'daily';
                        }),
                    });
                } else if (query.interval === 'week' || query.interval === 'month') {
                    this.setState({
                        days: days,
                    });
                }

                var params = {
                    access_token: token,
                    date: this.state.start_date.toISOString(),
                };

                if (query.group) {
                    // params.group = query.group;
                }

                if (this.props.automatic) {
                    delete params.date;
                    params.autopost_mode = true;
                    if (query.interval) {
                        params.autopost_interval = query.interval;
                    }

                    if (query.period) {
                        params.autopost_period = query.period;
                    }
                }

                var __params = new URLSearchParams(params).toString()

                  try {
                    if (this.get_groups_conroller) {
                        if (this.current_request && this.current_request == __params) {
                            return;
                        } else {
                            this.get_groups_conroller.abort();
                        }
                    }
                  } catch {
                  }
                this.get_groups_conroller = new AbortController();
                let signal = this.get_groups_conroller.signal;

                this.current_request = __params;

                fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/calendar?${this.current_request}`, {
                    signal: signal,
                })
                .then(response => response.json())
                .then(data => {
                    delete this.get_groups_conroller;
                    this.setState({
                        calendar: data,
                    });

                    resolve();

                    if (!is_live) {
                        this.move_to_position();
                    }
                })
                .catch(err => {
                    delete this.get_groups_conroller;
                    resolve();
                });
                
                if (!is_live) {
                    this.setState({
                        current: {
                            day: moment().format('L'),
                            hour: new Date().getHours().toString(),
                        },
                    });
                }

            })()
        });
    }

    move_to_position(props) {
        setTimeout(() => {
            if ($('.current-hour.current-day')) {
                if ($('.current-hour.current-day')[0]) {
                    // $('#calendar .overflow-auto').scrollTop(100);
                    // $('#calendar .overflow-auto').scrollTop(100);
                    // $('#calendar .overflow-auto').scrollTop(100);
                    // $('#calendar .overflow-auto').scrollTop(100);

                        var scroll_top_amount = $('#calendar .overflow-auto')[0].offsetTop;
                        var current_position_y = $('.current-hour.current-day')[0].offsetTop;
                        // window.alert(current_position_y);

                        var container_height = $('#calendar .overflow-auto')[0].getBoundingClientRect().height;
                        var element_height = $('.current-hour.current-day')[0].getBoundingClientRect().height;
                        var scroll_h = $('#calendar .overflow-auto')[0].scrollHeight;
                        // var amount_to_move = current_position_y - ((container_height+element_height)/2);
                        var amount_to_move = (current_position_y -element_height ) - ((container_height-element_height) /2);
                        $('#calendar .overflow-auto').scrollTop(amount_to_move);
                }
            }
        }, 0)
    }

    componentWillReceiveProps(props) {
        var query = queryString.parse(this.props.location.search);

        query.interval = query.interval || 'day';
                if (!this.props.automatic) {
                    query.interval = undefined;
                    query.period = undefined;
                }

    	var should_update = false;

    	if (query.date && this.state.start_date.getDate() !== new Date(query.date).getDate()) {
    		// this.state.start_date.setDate(new Date(query.date).getDate());
            this.setState({
                start_date: new Date(query.date)
            })

    		should_update = true;
    	}

        if (query['new-campaign'] && this.state.latest_new !== query['new-campaign']) {
            this.setState({
                latest_new: query['new-campaign'],
            });

            should_update = true;
        }


    	// if (query.group !== this.state.selected_group) {
    	// 	should_update = true;
    	// }

        if (!this.state.old_query || query.interval !== this.state.old_query.interval) {
            should_update = true;
        }
        if (!this.state.old_query || query.period !== this.state.old_query.interval) {
            should_update = true;
        }

    	this.setState({
    		selected_group: query.group,
    	});

    	if (should_update) {
            setTimeout(() => {
    		    this.update_data(props, 'live');
            });
    	}
    }
    
    componentWillMount(props) {
        var query = queryString.parse(this.props.location.search);
        query.interval = query.interval || 'day';
                if (!this.props.automatic) {
                    query.interval = undefined;
                    query.period = undefined;
                }

    	this.setState({
    		selected_group: query.group,
            debug: query.debug || false,
    	});
	
    	if (query.date && this.state.start_date.getDate() !== new Date(query.date).getDate()) {
    		// this.state.start_date.setDate(new Date(query.date).getDate());
            this.setState({
                start_date: new Date(query.date)
            })
    	}

        setTimeout(() => {
    	    this.update_data(this.props);
            this.get_groups(this.props);
        });
        // setInterval(() => {
        //     this.update_data(this.props);
        // }, 10000);



        var intervalId = setInterval(() => {
            if (this.props.calendarel.offsetWidth !== this.state.elem_width) {
                setTimeout(() => {
                    this.setState({elem_width: this.props.calendarel.offsetWidth});
                }, 0);
            }
        }, 100);

        var interval_auto_update = setInterval(() => {
            this.update_data(this.props, 'live');
        }, 1000 * 60);

        this.setState({
            intervalId: intervalId,
            interval_auto_update: interval_auto_update
        })



    }

    componentDidUpdate(props) {
        ReactTooltip.rebuild();
    }

    async handleDrop(data, post, group) {
    	var query = queryString.parse(this.props.location.search);
        query.interval = query.interval || 'day';
                if (!this.props.automatic) {
                    query.interval = undefined;
                    query.period = undefined;
                }

    	var token = localStorage.getItem('user_token');

        // console.log(123)
        // console.log(JSON.stringify(data))
        // console.log(JSON.stringify(post))
        // console.log(666)
        // window.alert(214124);
        // return;

        if (post.type === 'post') {

        	var scheduleSlot = {
    			campaign: query.campaign,
    			group: query.group,
    			user: query.profile,
    			post: post.post._id,
    			time_slot: data.slot.slot,
    			date: data.day,
    			autopost_mode: false,
        	};

            if (this.props.automatic) {
                scheduleSlot.autopost_mode = true;
                // delete scheduleSlot.date;
        
                if (query.interval) {
                    scheduleSlot.autopost_interval = query.interval;
                }

                if (query.period) {
                    scheduleSlot.autopost_period = query.period;
                }
            }

        	// return console.log(scheduleSlot);

        	try {		
    			var result = await fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/schedule-post?access_token=${token}`, {
    				method: 'post',
    				body:    JSON.stringify(scheduleSlot),
    				headers: { 'Content-Type': 'application/json' },
    			});
    			var response = await result.json();

    			if (response.error) {
                    NotificationManager.error('ohhh no :(', response.message);
    				throw new Error();
    			}
                
                this.props.tutorialEvent({action: 'schedule_post_success'});

    			NotificationManager.success('Yayyy..', 'Slot added successfully');
        	} catch (e) {
        		console.log(e)
        		// NotificationManager.error(':(', response.message ||'Error');
        	}


        	setTimeout(() => {
        		this.update_data(this.props, 'live');
        	}, 0)
        } else if (post.type === 'campaign') {
            var scheduleSlotCampaign = {
                campaign: post.campaign._id,
                group: group || query.group,
                time_slot: data.slot.slot,
                date: data.day,
                autopost_mode: true,
                autopost_interval: query.interval,
                autopost_period: query.period,

                max_post_amount: 10,
                post_per_period: 10,
                skip_period: 0,

            };

            try {        
                var result = await fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/schedule-post-campaign?access_token=${token}`, {
                    method: 'post',
                    body:    JSON.stringify(scheduleSlotCampaign),
                    headers: { 'Content-Type': 'application/json' },
                });
                var response = await result.json();
                if (response.error) {
                    NotificationManager.error('ohhh no :(', response.message);
                    throw new Error();
                }
                NotificationManager.success('Yayyy..', 'Slot added successfully');
            } catch (e) {
                console.log(e)
                NotificationManager.error(':(', 'Error');
            }


            setTimeout(() => {
                this.update_data(this.props, 'live');
            }, 0)
        } else if (post.type === 'profile') {
            
            var schedueJoinGroup = {
                user: post.profile._id,
                group: data.group || query.group,
                date: data.day,
                time_slot: data.slot.slot,
                triggerd: false,
            };

            try {
                console.log(schedueJoinGroup)
                var result = await fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/schedule-join-group?access_token=${token}`, {
                    method: 'post',
                    body:    JSON.stringify(schedueJoinGroup),
                    headers: { 'Content-Type': 'application/json' },
                });
                var response = await result.json();
                if (response.error) {
                    NotificationManager.error('ohhh no :(', response.message);
                    throw new Error(response.message);
                }

                setTimeout(() => {
                    this.update_data(this.props, 'live');
                }, 0)

                NotificationManager.success('Yayyy..', 'Slot added successfully');
            } catch (e) {
                console.log(e)
                // NotificationManager.error(':(', e);
            }


        }
    }

    calulateAllPosts (times, group_settings = {}, selected_groups = []) {
      var amount = 0;
      selected_groups = selected_groups.map((a) => a._id);
      
      for (var i = 0; i < times.length; i++) {
        var time_slot = times[i];
        time_slot.group_map = time_slot.group_map || {};
        amount += Object.keys(time_slot.group_map).reduce((amount, group_id) => {
          var group_exists;
          
          var group_not_exists = false;

          if (group_settings[group_id]) {
            group_not_exists = group_settings[group_id].is_disabled; // .find((g) => g._id == group_id);
          }

          if (!group_not_exists && time_slot.time && time_slot.group_map[group_id] && selected_groups.indexOf(group_id) > -1) {
            if (time_slot.group_map[group_id].active) {
              amount += parseInt(time_slot.group_map[group_id].post_amount) || 1;
            }
          }
          return amount;
        }, 0);
      }

      return amount;
    }

    schedule_join_today_groups() {

        confirmAlert({
          title: 'Confirm to schedule groups',
          message: 'Are you sure you want to do this.',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                var token = localStorage.getItem('user_token');
                var query = queryString.parse(this.props.location.search);
                query.interval = query.interval || 'day';
                if (!this.props.automatic) {
                    query.interval = undefined;
                    query.period = undefined;
                }

                NotificationManager.success('success', 'removed post');

// schedule-today-campaign-slots
                fetch(`${process.env.NODE_ENV !== 'development' ? "/" : "http://localhost:8888/"}scheduled-tasks/schedule-today-join-groups?access_token=${token}`, {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                })
                .then(response => response.json())
                .then(data => {

                    NotificationManager.success('success', 'schedule groups');

                })
                .catch(err => {

                });

              }
            },
            {
              label: 'No',
              onClick: () => {

              }
            }
          ]
        });


    }
    delete_all_today_posts() {

        confirmAlert({
          title: 'Confirm to schedule groups',
          message: 'Are you sure you want to do this.',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                var token = localStorage.getItem('user_token');
                var query = queryString.parse(this.props.location.search);
                query.interval = query.interval || 'day';
                if (!this.props.automatic) {
                    query.interval = undefined;
                    query.period = undefined;
                }

                NotificationManager.success('success', 'removed post');

                fetch(`${process.env.NODE_ENV !== 'development' ? "/" : "http://localhost:8888/"}api2/delete-all-today-posts?access_token=${token}`, {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                })
                .then(response => response.json())
                .then(data => {

                    NotificationManager.success('success', 'schedule groups');

                })
                .catch(err => {

                });

              }
            },
            {
              label: 'No',
              onClick: () => {

              }
            }
          ]
        });


    }

    schedule_today_posts() {
        confirmAlert({
          title: 'Confirm to schedule posts',
          message: 'Are you sure you want to do this.',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                var token = localStorage.getItem('user_token');
                var query = queryString.parse(this.props.location.search);
                query.interval = query.interval || 'day';
                if (!this.props.automatic) {
                    query.interval = undefined;
                    query.period = undefined;
                }

                NotificationManager.success('success', 'removed post');

// 
                fetch(`${process.env.NODE_ENV !== 'development' ? "/" : "http://localhost:8888/"}scheduled-tasks/schedule-today-campaign-slots?access_token=${token}`, {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    // body: JSON.stringify({
                    //     id: id,
                    // })
                })
                .then(response => response.json())
                .then(data => {

                    NotificationManager.success('success', 'schedule posts');

                })
                .catch(err => {

                });

              }
            },
            {
              label: 'No',
              onClick: () => {

              }
            }
          ]
        });
    }
    schedule_account_profile_pics() {
        confirmAlert({
          title: 'Confirm to schedule posts',
          message: 'Are you sure you want to do this.',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                var token = localStorage.getItem('user_token');
                var query = queryString.parse(this.props.location.search);
                query.interval = query.interval || 'day';
                if (!this.props.automatic) {
                    query.interval = undefined;
                    query.period = undefined;
                }

                NotificationManager.success('success', 'removed post');

// 
                fetch(`${process.env.NODE_ENV !== 'development' ? "/" : "http://localhost:8888/"}api2/schedule-account-profile-pics?access_token=${token}`, {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    // body: JSON.stringify({
                    //     id: id,
                    // })
                })
                .then(response => response.json())
                .then(data => {

                    NotificationManager.success('success', 'schedule posts');

                })
                .catch(err => {

                });

              }
            },
            {
              label: 'No',
              onClick: () => {

              }
            }
          ]
        });
    }

    render() {
    	const { t, i18n } = this.props;
        var query = queryString.parse(this.props.location.search);
        query.interval = query.interval || 'day';
                if (!this.props.automatic) {
                    query.interval = undefined;
                    query.period = undefined;
                }
    	// const [startDate, setStartDate] = useState(new Date());

        return (
        	<React.Fragment>
              <div id="calendar_head" className="m_row">

                <div className="m_col calendar-column">
                    {this.props.automatic && <div className="calendar-column-period switch-toggle switch-3 switch-candy">
                        <input id="on" name="state-type" type="radio" checked={query.interval === 'day' ? 'checked' : ''} />
                        <label for="on" onClick={this.props.updateSearch.bind(this, {interval: 'day', period: undefined})}>{t('Day')} <FaQuestionCircle data-tip={this.props.t('Your daily scheduled posts for every day')}/></label>

                        <input id="na" name="state-type" type="radio" checked={query.interval === 'week' ? 'checked' : ''} />
                        <label for="na"  onClick={this.props.updateSearch.bind(this, {interval: 'week'})}>{t('Week')} <FaQuestionCircle data-tip={this.props.t('Your weekly schedule for each day of the week separately')}/></label>

                        <input id="off" name="state-type" type="radio" checked={query.interval === 'month' ? 'checked' : ''} />
                        <label for="off"  onClick={this.props.updateSearch.bind(this, {interval: 'month', period: 'week1'})}>{t('Month')} <FaQuestionCircle data-tip={this.props.t('Your monthly schedule for each day of the month separately')}/></label>
                    </div>}

                    {this.props.automatic && query.interval === 'month' && <div className="switch-toggle switch-3 switch-candy">
                        <input id="on" name="state-week" type="radio" checked={query.period === 'week1' ? 'checked' : ''} />
                        <label for="on" onClick={this.props.updateSearch.bind(this, {interval: 'month', period: 'week1'})}>1</label>
                        
                        <input id="on" name="state-week" type="radio" checked={query.period === 'week2' ? 'checked' : ''} />
                        <label for="on" onClick={this.props.updateSearch.bind(this, {interval: 'month', period: 'week2'})}>2</label>
                        
                        <input id="on" name="state-week" type="radio" checked={query.period === 'week3' ? 'checked' : ''} />
                        <label for="on" onClick={this.props.updateSearch.bind(this, {interval: 'month', period: 'week3'})}>3</label>
                        
                        <input id="on" name="state-week" type="radio" checked={query.period === 'week4' ? 'checked' : ''} />
                        <label for="on" onClick={this.props.updateSearch.bind(this, {interval: 'month', period: 'week4'})}>4</label>
                        
                        <input id="on" name="state-week" type="radio" checked={query.period === 'week5' ? 'checked' : ''} />
                        <label for="on" onClick={this.props.updateSearch.bind(this, {interval: 'month', period: 'week5'})}>5</label>
                    </div>}
                    {!this.props.automatic && <div className="rtl">
                        <label style={{color: 'black'}}>{this.props.t('Select Date')}:</label>
                        {!this.props.automatic && <DatePicker selected={this.state.start_date} onChange={(date) => { this.props.updateSearch({date: date.toISOString()})} } />}
                    </div>}

                    {this.state.debug && <div>
                        <button onClick={this.schedule_join_today_groups.bind(this)}>Schedule Today Groups</button>
                    </div>}
                    {this.state.debug && <div>
                        <button onClick={this.delete_all_today_posts.bind(this)}>Delete All Today Posts</button>
                    </div>}
                    {this.state.debug && <div>
                        <button onClick={this.schedule_today_posts.bind(this)}>Schedule Today Posts</button>
                    </div>}
                    {this.state.debug && <div>
                        <button onClick={this.schedule_account_profile_pics.bind(this)}>Schedule Account Profile Pics</button>
                    </div>}
                </div>
                {this.state.calendar.campaigns && this.state.days.slice(0,  Math.min(Math.floor((this.state.elem_width-172)/172), 7) ).map((day, index) => ( <div className="calendar-column p-1 m_col text-center">
                    <a className="px-4 rounded rounded-pill small fw-light">
                        <span>{!query.interval === 'day' ? <strong>{this.state.display_days && this.state.display_days[index]}</strong> : <strong>{t(day)}</strong>}</span>
                        
                        {!this.props.automatic && index === 0 && <div style={{direction: 'rtl', fontSize: '12px'}}>

                      {this.props.t('Display join groups')}? &nbsp;
                            <Checkbox 
                              onClick={(e) => {
                                this.props.updateSearch({"show_groups": !queryString.parse(this.props.location.search).show_groups ? '1' : undefined})
                              }}
                              style={{padding: '0'}}
                              checked={queryString.parse(this.props.location.search).show_groups}
                            />
                        </div>}

                      {this.props.automatic && this.state.calendar.multi_group_schedule_slots[((query.interval || 'day')+','+day)] && this.state.calendar.multi_group_schedule_slots[((query.interval || 'day')+','+day)].map((slot) => <a style={{position: 'relative', paddingTop: '10px'}}>
                        <span>
                          <div className={`position-relative ${queryString.parse(this.props.location.search)['multiple-group-camaign'] == slot._id ? 'selected' : ''}`}>
                            <div className={'multi-schedule-campaign'} onClick={ this.props.updateSearch.bind(this, {"multiple-group-camaign": slot._id, "interval": query.interval, "day": day}) } currentitem="false" style={{
                              background: slot.campaign && slot.campaign.color,
                            }}>
                              {slot.campaign && slot.campaign.name} ({slot.count})
                            </div>
                            
                            <span onClick={ this.props.updateSearch.bind(this, {path: [':lang', 'console','multiple-group-camaign', slot._id]}) } className="edit-button badge">{this.props.t('Edit')}</span>
                          </div>
                        </span>
                      </a>)}

                      {(queryString.parse(this.props.location.search).interval === 'week' || queryString.parse(this.props.location.search).interval === 'month') && this.props.automatic && <a style={{position: 'relative', paddingTop: '10px'}}>
                        <span>
                          <div className="position-relative add-multi-group-campaign-schedule">
                            <div onClick={ this.props.updateSearch.bind(this, {path: [':lang', 'console','multiple-group-camaign'], "multiple-group-camaign": '', "edit-multiple-group-camaign": "1", "interval": query.interval, "day": day}, 'open_add_new_automatic_schedule_campaign') } className currentitem="false" style={{
                              color: 'black',
                              lineHeight: '41px',
                              textAlign: 'center',
                              width: '100%',
                              fontWeight: 'bold',
                              height: '40px',
                              background: '#d4d4df',
                              borderRadius: '100%',
                            }}>
                              {<FaPlus/>} {t('Multi group campaign')} {<FaPlus/>}
                            </div>
                          </div>
                        </span>
                      </a>}





                    {this.state.multi_select.length && <button onClick={() => {
                        this.setState({multi_select: [].concat.apply([], this.state.slots.map((slot) => {
                        // return slot;
                        if (this.state.calendar.campaigns[day + ',' + slot]) {
                            return this.state.calendar.campaigns[day + ',' + slot].map((a) => {
                                return a._id;
                            });
                        } else {
                            return [];
                        }
                    }))})
                    }}>Select All Campaigns {index}</button> || ''}

                        {this.state.multi_select.length && <button onClick={this.props.updateSearch.bind(this, {'edit-scheduled-camaign': this.state.multi_select.join(',') })}>Edit Selected</button> || ''}
                        {this.state.multi_select.length && <button className={"btn btn-danger"} onClick={this.mass_delete_campaign_slots.bind(this)}>Delete Selected</button> || ''}
                </a></div>))}
              </div>
              {!this.props.automatic && <div className=" border shadow my-4 overflow-auto mh-75">
              	{this.state.slots.map((slot) => (
	                <div className="m_row">
	                  <div className={`hour m_col p-4 text-muted calendar-column ${slot == new Date().getHours().toString() && 'current-hour'}`}><strong>{slot}:00</strong> ({this.state.calendar.total_count && this.state.calendar.total_count[slot]} posts)</div>
	                  {this.state.days.slice(0,  Math.min(Math.floor((this.state.elem_width-172)/172), 7) ).map((day) => (<div className={`m_col calendar-column border-bottom border-start ${slot == new Date().getHours().toString() && 'current-hour'} ${day == this.state.current.day && 'current-day'}`}>
					          {this.state.calendar.slots && <Dropbox
					            accept={this.props.automatic ? ['CARD', 'CAMPAIGN'] : ['CARD', 'PROFILE']}
                                automatic={this.props.automatic}
					            onDrop={(a,b,c,d,e,f,g) => this.handleDrop(a,b,c,d,e,f,g)}
					            key={"helloooooooo"}
					            day={day}
                                t={t}
					            slot={{slot, day, groups: this.state.calendar.groups[day + ',' + slot] || [], campaigns: this.state.calendar.campaigns[day + ',' + slot] || [], posts: this.state.calendar.slots[day + ',' + slot] || []}}
					            _delete={this._delete}
					            props={this.props}
                                interval={query.interval}
					            set_selected={this.set_selected.bind(this)}
                                set_selected_group={this.set_selected_group.bind(this)}
					            that={this}
					            remove={this.remove.bind(this, slot, day)}
                                removeCampaign={this.removeCampaign.bind(this, slot, day)}
					            selected={this.state.selected}
                                live_events={this.state.live_events}
                                multi_select={this.state.multi_select}
					            updateSearch={this.props.updateSearch}
                                query={queryString.parse(this.props.location.search)}
                                multi_group_slots={this.state.calendar.multi_group_schedule_slots[((query.interval || 'day')+','+day)]}
					          /> }





	                    {/*<aside id="secondary_menu" className="px-1 py-1 rounded-3 small text-white position-absolute bottom-0 start-50 translate-middle-x d-flex">
	                      <div className="d-flex px-1 me-2">
	                        <img width="11px" className="mx-1" src={like} g="icons/like.svg" alt="" />
	                        <span>999K</span>
	                        <img width="15px" className="mx-1" src={chat} g="icons/chat.svg" alt="" />
	                        <span>999K</span>
	                      </div>
	                      <div className="d-flex px-1">
	                        <a className="mx-1" href="#"><img width="15px" src={monitor} g="icons/monitor.svg" alt="" /></a>
	                        <a className="mx-1" href="#"><img width="15px" src={settings} g="icons/settings.svg" alt="" /></a>
	                        <a className="mx-1" href="#"><img width="15px" src={bin} g="icons/bin.svg" alt="" /></a>
	                      </div>
	                    </aside>*/}



	                  </div>))}
	                </div>
              	))}
              </div>}
	        </React.Fragment>
        );
    }
}

Calendar.propTypes = {};

Calendar.defaultProps = {};

export default withRouter(Calendar);
