import logo from './assets/images/logo.png';
import settings from './assets/icons/settings.svg';
import help from './assets/icons/help.svg';
import alert from './assets/icons/alert.svg';
import signin from './assets/icons/signin.svg';
import clock from './assets/icons/clock.svg';
import add from './assets/icons/add.svg';
import user1 from './assets/images/user1.png';
import user2 from './assets/images/user2.png';
import user3 from './assets/images/user3.png';
import user4 from './assets/images/user4.png';
import post1 from './assets/images/post1.png';
import post2 from './assets/images/post2.png';
import chat from './assets/icons/chat.svg';
import monitor from './assets/icons/monitor.svg';
import bin from './assets/icons/bin.svg';
import group1 from './assets/images/group1.png';
import account from './assets/icons/account.svg';
import password from './assets/icons/password.svg';
import like from './assets/icons/like.svg';
import follower from './assets/icons/follower.svg';

import Home from './components/Home/Home.js';
import Activate from './components/Activate/Activate.js';
import Faq from './components/Faq/Faq.js';
import Contact from './components/Contact/Contact.js';
import Plans from './components/Plans/Plans.js';
import Terms from './components/Terms/Terms.js';
import Header2 from './components/Header2/Header2.js';
import Footer from './components/Footer/Footer.js';
import Header from './components/Header/Header.js';
import Login from './components/Login/Login.js';
import PostPopup from './components/PostPopup/PostPopup.js';
import JoinGroupPopup from './components/JoinGroupPopup/JoinGroupPopup.js';
import CreateCampaignPopup from './components/CreateCampaignPopup/CreateCampaignPopup.js';
import Tutorial from './components/Tutorial/Tutorial.js';
import Management from './components/Management/Management.js';

import CreateUserPopup from './components/CreateUserPopup/CreateUserPopup.js';
import SidePane from './components/SidePane/SidePane.js';
import LockedUserPopup from './components/LockedUserPopup/LockedUserPopup.js';
import NewUserCreatePopup from './components/NewUserCreatePopup/NewUserCreatePopup.js';
import CreateGroupPopup from './components/CreateGroupPopup/CreateGroupPopup.js';
import GroupSettingsPopup from './components/GroupSettingsPopup/GroupSettingsPopup.js';
import ManageGroupsPopup from './components/ManageGroupsPopup/ManageGroupsPopup.js';
import ManageGroupSettings from './components/ManageGroupSettings/ManageGroupSettings.js';
import CreatePostPopup from './components/CreatePostPopup/CreatePostPopup.js';
import SettingsPopup from './components/SettingsPopup/SettingsPopup.js';
import ProgramSettings from './components/ProgramSettings/ProgramSettings.js';
import ScheduleCampaignPopup from './components/ScheduleCampaignPopup/ScheduleCampaignPopup.js';
import ScheduleMultiGroupPopup from './components/ScheduleMultiGroupPopup/ScheduleMultiGroupPopup.js';
import SchedulePostPopup from './components/SchedulePostPopup/SchedulePostPopup.js';
import CreateCommentGroupPopup from './components/CreateCommentGroupPopup/CreateCommentGroupPopup.js';
import ManageCommentsPopup from './components/ManageCommentsPopup/ManageCommentsPopup.js';
import ManageGroupLibraries from './components/ManageGroupLibraries/ManageGroupLibraries.js';
import CreateGroupLibrary from './components/CreateGroupLibrary/CreateGroupLibrary.js';
import Stats from './components/Stats/Stats.js';

import Profiles from './components/Profiles/Profiles.js';
import Campaigns from './components/Campaigns/Campaigns.js';
import Groups from './components/Groups/Groups.js';
import Posts from './components/Posts/Posts.js';
import Calendar from './components/Calendar/Calendar.js';

// @import 'react-user-guide/dist/custom-style.css';
import 'semantic-ui-css/semantic.min.css'
import './assets/css/main.scss';
import './assets/css/app.css';
import 'react-notifications/lib/notifications.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import './decode/css/animate.css'; // Import css
import './decode/css/bootstrap.min.css'; // Import css
import './decode/css/slick.css'; // Import css
import './decode/css/materialdesignicons.min.css'; // Import css
import './decode/css/line-awesome.min.css'; // Import css
import './decode/css/fontawesome.min.css'; // Import css
import './decode/css/style.css'; // Import css
import './decode/css/rtl-style.css'; // Import css
import './decode/css/colors/default-color.css'; // Import css
import tutorialEventBus from "./tutorialEventBus";
import sidePaneEventBus from "./sidePaneEventBus";


    //     <link href="/css/animate.css" rel="stylesheet" type="text/css"  />
    //     <link href="/css/bootstrap.min.css" rel="stylesheet" type="text/css" />
    //     <link href="/css/slick.css" rel="stylesheet" type="text/css"  />
    //     <link href="/css/materialdesignicons.min.css" rel="stylesheet" type="text/css" />
    //     <link href="/css/line-awesome.min.css" rel="stylesheet" type="text/css" />
    //     <link href="/css/fontawesome.min.css" rel="stylesheet" type="text/css" />
    //     <link href="/css/style.css" rel="stylesheet" type="text/css" id="theme-default" />
    // <link href="/css/rtl-style.css" rel="stylesheet" type="text/css" id="theme-default" />
    //     <link href="/css/rtl-style.css" rel="stylesheet" type="text/css" id="rtl-theme-default" disabled="disabled" />
    //     <link href="/css/colors/default-color.css" rel="stylesheet" type="text/css" id="theme-color" />



import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import ReactTooltip from 'react-tooltip';


import {Button} from 'react-bootstrap/Button';
import {DropdownButton, ButtonGroup, Dropdown} from 'react-bootstrap';

import { useTranslation, initReactI18next, withTranslation } from 'react-i18next';
import i18n from "i18next";
import React, { useEffect, Component, useState, useLayoutEffect, useRef } from 'react'
import fetch from 'node-fetch';

import queryString from 'query-string';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  withRouter,
  useLocation
} from "react-router-dom";


const UserGuide_style = {
  width: '20vw',
  backgroundColor: 'grey',
  marginTop: '20vh',
  marginLeft: '40vw',
  padding: 5
};

const UserGuide_buttonConfig = {
  yesText: 'כן',
  noText: 'לא',
  nextText: 'הבא',
  skipText: 'דלג',
  finishText: 'סיים',
};

const guides = [
  {
    querySelector: '.add-new-user',
    position: 'east',
    title: 'Adding Users',
    message: 'Click here to add facebook profiles'
  },
  {
    querySelector: '.new-campaign',
    position: 'east',
    title: 'Second',
    message: 'Click here to add campigns'
  },
];

function switchLang(lang) {
  i18n.use(initReactI18next).init({lng: lang});
}

const base = '/:locale(en|he)';

class App extends Component {

  state = {
    user: null,
    logged_in: true,
    query: {},
    host: window.location.hostname,
  }

  tutorialEvent(action) {
    var progress = action.progress === undefined ? 100 : action.progress;

    tutorialEventBus.dispatch("action", {
      action: action.action,
      progress: progress,
    });
  }
  sidePaneEvent(changes) {
    sidePaneEventBus.dispatch("side_pane_change", changes);
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();

    if (this.state.logged_in && this.state.user && !this.state.user.email_verified && this.props.match.params.page !== 'activate') {
      this.updateSearch({path: [':lang', 'activate']});
    } else if (this.state.logged_in && this.state.user && this.state.user.email_verified && !this.state.user.active_plan && this.props.match.params.page !== 'plans') {
      this.updateSearch({path: [':lang', 'plans']});
    } else if (this.state.logged_in && this.state.user && (this.props.match.params.page === 'login' || this.props.match.params.page === 'register')) {
      this.updateSearch({path: [':lang', 'home']});
    }

    if (this.state.logged_in && this.state.user && this.state.user.email_verified && this.props.match.params.page === 'activate') {
      // if (this.props.match.params.page !== 'activate') {
         this.updateSearch({path: [':lang', 'home']})
      // }
    }
  }
  componentWillMount() {
    const { history } = this.props;
    this.unsubscribeFromHistory = history.listen(this.handleLocationChange);
    this.handleLocationChange(history.location);

    this.checkLoggedIn();

    var query = queryString.parse(this.props.location.search);

    this.setState({query: query})

  }

  componentWillUnmount() {
    if (this.unsubscribeFromHistory) this.unsubscribeFromHistory();
  }

  handleLocationChange = (location) => {
    // Do something with the location
    setTimeout(() => {
      switchLang(this.props.match.params.lang);
    }, 0);
  }

  checkLoggedIn = () => {
    var token = localStorage.getItem('user_token');

    if (token) {
      if (!this.state.old_token) {
        this.setState({old_token: token});
      }
      setInterval(() => {
        if (this.state.old_token && localStorage.getItem('user_token') && this.state.old_token !== localStorage.getItem('user_token')) {
          // window.alert('session has changed');
          window.location.reload();
        }
      }, 10000)
    }

    if (token) {
      this.setState({
        logged_in: true,
      })
    } else {
      this.setState({
        user: null,
        logged_in: false,
      })      
      return;
    }

    fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/user?access_token=${token}`, {})
    .then(response => response.json())
    .then(data => {
      this.setState({
        user: data,
      });
    })
    .catch(err => {
      // localStorage.setItem('user_token', "");
    })

  }

  logout() {
    localStorage.setItem('user_token', "");

    NotificationManager.success('Logged out successfully');

    this.checkLoggedIn();

    this.updateSearch({path: [':lang', 'home']})
  }

  updateSearch(options, tutorial_action) {
    if (tutorial_action) {
      this.tutorialEvent({action: `${tutorial_action}`});
    }

    var query = queryString.parse(this.props.location.search);

    // this.setState({query: query});

    // window.scrollTo(0, 0);

    if (options.path && Array.isArray(options.path)) {

      var _path = [];

      for (var i = 0; i < options.path.length; i++) {
        if (options.path[i][0] === ':') {
          var key = options.path[i].substring(1)
          _path.push(this.props.match.params[key])
        } else {
          _path.push(options.path[i])
        }
      }

      _path = "/" + _path.join('/')

      delete options.path;
    }

    query = Object.assign(query, options);
    

    Object.keys(query).forEach(key => query[key] === undefined ? delete query[key] : {});

    var path = _path || this.props.location.pathname;
    // console.log(555, query, this.props);
    this.tutorialEvent(`route_${path}`);

    this.props.history.push({
        pathname: path,
        search: "?" + new URLSearchParams(query).toString()
    });
  }


    set_calendar_element(el) {
      if (el) {
        if (!this.state.calendar_el) {
          // window.alert(el)
          this.setState({
            calendar_el: el
          });
        }
      }
    }
    render() {
      const { t, i18n } = this.props;

      var page = (    <React.Fragment>

        {queryString.parse(this.props.location.search)['tutorial'] && <Tutorial {... this.props} tutorialEvent={this.tutorialEvent.bind(this)} updateSearch={this.updateSearch.bind(this)}/>}
        
        {false && <Management {... this.props} updateSearch={this.updateSearch.bind(this)}/>}


        {queryString.parse(this.props.location.search)['auth-key'] && (() => {
          // window.alert(124)
          this.updateSearch({
            "auth-key": undefined
          })
          localStorage.setItem('user_token', queryString.parse(this.props.location.search)['auth-key'])
          window.location.reload();
        })()}

    {!this.state.logged_in && <Route exact path={'/he'}><Redirect exact from="/he" to="/he/home" /></Route>}
    {!this.state.logged_in && <Route exact path={'/en'}><Redirect exact from="/en" to="/he/home" /></Route>}
    {this.state.logged_in && <Route exact path={'/he'}><Redirect exact from="/he" to="/he/console" /></Route>}
    {this.state.logged_in && <Route exact path={'/en'}><Redirect exact from="/en" to="/he/console" /></Route>}
    
    {this.state.logged_in && this.state.user && !this.state.user.email_verified && <Redirect to="/he/activate" />}
    
    <Route exact path={'/'}>
      <Redirect exact from="/" to="/he" />
    </Route>



      <div className="full-page" style={{transition: 'all 0.5s ease-in-out', float: 'left', width: '100%'}}>
        <div className="box-wrapper">
          <Header2 {... this.props} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)} user={this.state.user} logged_in={this.state.logged_in} logout={this.logout.bind(this)}/>

          <Route path='/:lang/home'>
            <Home {... this.props}/>
          </Route>
          <Route path='/:lang/activate'>
            {this.state.user && <Activate checkLoggedIn={this.checkLoggedIn.bind(this)} user={this.state.user} page={'activate'} {... this.props} checkLoggedIn={this.checkLoggedIn.bind(this)} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)}/>}
          </Route>
          <Switch>
            <Route path='/:lang/faq/:section_id?/:question_id?'>
              <Faq {... this.props} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)}/>
            </Route>
          </Switch>
          <Route path='/:lang/terms-of-service'>
            <Terms {... this.props}/>
          </Route>
          <Route path='/:lang/contact'>
            <Contact {... this.props}/>
          </Route>
          <Route path='/:lang/support'>
            <Contact {... this.props}/>
          </Route>
          <Route path='/:lang/plans/:param1?/:plan_id?'>
            <Plans {... this.props} checkLoggedIn={this.checkLoggedIn.bind(this)} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)} user={this.state.user}/>
          </Route>
          <Route path='/:lang/login'>
            <Login page={'login'} {... this.props} checkLoggedIn={this.checkLoggedIn.bind(this)} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)}/>
          </Route>
          <Route path='/:lang/forgot-password'>
            <Login page={'forgot-password'} {... this.props} checkLoggedIn={this.checkLoggedIn.bind(this)} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)}/>
          </Route>
          <Route path='/:lang/forgot-password-confirm'>
            <Login page={'forgot-password-confirm'} {... this.props} checkLoggedIn={this.checkLoggedIn.bind(this)} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)}/>
          </Route>
          <Route path='/:lang/sent-email'>
            <Login page={'sent-email'} {... this.props} checkLoggedIn={this.checkLoggedIn.bind(this)} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)}/>
          </Route>
          <Route path='/:lang/register'>
            <Login page={'register'} {... this.props} checkLoggedIn={this.checkLoggedIn.bind(this)} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)}/>
          </Route>
          <Route path='/:lang/console'>

          {!this.state.logged_in && <Route exact path={'/he/:asf?'}><Redirect exact from="/he" to="/he/home" /></Route>}
          {!this.state.logged_in && <Route exact path={'/en/:asf?'}><Redirect exact from="/en" to="/he/home" /></Route>}
          {this.state.logged_in && <Route exact path={'/he'}><Redirect exact from="/he" to="/he/console" /></Route>}
          {this.state.logged_in && <Route exact path={'/en'}><Redirect exact from="/en" to="/he/console" /></Route>}
          
          {this.state.host === 'fbzipper.com' && <Route exact path={'/:lang/console'} component={() => {
              window.location.href = 'http://console.fbzipper.com';
              return null;
          }}></Route>}
        
        {/*<style>{`body { height: 100%; overflow: hidden; }`}</style>*/}

          <div id="main-wrapper" className="page-wrapper">
            <div className="page-header section-padding style-dark full-height dc-two pb-0">
              <div className="container-fluid" style={{paddingLeft: '0px', paddingRight: '0px'}}>

                <div>
                  <ReactTooltip place="top" effect="solid"/>
                  <p data-tip="hello world" style={{display: 'none'}}>this makes the tooltip work for some reason</p>
                  <DndProvider backend={HTML5Backend}>

                    <Route path={base}>
                      <div className="App">
                      {/*<Header {... this.props} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)} user={this.state.user} logged_in={this.state.logged_in} logout={this.logout.bind(this)}/>*/}
                      
                      <main className="p-3">
                        <div className="header-stats-section"> 
                          <Stats {... this.props} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)} user={this.state.user}/>
                        </div>
                        <div className="header-section" style={{background: 'rgb(255 255 255)'}}> 
                          
                          <Profiles {... this.props} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)} user={this.state.user}/>
                          
                          <Campaigns {... this.props} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)} user={this.state.user}/>
                        
                        </div>

                        <section id="main" className="">
                          <aside id="posts" className="">
                            <div className="mh-75 mt-2">
                              <Posts {... this.props} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)} user={this.state.user}/>
                            </div>
                          </aside>
                          <section id="calendar" className="" ref={element => this.set_calendar_element(element)}>
                            <Switch>
                              {/*<Route path='/:lang/console/schedule/automatic/:interval/:period?'>
                                {this.state.calendar_el && <Calendar calendarel={this.state.calendar_el} width={1500} automatic={true} {... this.props} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)} user={this.state.user}/>}
                              </Route>*/}
                              <Route path='/'>
                                {this.state.calendar_el && <Calendar calendarel={this.state.calendar_el} width={1500} automatic={true} {... this.props} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)} user={this.state.user}/>}
                                {this.state.calendar_el && <Calendar calendarel={this.state.calendar_el} width={1500} {... this.props} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)} user={this.state.user}/>}
                              </Route>
                            </Switch>
                          </section>
                          <aside id="groups" className="">
                            <Groups {... this.props} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)} user={this.state.user}/>
                          </aside>
                        </section>
                      </main>

                      <Route path='/:lang/console/login'>
                        <Login {... this.props} checkLoggedIn={this.checkLoggedIn.bind(this)}/>
                      </Route>

                      <Route path='/:lang/console/program-settings' exact>
                        <SettingsPopup {... this.props} checkLoggedIn={this.checkLoggedIn.bind(this)} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)}/>
                      </Route>

                      <Route path='/:lang/console/settings' exact>
                        <ProgramSettings {... this.props} user={this.state.user} checkLoggedIn={this.checkLoggedIn.bind(this)} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)}/>
                      </Route>

                      <Route path='/:lang/console/post/add' exact>
                        {<CreatePostPopup {... this.props} user={this.state.user} checkLoggedIn={this.checkLoggedIn.bind(this)} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)}/>}
                      </Route>
                      <Route path='/:lang/console/post/edit/:post_id' exact>
                        <CreatePostPopup  edit={true} user={this.state.user} {... this.props} checkLoggedIn={this.checkLoggedIn.bind(this)} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)}/>
                      </Route>
                      <Route path='/:lang/console/slot/:post_id' exact>
                        <PostPopup {... this.props} checkLoggedIn={this.checkLoggedIn.bind(this)} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)}/>
                      </Route>
                      <Route path='/:lang/console/multiple-group-camaign/:multiple_group_camaign_id?' exact>
                      <ScheduleMultiGroupPopup {... this.props} user={this.state.user} checkLoggedIn={this.checkLoggedIn.bind(this)} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)}/>
                      </Route>
                      <Route path='/:lang/console/join-group/:post_id' exact>
                        <JoinGroupPopup {... this.props} checkLoggedIn={this.checkLoggedIn.bind(this)} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)}/>
                      </Route>

                      {queryString.parse(this.props.location.search)['edit-scheduled-camaign'] && <ScheduleCampaignPopup {... this.props} checkLoggedIn={this.checkLoggedIn.bind(this)} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)}/>}
                      
                      {/*<Route path='/:lang/console/schedule/automatic/:interval/:period?'>                      
                        {Object.keys(queryString.parse(this.props.location.search)).indexOf('multiple-group-camaign') > -1 && Object.keys(queryString.parse(this.props.location.search)).indexOf('edit-multiple-group-camaign') > -1 && <ScheduleMultiGroupPopup {... this.props} checkLoggedIn={this.checkLoggedIn.bind(this)} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)}/>}
                      </Route>*/}

                      {/*this.state.host === 'old.fbzipper.com' &&*/ <Route path='/:lang/console'>
                        {Object.keys(queryString.parse(this.props.location.search)).indexOf('schedule-new-post') > -1 && <SchedulePostPopup {... this.props} checkLoggedIn={this.checkLoggedIn.bind(this)} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)}/>}
                      </Route>}

                      <Route path='/:lang/console/group-library' exact>
                        <ManageGroupLibraries {... this.props} checkLoggedIn={this.checkLoggedIn.bind(this)} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)}/>
                      </Route>
                      <Route path='/:lang/console/group-library/add'>
                        <ManageGroupsPopup {... this.props} groupLibrary={true} checkLoggedIn={this.checkLoggedIn.bind(this)} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)}/>
                      </Route>
                      <Route path='/:lang/console/group-library/edit/:library_id'>
                        <ManageGroupsPopup edit={true} {... this.props} groupLibrary={true} checkLoggedIn={this.checkLoggedIn.bind(this)} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)}/>
                      </Route>

                      <Route path='/:lang/console/comment-library'>
                        <ManageCommentsPopup {... this.props} checkLoggedIn={this.checkLoggedIn.bind(this)} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)}/>
                      </Route>
                      <Route path='/:lang/console/comment-library/add'>
                        <CreateCommentGroupPopup {... this.props} checkLoggedIn={this.checkLoggedIn.bind(this)} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)}/>
                      </Route>
                      <Route path='/:lang/console/comment-library/edit/:comment_id'>
                        <CreateCommentGroupPopup edit={true} {... this.props} checkLoggedIn={this.checkLoggedIn.bind(this)} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)}/>
                      </Route>
                      {this.state.user && <Route path='/:lang/console/campaign/add'>
                        <CreateCampaignPopup {... this.props} user={this.state.user} checkLoggedIn={this.checkLoggedIn.bind(this)} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)}/>
                      </Route>}
                      <Route path='/:lang/console/groups/:group_id'>
                        <GroupSettingsPopup {... this.props} user={this.state.user} checkLoggedIn={this.checkLoggedIn.bind(this)} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)}/>
                      </Route>
                      {this.state.user && <Route path='/:lang/console/campaign/edit/:campaign_id'>
                        <CreateCampaignPopup edit={true} user={this.state.user} {... this.props} checkLoggedIn={this.checkLoggedIn.bind(this)} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)}/>
                      </Route>}
                      <Route path='/:lang/console/locked_users'>
                        <LockedUserPopup {... this.props} checkLoggedIn={this.checkLoggedIn.bind(this)} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)}/>
                      </Route>
                      <Route path='/:lang/console/new_users'>
                        <NewUserCreatePopup {... this.props} checkLoggedIn={this.checkLoggedIn.bind(this)} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)}/>
                      </Route>

                      <SidePane {... this.props} query={queryString.parse(this.props.location.search)} checkLoggedIn={this.checkLoggedIn.bind(this)} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)}/>

                      {this.state.user && <Route path='/:lang/console/profile/add'>
                          <CreateUserPopup {... this.props} user={this.state.user} checkLoggedIn={this.checkLoggedIn.bind(this)} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)}/>
                      </Route>}
                      {this.state.user && <Route path='/:lang/console/profile/edit/:profile_id'>
                        <CreateUserPopup edit={true} user={this.state.user} {... this.props} checkLoggedIn={this.checkLoggedIn.bind(this)} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)}/>
                      </Route>}
                      <Route path='/:lang/console/group/add'>
                        <CreateGroupPopup {... this.props} checkLoggedIn={this.checkLoggedIn.bind(this)} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)}/>
                      </Route>
                      <Route path='/:lang/console/group/edit/:group_id'>
                        <CreateGroupPopup edit={true} {... this.props} checkLoggedIn={this.checkLoggedIn.bind(this)} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)}/>
                      </Route>

                      <Route path='/:lang/console/group/manage'>
                        <ManageGroupsPopup groupSettings={true} {... this.props} checkLoggedIn={this.checkLoggedIn.bind(this)} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)}/>
                      </Route>
                      </div> 
                    </Route>
                  </DndProvider>
                </div>


              </div>
            </div>
          </div>




          </Route>

          {this.props.location.pathname.indexOf('console') === -1 && <Footer {... this.props} updateSearch={this.updateSearch.bind(this)} tutorialEvent={this.tutorialEvent.bind(this)} sidePaneEvent={this.sidePaneEvent.bind(this)}/>}
        </div>
        {this.state.user && this.state.user.active_plan && this.state.user.active_plan.next_payment_data && <div className="page-footer">
          דרוש תשלום החל מ: {this.state.user.active_plan.next_payment_data}</div>
        }
      </div>

          <NotificationContainer/>

          

          </React.Fragment>
);
      
        return page;
  

    }
}
export default withRouter(withTranslation()(props => <App {...props}/>));

