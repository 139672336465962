import React from 'react';
import PropTypes from 'prop-types';
import styles from './ScheduleMultiGroupPopup.module.scss';
import fetch from 'node-fetch';
import md5 from 'md5';
import _ from 'lodash';
import Multiselect from 'multiselect-react-dropdown';
import GroupSearch from '../ManageGroupsPopup/GroupSearch';
import {Checkbox, RadioGroup} from "@material-ui/core";

import settings from '../../assets/icons/settings.svg';
import logo from '../../assets/images/logo.png';
import help from '../../assets/icons/help.svg';
import alert from '../../assets/icons/alert.svg';
import signin from '../../assets/icons/signin.svg';
import clock from '../../assets/icons/clock.svg';
import add from '../../assets/icons/add.svg';
import user1 from '../../assets/images/user1.png';
import user2 from '../../assets/images/user2.png';
import user3 from '../../assets/images/user3.png';
import user4 from '../../assets/images/user4.png';
import post1 from '../../assets/images/post1.png';
import post2 from '../../assets/images/post2.png';
import chat from '../../assets/icons/chat.svg';
import monitor from '../../assets/icons/monitor.svg';
import bin from '../../assets/icons/bin.svg';
import group1 from '../../assets/images/group1.png';
import account from '../../assets/icons/account.svg';
import password from '../../assets/icons/password.svg';
import like from '../../assets/icons/like.svg';
import follower from '../../assets/icons/follower.svg';
import { RadioButton } from 'primereact/radiobutton';

import {Button} from 'react-bootstrap/Button';
import moment from 'moment';
import {DropdownButton, ButtonGroup, Dropdown} from 'react-bootstrap';

import {NotificationContainer, NotificationManager} from 'react-notifications';

import { useTranslation, initReactI18next, withTranslation } from 'react-i18next';

import ReactTooltip from 'react-tooltip';
import {
  BrowserRouter as Router,
  Switch as SwitchRouter,
  Route,
  Link,
  Redirect,
  withRouter,
  useLocation
} from "react-router-dom";

import queryString from 'query-string';


import DateFnsUtils from '@date-io/date-fns';
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Switch from "@material-ui/core/Switch";



import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

import { FaLock, FaClock, FaPeopleGroup,FaUserTie,FaRegQuestionCircle,FaRegIdCard, FaImage,FaCog, FaImages,FaUsers, FaUserPlus,FaRegCalendarAlt, aUsers, FaChartBar, FaAd, FaRegNewspaper, FaVideo, FaExternalLinkSquareAlt, FaEquals, FaCube, FaDollarSign, FaQuestionCircle, FaStepBackward, FaStepForward } from "react-icons/fa";

function nFormatter(num, digits) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function(item) {
    return num >= item.value;
  });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}

const PrettoSlider = withStyles({
  root: {
    color: '#52af77',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);



// select_campaign
// Select Posts
// Schedule
// Groups
// Custom Groups
// Profiles
// Settings
// Summary

class ScheduleMultiGroupPopup extends React.Component {
    state = {
      loading_footer_action: false,
      schedule_for_me: {
        amount: 1,
        selected_list: [{name: '4:00', id: 4}]
      },
      steps: [
        "select_campaign",
        // "select_posts",
        "profiles",
        "groups",
        "custom_groups",
        "settings",
        "summary",
      ],
      stats_view: 'week',
      "filter_query": {
        by_selected: {
          value: 'all',
          query: {},
        },
      },      
    	// current_step: 'select_campaign',
      current_step: 'select_campaign',
      // current_step: 'groups',
      complete_steps: {
        "select_campaign": () => {
          // return true;
          if (this.state.post.schedule_type === 'comments') {
            return this.state.post.comment_group && this.state.post.campaign._id;
          } else {
            return this.state.steps.indexOf(this.state.current_step) >= this.state.steps.indexOf('select_campaign') && this.state.post.campaign._id && this.state.post.settings.all_posts || this.state.post.selected_posts.length > 0;
          }
        },
        "select_posts": () => {
          return this.state.steps.indexOf(this.state.current_step) >= this.state.steps.indexOf('select_posts') && (this.state.post.settings.all_posts || this.state.post.selected_posts.length > 0);
        },
        "profiles": () => {
          return this.state.post.settings.post_from_all_fictitious || this.state.steps.indexOf(this.state.current_step) >= this.state.steps.indexOf('profiles') && (this.state.post.users.length > 0);
        },
        "groups": () => {
          return this.state.steps.indexOf(this.state.current_step) >= this.state.steps.indexOf('groups') && (this.state.post.group_libraries.length > 0 || this.state.post.selected_groups.length > 0);
        },
        "custom_groups": () => {
          return this.state.steps.indexOf(this.state.current_step) >= this.state.steps.indexOf('custom_groups') && (this.calulateAllPosts());
        },
        "settings": () => {
          return this.state.steps.indexOf(this.state.current_step) >= this.state.steps.indexOf('settings') && (this.state.steps.indexOf(this.state.current_step) >= this.state.steps.indexOf('select_campaign'));
        },
        "summary": () => {
          return false;
        },
      },
      post: null,
  	  selected: null,
      search_text: '',
      profiles: [],
      campaigns: null,
      posts: [],
      comment_groups: [],
      custom_group_settings: {}, 
      groups: [],
      edit_campaign_posts: [],

      selected_campaign: null,
      selected_edit_campaign: null,

      times: [
        '0',
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
      ]
    }

    refresh() {

    }

    selectAll(groups) {
      this.state.post.selected_groups = [...new Set(this.state.post.selected_groups.concat(groups.map((a) => a._id)))];
      this.setState({
        post: this.state.post
      });
    }

    next() {
      this.props.tutorialEvent({action: `finish_${this.state.current_step}`})




      this.select_step(this.state.steps[this.state.steps.indexOf(this.state.current_step) + 1]);

      this.setState({
        // current_step: this.state.steps[this.state.steps.indexOf(this.state.current_step) + 1],
        filter_query: {
          by_selected: {
            value: 'all',
            query: {},
          },
        },
      });
    }

    prev() {
      this.select_step(this.state.steps[this.state.steps.indexOf(this.state.current_step) - 1]);
    }

    select_step(step) {
      this.get_groups();
      this.setState({ current_step: step })
    }

    delete() {
      var token = localStorage.getItem('user_token');
        if (this.state.selected) {
          fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/delete-multi-campaign-slot?access_token=${token}&post=${this.state.selected}`, {})
          .then(response => response.json())
          .then(data => {
            NotificationManager.success('deleted successfully');
            this.props.updateSearch({'multiple-group-camaign': undefined, 'edit-multiple-group-camaign': undefined, "new-campaign": Math.random()})

          })
          .catch(err => {
            NotificationManager.error('Error');
          });
        }
    }
    schedule() {
      var token = localStorage.getItem('user_token');
        if (this.state.selected) {
          fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/aaaaaaaa?access_token=${token}&post=${this.state.selected}`, {})
          .then(response => response.json())
          .then(data => {
            NotificationManager.success('deleted successfully');
            this.props.updateSearch({'multiple-group-camaign': undefined, 'edit-multiple-group-camaign': undefined, "new-campaign": Math.random()})

          })
          .catch(err => {
            NotificationManager.error('Error');
          });
        }
    }



    saveCurrentAsLibrary() {
      var name = window.prompt(this.props.t('What should the library be called'));

      if (!name) {
        return;
      }

      var query = queryString.parse(this.props.location.search);

        var token = localStorage.getItem('user_token');


        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/create-group-library?access_token=${token}`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                selected: this.state.post.selected_groups,
                allow_permissions: [],
                name: name,
              }),
        })
        .then(response => response.json())
        .then(data => {
          NotificationManager.success('Added New library successfully');
          this.state.post.selected_groups = [];
          this.state.post.group_libraries.push(data._id);

          this.setState({
            post: this.state.post,
          });

          this.updateGroupLibraries();
        })
        .catch(err => {
          NotificationManager.error('Error adding group library');
          // this.setState({
          //   edit_library: false,
          // });


        })


    }

    save() {

      // this.setState({
      //   loading_footer_action: true,
      // });

      var query = queryString.parse(this.props.location.search);

        var token = localStorage.getItem('user_token');

        if (this.props.user.personal_plan) {
          this.state.post.settings.post_from_all_fictitious = false;
          this.state.post.settings.should_delete = false;
          this.state.post.settings.should_pump = false;
          this.state.post.settings.should_edit = false;
          this.state.post.settings.should_delete_time_after_publish = false;
        }

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/save-schedule-multi-group-campaign?access_token=${token}`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                post: this.state.post,
                interval: query.interval,
                day: query.day,
              }),
        })
        .then(response => response.json())
        .then(data => {
          NotificationManager.success('saved scheduled campaign successfully');
          this.props.updateSearch({path: [':lang', 'console'], "new-campaign": Math.random()})
          
          this.props.tutorialEvent({action: 'save_multi_campaign_automatic_schedule'})

          this.setState({
            loading_footer_action: false,
          });
        })
        .catch(err => {
          NotificationManager.error('Error adding campaign');
          this.setState({
            loading_footer_action: false,
          });


        })
    }
    saveGroupLibrary() {

      // this.setState({
      //   loading_footer_action: true,
      // });

      var query = queryString.parse(this.props.location.search);

        var token = localStorage.getItem('user_token');


        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/create-group-library?access_token=${token}`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                id: this.state.edit_library._id || null,
                selected: this.state.edit_library.facebook_groups || [],
                allow_permissions: this.state.allow_permissions || [],
                name: this.state.edit_library.name,
              }),
        })
        .then(response => response.json())
        .then(data => {
          NotificationManager.success('Edited group library successfully');

          this.setState({
            edit_library: undefined,
          });
        })
        .catch(err => {
          NotificationManager.error('Error saving group library');
          // this.setState({
          //   edit_library: false,
          // });


        })
    }
    get_posts(state_name, campaign_id) {
        var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/posts?access_token=${token}&campaign=${campaign_id}`, {})
        .then(response => response.json())
        .then(data => {
          var q = {};
          q[state_name] = data;
          this.setState(q);
        })
        .catch(err => {

        });
    }
    get_comments(campaign_id) {
        var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/comment-groups?access_token=${token}&campaign=${campaign_id}`, {})
        .then(response => response.json())
        .then(data => {
          var q = {};
          q.comment_groups = data;
          this.setState(q);
        })
        .catch(err => {

        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.post && this.state.post.settings.edit_campaign && this.state.post.settings.edit_campaign !== this.state.selected_edit_campaign) {
            this.setState({
                selected_edit_campaign: this.state.post.settings.edit_campaign,
            })
            setTimeout(() => {
                this.get_posts('edit_campaign_posts', this.state.post.settings.edit_campaign);
            }, 1);
        }

        if (this.state.post && this.state.post.campaign._id && this.state.post.campaign._id !== this.state.selected_campaign) {
            this.setState({
                selected_campaign: this.state.post.campaign._id,
            })
            setTimeout(() => {
                this.get_posts('posts', this.state.post.campaign._id);
                this.get_comments(this.state.post.campaign._id);
            }, 1);
        }

        ReactTooltip.rebuild();
    }

    group_map = {};

    get_groups = async () => {
      var token = localStorage.getItem('user_token');

      if (this.state.post.group_libraries && this.state.post.selected_groups) {
        var group_map_index = md5(JSON.stringify(this.state.post.group_libraries) + JSON.stringify(this.state.post.selected_groups) + JSON.stringify(this.state.post.users));
        // window.alert(group_map_index)
      }

      if (!this.group_map[group_map_index]) {
        var result = await (await fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/get-multi-campaign-slot-groups?access_token=${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            groups: this.state.post.selected_groups,
            group_libraries: this.state.post.group_libraries,
            campaign: this.state.post.campaign._id,
            selected_profiles: this.state.post.users,
          }),
        })).json();

        result.map((g) => {

          g.follow_count = g.follow_count || 0;


          this.state.post.group_settings = this.state.post.group_settings || {};
          this.state.post.group_settings[g._id] = this.state.post.group_settings[g._id] || {};
        })

        this.group_map[group_map_index] = result;

        this.setState({
          groups: result
        })

        // apply only if not selected
        if (!this.state.selected) {
          setTimeout(() => {
            this.apply_schedule_for_me()
          }, 1);
        }
      } else {
        this.setState({
          groups: this.group_map[group_map_index]
        })

      }

    }

    update_data(props) {
    	var token = localStorage.getItem('user_token');
        if (this.state.selected) {
          fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/get-multi-campaign-slot?access_token=${token}&post=${this.state.selected}`, {})
          .then(response => response.json())
          .then(data => {
            data.settings = data.settings || {};

            if (this.state.post && this.state.post.group_settings) {
              data.group_settings = this.state.post.group_settings;
            }
            // console.log(b.group_map[Object.keys(b.group_map)[0]], 7777)
          	// console.log(data.times[0].group_map, 6666);
            try {
              var amount = this.calulateGroupPosts(Object.keys(data.times[0].group_map)[0]);
            } catch (e) {
              var amount = 1;
            }

            this.setState({
          		post: data,
              schedule_for_me: {
                amount: amount,
                // amount: b.group_map[Object.keys(b.group_map)[0]],
                // amount: 5,
                selected_list: data.times.filter((a) => a.time).map( (time) => {
                  return {
                    name: `${time.time}:00`,
                    id: time.time,
                  }
                })
              },
              // current_step: 'summary'
              // current_step: 'groups'
              // current_step: 'custom_groups'
          	});

            this.select_step('custom_groups');
            // this.select_step('groups');

          })
          .catch(err => {

          });
        }


        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/profiles?access_token=${token}&real=1`, {})
        .then(response => response.json())
        .then(data => {
            this.setState({
                profiles: data,
            });



            if (!this.state.selected) {
              this.state.post.users = data.map((a) => a._id);

              this.setState({
                  post: this.state.post,
              });


            }
        })
        .catch(err => {

        });;

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/campaigns?access_token=${token}`, {})
        .then(response => response.json())
        .then(data => {
            this.setState({
                campaigns: data,
            });
        })
        .catch(err => {

        });


        // fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/groups?access_token=${token}`, {})
        // .then(response => response.json())
        // .then(data => {
        //   this.setState({
        //     groups: data,
        //   });
        //     this._sort();
        // })
        // .catch(err => {

        // });

        this.updateGroupLibraries();


    }

    updateGroupLibraries() {
      var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/group-libraries?all=true&access_token=${token}`, {})
        .then(response => response.json())
        .then(data => {
          this.setState({
            libraries: data,
          });
            this._sort();
        })
        .catch(err => {

        });
    }

    componentWillReceiveProps(props) {
      var id = this.props.match.params.rand2

    	if (id !== this.state.selected) {
  			setTimeout(() => {
          if (!this.props.user) {
            return;
          }
      this.setState({
        groups: [],

        post: {
          schedule_type: 'post',
          group_libraries: [],
          settings: {
            post_from_all_fictitious: this.props.user.personal_plan ? false : true,
            should_delete: this.props.user.personal_plan ? true : false,
            should_pump: this.props.user.personal_plan ? true : false,
            should_edit: this.props.user.personal_plan ? true : false,
            should_delete_time_after_publish: this.props.user.personal_plan ? true : false,
          },

          // times: [
          //   {
          //     time: 4,
          //     post_amount: 1,
          //     group_map: {},
          //   },
          //   {
          //     time: 12,
          //     group_map: {},
          //   },
          // ],
          times: new Array(17).fill(null).map((v, index) => {
            return {
              post_amount: 1,
              time: index+7,
              group_map: {},
            }
          }),
          campaign: {},
          users: [],

          selected_groups: [],
          selected_posts: [],
        },
        selected: this.props.match.params.rand2 || '',
        allow_edit: {
          profiles: true,
          max_post_amount: true,
          post_per_period: true,
          skip_period: true,
          should_delete: true,
          should_pump: true,
          should_edit: true,
        },
      });


  				this.update_data(props);	
  			}, 0)
    	}
    }

    componentWillMount(props) {
      var query = queryString.parse(this.props.location.search);

    	this.setState({
        post: {
          schedule_type: 'post',
          group_libraries: [],
          settings: {},
          times: [
            // {
            //   time: 4,
            //   post_amount: 1,
            //   group_map: {},
            // },
            // {
            //   time: 12,
            //   group_map: {},
            // },
          ],
          campaign: {},
          users: [],

          selected_groups: [],
          selected_posts: [],
        },
    		selected: this.props.match.params.rand2 || '',
        allow_edit: {
          profiles: true,
          max_post_amount: true,
          post_per_period: true,
          skip_period: true,
          should_delete: true,
          should_pump: true,
          should_edit: true,
        },
    	});
		
  		setTimeout(() => {
  			this.update_data(this.props);	
  		}, 0)
    }

    check_if_pending(date) {
    	var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/check-pending?access_token=${token}&post=${this.state.selected}`, {})
        .then(response => response.json())
        .then(data => {
        	NotificationManager.success('Yayyy..', 'Added to que');
        })
        .catch(err => {
        });
    }

    fromNow(date) {
    	// return moment(date).relativeTimeThreshold('m', 60);
    	return (moment(date)).fromNow();
    }

    togglUser(user_id) {
       this.state.post.users = _.xor(this.state.post.users, [user_id]);
       this.setState({
           users: this.state.post
       });
    }

    toggleAddGroup(group_id) {
      if (this.state.post.selected_groups.indexOf(group_id) > -1) {
        this.state.post.selected_groups.splice(this.state.post.selected_groups.indexOf(group_id), 1);
      } else {
        this.state.post.selected_groups.push(group_id);
      }

      this.setState({
        selected_groups: this.state.post.selected_groups,
      });
    }

    toggleAddPost(group_id) {
      if (this.state.post.selected_posts.indexOf(group_id) > -1) {
        this.state.post.selected_posts.splice(this.state.post.selected_posts.indexOf(group_id), 1);
      } else {
        this.state.post.selected_posts.push(group_id);
      }

      this.setState({
        selected_posts: this.state.post.selected_posts,
      });
    }

    toggleArray(array, value) {
      if (array.indexOf(value) > -1) {
        array.splice(array.indexOf(value), 1);
      } else {
        array.push(value);
      }

      return array;
    }

    getDisplayGroups(sort_by, search_text, amount_to_display, filter_by_profile, filter_query, selected, groups) {
      search_text = search_text || '';
      this.state.groups.map((g) => {
        g.follow_count = g.follow_count || 0;
      })

      var groups = _.orderBy(groups || this.state.groups, [sort_by, 'follow_count', 'today_slot_count', 'users_in_group_count'],['desc'])

      if (filter_query && filter_query.by_selected.value !== 'all') {

        groups = groups.filter((group) => {
          if (filter_query.by_selected.value === 'unselected') {
            return selected.indexOf(group._id) === -1;
          } else {
            return selected.indexOf(group._id) > -1;
          }
        })

        // 

      }

      if (filter_by_profile) {
        groups = groups.filter((group) => {
          return Object.keys(this.state.profile_group[filter_by_profile] || {}).indexOf(group._id) > -1
        })
      }

      return groups.filter((group) => {
          return group && group.name.toLowerCase().indexOf(search_text.toLowerCase()) > -1;
        })
        .slice(0,amount_to_display)

    }

    getCustomGroupSettings (time_slot, group_id) {
      time_slot.group_map[group_id] = time_slot.group_map[group_id] || {
        post_amount: null,
        active: false,
      };
      return time_slot.group_map[group_id];
    }

    getByGroupID (group_id) {
      if (Array.isArray(group_id)) {
        return group_id.map((_id) => {
          return this.state.groups.find((g) => g._id === _id);
        });
      }
      return this.state.groups.find((g) => g._id === group_id)
    }

    getByCampaignID (campaign_id) {
      if (Array.isArray(campaign_id)) {
        return campaign_id.map((_id) => {
          return this.state.campaigns.find((g) => g._id === _id);
        });
      }
      return this.state.campaigns.find((g) => g._id === campaign_id) || {}
    }

    calulateColumnPosts (time_slot) {
      // return 0;

      var amount = Object.keys(time_slot.group_map).reduce((amount, group_id) => {
        if (time_slot.time && time_slot.group_map[group_id]) {
          if (time_slot.group_map[group_id].active) {
            amount += parseInt(time_slot.group_map[group_id].post_amount) || 1;
          }
        }
        return amount;
      }, 0);

      return amount;
    }

    calulateAllPosts () {
      var amount = 0;
      
      for (var i = 0; i < this.state.post.times.length; i++) {
        var time_slot = this.state.post.times[i];
        time_slot.group_map = time_slot.group_map || {};
        amount += Object.keys(time_slot.group_map).reduce((amount, group_id) => {
          var group_exists;

          this.state.post.group_settings = this.state.post.group_settings || {};

          if (this.state.post.group_settings[group_id]) {
            group_exists = !this.state.post.group_settings[group_id].is_disabled; // .find((g) => g._id == group_id);
          }

          if (group_exists && time_slot.time && time_slot.group_map[group_id]) {
            if (time_slot.group_map[group_id].active) {
              amount += parseInt(time_slot.group_map[group_id].post_amount) || 1;
            }
          }
          return amount;
        }, 0);
      }

      return amount;
    }

    calulateGroupPosts (group_id) {
      var group = this.getByGroupID(group_id);

      var amount = this.state.post.times.reduce((amount, time) => {
        if (time.time && time.group_map[group_id]) {
          if (time.group_map[group_id].active) {
            amount += parseInt(time.group_map[group_id].post_amount) || 1;
          }
        }
        return amount;
      }, 0);

      return amount;
    }

    selectGroupRow (group_id) {
      var group = this.getByGroupID(group_id);
      this.state.post.times.filter((a) => a.time).map((time) => {
        var settings = this.getCustomGroupSettings(time, group_id);
        settings.active = true;
      });
      this.setState({post: this.state.post})
    }

    selectTimes (selected_list, selectedItem) {

      this.state.post.times.push({time: selectedItem.id, post_amount: 1, group_map: {}});
      this.setState({post: this.state.post})


      // this.state.schedule_for_me.selected_list = selected_list;
      // this.setState({schedule_for_me: this.state.schedule_for_me});
      // console.log(2134124, this.state.schedule_for_me.selected_list)
      // this.resetValues();
    }

    removeTimes (selected_list, selectedItem) {

      var time = this.state.post.times.find((time) => time.time === selectedItem.id);

      this.state.post.times.splice(this.state.post.times.indexOf(time), 1);
      // this.state.post.times.push({time: selectedItem.id, post_amount: 1, group_map: {}});
      this.setState({post: this.state.post})


      // this.state.schedule_for_me.selected_list = selected_list;
      // this.setState({schedule_for_me: this.state.schedule_for_me});
      // console.log(2134124, this.state.schedule_for_me.selected_list)
      // this.resetValues();
    }

    unselect_all() {

      this.state.post.times.filter((a) => a.time).map((time) => {

        Object.keys(time.group_map).map((group_id) => {
          if (time.group_map[group_id]) {
            delete time.group_map[group_id];
            // time.group_map[group_id].active = false;
          }
        })
      })

      this.setState({
        post: this.state.post,
      })

    }

    apply_schedule_for_me() {

      this.unselect_all();
      
      // return;

      var selected_list = _.orderBy(this.state.post.times, ['time']).map((time, index) => {
        return {
          name: `${time.time}:00`,
          id: time.time,
        }
      })

      if (!selected_list || !selected_list.length) {
        return NotificationManager.error('no times selected');
      }
      var times = [];
      for (var i = 0; i < selected_list.length; i++) {
        var selected_time = selected_list[i];
        var time = this.state.post.times.find((t) => {
          return t.time === selected_time.id;
        });

        if (!time) {
          time = {time: selected_time.id, post_amount: 1, group_map: {}};
          this.state.post.times.push(time);
        }

        times.push(time);

        // this.setState({post: this.state.post})
      }

        var groups = this.state.groups.map((g) => g._id)
        
        for (var n = 0; n < groups.length; n++) {
          var _times = [... new Set(times)];
          var g_id = groups[n];

          for (var b = 0; b < _times.length; b++) {
            var time = _times[b];
            // time.group_map[g_id] = {active: false, post_amount: 0};
          }
          
          for (var i = 0; i < this.state.schedule_for_me.amount; i++) {
            
              
              var lowest_amount_column = Math.min.apply(Math, _times.map((time) => {
                return this.calulateColumnPosts(time);
              }));

              var __times = _times.filter((time) => {
                return lowest_amount_column === this.calulateColumnPosts(time);
              })

              var time = __times[Math.floor(Math.random()*__times.length)];


              


              // _times.splice(_times.indexOf(time), 1);

              // if (_times.length === 0) {
              //   _times = [... new Set(times)];
              // }

              

              // this.getCustomGroupSettings(time, g_id)

              time.group_map[g_id] = time.group_map[g_id] || {
                post_amount: 0,
              };

              time.group_map[g_id].active = true;
              time.group_map[g_id].post_amount++;


              // time.group_map
          }
        }
        this.setState({
          post: this.state.post,
        })

        return NotificationManager.success(this.props.t('Schedule has been applied'));

      // 
      // 


      // this.state.schedule_for_me.selected_list
    }

    setActiveHour (group_map, _id) {
      _.set(group_map, `${_id}.active`, !_.get(group_map, `${_id}.active`, false))
      this.setState({
        post: this.state.post,
      })
    }


    buttons = [
      {
        label: 'Delete',
        tool_tip: 'Delete',
        class: 'delete-button',
        action: () => {
          this.delete();
        },
        should_display: () => {

          var query = queryString.parse(this.props.location.search);

          return query['multiple-group-camaign'];
        },
        class: 'danger'
      },          
      {
        label: 'Schedule Now',
        tool_tip: 'Schedule Now',
        class: 'ss-button',
        action: () => {
          this.schedule();
        },
        should_display: () => {

          var query = queryString.parse(this.props.location.search);

          return query['multiple-group-camaign'];
        },
        class: 'danger'
      },          
    ];

    extra_options = [
      {
        label: 'Stop Profile Activity',
        tool_tip: 'Stop Profile Activity',
        action: () => {
          this.stopProfileActivity();
        },
        should_display: () => {
          return !this.state.locked_account && this.props.match.params.profile_id;
        }
      },
      {
        label: 'Unlock',
        tool_tip: 'Unlock',
        action: () => {
          this.handleUnlock();
        },
        should_display: () => {
          return this.state.locked_account && this.props.match.params.profile_id;
        }
      },
      {
        label: 'Reset User Agent',
        tool_tip: 'Reset User Agent',
        action: () => {
          this.handleResetAgent();
        },
        should_display: () => {
          return this.props.match.params.profile_id;
        }
      },
    ];

    close_path = [':lang', 'console'];
    tabs = [
      {
        label: 'Settings',
        active: true,
        should_display: () => {
          return true;
        },
        sticky_header: () => <div>
          <div className="steps-form">
            <div className="steps-row setup-panel">
              <div className="steps-step">
                <a type="button" onClick={(e) => {this.select_step('select_campaign'); this.setState({ post: this.state.post })}} className={`btn btn-default btn-circle ${(this.state.current_step === 'select_campaign' ? 'active' : '')} ${this.state.complete_steps['select_campaign']() ? "success" : ''}`}>1</a>
                <p>{this.props.t('Select Campaign')}</p>
              </div>
              {/*<div className="steps-step">
                <a type="button" onClick={(e) => {this.select_step('select_posts'); this.setState({ post: this.state.post })}} className={`btn btn-default btn-circle ${(this.state.current_step === 'select_posts' ? 'active' : '')} ${this.state.complete_steps['select_posts']() ? "success" : ''}`} disabled="disabled">2</a>
                <p>{this.props.t('Select Posts')}</p>
              </div>*/}
              <div className="steps-step">
                <a type="button" onClick={(e) => {this.select_step('profiles'); this.setState({ post: this.state.post })}} className={`btn btn-default btn-circle ${(this.state.current_step === 'profiles' ? 'active' : '')} ${this.state.complete_steps['profiles']() ? "success" : ''}`} disabled="disabled">2</a>
                <p>{this.props.t('Select Profiles')}</p>
              </div>
              <div className="steps-step">
                <a type="button" onClick={(e) => {this.select_step('groups'); this.setState({ post: this.state.post })}} className={`btn btn-default btn-circle ${(this.state.current_step === 'groups' ? 'active' : '')} ${this.state.complete_steps['groups']() ? "success" : ''}`} disabled="disabled">3</a>
                <p>{this.props.t('Select Groups')}</p>
              </div>
              <div className="steps-step">
                <a type="button" onClick={(e) => {this.select_step('custom_groups'); this.setState({ post: this.state.post })}} className={`btn btn-default btn-circle ${(this.state.current_step === 'custom_groups' ? 'active' : '')} ${this.state.complete_steps['custom_groups']() ? "success" : ''}`} disabled="disabled">4</a>
                <p>{this.props.t('Schedule Groups')}</p>
              </div>
              <div className="steps-step">
                <a type="button" onClick={(e) => {this.select_step('settings'); this.setState({ post: this.state.post })}} className={`btn btn-default btn-circle ${(this.state.current_step === 'settings' ? 'active' : '')} ${this.state.complete_steps['settings']() ? "success" : ''}`} disabled="disabled">5</a>
                <p>{this.props.t('Settings')}</p>
              </div>
              <div className="steps-step">
                <a type="button" onClick={(e) => {this.select_step('summary'); this.setState({ post: this.state.post })}} className={`btn btn-default btn-circle ${(this.state.current_step === 'summary' ? 'active' : '')} ${this.state.complete_steps['summary']() ? "success" : ''}`} disabled="disabled"></a>
                <p>{this.props.t('Summary')}</p>
              </div>
            </div>
          </div>
        </div>,
        content: () => <div>


          <div className="container">
            <div className="row">
              <div action="" className={`needs-validation ${this.state.validate_form ? 'was-validated' : ''}`} noValidate="">

                {this.state.current_step === 'select_campaign' && <div>
                  <h4>{this.props.t('Select Campaign')}</h4>


                  <button type="button" onClick={() => {
                    this.state.post.schedule_type = 'post';
                    this.setState({post: this.state.post})
                  }} className={`btn ${this.state.post.schedule_type === 'post' ? 'btn-success' : 'btn-primary'}`}>{this.props.t('Post')}</button>
                  <button type="button" onClick={() => {
                    this.state.post.schedule_type = 'comments';
                    this.state.post.settings.all_posts = false;
                    this.setState({post: this.state.post})
                  }} className={`btn ${this.state.post.schedule_type === 'comments' ? 'btn-success' : 'btn-primary'}`}>{this.props.t('Comments')}</button>


                  <div className="form-group">
                    <p>{this.props.t('The first step you must choose your campaign')}</p>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{this.props.t('Select Campaign')} <span style={{color: ''}}>({this.props.t('Required')})</span></label>      
                    <select className="form-control" 
                      value={this.state.post.campaign._id} 
                      onChange={(e) => {

                        this.props.tutorialEvent({action: `finish_selected_campaign`})

                        this.state.post.campaign = this.state.campaigns.find((c) => c._id == e.target.value);
                        this.setState({post: this.state.post}) }}>
                      <option></option>
                      {this.state.campaigns.map((campaign)=>(<option value={campaign._id} key={campaign._id}>{campaign.name}</option>))}
                    </select>
                    <small id="emailHelp" className="form-text text-muted">{this.props.t(`Your advertising campaign you want to schedule`)}</small>
                  </div>


                  {this.state.post.campaign && this.state.post.campaign._id && <div>

                    <h4>{this.props.t('Select posts')}</h4>
                    <p>{this.props.t('In this step we will choose the posts that we want to publish within the campaign that we have selected')} <FaQuestionCircle data-tip={this.props.t('If you select more then one post, the program will rotate between the posts')}/></p>

                    {this.state.post.schedule_type !== 'comments' && <div>
                        {this.props.t('All posts')}
                        <Switch
                          classes={this.props.classes}
                          checked={this.state.post.settings.all_posts}
                          onClick={(e) => {
                            this.state.post.settings.all_posts = !this.state.post.settings.all_posts;
                            this.setState({
                              post: this.state.post,
                            })
                          }}
                        />
                    </div>}

                    {!this.state.post.settings.all_posts && <div>
                    <span>({this.state.post.selected_posts.length} {this.props.t('Posts selected')})</span>


                      {/*<input className="form-control" placeholder={this.props.t('Search Groups')} type="text" value={this.state.search_text} onChange={(e) => {this.setState({search_text: e.target.value})}}/>*/}

                      {this.state.post.schedule_type !== 'comments' && <div style={{overflowY: 'scroll', height: '500px', border: '1px solid black',}}>
                        {this.state.posts.map((post) => (<div className={`text-center`} style={{padding: '13px', width: '33%', float: 'left'}}>
                          <span dir="ltr" className={"text-decoration-none my-1 d-block square post " + (this.state.selected.indexOf(post._id) > -1 ? ' active': '') }>
                            <div className="rounded rounded-circle ">
                              {post.media_url && <img style={{left: 0}} className="post-image text-center img img-thumbnail img-fluid " src={post.media_url.split(',')[0]} g="img/post.png" alt="" />}
                              {(post.type === 'text-background' || post.type === 'text') && <span style={{backgroundColor: '#d37554'}} className="position-absolute top-50 start-50 translate-middle bg-dark-75 w-75 h-75">
                                <strong style={{color: '#000', textDecoration: 'underline'}}>{post.name}</strong>
                                <p className=" position-absolute top-50 start-50 translate-middle w-100 text-center text-white fw-bolder">
                                  {post.post_content.substring(0,100)}
                                  {post.post_content.length > 100 ? <span>...</span> : <span></span>}
                                </p>
                              </span>}
                            </div>
                          </span>
                          <button type="button" onClick={this.toggleAddPost.bind(this, post._id)} className={`btn ${(this.state.post.selected_posts.indexOf(post._id) > -1 ? 'btn-danger': 'btn-success')}`}>{(this.state.post.selected_posts.indexOf(post._id) > -1 ? this.props.t('Unselect'): this.props.t('Select'))}</button>
                        </div>))}
                      </div>}


                      {this.state.post.schedule_type === 'comments' && <div style={{overflowY: 'scroll', height: '500px', border: '1px solid black',}}>
                        {this.state.comment_groups.map((post) => (<div className={`text-center`} style={{padding: '13px', width: '33%', float: 'left'}}>
                          <span dir="ltr" className={"text-decoration-none my-1 d-block square post " + (this.state.selected.indexOf(post._id) > -1 ? ' active': '') }>
                            <div className="rounded rounded-circle ">
                              {<span style={{backgroundColor: '#d37554'}} className="position-absolute top-50 start-50 translate-middle bg-dark-75 w-75 h-75">
                                <p className=" position-absolute top-50 start-50 translate-middle w-100 text-center text-white fw-bolder">
                                  {post.name.substring(0,100)} ({post.advanced_comments.length} {this.props.t('Comments')})
                                  {post.name.length > 100 ? <span>...</span> : <span></span>}
                                </p>
                              </span>}
                            </div>
                          </span>
                          <button type="button" onClick={() => {
                            this.state.post.comment_group = post._id;
                            this.setState({post: this.state.post})
                          }} className={`btn ${post._id != this.state.post.comment_group ? 'btn-danger': 'btn-success'}`}>{(post._id != this.state.post.comment_group ? this.props.t('Unselect'): this.props.t('Select'))}</button>
                        </div>))}
                      </div>}
                    </div>}



                  </div>}

                </div>}

  {this.state.current_step === 'select_posts' && <div>
    <h4>{this.props.t('Select posts')}</h4>
    <p>{this.props.t('In this step we will choose the posts that we want to publish within the campaign that we have selected')}</p>

    <div>
        {this.props.t('All posts')}
        <Switch
          classes={this.props.classes}
          checked={this.state.post.settings.all_posts}
          onClick={(e) => { this.state.post.settings.all_posts = !this.state.post.settings.all_posts; this.setState({
            post: this.state.post,
          }) }}
        />
    </div>

    {!this.state.post.settings.all_posts && <div>
    <span>({this.state.post.selected_posts.length} {this.props.t('Posts selected')})</span>


      {/*<input className="form-control" placeholder={this.props.t('Search Groups')} type="text" value={this.state.search_text} onChange={(e) => {this.setState({search_text: e.target.value})}}/>*/}

      <div style={{overflowY: 'scroll', height: '500px', border: '1px solid black',}}>
        {this.state.posts.map((post) => (<div className={`text-center`} style={{padding: '13px', width: '33%', float: 'left'}}>
          <span dir="ltr" className={"text-decoration-none my-1 d-block square post " + (this.state.selected.indexOf(post._id) > -1 ? ' active': '') }>
            <div className="rounded rounded-circle ">
              {post.media_url && <img style={{left: 0}} className="post-image text-center img img-thumbnail img-fluid " src={post.media_url.split(',')[0]} g="img/post.png" alt="" />}
              {(post.type === 'text-background' || post.type === 'text') && <span style={{backgroundColor: '#d37554'}} className="position-absolute top-50 start-50 translate-middle bg-dark-75 w-75 h-75">
                <p className=" position-absolute top-50 start-50 translate-middle w-100 text-center text-white fw-bolder">
                  {post.post_content}
                </p>
              </span>}
            </div>
          </span>
          <button type="button" onClick={this.toggleAddPost.bind(this, post._id)} className={`btn ${(this.state.post.selected_posts.indexOf(post._id) > -1 ? 'btn-danger': 'btn-success')}`}>{(this.state.post.selected_posts.indexOf(post._id) > -1 ? this.props.t('Unselect'): this.props.t('Select'))}</button>
        </div>))}
      </div>
    </div>}


  </div>}

  {this.state.current_step === 'schedule' && <div>
    <div>
      <h4>{this.props.t('At what times?')}</h4>

      {this.state.post.times.map((time_slot, index) => (<div className="input-group mb-3">
        {/*<div className="input-group-prepend">
          <button className="btn btn-success">+</button>
        </div>*/}

        <div className="input-group-prepend">
          <button disabled={this.state.post.times.length == 1} className="btn btn-danger" onClick={(e) => {
            this.state.post.times.splice(index, 1)
            this.setState({post: this.state.post})
          }}>-</button>
        </div>
        <div className="input-group-prepend">
          <input className="form-control" type="number" value={time_slot.post_amount} onChange={(e) => { time_slot.post_amount = Math.max(e.target.value, 1); this.setState({post: this.state.post}) }}/>
        </div>
        <div class="input-group-prepend">
          <span class="input-group-text">{this.props.t('Post Amount')}</span>
        </div>

        <select className="form-control" value={this.state.post.time_slot} value={time_slot.time} onChange={(e) => { time_slot.time = e.target.value; this.setState({post: this.state.post}) }}>
          <option></option>
          {this.state.times.map((time)=>(<option value={time}>{time}:00</option>))}
        </select>

        {/*<div className="input-group-append">
          <span className="input-group-text">.00</span>
        </div>*/}
      </div>))}



      {/*<button className="btn btn-success" onClick={(e) => {
        this.state.post.times.push({time: null, post_amount: 1, group_map: {}});
        this.setState({post: this.state.post})
      }}>{this.props.t('Add Time')}</button>*/}
    </div>
  </div>}
  {this.state.current_step === 'groups' && <div>
    {this.state.edit_library && <div>
          <h4>{this.props.t('Edit Library')}</h4>

                {<div>


          <div className="container">
            <div className="row">
                {<div className="form-group">
                  <label>{this.props.t('Library name')} <span style={{color: ''}}>({this.props.t('Required')})</span></label>
                  <input
                    required={true}
                    type="text"
                    placeholder={this.props.t('Library name')}
                    className="form-control"
                    value={this.state.edit_library.name}
                    onChange={event => {this.state.edit_library.name = event.target.value;this.setState({ edit_library: this.state.edit_library })}}
                  />
                </div>}
            </div>



                  <GroupSearch {... this.props} selected={this.state.edit_library.facebook_groups} onChange={(selected) => {
                      this.state.edit_library.facebook_groups = selected;
                      this.setState({edit_library: this.state.edit_library});
                  }}/>


            </div>
                    
                </div>}

    </div>}

    {!this.state.edit_library && <div>
      <h4>{this.props.t('Select Groups Libraries')}</h4>
      <p>{this.props.t('In this step we will choose the facebook groups/libraries we want to publish the posts')}</p>


      {this.state.libraries.map((group) => (<div className={`text-center`} style={{padding: '13px', width: '25%', float: 'right'}}>

      <span dir="ltr" className={"text-decoration-none my-1 d-block group text-center" } style={{paddingBottom: '0', overflow: 'inherit'}}>
          <div style={{'height': '95px', position: 'relative'}}>
            {/*group.group_pictures.map((g, index) => (<img data-tip={g.name} style={{left: `${index*10}px`, top: '0px', width: '95px', height: '95px'}} className="group-item-pick group-image img img-thumbnail img-fluid rounded rounded-circle " src={g.image_url} g="img/group1.png" alt=""/>))*/}
            {[group.group_pictures[0] || 'sss'].map((g, index) => (
              <img data-tip={g.name} style={{margin: 'auto', width: '95px', height: '95px'}} className="group-item-pick group-image img img-thumbnail img-fluid rounded rounded-circle " src={g.image_url} g="img/group1.png" alt=""/>))}
          </div>
        {/*<div className="">
          <span className="position-absolute top-50 start-50 translate-middle bg-dark-75 w-75 h-75">
            <p className=" position-absolute top-50 start-50 translate-middle w-100 text-center text-white fw-bolder">{group.name}</p>
          </span>
        </div>
        <span className="badge bg-info px-2 py-2 small text-white rounded-pill position-25">
          <img className="d-inline mx-1 h-icon" width="5px" src="/static/media/follower.e4d49cda.svg" g="icons/follower.svg" />{nFormatter(group.facebook_groups.length, 2)}
        </span>*/}
        </span>
        <div>
          {group.name} ({nFormatter(group.total_group_count, 2)})
          
          <div><button className="btn btn-primary" onClick={() => {
            this.setState({edit_library: group})
          }}>{this.props.t('Edit')}</button></div>
        </div>

        <Switch
          classes={this.props.classes}
          checked={this.state.post.group_libraries.indexOf(group._id) > -1}
          checked={this.state.post.group_libraries.indexOf(group._id) > -1}
          onClick={(e) => {

            this.toggleArray(this.state.post.group_libraries, group._id)

            this.setState({
              post: this.state.post,
            }) }}
        />



      </div>))}

      <div style={{clear: 'both'}}></div>

      <h4>{this.props.t('Select Custom Groups')} ({this.state.post.selected_groups.length} {this.props.t('Selected')}) {this.state.post.selected_groups.length > 0 && <button className="btn btn-success" onClick={this.saveCurrentAsLibrary.bind(this)}>{this.props.t('Save As Library')}</button>} </h4>
                {<div>
                    {/*<Switch
                      classes={this.props.classes}
                      checked={this.state.custom_groups}
                      onClick={(e) => {
                        this.setState({
                          custom_groups: !this.state.custom_groups,
                        }) 
                      }}
                    />*/}
                    {this.props.t('You can select custom groups')}, {this.props.t('If you have selected a library the field is not required')} <FaQuestionCircle data-tip={this.props.t('If you select groups that the profiles are not in, the program will join the groups from each profile automatically')}/>
                </div>}

                <hr/>

                {<div>

                  <GroupSearch {... this.props} selected={this.state.post.selected_groups} onChange={(selected) => {
                      this.state.post.selected_groups = selected;
                      this.setState({post: this.state.post});
                  }}/>   
                    
                </div>}
                    
    </div>}




              </div>}
  {this.state.current_step === 'custom_groups' && <div>

        <h4>{this.props.t('Schedule Groups')}</h4>
        <p>{this.props.t("In this step we will set a custom schedule for posts in groups")} <FaQuestionCircle data-tip={this.props.t('If you select groups that the profiles are not in, the program will join the groups from each profile automatically')}/></p>


         <span style={{fontWeight: 'bold'}}>1. {this.props.t('Select Hours')} <FaQuestionCircle data-tip={this.props.t('You can select the hours you want to publish and devide the hours you want to publish in')}/></span>

         <Multiselect
          options={this.state.times.map( (time) => {
            return {
              name: `${time}:00`,
              id: time,
            }
          } )} 
          selectedValues={_.orderBy(this.state.post.times, ['time']).length ? _.orderBy(this.state.post.times, function (obj) {
              return parseInt(obj.time, 10);
          }).map((time, index) => {
            return {
              name: `${time.time}:00`,
              id: time.time,
            }
          }) : [] } 
          style={{
            backgroundColor: '#fff',
            width: '100%',
            display: 'inline-block',
          }}
          className={'adad'}
          placeholder={this.props.t('Click here to select hours')}
          onSelect={ this.selectTimes.bind(this) }
          onRemove={ this.removeTimes.bind(this) } 


          /*onSelect={this.onSelect} 
          onRemove={this.onRemove}*/ 
          displayValue="name" 
          ref={this.multiselectRef}
        />
          <span style={{fontWeight: 'bold'}}>2. {this.props.t('Schedule for me')} <FaQuestionCircle data-tip={this.props.t('Divided randomly and evenly')}/> <input style={{width: '40px'}} type="number" value={this.state.schedule_for_me.amount} onChange={(e) => { this.state.schedule_for_me.amount = Math.max(e.target.value, 1); this.setState({schedule_for_me: this.state.schedule_for_me})}} /> {this.props.t('posts in each group')}         <button onClick={this.apply_schedule_for_me.bind(this)}>{this.props.t('Apply')}</button> <FaQuestionCircle data-tip={this.props.t('The program will devide the posts evenly within the selected hours')}/></span> <span style={{
            color: '#46f718',
            fontWeight: 'bold',
          }}>({this.props.t('Recommended for use')})</span>









        {this.state.post.times.length > 0 && <table className="table group-selection" style={{
          background: '#87b0ec',
          padding: '10px 0',
          marginTop: '20px',

        }}>
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th>
              </th>
              <th></th>
            </tr>
            <tr>
              <th scope="col">

                <select className="form-control" value={this.state.sort_by} onChange={(e) => { this.setState({sort_by: e.target.value}); }} aria-label="Default select example">
                  <option value={null}>{this.props.t('Sort By')}</option>

                  <optgroup label={this.props.t('Groups')}>
                    <option value={'today_slot_count'}>{this.props.t('Slot Count')}</option>
                    <option value={'users_in_group_count'}>{this.props.t('User in group')}</option>
                    <option value={'follow_count'}>{this.props.t('Followers')}</option>
                    <option value={'today_slot_count'}>{this.props.t('Pending Profile Amount')}</option>
                    <option value={'blocked_users_in_group_count'}>{this.props.t('Blocked Profile Amount')}</option>
                  </optgroup>

                  <optgroup label={this.props.t('Stats')}>
                    <option value="weekly_slot_count">{this.props.t('Scheduled this week')}</option>
                    <option value="pending_count">{this.props.t('Pending Amount')}</option>
                    <option value="published_count">{this.props.t('Posted Amount')}</option>
                    <option value="no_box_count">{this.props.t('Not successfully posted Amount')}</option>
                    <option value="no_box_count">{this.props.t('Real comment amount')}</option>
                    <option value="deleted_count">{this.props.t('Deleted Count')}</option>
                    <option value="not_published_count">{this.props.t('Not Published Count')}</option>
                  </optgroup>


                </select>


              </th>
              <th style={{
                    minWidth: '302px',
              }}>  {/*jjjjjjjjjj*/}

                <div className="input-group">
                  <input className="form-control" placeholder={this.props.t('Search Groups')} type="text" value={this.state.search_text} onChange={(e) => {this.setState({search_text: e.target.value})}}/>
                </div>

              </th>




              <th className="text-center" style={{
                opacity: this.props.user.personal_plan ? 0.5 : 1,
              }}>
                {this.props.user.personal_plan && <FaLock/>}
                {<div className="calendar-column-period switch-toggle switch-3 switch-candy" style={{fontSize: '14px'}}>
                    <input disabled={this.props.user.personal_plan} id="on" name="stats-period" type="radio" checked={this.state.stats_view === 'week'} />
                    <label style={{float: 'right'}} for="on" onClick={() => this.setState({stats_view: 'week'})}>7 {this.props.t('days')}</label>

                    <input disabled={this.props.user.personal_plan} id="na" name="stats-period" type="radio" checked={this.state.stats_view === 'month'} />
                    <label style={{float: 'right'}} for="na" onClick={() => this.setState({stats_view: 'month'})}>30 {this.props.t('days')} </label>

                    <input disabled={this.props.user.personal_plan} id="off" name="stats-period" type="radio" checked={this.state.stats_view === 'all_time'} />
                    <label style={{float: 'right'}} for="off" onClick={() => this.setState({stats_view: 'all_time'})}>{this.props.t('All Time')}</label>
                </div>}

                {this.props.t('Weekly Stats')}
              </th>
              <th scope="col" style={{
                width: '180px',
                background: '#60ff01',
                borderRadius: '20%',
              }}>
                <div>
                  <span>{this.props.t('A total of')} <strong>{this.calulateAllPosts()}</strong> {this.props.t('Posts scheduled in all groups')}</span>
                </div>
                {/*<button className={`btn btn-success ${this.state.post.times.length === 0 ? 'blink-bg' : ''}`} onClick={(e) => {
                  this.state.post.times.push({time: null, post_amount: 1, group_map: {}});
                  this.setState({post: this.state.post})
                }}>{this.props.t('Add Time')}</button>*/}

              </th>
              <th style={{whiteSpace: 'nowrap'}}></th>
            </tr>
          </thead>
          <tbody>
            {_.orderBy(this.state.groups, this.state.sort_by || 'name', 'desc').map((group, key) => <tr
                style={{
                  borderBottom: '5px solid black',
                }}
                key={key}
              >
              <td scope="row" style={{
                width: "180px",
                opacity: this.state.post.group_settings[group._id].is_disabled ? '0.5' : '1'
              }}>
                {(<div style={{}}>

                  <span dir="ltr" className={"text-decoration-none my-1 d-block square group " + (this.state.selected.indexOf(group._id) > -1 ? ' active': '') }>
                    <div className="rounded rounded-circle ">
                      <img style={{left: 0}} className="group-image img img-thumbnail img-fluid rounded rounded-circle " src={group.image_url} g="img/group1.png" alt="" />
                      <span className="position-absolute top-50 start-50 translate-middle bg-dark-75 rounded rounded-circle w-75 h-75">
                        <p className=" position-absolute top-50 start-50 translate-middle w-100 text-center text-white fw-bolder"><a target="_blank" style={{color: '#fff'}} href={`https://facebook.com/groups/${group.group_id}`}>{group.name}</a></p>
                      </span>
                    </div>
                    <span className="badge bg-info px-2 py-2 small text-white rounded-pill position-25"><img className="d-inline mx-1 h-icon" width="5px" src="/static/media/follower.e4d49cda.svg" g="icons/follower.svg" />{nFormatter(group.follow_count, 2)}</span><span className="badge bg-info px-2 py-2 small text-white rounded-pill position-75"><img className="d-inline mx-1 h-icon" width="5px" src="/static/media/clock.66a84231.svg" g="icons/clock.svg" />{group.today_slot_count}<img className="d-inline mx-1 h-icon" width="5px" src="/static/media/account.9ec423dc.svg" g="icons/account.svg" />{group.users_in_group_count}</span>
                  
                          {<span style={{left: '36px'}} className="badge bg-info text-white rounded-pill position-absolute bottom-0">
                            {this.props.t('Pending profiles')}: {group.pending_users_in_group_count} <br/>
                            {this.props.t('Blocked profiles')}: {group.blocked_users_in_group_count}
                            {/*a: {group.auto_approval_percentage}<br/>
                            b: {group.approval_percentage}*/}
                          </span>}


                  </span>
                </div>)}
              </td>
              <td>
                
                <table style={{width: '100%'}}>
                  <tr>
                    <td style={{textAlign: 'center'}}><FaQuestionCircle data-tip={this.props.t('Post schedule hour')}/></td>
                    <td style={{textAlign: 'center'}}><FaQuestionCircle data-tip={this.props.t('Amount of posts in total at this hour')}/></td>
                    <td style={{textAlign: 'center'}}><FaQuestionCircle data-tip={this.props.t('Select this checkbox if you want posts to publish on this group at this hour')}/></td>
                    <td style={{textAlign: 'center'}}><FaQuestionCircle data-tip={this.props.t('Amount of posts in each hour')}/></td>
                  </tr>
                

                {!this.state.post.group_settings[group._id].is_disabled && _.orderBy(this.state.post.times, ['time']).map((time_slot, index) => (<tr>
                    {time_slot && time_slot.time && <React.Fragment>
                        <td>{time_slot.time}:00</td>
                        <td>({this.calulateColumnPosts(time_slot)})</td>


                        <td className="form-check form-switch">
                          {<input 
                            type="checkbox"
                            className="form-check-input"
                            checked={time_slot.group_map[group._id] && time_slot.group_map[group._id].active ? 'checked' : false}
                            onClick={(e) => {
                              _.set(time_slot.group_map, `${group._id}.active`, !_.get(time_slot.group_map, `${group._id}.active`, false))
                              this.setState({
                                post: this.state.post,
                              })
                            }}
                            style={{
                              marginTop: '8px'
                            }}
                          />}
                        </td>
                        <td>
                          {time_slot.group_map[group._id] && time_slot.group_map[group._id].active && <span style={{
                            paddingRight: '37px',
                          }}>
                            <input 
                              type="number"
                              value={time_slot.group_map[group._id].post_amount || 1}
                              min="1"
                              style={{maxWidth: '100%', 'width': '49px'}}
                              onChange={(e) => { 
                                  time_slot.group_map[group._id].post_amount = e.target.value; 
                                  this.setState({
                                    custom_group_settings: time_slot.group_map,
                                  })
                              }}
                            />
                          </span>}
                        </td>
                    </React.Fragment>}



                </tr>))}


              </table>

              {!this.state.post.group_settings[group._id].is_disabled && <button onClick={this.selectGroupRow.bind(this, group._id)}>{this.props.t('Select All Hours')}</button>}


              </td>
                <td>
                  <div style={{
                  opacity: this.props.user.personal_plan ? 0.5 : 1,
                }}>
                  <div>
                    <strong>{this.props.t('Scheduled this week')}:</strong> {this.props.user.personal_plan ? <FaLock/> : group.stats[this.state.stats_view].weekly_slot_count}
                  </div>
                  <div>
                    <strong>{this.props.t('Pending Amount')}:</strong> {this.props.user.personal_plan ? <FaLock/> : group.stats[this.state.stats_view].pending_count}
                  </div>
                  <div>
                    <strong>{this.props.t('Posted Amount')}:</strong> {this.props.user.personal_plan ? <FaLock/> : group.stats[this.state.stats_view].published_count}
                  </div>
                  <div>
                    <strong>{this.props.t('Not successfully posted Amount')}:</strong> {this.props.user.personal_plan ? <FaLock/> : group.stats[this.state.stats_view].no_box_count}
                  </div>
                  <div>
                    <strong>{this.props.t('Real comment amount')}:</strong> {this.props.user.personal_plan ? <FaLock/> : group.stats[this.state.stats_view].no_box_count}
                  </div>
                  <div>
                    <strong>{this.props.t('Deleted Count')}:</strong> {this.props.user.personal_plan ? <FaLock/> : group.stats[this.state.stats_view].deleted_count}
                  </div>
                  <div>
                    <strong>{this.props.t('Not Published Count')}:</strong> {this.props.user.personal_plan ? <FaLock/> : group.stats[this.state.stats_view].not_published_count}
                  </div>
                </div>
                {this.props.user.personal_plan && <button type="button" className="bigger-package-button" onClick={this.props.updateSearch.bind(this, {path: [':lang', 'plans']})}>{this.props.t('Purchase Package with profiles')}</button>}
              </td>
              <td>
                <div>
                  <span>סה"כ <strong>{this.calulateGroupPosts(group._id)}</strong> פוסטים בקבוצה זו</span>
                  
                  <div className="form-group">
                    {<div className="form-check form-switch">
                      {this.props.t('Active Group')} 
                   




                      {<input 
                        type="checkbox"
                        className={'form-check-input'}
                        checked={!this.state.post.group_settings[group._id].is_disabled ? 'checked' : false}
                        style={{
                          margin: '5px 4px 0 0',
                        }}
                        onClick={(e) => { 
                          this.state.post.group_settings[group._id].is_disabled = !this.state.post.group_settings[group._id].is_disabled;

                          this.setState({
                            post: this.state.post,
                          })
                        }}
                      />}
<FaQuestionCircle style={{marginRight: '41px'}} data-tip={this.props.t('You can disable this group by selecting the checkbox')}/>

                      {/*<Switch
                        classes={this.props.classes}
                        checked={!this.state.post.group_settings[group._id].is_disabled}
                        onClick={(e) => { 
                          this.state.post.group_settings[group._id].is_disabled = !this.state.post.group_settings[group._id].is_disabled;

                          this.setState({
                            post: this.state.post,
                          })
                        }}
                      />*/}
                    </div>}
                  </div>
                  {/*<div className="form-group">
                    <label htmlFor="exampleInputEmail1">{this.props.t('Note')} <FaQuestionCircle data-tip={this.props.t('You can write a note for this group in order to optimize your posts')}/></label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={this.props.t('Note')}
                      onChange={event => {
                        this.state.post.group_settings[group._id].note = event.target.value;
                        this.setState({ post: this.state.post })
                      }}
                      value={this.state.post.group_settings[group._id].note}
                    />
                  </div>*/}



                  <div>
                  </div>
                </div>
              </td>
            </tr>)}
          </tbody>
        </table>}
    <div>

    </div>
  </div>}
  {this.state.current_step === 'profiles' && <div>
    <div>
      <h4>{this.props.t('From what profiles?')}</h4>

      <p>{this.props.t('In this step we will choose the profiles that will publish the posts in the groups, you can select all profiles')} <FaQuestionCircle data-tip={this.props.t('If you have selected more then one profile, the program will rotate between profiles')}/></p>


                  


      <div>
          <span style={{
        opacity: this.props.user.personal_plan ? 0.7 : 1,
      }}>
          {this.props.user.personal_plan && <FaLock/>}
          {this.props.t('Advertise from all fictitious profiles')}
          <Switch
            classes={this.props.classes}
            checked={this.state.post.settings.post_from_all_fictitious}
            onClick={(e) => { this.state.post.settings.post_from_all_fictitious = !this.state.post.settings.post_from_all_fictitious; this.setState({
              post: this.state.post,
            }) }}
            disabled={this.props.user.personal_plan}
          />
          <FaQuestionCircle data-tip={this.props.t('For higher porformance turn on automatic comments')}/>
          </span>

          {this.props.user.personal_plan && <button type="button" className="bigger-package-button" onClick={this.props.updateSearch.bind(this, {path: [':lang', 'plans']})}>{this.props.t('Purchase Package with profiles')}</button>}
      </div>

      {this.state.allow_edit.profiles && <div>
            <button type="button" className="btn btn-warning" onClick={() => {
               this.state.profiles.map((profile) => this.state.post.users.push(profile._id));
               this.state.post.users = [...new Set(this.state.post.users)];

               this.setState({
                   users: this.state.post
               });
            }}>{this.props.t("Mark All")}</button>
      {this.state.profiles.filter((profile) => {
        // if (!this.state.post.settings.post_from_all_fictitious && profile.fictitious) {
        //   return false;
        // }
        return true;
      }).map((profile) => (<React.Fragment><span className="position-relative"><span data-tip={profile.first_name + " " + profile.last_name} onClick={this.togglUser.bind(this, profile._id)} className={"me-3 " + ((this.state.post.users.indexOf(profile._id) > -1 || (profile.fictitious && this.state.post.settings.post_from_all_fictitious)) ? 'active' : '')} key={profile._id}>
        <img width="60px" className={`profile rounded rounded-circle border border-3  ${profile.is_logged_in ? 'logged_in' : 'logged_out'} `} src={'https://images.fbzipper.com/api/users/profile/' + profile._id} g="img/user1.png" alt="" />
      </span></span></React.Fragment>))}
      </div>}
    </div>
  </div>}
  {this.state.current_step === 'settings' && <div>
    {<div className="settings">
      <div style={{
            opacity: this.props.user.personal_plan ? 0.7 : 1,
          }}>

        <h4>{this.props.user.personal_plan && <FaLock/>} {this.props.t('Extra Settings')} {this.props.user.personal_plan && <FaLock/>}</h4>

        <p style={{fontWeight: 'bold'}}>{this.props.t('In this step you can select custom settings if needed (not required)')}</p>


        <MuiPickersUtilsProvider utils={DateFnsUtils}>

          {/*this.state.allow_edit.should_delete && <div>
            <div>
                {this.props.t('Should Delete At Specific Time')}
                <Switch
                  classes={this.props.classes}
                  checked={this.state.post.settings.should_delete}
                  onClick={(e) => { this.state.post.settings.should_delete = !this.state.post.settings.should_delete; this.setState({
                    post: this.state.post,
                  }) }}
                />
            </div>

            {this.state.post.settings.should_delete && <TimePicker
              label={this.props.t("Delete At")}
              inputVariant="outlined"
              value={this.state.post.settings.delete_at}
              onChange={ (newValue)=> {
                  this.state.post.settings.delete_at = newValue;
                  this.setState({
                      post: this.state.post,
                  })
              }}
            />}
          </div>*/}

          {this.state.allow_edit.should_delete && <div>
            <div>
                {this.props.t('Should Delete Time After Publish')} <FaQuestionCircle data-tip={this.props.t('You can define your posts to delete after a specific time after publication, it is not recommended to delete more then 50 posts a day')}/>{this.props.t('Extra Settings')} {this.props.user.personal_plan && <FaLock/>}
                <Switch
                  classes={this.props.classes}
                  checked={this.state.post.settings.should_delete_time_after_publish}
                  onClick={(e) => { this.state.post.settings.should_delete_time_after_publish = !this.state.post.settings.should_delete_time_after_publish; this.setState({
                    post: this.state.post,
                  }) }}
                  disabled={this.props.user.personal_plan}
                />
            </div>

            {this.state.post.settings.should_delete_time_after_publish && <div>
              {this.props.t('Delete posts from this campaign, after')} {this.props.t('Extra Settings')} {this.props.user.personal_plan && <FaLock/>}

             <select disabled={this.props.user.personal_plan} value={this.state.post.settings.delete_after_hours} onChange={(e) => {
                    this.state.post.settings.delete_after_hours = e.target.value; 
                    this.setState({
                      post: this.state.post,
                    })
              }}>
                  <option value={1}>{this.props.t('hour')}</option>
                  <option value={2}>2 {this.props.t('hours')}</option>
                  <option value={4}>4 {this.props.t('hours')}</option>
                  <option value={6}>6 {this.props.t('hours')}</option>
                  <option value={8}>8 {this.props.t('hours')}</option>
                  <option value={10}>10 {this.props.t('hours')}</option>
                  <option value={12}>12 {this.props.t('hours')}</option>
                  <option value={16}>16 {this.props.t('hours')}</option>
                  <option value={20}>20 {this.props.t('hours')}</option>
                  <option value={24}>{this.props.t('day')}</option>
                  <option value={24*2}>2 {this.props.t('days')}</option>
                  <option value={24*3}>3 {this.props.t('days')}</option>
                  <option value={24*4}>4 {this.props.t('days')}</option>
                  <option value={24*5}>5 {this.props.t('days')}</option>
                  <option value={24*6}>6 {this.props.t('days')}</option>
                  <option value={24*7}>1 {this.props.t('week')}</option>
              </select>

              {this.props.t('they have been published')}


              </div>}


          </div>}

          {this.state.allow_edit.should_pump && <div>
            <div>
               {this.props.t('Should Pump')} <FaQuestionCircle data-tip={this.props.t('Comments a random comment from your comment library, then deleted it immediately in order to pump your post')}/> {this.props.t('Extra Settings')} {this.props.user.personal_plan && <FaLock/>}
                <Switch
                  classes={this.props.classes}
                  checked={this.state.post.settings.should_pump}
                  onClick={(e) => { this.state.post.settings.should_pump = !this.state.post.settings.should_pump; this.setState({
                    post: this.state.post,
                  }) }}
                  disabled={this.props.user.personal_plan}
                />
            </div>
            {this.state.post.settings.should_pump && <div>
                {this.props.t('Pump Every')} {this.props.t('Extra Settings')} {this.props.user.personal_plan && <FaLock/>}

                <select disabled={this.props.user.personal_plan} value={this.state.post.settings.pump_every_minutes} onChange={(e) => {
                      this.state.post.settings.pump_every_minutes = e.target.value; 
                      this.setState({
                        post: this.state.post,
                      })
                }}>
                    <option value={60}>{this.props.t('hour')}</option>
                    <option value={60*1.5}>1.5 {this.props.t('hours')}</option>
                    <option value={60*2}>2 {this.props.t('hours')}</option>
                    <option value={60*2.5}>2.5 {this.props.t('hours')}</option>
                    <option value={60*3}>3 {this.props.t('hours')}</option>
                    <option value={60*4}>4 {this.props.t('hours')}</option>
                    <option value={60*5}>5 {this.props.t('hours')}</option>
                    <option value={60*6}>6 {this.props.t('hours')}</option>
                    <option value={60*7}>7 {this.props.t('hours')}</option>
                    <option value={60*8}>8 {this.props.t('hours')}</option>
                    <option value={60*9}>9 {this.props.t('hours')}</option>
                    <option value={60*10}>10 {this.props.t('hours')}</option>
                </select>

                {this.props.t('For the duration of')}

                <select disabled={this.props.user.personal_plan} value={this.state.post.settings.duration_pump} onChange={(e) => {
                      this.state.post.settings.duration_pump = e.target.value; 
                      this.setState({
                        post: this.state.post,
                      })
                }}>
                    <option value={60*8}>8 {this.props.t('hours')}</option>
                    <option value={60*10}>10 {this.props.t('hours')}</option>
                    <option value={60*12}>12 {this.props.t('hours')}</option>
                    <option value={60*14}>14 {this.props.t('hours')}</option>
                    <option value={60*16}>16 {this.props.t('hours')}</option>
                    <option value={60*18}>18 {this.props.t('hours')}</option>
                    <option value={60*20}>20 {this.props.t('hours')}</option>
                    <option value={60*22}>22 {this.props.t('hours')}</option>
                    <option value={60*24}>24 {this.props.t('hours')}</option>
                </select>

                {this.props.t('Starting from the publish time')} ({Math.floor(this.state.post.settings.duration_pump/this.state.post.settings.pump_every_minutes)} {this.props.t('Pumps')})
            </div>}
          </div>}

          {this.state.allow_edit.should_edit && <div>
            <div>
                {this.props.t('Should Edit')} <FaQuestionCircle data-tip={this.props.t('You edit the text in the post after a specific period after publication')}/> {this.props.t('Extra Settings')} {this.props.user.personal_plan && <FaLock/>}
                <Switch
                  classes={this.props.classes}
                  checked={this.state.post.settings.should_edit}
                  onClick={(e) => { this.state.post.settings.should_edit = !this.state.post.settings.should_edit; this.setState({
                    post: this.state.post,
                  }) }}
                  disabled={this.props.user.personal_plan}
                />
            </div>
            {this.state.post.settings.should_edit && <div>
                {this.props.t('Edit After Minutes')}

                <input 
                  type="number"
                  name="edit_after_minutes"
                  value={this.state.post.settings.edit_after_minutes}
                  min="0"
                  onChange={(e) => { 
                      this.state.post.settings.edit_after_minutes = e.target.value; 
                      this.setState({
                        post: this.state.post,
                      })
                  }}
                  style={{
                    width: '58px',
                  }}
                  disabled={this.props.user.personal_plan}
                />
                {this.props.t('minutes')} {this.props.t('they have been published')}
            </div>}
          </div>}

          {this.state.post.settings.should_edit && <div>
              {this.props.t('To another post from campaign')} {this.props.t('Extra Settings')} {this.props.user.personal_plan && <FaLock/>}
              <select disabled={this.props.user.personal_plan} value={this.state.post.settings.edit_campaign} onChange={(e) => {
                    this.state.post.settings.edit_campaign = e.target.value; 
                    this.setState({
                      post: this.state.post,
                    })
              }}>
                  <option></option>
                  {this.state.campaigns.map((campaign) => <option value={campaign._id}>{campaign.name}</option>)}
              </select>

                  <div className="select-edit-posts">
                    {this.props.t('Select the final post you want to modify to')} ({this.props.t('You may select multiple posts')} <FaQuestionCircle data-tip={this.props.t('The Program will rotate between posts if multiple are selected')}/>)
                    {this.state.edit_campaign_posts.map((post) => (
                        <React.Fragment>

                            <span className={`position-relative select-edit-post ${(this.state.post.settings.edit_posts || []).indexOf(post._id) > -1 ? 'selected' : ''}`} onClick={() => {
                                  this.state.post.settings.edit_posts = this.state.post.settings.edit_posts || []; 
                                  var current_index = this.state.post.settings.edit_posts.indexOf(post._id);
                                  if (current_index > -1) {
                                    this.state.post.settings.edit_posts.splice(current_index, 1)
                                  } else {
                                    this.state.post.settings.edit_posts.push(post._id); 
                                  }

                                  this.setState({
                                    post: this.state.post,
                                  })
                            }}>
                            <span className="text-background d-block m-1 border border-2 border-secondary text-center position-relative post" >

                                    {(!post.type || post.type === 'image') && 
                                      <img className="img img-thumbnail img-fluid " width src={"/api/images/" + post._id} g="img/post1.png" alt="" />
                                    }

                                    {(post.type === 'video') && 
                                      <img className="img img-thumbnail img-fluid " width src={"/api/images/" + post._id} g="img/post1.png" alt="" />
                                    }

                                    {(post.type === 'album') && 
                                      <img className="img img-thumbnail img-fluid " width src={"/api/images/" + post._id} g="img/post1.png" alt="" />
                                    }

                                    {(post.type === 'text-background' || post.type === 'text') && 
                                      <span className="text-background">
                                         {post.post_content}
                                      </span>
                                    }
                            </span>



                        </span>

                        </React.Fragment>
                    ))}
                   </div>

          </div>}



        </MuiPickersUtilsProvider>


        <br/>
        <br/>
      </div>

      {this.props.user.personal_plan && <button type="button" className="bigger-package-button" onClick={this.props.updateSearch.bind(this, {path: [':lang', 'plans']})}>{this.props.t('Purchase Package with profiles')}</button>}


    </div>}

  </div>}
  {this.state.current_step === 'summary' && <div>

        <h4>{this.props.t('Summary')}</h4>
        <p style={{direction: "rtl"}}>{this.props.t('Congratulations! you have successfully scheduled your campaign, Your posts will start to schedule tomorrow for the rest of youe life')}</p>
        
        <div className="summary">
          <div style={{direction: 'rtl'}}>
            <span className="title">{this.props.t('Selected Campaign Name')}:</span> {this.state.post.campaign && this.state.post.campaign.name}
          </div>
          <div style={{direction: 'rtl'}}>
            <span className="title">{this.props.t('Selected Post Campaign Count')}:</span> {this.state.post.settings.all_posts ? this.props.t('All posts') : this.state.post.selected_posts.length}
          </div>
          <div style={{direction: 'rtl'}}>
            <span className="title">{this.props.t('Selected profiles that will publish')}:</span> {this.state.post.users.length}
          </div>
          <div style={{direction: 'rtl'}}>
            <span className="title">{this.props.t('Selected group amount')}:</span> {this.state.groups.length}
          </div>
          <div style={{direction: 'rtl'}}>
            <span className="title">{this.props.t('Amount of posts to publish from this campaign')}:</span> {this.calulateAllPosts()}
          </div>
          {/*<div style={{direction: 'rtl'}}>
            <span className="title">{this.props.t('Amount of hours to publish from this campaign')}:</span> {this.state.post.times.filter((time) => { return this.calulateColumnPosts(time) }).length } <span className="title">{this.props.t('And they are')}:</span> {this.state.post.times.filter((time) => { return this.calulateColumnPosts(time) }).map((time) => { return `${time.time}:00 (${this.calulateColumnPosts(time)}) ` }).join(', ') }
          </div>*/}

          {this.state.post.settings.delete_at && this.state.post.settings.should_delete && <div style={{direction: 'rtl'}}>
            <span className="title">{this.props.t('These posts will delete at')}:</span> {moment(this.state.post.settings.delete_at).format('HH:mm')}
          </div>}

          {this.state.post.settings.should_delete_time_after_publish && this.state.post.settings.delete_after_hours && <div style={{direction: 'rtl'}}>
            <span className="title">{this.props.t('Posts will delete after publish after')}:</span> 

            {this.state.post.settings.delete_after_hours < 24 && <span>{ this.state.post.settings.delete_after_hours } {this.props.t('hours')}</span>}
            {this.state.post.settings.delete_after_hours >= 24 && this.state.post.settings.delete_after_hours < 24*7 && <span>{ this.state.post.settings.delete_after_hours / 24 } {this.props.t('days')}</span>}
            {this.state.post.settings.delete_after_hours >= 24*7 && <span>{ this.state.post.settings.delete_after_hours / (24*7) } {this.props.t('weeks')}</span>}
          </div>}

          {this.state.post.settings.should_pump && this.state.post.settings.pump_every_minutes && this.state.post.settings.delete_after_hours && <div style={{direction: 'rtl'}}>
            <span className="title">{this.props.t('Posts will be pumped every')}:</span> 

            {this.state.post.settings.pump_every_minutes < 60 && <span>{ this.state.post.settings.pump_every_minutes } {this.props.t('minutes')}</span>}
            {this.state.post.settings.pump_every_minutes >= 60 && <span>{ this.state.post.settings.pump_every_minutes/60 } {this.props.t('hours')}</span>}
          </div>}

          {this.state.post.settings.should_edit && this.state.post.settings.edit_posts && this.state.post.settings.edit_posts.length > 0 && this.state.post.settings.edit_after_minutes && this.state.post.settings.delete_after_hours && <div style={{direction: 'rtl'}}>
            <span className="title">{this.props.t('Posts will be edited after')}: </span> 

            {this.state.post.settings.edit_after_minutes < 60 && <span>{ this.state.post.settings.edit_after_minutes } {this.props.t('minutes')}</span>}
            {this.state.post.settings.edit_after_minutes >= 60 && <span>{ this.state.post.settings.edit_after_minutes/60 } {this.props.t('hours')}</span>}
          
            <span className="title"> {this.props.t('to campaign')}: </span> {this.getByCampaignID(this.state.post.settings.edit_campaign).name} 
            <span className="title"> {this.props.t('and will rotate between')} </span> {this.state.post.settings.edit_posts.length} <span className="title"> {this.props.t('posts')} </span>
          </div>}



        </div>
        <br/>
        <p style={{color: 'lime', fontSize: '24px'}}><strong>{this.props.t("Don't forget to save your settings")}</strong></p>

  </div>}



          </div>
          </div>

                    </div>
        </div>,
        footer: () => <div>


          {!this.state.edit_library && <div className="container">
            <div className="row">
              <div className="btn-group" role="group" aria-label="Basic example">
                {<button disabled={this.state.steps.indexOf(this.state.current_step) < 1} onClick={this.prev.bind(this)} type="submit" className="btn btn-primary-next"><FaStepForward/> {this.props.t('Previous')}</button>}
                <span style={{display: 'inline-block', width: '20px'}}></span>
                {<button disabled={!this.state.complete_steps[this.state.current_step]()} onClick={this.next.bind(this)} type="submit" className="btn btn-primary-next">{this.props.t('Next')}  <FaStepBackward/></button>}
              </div>
            </div>
            <div className="row" style={{marginTop: '5px'}}>
              <div className="btn-group" role="group" aria-label="Basic example">

                {(this.state.post._id || this.state.current_step === 'summary') && <button onClick={this.save.bind(this)} type="submit" className="btn btn-warning">{this.props.t('Save')}</button>}
              

               </div>
            </div>
          </div>}
          {this.state.edit_library && <div className="container">
            <div className="row" style={{marginTop: '5px'}}>
              <div className="btn-group" role="group" aria-label="Basic example">
                {<button onClick={this.saveGroupLibrary.bind(this)} type="submit" className="btn btn-warning">{this.props.t('Save Group Library')}</button>}
               </div>
            </div>
          </div>}





        </div>,
      },
      {
        label: 'Logs',
        active: true,
        should_display: () => {
          return this.props.match.params.profile_id;
        },        
        content: () => <div>
        </div>,
      },
      {
        label: 'Data',
        active: true,
        should_display: () => {
          return this.props.match.params.profile_id;
        },                
        content: () => <div>
        </div>,
      },
      {
        label: 'Friends',
        active: true,
        should_display: () => {
          return this.props.match.params.profile_id;
        },                
        content: () => <div>
        </div>,
      },
    ];

    render() {
    	const { t, i18n } = this.props;

      // window.alert((this.state.post._id || !this.state.selected));
      // window.alert((this.state.post._id || !this.state.selected) && this.state.campaigns && this.state.libraries ? false : true);

      this.props.sidePaneEvent({
        id: `multiple-group-camaign-schedule`,
        buttons: this.buttons,
        extra_options: this.extra_options,
        close_path: [':lang', 'console'],
        tabs: this.tabs,
        name: 'multiple-group-camaign-schedule',
        loading: (this.state.post._id || !this.state.selected) && this.state.campaigns && this.state.libraries ? false : true,
        title: 'Automatic Scheduling',
        sub_title: this.props.match.params.profile_id ? `${this.state.first_name} ${this.state.last_name}` : null,
        // loading: false,
        loading_footer_action: this.state.loading_footer_action,
      });
      return (<div></div>);


        return (
		  <div className="overlay style-light"> 
              {(this.state.post._id || !this.state.selected) && this.state.campaigns && this.state.groups ? <div className="bg-primary p-5 text-white text-center rounded-3 " id="myForm">
                  
              <div className="pb-5 position-relative" id="">
                <h4 className="fs-5 fw-bolder text-uppercase text-center">{this.props.edit ? this.props.t('Schedule Campaign') : this.props.t('Schedule Campaign')}</h4>
                <a type="button" className="btn text-white close_btn fs-2 fw-bolder" onClick={this.props.updateSearch.bind(this, {'multiple-group-camaign': undefined})}>×</a>
              </div>
        <div className="d-flex justify-content-center">
          <div className={`bd-highlight ${this.state.complete_steps.select_campaign() ? "complete" : 'not-complete'} select-option ` + (this.state.current_step === 'select_campaign' ? 'active' : '')} onClick={(e) => {this.select_step('select_campaign'); this.setState({ post: this.state.post })}}>
            <div className="icon"><h2 style={{padding: 0, margin: 0}}>1</h2><FaAd/></div>
            <strong>{this.props.t('Select Campaign')}</strong>
          </div>
          <div className={`bd-highlight ${this.state.complete_steps.select_posts() ? "complete" : 'not-complete'} select-option ` + (this.state.current_step === 'select_posts' ? 'active' : '')} onClick={(e) => {this.select_step('select_posts'); this.setState({ post: this.state.post })}}>
            <div className="icon"><h2 style={{padding: 0, margin: 0}}>2</h2><FaRegIdCard/></div>
            <strong>{this.props.t('Select Posts')}</strong>
          </div>
          <div className={`bd-highlight ${this.state.complete_steps.profiles() ? "complete" : 'not-complete'} select-option ` + (this.state.current_step === 'profiles' ? 'active' : '')} onClick={(e) => {this.select_step('profiles'); this.setState({ post: this.state.post })}}>
            <div className="icon"><h2 style={{padding: 0, margin: 0}}>3</h2><FaUserPlus/></div>
            <strong>{this.props.t('Select Profiles')}</strong>
          </div>
          <div className={`bd-highlight ${this.state.complete_steps.groups() ? "complete" : 'not-complete'} select-option ` + (this.state.current_step === 'groups' ? 'active' : '')} onClick={(e) => {this.select_step('groups'); this.setState({ post: this.state.post })}}>
            <div className="icon"><h2 style={{padding: 0, margin: 0}}>4</h2><FaUsers/></div>
            <strong>{this.props.t('Select Groups')}</strong>
          </div>
          <div className={`bd-highlight ${this.state.complete_steps.custom_groups() ? "complete" : 'not-complete'} select-option ` + (this.state.current_step === 'custom_groups' ? 'active' : '')} onClick={(e) => {this.select_step('custom_groups'); this.setState({ post: this.state.post })}}>
            <div className="icon"><h2 style={{padding: 0, margin: 0}}>5</h2><FaRegCalendarAlt/></div>
            <strong>{this.props.t('Schedule Groups')}</strong>
          </div>
          <div className={`bd-highlight ${this.state.complete_steps.settings() ? "complete" : 'not-complete'} select-option ` + (this.state.current_step === 'settings' ? 'active' : '')} onClick={(e) => {this.select_step('settings'); this.setState({ post: this.state.post })}}>
            <div className="icon"><h2 style={{padding: 0, margin: 0}}>6</h2><FaCog/></div>
            <strong>{this.props.t('Settings')}</strong>
          </div>
          <div className={`bd-highlight ${this.state.complete_steps.summary() ? "complete" : 'not-complete'} select-option ` + (this.state.current_step === 'summary' ? 'active' : '')} onClick={(e) => {this.select_step('summary'); this.setState({ post: this.state.post })}}>
            <div className="icon"><h2 style={{padding: 0, margin: 0}}>7</h2><FaChartBar/></div>
            <strong>{this.props.t('Summary')}</strong>
          </div>

                  </div>

                  <div className="pb-5 position-relative" id="signin_header" style={{minWidth: '700px'}}>


{this.state.current_step === 'select_campaign' && <div>
  <div>
    <h4>{this.props.t('Select Campaign')}</h4>
    <p>{this.props.t('The first step you must choose your campaign')}</p>
    <select className="form-control" value={this.state.post.campaign._id} onChange={(e) => { this.state.post.campaign = this.state.campaigns.find((c) => c._id == e.target.value); this.setState({post: this.state.post}) }}>
      <option></option>
      {this.state.campaigns.map((campaign)=>(<option value={campaign._id}>{campaign.name}</option>))}
    </select>
  </div>
</div>}

{this.state.current_step === 'select_posts' && <div>
  <h4>{this.props.t('Select posts')}</h4>
  <p>{this.props.t('In this step we will choose the posts that we want to publish within the campaign that we have selected')}</p>

  <div>
      {this.props.t('All posts')}
      <Switch
        classes={this.props.classes}
        checked={this.state.post.settings.all_posts}
        onClick={(e) => { this.state.post.settings.all_posts = !this.state.post.settings.all_posts; this.setState({
          post: this.state.post,
        }) }}
      />
  </div>

  {!this.state.post.settings.all_posts && <div>
  <span>({this.state.post.selected_posts.length} {this.props.t('Posts selected')})</span>


    {/*<input className="form-control" placeholder={this.props.t('Search Groups')} type="text" value={this.state.search_text} onChange={(e) => {this.setState({search_text: e.target.value})}}/>*/}

    <div style={{overflowY: 'scroll', height: '500px', border: '1px solid black'}}>
      {this.state.posts.map((post) => (<div style={{padding: '13px', width: '33%', float: 'left'}}>
        <span dir="ltr" className={"text-decoration-none my-1 d-block square post " + (this.state.selected.indexOf(post._id) > -1 ? ' active': '') }>
          <div className="rounded rounded-circle ">
            {post.media_url && <img style={{left: 0}} className="post-image text-center img img-thumbnail img-fluid " src={post.media_url.split(',')[0]} g="img/post.png" alt="" />}
            {(post.type === 'text-background' || post.type === 'text') && <span style={{backgroundColor: '#d37554'}} className="position-absolute top-50 start-50 translate-middle bg-dark-75 w-75 h-75">
              <p className=" position-absolute top-50 start-50 translate-middle w-100 text-center text-white fw-bolder">
                {post.post_content}
              </p>
            </span>}
          </div>
        </span>
        <button onClick={this.toggleAddPost.bind(this, post._id)} className={`btn ${(this.state.post.selected_posts.indexOf(post._id) > -1 ? 'btn-danger': 'btn-success')}`}>{(this.state.post.selected_posts.indexOf(post._id) > -1 ? this.props.t('Unselect'): this.props.t('Select'))}</button>
      </div>))}
    </div>
  </div>}


</div>}

{this.state.current_step === 'schedule' && <div>
  <div>
    <h4>{this.props.t('At what times?')}</h4>

    {this.state.post.times.map((time_slot, index) => (<div className="input-group mb-3">
      {/*<div className="input-group-prepend">
        <button className="btn btn-success">+</button>
      </div>*/}

      <div className="input-group-prepend">
        <button disabled={this.state.post.times.length == 1} className="btn btn-danger" onClick={(e) => {
          this.state.post.times.splice(index, 1)
          this.setState({post: this.state.post})
        }}>-</button>
      </div>
      <div className="input-group-prepend">
        <input className="form-control" type="number" value={time_slot.post_amount} onChange={(e) => { time_slot.post_amount = Math.max(e.target.value, 1); this.setState({post: this.state.post}) }}/>
      </div>
      <div class="input-group-prepend">
        <span class="input-group-text">{this.props.t('Post Amount')}</span>
      </div>

      <select className="form-control" value={this.state.post.time_slot} value={time_slot.time} onChange={(e) => { time_slot.time = e.target.value; this.setState({post: this.state.post}) }}>
        <option></option>
        {this.state.times.map((time)=>(<option value={time}>{time}:00</option>))}
      </select>

      {/*<div className="input-group-append">
        <span className="input-group-text">.00</span>
      </div>*/}
    </div>))}



    <button className="btn btn-success" onClick={(e) => {
      this.state.post.times.push({time: null, post_amount: 1, group_map: {}});
      this.setState({post: this.state.post})
    }}>{this.props.t('Add Time')}</button>
  </div>
</div>}
{this.state.current_step === 'groups' && <div>
  <div>
    <h4>{this.props.t('Select Groups')}</h4>
    <p>{this.props.t('In this step we will choose the facebook groups where we want to publish the posts')}</p>

                    {this.state.libraries.map((group) => (<div style={{padding: '13px', width: '25%', float: 'left'}}>

                      <span dir="ltr" className={"text-decoration-none my-1 d-block square group " }>
                        <div className="">
                          <span className="position-absolute top-50 start-50 translate-middle bg-dark-75 w-75 h-75">
                            <p className=" position-absolute top-50 start-50 translate-middle w-100 text-center text-white fw-bolder">{group.name}</p>
                          </span>
                        </div>
                        <span className="badge bg-info px-2 py-2 small text-white rounded-pill position-25"><img className="d-inline mx-1 h-icon" width="5px" src="/static/media/follower.e4d49cda.svg" g="icons/follower.svg" />{nFormatter(group.follow_count, 2)}</span><span className="badge bg-info px-2 py-2 small text-white rounded-pill position-75"><img className="d-inline mx-1 h-icon" width="5px" src="/static/media/clock.66a84231.svg" g="icons/clock.svg" />{group.today_slot_count}<img className="d-inline mx-1 h-icon" width="5px" src="/static/media/account.9ec423dc.svg" g="icons/account.svg" />{group.users_in_group_count}</span>
                      </span>
                      <button className={`btn btn-primary`} onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console','group-library', 'edit', group._id]})} >{this.props.t('Edit')}</button>
                    </div>))}






                  <div>
                    <div style={{width: '50%', float: 'left'}}>
                      <h3>{this.props.t('Sort By')}</h3>

                      <select value={this.state.sort_by} onChange={(e) => { this.setState({sort_by: e.target.value}); }} className="form-select bg-primary  rounded-3 text-white py-3 mb-4 px-1" aria-label="Default select example">
                        <option>{this.props.t('Sort By')}</option>
                        <option value={'today_slot_count'}>{this.props.t('Slot Count')}</option>
                        <option value={'users_in_group_count'}>{this.props.t('User in group')}</option>
                        <option value={'follow_count'}>{this.props.t('Followers')}</option>
                      



                      </select>

                    </div>
                    <div style={{width: '50%', float: 'left'}}>
                      <h3>{this.props.t('Filter By')}</h3>

                      <div className="btn-group">
                        <a onClick={() => { this.state.filter_query.by_selected.value = 'all'; this.setState({filter_query: this.state.filter_query}) }}  className={`btn ${this.state.filter_query.by_selected.value === 'all' ? 'btn-success' : 'btn-danger'}`}>{this.props.t('All')}</a>
                        <a onClick={() => { this.state.filter_query.by_selected.value = 'selected'; this.setState({filter_query: this.state.filter_query}) }} className={`btn ${this.state.filter_query.by_selected.value === 'selected' ? 'btn-success' : 'btn-danger'}`}>{this.props.t('Selected')}</a>
                        <a onClick={() => { this.state.filter_query.by_selected.value = 'unselected'; this.setState({filter_query: this.state.filter_query}) }}  className={`btn ${this.state.filter_query.by_selected.value === 'unselected' ? 'btn-success' : 'btn-danger'}`}>{this.props.t('Unselected')}</a>
                      </div>
                    </div>
                  </div>

                  <div style={{clear: 'both'}}></div>









              <div className="input-group">
                <button className="btn btn-primary" onClick={this.selectAll.bind(this, this.getDisplayGroups(this.state.sort_by, this.state.search_text, this.state.amount_to_display, this.state.filter_by_profile, this.state.filter_query, this.state.post.selected_groups))}>{this.props.t('Select All')} ({this.getDisplayGroups(this.state.sort_by, this.state.search_text, this.state.amount_to_display, this.state.filter_by_profile, this.state.filter_query, this.state.post.selected_groups).length})</button>
                <input className="form-control" placeholder={this.props.t('Search Groups')} type="text" value={this.state.search_text} onChange={(e) => {this.setState({search_text: e.target.value})}}/>
              </div>

              <div className="group-info"><strong>{this.props.t(`Showing`)}:</strong> {this.getDisplayGroups(this.state.sort_by, this.state.search_text, this.state.amount_to_display, this.state.filter_by_profile, this.state.filter_query, this.state.post.selected_groups).length} {this.props.t(`groups`)} {this.props.t('of')} {this.state.groups && this.state.groups.length}</div>
                  <span>({this.state.post.selected_groups.length} {this.props.t('groups selected')})</span>


    <div style={{overflowY: 'scroll', height: '500px', border: '1px solid black'}}>
      {this.getDisplayGroups(this.state.sort_by, this.state.search_text, this.state.amount_to_display, this.state.filter_by_profile, this.state.filter_query, this.state.post.selected_groups).map((group) => (<div style={{padding: '13px', width: '20%', float: 'left'}}>
        <span dir="ltr" className={"text-decoration-none my-1 d-block square group " + (this.state.selected.indexOf(group._id) > -1 ? ' active': '') }>
          <div className="rounded rounded-circle ">
            <img style={{left: 0}} className="group-image img img-thumbnail img-fluid rounded rounded-circle " src={group.image_url} g="img/group1.png" alt="" />
            <span className="position-absolute top-50 start-50 translate-middle bg-dark-75 rounded rounded-circle w-75 h-75">
              <p className=" position-absolute top-50 start-50 translate-middle w-100 text-center text-white fw-bolder"><a target="_blank" href={`https://facebook.com/groups/${group.group_id}`}>{group.name}</a></p>
            </span>
          </div>
          <span className="badge bg-info px-2 py-2 small text-white rounded-pill position-25"><img className="d-inline mx-1 h-icon" width="5px" src="/static/media/follower.e4d49cda.svg" g="icons/follower.svg" />{nFormatter(group.follow_count, 2)}</span><span className="badge bg-info px-2 py-2 small text-white rounded-pill position-75"><img className="d-inline mx-1 h-icon" width="5px" src="/static/media/clock.66a84231.svg" g="icons/clock.svg" />{group.today_slot_count}<img className="d-inline mx-1 h-icon" width="5px" src="/static/media/account.9ec423dc.svg" g="icons/account.svg" />{group.users_in_group_count}</span>
        






        </span>
          <button onClick={this.toggleAddGroup.bind(this, group._id)} className={`btn ${(this.state.post.selected_groups.indexOf(group._id) > -1 ? 'btn-danger': 'btn-success')}`}>{(this.state.post.selected_groups.indexOf(group._id) > -1 ? this.props.t('Unselect'): this.props.t('Select'))}</button>
      </div>))}
    </div>
  </div>
</div>}
{this.state.current_step === 'custom_groups' && <div>


      <h4>{this.props.t('Schedule Groups')}</h4>







      <p>{this.props.t("In this step we will set a custom schedule for posts in groups")}</p>
      {this.state.post.times.length === 0 && <p style={{color: 'black'}}>{this.props.t("You must add slot times")}</p>}

      <hr/>
      <div className="schedule-for-me" style={{direction: 'rtl'}}>
        <h4>{this.props.t('Schedule for me')}</h4>
        {this.props.t('Schedule for me')} <input type="number" value={this.state.schedule_for_me.amount} onChange={(e) => { this.state.schedule_for_me.amount = Math.max(e.target.value, 1); this.setState({schedule_for_me: this.state.schedule_for_me})}} /> {this.props.t('posts in each group')} {this.props.t('disperse at')} 

         <Multiselect
          options={this.state.times.map( (time) => {
            return {
              name: `${time}:00`,
              id: time,
            }
          } )} 
          selectedValues={/*[{name: '4:00', id: 4}]*/ this.state.post.times.filter((a) => a.time).map( (time) => {
            return {
              name: `${time.time}:00`,
              id: time.time,
            }
          }) } 
          style={{
            backgroundColor: '#fff',
            width: '300px',
            display: 'inline-block',
          }}
          className={'adad'}
          onSelect={ this.selectTimes.bind(this) }
          /*onSelect={this.onSelect} */
          onRemove={this.removeTimes.bind(this)} 
          displayValue="name" 
        />

 {/*{this.props.t('sorting by')} <select value={this.state.sort_by} onChange={(e) => { this.setState({sort_by: e.target.value}); }} className="" aria-label="Default select example">
          <option>{this.props.t('Sort By')}</option>
          <option value={'today_slot_count'}>{this.props.t('Slot Count')}</option>
          <option value={'users_in_group_count'}>{this.props.t('User in group')}</option>
          <option value={'follow_count'}>{this.props.t('Followers')}</option>
        </select>*/}
        <button onClick={this.apply_schedule_for_me.bind(this)}>{this.props.t('Apply')}</button> 
        </div>
      <hr/>
        <h4>{this.props.t('Custom')}</h4>

      <table className="table group-selection">
        <thead>
          <tr>
            <th scope="col">
              <h3>{this.props.t('Sort By')}</h3>

              <select value={this.state.sort_by} onChange={(e) => { this.setState({sort_by: e.target.value}); }} className="form-select bg-primary  rounded-3 text-white py-3 mb-4 px-1" aria-label="Default select example">
                <option>{this.props.t('Sort By')}</option>
                <option value={'today_slot_count'}>{this.props.t('Slot Count')}</option>
                <option value={'users_in_group_count'}>{this.props.t('User in group')}</option>
                <option value={'follow_count'}>{this.props.t('Followers')}</option>
              


              </select>

              <div className="input-group">
                <input className="form-control" placeholder={this.props.t('Search Groups')} type="text" value={this.state.search_text} onChange={(e) => {this.setState({search_text: e.target.value})}}/>
              </div>

            </th>
            <th scope="col" style={{width: '180px'}}>
              <div>
                <span>סה"כ <strong>{this.calulateAllPosts()}</strong> פוסטים מתוזמנים</span>
              </div>
              <button className={`btn btn-success ${this.state.post.times.length === 0 ? 'blink-bg' : ''}`} onClick={(e) => {
                this.state.post.times.push({time: null, post_amount: 1, group_map: {}});
                this.setState({post: this.state.post})
              }}>{this.props.t('Add Time')}</button>

            </th>
            <th style={{whiteSpace: 'nowrap'}}></th>
          </tr>
        </thead>
        <tbody>



          {this.getDisplayGroups(this.state.sort_by, this.state.search_text, this.state.amount_to_display, null, null, [], this.getByGroupID(this.state.post.selected_groups)).map((group) => <tr>
            <td scope="row" style={{width: "180px"}}>
              {(<div style={{}}>
                <span dir="ltr" className={"text-decoration-none my-1 d-block square group " + (this.state.selected.indexOf(group._id) > -1 ? ' active': '') }>
                  <div className="rounded rounded-circle ">
                    <img style={{left: 0}} className="group-image img img-thumbnail img-fluid rounded rounded-circle " src={group.image_url} g="img/group1.png" alt="" />
                    <span className="position-absolute top-50 start-50 translate-middle bg-dark-75 rounded rounded-circle w-75 h-75">
                      <p className=" position-absolute top-50 start-50 translate-middle w-100 text-center text-white fw-bolder"><a target="_blank" href={`https://facebook.com/groups/${group.group_id}`}>{group.name}</a></p>
                    </span>
                  </div>
                  <span className="badge bg-info px-2 py-2 small text-white rounded-pill position-25"><img className="d-inline mx-1 h-icon" width="5px" src="/static/media/follower.e4d49cda.svg" g="icons/follower.svg" />{nFormatter(group.follow_count, 2)}</span><span className="badge bg-info px-2 py-2 small text-white rounded-pill position-75"><img className="d-inline mx-1 h-icon" width="5px" src="/static/media/clock.66a84231.svg" g="icons/clock.svg" />{group.today_slot_count}<img className="d-inline mx-1 h-icon" width="5px" src="/static/media/account.9ec423dc.svg" g="icons/account.svg" />{group.users_in_group_count}</span>
                </span>
              </div>)}
            </td>
            <td>
              <button onClick={this.selectGroupRow.bind(this, group._id)}>{this.props.t('Select All')}</button>
              <div>
                <span>סה"כ <strong>{this.calulateGroupPosts(group._id)}</strong> פוסטים בקבוצה זו</span>
              </div>
            </td>
          </tr>)}
        </tbody>
      </table>
</div>}
{this.state.current_step === 'profiles' && <div>
  <div>
    <h4>{this.props.t('From what profiles?')}</h4>

    <p>{this.props.t('In this step we will choose the profiles that will publish the posts in the groups, you can select all profiles')}</p>




    {this.state.allow_edit.profiles && <div>
          <button className="btn btn-warning" onClick={() => {
             this.state.post.users = this.state.profiles.map((profile) => profile._id);
             this.setState({
                 users: this.state.post
             });
          }}>{this.props.t("Mark All")}</button>
    {this.state.profiles.map((profile) => (<React.Fragment><span className="position-relative"><span data-tip={profile.first_name + " " + profile.last_name} onClick={this.togglUser.bind(this, profile._id)} className={"me-3 " + ((this.state.post.users.indexOf(profile._id) > -1) ? 'active' : '')} key={profile._id}>
      <img width="60px" className={`profile rounded rounded-circle border border-3  ${profile.is_logged_in ? 'logged_in' : 'logged_out'} `} src={'https://images.fbzipper.com/api/users/profile/' + profile._id} g="img/user1.png" alt="" />
    </span></span></React.Fragment>))}
    </div>}
  </div>
</div>}
{this.state.current_step === 'settings' && <div>
  {<div className="settings">

      <h4>{this.props.t('Settings')}</h4>

      <p>{this.props.t('In this step you can select custom settings if needed (not required)')}</p>


      <MuiPickersUtilsProvider utils={DateFnsUtils}>

        {this.state.allow_edit.should_delete && <div>
          <div>
              {this.props.t('Should Delete At Specific Time')}
              <Switch
                classes={this.props.classes}
                checked={this.state.post.settings.should_delete}
                onClick={(e) => { this.state.post.settings.should_delete = !this.state.post.settings.should_delete; this.setState({
                  post: this.state.post,
                }) }}
              />
          </div>

          {this.state.post.settings.should_delete && <TimePicker
            label={this.props.t("Delete At")}
            inputVariant="outlined"
            value={this.state.post.settings.delete_at}
            onChange={ (newValue)=> {
                this.state.post.settings.delete_at = newValue;
                this.setState({
                    post: this.state.post,
                })
            }}
          />}
        </div>}

        {this.state.allow_edit.should_delete && <div>
          <div>
              {this.props.t('Should Delete Time After Publish')}
              <Switch
                classes={this.props.classes}
                checked={this.state.post.settings.should_delete_time_after_publish}
                onClick={(e) => { this.state.post.settings.should_delete_time_after_publish = !this.state.post.settings.should_delete_time_after_publish; this.setState({
                  post: this.state.post,
                }) }}
              />
          </div>

          {this.state.post.settings.should_delete_time_after_publish && <select value={this.state.post.settings.delete_after_hours} onChange={(e) => {
                  this.state.post.settings.delete_after_hours = e.target.value; 
                  this.setState({
                    post: this.state.post,
                  })
            }}>
                <option value={1}>{this.props.t('hour')}</option>
                <option value={2}>2 {this.props.t('hours')}</option>
                <option value={4}>4 {this.props.t('hours')}</option>
                <option value={6}>6 {this.props.t('hours')}</option>
                <option value={8}>8 {this.props.t('hours')}</option>
                <option value={10}>10 {this.props.t('hours')}</option>
                <option value={12}>12 {this.props.t('hours')}</option>
                <option value={16}>16 {this.props.t('hours')}</option>
                <option value={20}>20 {this.props.t('hours')}</option>
                <option value={24}>{this.props.t('day')}</option>
                <option value={24*2}>2 {this.props.t('days')}</option>
                <option value={24*3}>3 {this.props.t('days')}</option>
                <option value={24*4}>4 {this.props.t('days')}</option>
                <option value={24*5}>5 {this.props.t('days')}</option>
                <option value={24*6}>6 {this.props.t('days')}</option>
                <option value={24*7}>1 {this.props.t('week')}</option>
            </select>}
        </div>}

        {this.state.allow_edit.should_pump && <div>
          <div>
              <span data-tip={this.props.t('Comments a dot from a random profiles and deletes')}><FaRegQuestionCircle/></span> {this.props.t('Should Pump')}
              <Switch
                classes={this.props.classes}
                checked={this.state.post.settings.should_pump}
                onClick={(e) => { this.state.post.settings.should_pump = !this.state.post.settings.should_pump; this.setState({
                  post: this.state.post,
                }) }}
              />
          </div>
          {this.state.post.settings.should_pump && <div>
              {this.props.t('Pump Every')}

              <select value={this.state.post.settings.pump_every_minutes} onChange={(e) => {
                    this.state.post.settings.pump_every_minutes = e.target.value; 
                    this.setState({
                      post: this.state.post,
                    })
              }}>
                  <option value={60}>{this.props.t('hour')}</option>
                  <option value={60*1.5}>1.5 {this.props.t('hours')}</option>
                  <option value={60*2}>2 {this.props.t('hours')}</option>
                  <option value={60*2.5}>2.5 {this.props.t('hours')}</option>
                  <option value={60*3}>3 {this.props.t('hours')}</option>
                  <option value={60*4}>4 {this.props.t('hours')}</option>
                  <option value={60*5}>5 {this.props.t('hours')}</option>
                  <option value={60*6}>6 {this.props.t('hours')}</option>
                  <option value={60*7}>7 {this.props.t('hours')}</option>
                  <option value={60*8}>8 {this.props.t('hours')}</option>
                  <option value={60*9}>9 {this.props.t('hours')}</option>
                  <option value={60*10}>10 {this.props.t('hours')}</option>
              </select>
          </div>}
        </div>}

        {this.state.allow_edit.should_edit && <div>
          <div>
              {this.props.t('Should Edit')}
              <Switch
                classes={this.props.classes}
                checked={this.state.post.settings.should_edit}
                onClick={(e) => { this.state.post.settings.should_edit = !this.state.post.settings.should_edit; this.setState({
                  post: this.state.post,
                }) }}
              />
          </div>
          {this.state.post.settings.should_edit && <div>
              {this.props.t('Edit After Minutes')}
              <input 
                type="number"
                name="edit_after_minutes"
                value={this.state.post.settings.edit_after_minutes}
                min="0"
                onChange={(e) => { 
                    this.state.post.settings.edit_after_minutes = e.target.value; 
                    this.setState({
                      post: this.state.post,
                    })
                }}
              />
          </div>}
        </div>}

        {this.state.post.settings.should_edit && <div>
            {this.props.t('Campaign')}
            <select value={this.state.post.settings.edit_campaign} onChange={(e) => {
                  this.state.post.settings.edit_campaign = e.target.value; 
                  this.setState({
                    post: this.state.post,
                  })
            }}>
                <option></option>
                {this.state.campaigns.map((campaign) => <option value={campaign._id}>{campaign.name}</option>)}
            </select>

                <div className="select-edit-posts">
                  {this.state.edit_campaign_posts.map((post) => (
                      <React.Fragment>

                          <span className={`position-relative select-edit-post ${(this.state.post.settings.edit_posts || []).indexOf(post._id) > -1 ? 'selected' : ''}`} onClick={() => {
                                this.state.post.settings.edit_posts = this.state.post.settings.edit_posts || []; 
                                var current_index = this.state.post.settings.edit_posts.indexOf(post._id);
                                if (current_index > -1) {
                                  this.state.post.settings.edit_posts.splice(current_index, 1)
                                } else {
                                  this.state.post.settings.edit_posts.push(post._id); 
                                }

                                this.setState({
                                  post: this.state.post,
                                })
                          }}>
                          <span className="text-background d-block m-1 border border-2 border-secondary text-center position-relative post" >

                                  {(!post.type || post.type === 'image') && 
                                    <img className="img img-thumbnail img-fluid " width src={"/api/images/" + post._id} g="img/post1.png" alt="" />
                                  }

                                  {(post.type === 'video') && 
                                    <img className="img img-thumbnail img-fluid " width src={"/api/images/" + post._id} g="img/post1.png" alt="" />
                                  }

                                  {(post.type === 'album') && 
                                    <img className="img img-thumbnail img-fluid " width src={"/api/images/" + post._id} g="img/post1.png" alt="" />
                                  }

                                  {(post.type === 'text-background' || post.type === 'text') && 
                                    <span className="text-background">
                                       {post.post_content}
                                    </span>
                                  }
                          </span>



                      </span>

                      </React.Fragment>
                  ))}
                 </div>

        </div>}



      </MuiPickersUtilsProvider>


    <br/>
    <br/>
  </div>}

</div>}
{this.state.current_step === 'summary' && <div>

      <h4>{this.props.t('Summary')}</h4>
      <p style={{direction: "rtl"}}>{this.props.t('Congratulations! you have successfully scheduled your campaign, Your posts will start to schedule tomorrow for the rest of youe life')}</p>
      
      <div className="summary">
        <div style={{direction: 'rtl'}}>
          <span className="title">{this.props.t('Selected Campaign Name')}:</span> {this.state.post.campaign && this.state.post.campaign.name}
        </div>
        <div style={{direction: 'rtl'}}>
          <span className="title">{this.props.t('Selected Post Campaign Count')}:</span> {this.state.post.settings.all_posts ? this.props.t('All posts') : this.state.post.selected_posts.length}
        </div>
        <div style={{direction: 'rtl'}}>
          <span className="title">{this.props.t('Selected profiles that will publish')}:</span> {this.state.post.users.length}
        </div>
        <div style={{direction: 'rtl'}}>
          <span className="title">{this.props.t('Selected group amount')}:</span> {this.state.post.selected_groups.length}
        </div>
        <div style={{direction: 'rtl'}}>
          <span className="title">{this.props.t('Amount of posts to publish from this campaign')}:</span> {this.calulateAllPosts()}
        </div>
        <div style={{direction: 'rtl'}}>
          <span className="title">{this.props.t('Amount of hours to publish from this campaign')}:</span> {this.state.post.times.filter((time) => { return this.calulateColumnPosts(time) }).length } <span className="title">{this.props.t('And they are')}:</span> {this.state.post.times.filter((time) => { return this.calulateColumnPosts(time) }).map((time) => { return `${time.time}:00 (${this.calulateColumnPosts(time)}) ` }).join(', ') }
        </div>

        {this.state.post.settings.delete_at && this.state.post.settings.should_delete && <div style={{direction: 'rtl'}}>
          <span className="title">{this.props.t('These posts will delete at')}:</span> {moment(this.state.post.settings.delete_at).format('HH:mm')}
        </div>}

        {this.state.post.settings.should_delete_time_after_publish && this.state.post.settings.delete_after_hours && <div style={{direction: 'rtl'}}>
          <span className="title">{this.props.t('Posts will delete after publish after')}:</span> 

          {this.state.post.settings.delete_after_hours < 24 && <span>{ this.state.post.settings.delete_after_hours } {this.props.t('hours')}</span>}
          {this.state.post.settings.delete_after_hours >= 24 && this.state.post.settings.delete_after_hours < 24*7 && <span>{ this.state.post.settings.delete_after_hours / 24 } {this.props.t('days')}</span>}
          {this.state.post.settings.delete_after_hours >= 24*7 && <span>{ this.state.post.settings.delete_after_hours / (24*7) } {this.props.t('weeks')}</span>}
        </div>}

        {this.state.post.settings.should_pump && this.state.post.settings.pump_every_minutes && this.state.post.settings.delete_after_hours && <div style={{direction: 'rtl'}}>
          <span className="title">{this.props.t('Posts will be pumped every')}:</span> 

          {this.state.post.settings.pump_every_minutes < 60 && <span>{ this.state.post.settings.pump_every_minutes } {this.props.t('minutes')}</span>}
          {this.state.post.settings.pump_every_minutes >= 60 && <span>{ this.state.post.settings.pump_every_minutes/60 } {this.props.t('hours')}</span>}
        </div>}

        {this.state.post.settings.should_edit && this.state.post.settings.edit_posts.length > 0 && this.state.post.settings.edit_after_minutes && this.state.post.settings.delete_after_hours && <div style={{direction: 'rtl'}}>
          <span className="title">{this.props.t('Posts will be edited after')}: </span> 

          {this.state.post.settings.edit_after_minutes < 60 && <span>{ this.state.post.settings.edit_after_minutes } {this.props.t('minutes')}</span>}
          {this.state.post.settings.edit_after_minutes >= 60 && <span>{ this.state.post.settings.edit_after_minutes/60 } {this.props.t('hours')}</span>}
        
          <span className="title"> {this.props.t('to campaign')}: </span> {this.getByCampaignID(this.state.post.settings.edit_campaign).name} 
          <span className="title"> {this.props.t('and will rotate between')} </span> {this.state.post.settings.edit_posts.length} <span className="title"> {this.props.t('posts')} </span>
        </div>}



      </div>
      <br/>
      <p style={{color: 'lime', fontSize: '24px'}}><strong>{this.props.t("Don't forget to save your settings")}</strong></p>

</div>}




                  </div>



        <hr/>
          {(this.state.post._id || this.state.current_step === 'summary') && <button onClick={this.save.bind(this)} type="submit" className="btn w-75 fw-bold bg-white py-3 px-5 mt-4 text-primary rounded rounded-pill">{this.props.t('Save')}</button>}
          {this.state.post._id && <button onClick={this.delete.bind(this)} type="submit" className="btn w-75 fw-bold btn-danger py-3 px-5 mt-4 text-primary rounded rounded-pill">{this.props.t('Delete')}</button>}
          {this.state.current_step !== 'summary' && <button disabled={!this.state.complete_steps[this.state.current_step]()} onClick={this.next.bind(this)} type="submit" className="btn w-75 fw-bold bg-white py-3 px-5 mt-4 text-primary rounded rounded-pill">{this.props.t('Next')}</button>}
        <hr/>



         </div> : <div className="loader">
                  <svg width="300px" version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                    <circle fill="none" stroke="#fff" strokeWidth={4} cx={50} cy={50} r={44} style={{opacity: '0.5'}} />
                    <circle fill="#fff" stroke="#e74c3c" strokeWidth={3} cx={8} cy={54} r={6}>
                      <animateTransform attributeName="transform" dur="2s" type="rotate" from="0 50 48" to="360 50 52" repeatCount="indefinite" />
                    </circle>
                  </svg>
                 </div>}
		  </div>
        );
    }
}

ScheduleMultiGroupPopup.propTypes = {};

ScheduleMultiGroupPopup.defaultProps = {};

export default ScheduleMultiGroupPopup;



