import React from 'react';
import PropTypes from 'prop-types';
import styles from './Groups.module.scss';
import fetch from 'node-fetch';
import _ from 'lodash';
import $ from 'jquery';

import settings from '../../assets/icons/settings.svg';
import logo from '../../assets/images/logo.png';
import help from '../../assets/icons/help.svg';
import alert from '../../assets/icons/alert.svg';
import signin from '../../assets/icons/signin.svg';
import clock from '../../assets/icons/clock.svg';
import add from '../../assets/icons/add.svg';
import user1 from '../../assets/images/user1.png';
import user2 from '../../assets/images/user2.png';
import user3 from '../../assets/images/user3.png';
import user4 from '../../assets/images/user4.png';
import post1 from '../../assets/images/post1.png';
import post2 from '../../assets/images/post2.png';
import chat from '../../assets/icons/chat.svg';
import monitor from '../../assets/icons/monitor.svg';
import bin from '../../assets/icons/bin.svg';
import group1 from '../../assets/images/group1.png';
import account from '../../assets/icons/account.svg';
import password from '../../assets/icons/password.svg';
import like from '../../assets/icons/like.svg';
import follower from '../../assets/icons/follower.svg';
import tutorialEventBus from "../../tutorialEventBus";

import { FaEdit, FaLayerGroup } from "react-icons/fa";


import {Button} from 'react-bootstrap/Button';
import {DropdownButton, ButtonGroup, Dropdown} from 'react-bootstrap';

import { useTranslation, initReactI18next, withTranslation } from 'react-i18next';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  withRouter,
  useLocation
} from "react-router-dom";

import queryString from 'query-string';

function nFormatter(num, digits) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function(item) {
    return num >= item.value;
  });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}

class Groups extends React.Component {

    state = {
    	groups: [],
        selected_groups: [],
    	selected: null,
        search_text: '',
        latest_new: null,
        sort_by: '',
    }

    refresh() {

    }

    _sort() {
        var groups = _.orderBy(this.state.groups, this.state.sort_by || 'today_slot_count', 'desc');
        
        if (this.state.selected_groups) {
            groups = groups.sort((x,y) => { return this.state.selected_groups.indexOf(x._id) > -1 ? -1 : this.state.selected_groups.indexOf(y._id) > -1 ? 1 : 0; })
        }

        this.setState({
            groups: groups,
        });
    }

    update_data(props, selected_groups = []) {

          try {
            if (this.get_groups_conroller) {
              this.get_groups_conroller.abort();
            }
          } catch {
          }

        this.get_groups_conroller = new AbortController();
        let signal = this.get_groups_conroller.signal;

        var selected_groups = selected_groups || this.state.selected_groups || [];
        
        this.setState({
            selected_groups: selected_groups,
        })

        
        return new Promise((resolve, reject) => {
            (async () => {
                var token = localStorage.getItem('user_token');
                var response = await fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/groups?access_token=${token}&extra-selected-groups=${selected_groups.join(',')}`, {
                    signal: signal,
                })
                var data = await response.json();

                this.setState({
                    groups: data,
                });

                this._sort();
                this.move_to_selected();
            })()
        })
    }

    move_to_selected() {
        setTimeout(() => {
            if ($('#groups .active')) {
                if ($('#groups .active')[0]) {
                    $('.groups-container .overflow-auto')[0].scroll(0,$('#groups .active')[0].offsetTop-$('#groups .active')[0].clientHeight-300)
                }
            }
        }, 0)
    }

    componentDidMount() {
      tutorialEventBus.on("select_group", async (action) => {

        setTimeout(async () => {
            await this.props.updateSearch({group: action.group, show_groups: 1});
            await this.setState({
                selected: action.group,
            });
            await this.update_data(this.props, [action.group])
        }, 0)

      });
    }
    componentWillUnmount() {
      tutorialEventBus.remove("select_group");
    }

    componentWillReceiveProps(props) {
        var query = queryString.parse(this.props.location.search);
    	this.setState({
    		selected: query.group,
    	});
    	
    	if (props.user !== this.props.user) {
    		this.update_data(props);
    	}

        if (query['new-group'] && this.state.latest_new !== query['new-group']) {
            this.setState({
                latest_new: query['new-group'],
            });

            this.update_data(props);
        }


    }
    
    componentWillMount(props) {
        var query = queryString.parse(this.props.location.search);
    	this.setState({
    		selected: query.group,
    	});
	
		this.update_data(this.props);	
    }



    render() {
    	const { t, i18n } = this.props;
        return (
        	<React.Fragment>
	            <div className="group-sort">


              {/*<div className="group-info text-center">
                <a onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console','group', 'manage']})} className="btn btn-primary manage-group-button">{t('Manage groups')}</a>
              </div>*/}

              {queryString.parse(this.props.location.search).admin && <div className="btn-group d-flex" role="group">
                <button 
                    onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console','group', 'manage']})}
                    type="button" className="btn btn-secondary"
                    style={{padding: '0px 10px'}}>
                        <FaLayerGroup/>
                </button>
                <span className="create-new-group manage-posts-button w-100">
                    <button 
                        style={{padding: '0px 0px', marginRight: '0px'}}
                        onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console','group-library']}, 'open_manage_group_library_popup')}
                        className="btn btn-primary manage-group-button w-100">
                            {t('Manage groups')}
                        </button>
                </span>
              </div>}

            <select value={this.state.sort_by} onChange={(e) => { this.setState({sort_by: e.target.value}); setTimeout(() => this._sort(), 1); }} className="form-select bg-primary  rounded-3 text-white py-3 mb-4 px-1 group" aria-label="Default select example">
              <option value={null}>{this.props.t('Sort By')}</option>

              <option value={'today_slot_count'}>{this.props.t('Slot Count')}</option>
              <option value={'users_in_group_count'}>{this.props.t('User in group')}</option>
              <option value={'follow_count'}>{this.props.t('Followers')}</option>
              <option value={'today_slot_count'}>{this.props.t('Pending Profile Amount')}</option>
              <option value={'blocked_users_in_group_count'}>{this.props.t('Blocked Profile Amount')}</option>
            </select>

              <div className="search-section">
                <input style={{maxWidth: '100%'}} placeholder={t('Search Groups')} type="text" value={this.state.search_text} onChange={(e) => {this.setState({search_text: e.target.value})}}/>
              </div>



              <div className="group-info"><strong>{t(`Showing`)}:</strong> {this.state.groups.filter((group) => {return group && group.name.toLowerCase().indexOf(this.state.search_text.toLowerCase()) > -1;}).length} {t(`groups`)} {t('of')} {this.state.groups && this.state.groups.length}</div>

                {this.state.groups && this.state.groups.length > 0 && queryString.parse(this.props.location.search).group && [this.state.groups.find((a) => a._id == queryString.parse(this.props.location.search).group)].map((group) => (group && <div key={group._id} className="rtl">
                    <label style={{color: 'black'}}>{this.props.t('Selected Group')}:</label>
                    <span style={{
                        width: '149px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        cursor: 'pointer',
                    }} className="badge badge-secondary" onClick={this.props.updateSearch.bind(this, {group: undefined})}>X {group.name}</span>
                </div>))}

              </div>

	            <div dir="rtl" className="groups-container">
                    <div className="px-2 mh-75 overflow-auto groups">
    	              {/*<a dir="ltr" className="d-block my-2 cadre border border-2 border-secondary rounded rounded-circle text-center position-relative" onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console','group', 'add']})}>
    	                <span className="text-decoration-none text-secondary position-absolute top-50 start-50 w-100 translate-middle">
    	                  <img className="m-1" width="25px" src={add} g="icons/add.svg" alt="" /> {t('Add New Group')}
    	                </span>
    	              </a>*/}

                      {/*<a dir="ltr" className="d-block my-2 cadre border border-2 border-secondary rounded rounded-circle text-center position-relative" onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console','group', 'manage']})}>
                        <span className="text-decoration-none text-secondary position-absolute top-50 start-50 w-100 translate-middle">
                           <faEdit/> {t('Manage groups')} ({this.state.groups.length})
                        </span>
                      </a>*/}

                      

    	              {this.state.groups.filter((group) => {return group.name.toLowerCase().indexOf(this.state.search_text.toLowerCase()) > -1;})/*.slice(0, 300)*/.map((group) => (<React.Fragment><span className="position-relative">

                       <span key={group._id} dir="ltr" className={"text-decoration-none my-1 d-block square group " + ((queryString.parse(this.props.location.search).group === group._id) ? 'active' : '')}>
    	                <div className="rounded rounded-circle button-click" onClick={this.props.updateSearch.bind(this, {group: group._id})}>
    	                  <img className="group-image img img-thumbnail img-fluid rounded rounded-circle " src={group.image_url} g="img/group1.png" alt="" />
    	                  <span className="position-absolute top-50 start-50 translate-middle bg-dark-75 rounded rounded-circle w-75 h-75">
    	                    <p className=" position-absolute top-50 start-50 translate-middle w-100 text-center text-white fw-bolder"> {group.name}
    	                    </p>
    	                  </span>
    	                </div>
    	                <span className="badge bg-info px-2 py-2 small text-white rounded-pill position-25">
    	                  <img className="d-inline mx-1 h-icon" width="5px" src={follower} g="icons/follower.svg" />
    	                  {nFormatter(group.follow_count, 2)}
    	                </span>
    	                <span className="badge bg-info px-2 py-2 small text-white rounded-pill position-75">
    	                  <img className="d-inline mx-1 h-icon" width="5px" src={clock} g="icons/clock.svg" />
    	                  {group.today_slot_count}
                          
                          <img className="d-inline mx-1 h-icon" width="5px" src={account} g="icons/account.svg" />
                          {group.users_in_group_count}
    	                </span>

                        {/*
                        {(this.state.selected === group._id) && <span onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console','group', 'edit', group._id]})} className="badge bg-info text-white rounded-pill position-absolute top-50 start-0">
                          Edit
                        </span>}
                        */}

                        {<span className="badge bg-info text-white rounded-pill position-absolute bottom-0">
                          {t('Pending')}: {group.pending_users_in_group_count} <br/>
                          {t('Pending Participation')}: {group.pending_participation_users_in_group_count}  <br/>
                          {t('Blocked')}: {group.blocked_users_in_group_count}
                        </span>}

    	              </span>

                      </span></React.Fragment>))}
    	            </div>
                </div>
	        </React.Fragment>
        );
    }
}

Groups.propTypes = {};

Groups.defaultProps = {};

export default Groups;
