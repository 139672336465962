import React from 'react';
import PropTypes from 'prop-types';
import styles from './Tutorial.module.scss';

import {FaTimes, FaLongArrowAltRight, FaExclamationTriangle} from "react-icons/fa";

import tutorialEventBus from "../../tutorialEventBus";

import LoadingIcons from 'react-loading-icons'
import queryString from 'query-string';

const fff_css = `
  .add-new-user {
      z-index: 999999;
      position: relative;
  }
  .add-new-user button {
      z-index: 999999;
      position: relative;
  }
`;

// baloon aniamtion -> https://codepen.io/Jemimaabu/pen/vYEYdOy

class Tutorial extends React.Component {

    active_css_location = (step) => {
      var selectors = step.selected_css_selectors || [];
      return selectors.map((selector) => {
        return `
          ${selector} {
              z-index: 999999;
              position: relative;
          }
          ${selector} button {
              z-index: 999999;
              position: relative;
          }


          ${step.selector_text && step.selector_text[selector] !== 'none' ? `

            ${selector}:after {
              pointer-events: none;
              content: "↑";
              position: absolute;
              top: 100%;
              color: red;
              font-size: 142px;
              margin-left: auto;
              margin-right: auto;
              left: 0;
              right: 0;
              text-align: center;
              line-height: 1px;
              font-weight: bold;
              line-height: 69px;
              animation: blinker 1s linear infinite;
                  z-index: -1;
            }

            ${step.selector_position === 'top' ? `

              ${selector}:after {
                content: "↓";
                bottom: calc(100% + 30px);
                top: auto;
              }


            ` : ``}

            ${step.selector_position === 'right' ? `

              ${selector}:after {
                content: "←";
                color: red;
                font-size: 142px;
                line-height: 1px;
                position: absolute;
                margin-left: auto;
                margin-right: auto;
                left: -webkit-calc(100% - 28px);
                left:    -moz-calc(100% - 28px);
                left:         calc(100% - 28px);



                text-align: center;
                animation: blinker 1s linear infinite;
                top: auto;
                right: auto;
                top: calc(50% - 27px);

              }


            ` : ``}


            ${step.selector_position === 'left' ? `

              ${selector}:after {
                content: "→";
                color: red;
                font-size: 142px;
                line-height: 1px;
                position: absolute;
                margin-right: auto;
                margin-left: auto;
                right: -webkit-calc(100% - 28px);
                right:    -moz-calc(100% - 28px);
                right:         calc(100% - 28px);



                text-align: center;
                animation: blinker 1s linear infinite;
                top: auto;
                left: -117px;
                top: calc(50% - 27px);

              }


            ` : ``}



            
            
            ${step.selector_text ? `


              ${selector}:before {
                pointer-events: none;
                content: "${step.selector_text && step.selector_text[selector]}";
                position: absolute;
                top: 100%;
                color: #fff;
                border: 1px solid #fff;
                margin-left: auto;
                margin-right: auto;
                text-align: center;
                line-height: 1px;
                line-height: 38px;
                margin-top: 84px;
                background: red;
                white-space: nowrap;
                transform: translateX(-50%);
                padding: 0 10px;
                left: 50%;
                font-size: 17px;
              }
            ` : ``}


            ${step.selector_position === 'top' ? `

              ${selector}:before {
                top: auto;
                bottom: calc(100% + 88px);
              }


            ` : ``}

            ${step.selector_position === 'right' ? `

              ${selector}:before {
                left: -webkit-calc(100% + 85px);
                left:    -moz-calc(100% + 85px);
                left:         calc(100% + 85px);
                bottom: 50%;
                top: auto;
                transform: translateY(calc(50% + 4px));
                bottom: 50%;
                top: auto;
                position: absolute;
                margin-left: auto;
                margin-right: auto;
                text-align: center;
                top: auto;
                right: auto;
              }


            ` : ``}

            ${step.selector_position === 'left' ? `

              ${selector}:before {
                right: -webkit-calc(100% + 85px);
                right:    -moz-calc(100% + 85px);
                right:         calc(100% + 85px);
                bottom: 50%;
                top: auto;
                transform: translateY(calc(50% + 4px));
                bottom: 50%;
                top: auto;
                position: absolute;
                margin-right: auto;
                margin-left: auto;
                text-align: center;
                top: auto;
                left: auto;
              }


            ` : ``}



            @keyframes blinker {
              50% {
                opacity: 0.4;
              }
            }
          ` : `<!-- end none  -->`}

        `
      }).join('\n');
    }

    getCustomTutorialCss = () => {
      return `.overlay {
        background: none !important;
      }`;
    }

    componentDidMount() {
      var query = queryString.parse(this.props.location.search);

      var current_tutorial = this.state.tutorials.find((a) => a.id === query['current-tutoral']);
      // current-tutoral=

      // window.alert(2222222)
      if (current_tutorial) {
        this.setSelectedTutorial(current_tutorial, query.step || 0);
      }

      tutorialEventBus.on("action", (action) => {
        var event = this.get_current_step();
        // window.alert(action.action);
        if (event && event.success_action === action.action) {
          event.progress = action.progress;

          console.log(event);
          
          if (action.progress === 100) {
            event.action_triggered = true;

            if (event.must_watch_video) {
              if (event.watched_video) {
                this.next_step();
              }
            } else {
              this.next_step();
            }
          }

          this.setState({selected_tutorial: this.state.selected_tutorial})
        }
      });
    }

    componentWillUnmount() {
      tutorialEventBus.remove("action");
    }

    get_current_step() {
      if (this.state.selected_tutorial && this.state.selected_tutorial.steps && this.state.selected_tutorial.steps[this.state.selected_tutorial.step_index] && this.state.selected_tutorial.steps[this.state.selected_tutorial.step_index]) {
        return this.state.selected_tutorial.steps[this.state.selected_tutorial.step_index];
      }
    }

    set_watched_video () {
      var event = this.get_current_step();
      event.watched_video = true;

      if (event.action_triggered) {
        this.next_step();
      }

      this.setState({selected_tutorial: this.state.selected_tutorial})
    }



// this.setSelectedTutorial.bind(this, tutorial)
//     setSelectedTutorial(tutorial, index) {
//       // window.alert(123);
//       tutorial.step_index = parseInt(index) || 0;

//       this.props.updateSearch({'current-tutoral': tutorial.id, step: tutorial.step_index})
//       this.setState({selected_tutorial: tutorial});

//       setTimeout(() => {
//         var current_step = this.get_current_step();

//         if (current_step.init_function) {
//           current_step.init_function();
//         }
//       }, 0)
//     }




    next_tutorial () {
        var next_tutorial_ = this.state.tutorials[this.state.tutorials.indexOf(this.state.selected_tutorial) + 1];

        if (next_tutorial_) {
          this.setSelectedTutorial(next_tutorial_)
        }
        setTimeout(() => {
          this.setState({a: 1234});
        }, 0)
    }
    next_step() {
      var event = this.get_current_step();

      event.success = true;

      this.state.selected_tutorial.step_index++;
      this.setState({selected_tutorial: this.state.selected_tutorial})

      var current_step = this.get_current_step();
      
      if (!current_step) {
        this.next_tutorial();
        return;
      }

      if (current_step && current_step.init_function) {
        current_step.init_function();
      }

      setTimeout(() => {
        var query = queryString.parse(this.props.location.search);
        
        this.props.updateSearch({'current-tutoral': this.state.selected_tutorial.id, step: this.state.selected_tutorial.step_index})
      

        // var current_tutorial = this.state.tutorials.find((a) => a.id === this.state.selected_tutorial.id);

        // if (current_tutorial) {
        //   this.setSelectedTutorial(current_tutorial, query.step || 0);
        // }


      }, 0)
    }

    state = {
      tutorials: [
        // {
        //   name: 'Register'
        // },
        // {
        //   name: 'Install',
        //   id: 'install',
        //   video: null,
        //   step_index: 0,
        //   // start_route: ['console'],
        //   steps: [
        //     {
        //       name: 'Open settings',
        //       description: '',
        //       video: null,
        //       type: 'action',
        //       selected_css_selectors: [
        //         '.settings-popup-trigger',
        //       ],
        //       selector_text: {
        //         '.settings-popup-trigger': this.props.t('Click here in order to open the settings'),
        //       },
        //       check_success: () => {
        //         return false
        //       },
        //       success_action: 'open-settings',
        //       success: false,
        //       can_skip: true,
        //       warning_skip_message: '',
        //     },
        //     {
        //       name: 'Download',
        //       description: 'click the download button to start the download',
        //       type: 'action',
        //       selected_css_selectors: [
        //         '.download-button-windows'
        //       ],
        //       selector_text: {
        //         '.download-button-windows': this.props.t('Click here to download'),
        //       },              
        //       check_success: () => {
        //         return false
        //       },
        //       success_action: 'download-button-click',
        //       success: false,
        //       can_skip: true,
        //       warning_skip_message: '',
        //       position_left: true,
        //     },
        //     {
        //       name: 'Finish Download',
        //       description: 'click the download button to start the download',
        //       type: 'action',
        //       selected_css_selectors: [
        //         // '.download-button'
        //       ],
        //       check_success: () => {
        //         return false
        //       },
        //       success_action: 'download-exe',
        //       success: false,
        //       can_skip: true,
        //       warning_skip_message: '',
        //       position_left: true,
        //       must_watch_video: false,

        //       // action_triggered
        //       // watched_video

        //       helper_popup: {
        //         title: 'The application is Downloading...',
        //         content: (<div>
        //           {this.props.t(`While you wait, let's go over a few things`)}
        //           <video 
        //             controls="1"
        //             onEnded={() => {
        //               this.set_watched_video()
        //             }}
        //             class="giphy-video" 
        //             width="100%" 
        //             height="auto" 
        //             autoplay=""
        //             playsinline="" 
        //             src="https://eoz.ams3.digitaloceanspaces.com/tutorial/%D7%94%D7%A7%D7%93%D7%9E%D7%94%20%D7%9C%D7%94%D7%AA%D7%A7%D7%A0%D7%94.mp4"></video>
        //         </div>),
        //         position: 'center'
        //       },
        //     },
        //     {
        //       name: 'Open EXE file',
        //       description: 'Open and install the downloaded file',
        //       type: 'route',
        //       selected_css_selectors: [
        //       ],
        //       check_success: () => {
        //         return true;
        //       },
        //       success_action: 'aaaaaaaaa',
        //       next_action_text: `I'm done -> Next`,
        //       success: false,
        //       can_skip: true,
        //       warning_skip_message: '',
        //       position_left: true,
        //       must_watch_video: false,

        //       helper_popup: {
        //         title: 'Open EXE file',
        //         content: (<div>
        //           {this.props.t('Open and install the downloaded file')}
        //           <video 
        //             controls="1"
        //             onEnded={() => {
        //               this.set_watched_video();
        //             }}
        //             class="giphy-video" 
        //             width="100%" 
        //             height="auto" 
        //             autoplay=""
        //             playsinline="" 
        //             src="https://eoz.ams3.digitaloceanspaces.com/tutorial/%D7%9E%D7%93%D7%A8%D7%99%D7%9A%20%D7%94%D7%AA%D7%A7%D7%A0%D7%AA%20%D7%94%D7%AA%D7%95%D7%9B%D7%A0%D7%94%20%D7%A9%D7%9C%D7%91%201%20%20%20%D7%A4%D7%AA%D7%99%D7%97%D7%AA%20%D7%94%D7%A7%D7%95%D7%91%D7%A5%20%D7%9C%D7%90%D7%97%D7%A8%20%D7%94%D7%94%D7%95%D7%A8%D7%93%D7%94%20%20%202.mp4"></video>
        //         </div>),
        //         position: 'center'
        //       },


        //     },
        //     {
        //       name: 'Insert serial key',
        //       description: 'copy the serial key to the program and click save',
        //       type: 'route',
        //       selected_css_selectors: [
        //         '.serial-key-label',
        //         '.serial-key',
        //       ],
        //       selector_text: {
        //         '.serial-key': this.props.t('Click here to copy the serial key'),
        //       },              
        //       check_success: () => {
        //         return false
        //       },
        //       success_action: 'got_health_check',
        //       success: false,
        //       can_skip: true,
        //       wait_for_action_animation: true,
        //       warning_skip_message: '',
        //       must_watch_video: false,
        //       helper_popup: {
        //         title: 'Insert serial key',
        //         content: (<div>
        //           {this.props.t('copy the serial key to the program and click save')}
        //           <video 
        //             controls="1"
        //             onEnded={() => {
        //               this.set_watched_video()
        //             }}
        //             class="giphy-video" 
        //             width="100%" 
        //             height="auto" 
        //             autoplay=""
        //             playsinline="" 
        //             src="https://eoz.ams3.digitaloceanspaces.com/tutorial/%D7%9E%D7%93%D7%A8%D7%99%D7%9A%20%D7%94%D7%AA%D7%A7%D7%A0%D7%AA%20%D7%94%D7%AA%D7%95%D7%9B%D7%A0%D7%94%20%D7%A9%D7%9C%D7%91%202%20%20%20%D7%94%D7%9B%D7%A0%D7%A1%D7%AA%20%D7%94%D7%9E%D7%A4%D7%AA%D7%97%20%D7%97%D7%93%D7%A9%20%D7%94%D7%A1%D7%99%D7%93%D7%95%D7%A8%D7%99.mp4"></video>
        //         </div>),
        //         position: 'left'
        //       },
              
        //       position_left: true,
        //     },
        //     // {
        //     //   name: 'Define browsers',
        //     //   description: 'click the download button to start the download',
        //     //   type: 'route',
        //     //   selected_css_selectors: [
        //     //     '.application-status',
        //     //     '.define-browsers',
        //     //   ],
        //     //   check_success: () => {
        //     //     return false
        //     //   },
        //     //   success_action: 'got_browser_change',
        //     //   success: false,
        //     //   can_skip: true,
        //     //   warning_skip_message: '',
        //     //   helper_popup: {
        //     //     title: 'Set browsers',
        //     //     content: (<div>
        //     //       {this.props.t('Set the amount of browsers your coputer can handle, (5 is recommended)')}
        //     //       <video controls loop crossorigin="anonymous" draggable="true" class="giphy-video" width="100%" height="auto" autoplay="" playsinline="" src="https://media1.giphy.com/media/WRWaWzjroqHHqriLkL/giphy480p.mp4?cid=ecf05e477egc4nmbfvlj2gpe0xxfis6cyeh8jap241tv4ay3&amp;rid=giphy480p.mp4&amp;ct=v&amp;cc=en"></video>
        //     //     </div>),
        //     //     position: 'center'
        //     //   },
        //     // },
        //     {
        //       name: 'Start application',
        //       description: 'click the download button to start the download',
        //       type: 'route',
        //       selected_css_selectors: [
        //         '.application-status',
        //       ],
        //       check_success: () => {
        //         return false
        //       },
        //       success_action: 'started_application',
        //       success: false,
        //       can_skip: true,
        //       warning_skip_message: '',
        //       must_watch_video: false,

        //       helper_popup: {
        //         title: 'Start application',
        //         content: (<div>
        //           {/*this.props.t('start the application in order to get up and running')}
        //           <video 
        //             controls="1"
        //             onEnded={() => {
        //               this.set_watched_video()
        //             }}
        //             class="giphy-video" 
        //             width="100%" 
        //             height="auto" 
        //             autoplay=""
        //             playsinline="" 
        //             src="https://eoz.ams3.digitaloceanspaces.com/tutorial/%D7%9E%D7%93%D7%A8%D7%99%D7%9A%20%D7%94%D7%AA%D7%A7%D7%A0%D7%AA%20%D7%94%D7%AA%D7%95%D7%9B%D7%A0%D7%94%20%D7%A9%D7%9C%D7%91%203%20%20%20%D7%94%D7%A4%D7%A2%D7%9C%D7%AA%20%D7%94%D7%AA%D7%95%D7%9B%D7%A0%D7%94.mp4"></video>
        //         </div>),
        //         position: 'center'
        //       },

        //     },
        //     {
        //       name: 'Make PC never sleep',
        //       description: 'click the download button to start the download',
        //       type: 'route',
        //       selected_css_selectors: [

        //       ],
        //       check_success: () => {
        //         return true
        //       },
        //       next_action_text: `I'm ready to start`,
        //       success_action: 'aaaaaaaaa',
        //       success: false,
        //       can_skip: true,
        //       warning_skip_message: '',
        //       must_watch_video: false,

        //       helper_popup: {
        //         title: 'Make PC never sleep',
        //         content: (<div>
        //           {this.props.t('Just make sure your PC never goes to sleep and we are good to go')}
        //           <video 
        //             controls="1"
        //             onEnded={() => {
        //               this.set_watched_video()
        //             }}
        //             class="giphy-video" 
        //             width="100%" 
        //             height="auto" 
        //             autoplay=""
        //             playsinline="" 
        //             src="https://eoz.ams3.digitaloceanspaces.com/tutorial/%D7%9E%D7%93%D7%A8%D7%99%D7%9A%20%D7%9E%D7%A6%D7%91%20%D7%A9%D7%99%D7%A0%D7%94.mp4"></video>
        //         </div>),
        //         position: 'center'
        //       },
        //     },
        //   ]
        // },
        {
          name: '2 step auth',
          id: '2-step-auth',
          steps: [
            {
              name: 'Watch Intro Video',
              description: '',
              video: null,
              type: 'action',
              selected_css_selectors: [
              ],
              selector_text: {
              },              
              selector_position: 'right',
              check_success: () => {
                return false
              },
              success_action: 'watch_intro_video',
              success: false,
              can_skip: true,
              warning_skip_message: '',

              helper_popup: {
                title: '2 step auth',
                content: (<div>
                  <video 
                    controls="1"
                    onEnded={() => {
                      // this.set_watched_video()
                      // window.alert(this.props.tutorialEvent);
                      // this.props.tutorialEvent({action: 'watch_intro_video'});
                    }}
                    class="giphy-video" 
                    width="100%" 
                    height="auto" 
                    autoplay=""
                    playsinline="" 
                    src="https://eoz.ams3.digitaloceanspaces.com/new-tutorials/videoplayback.mp4"></video>
                    
                    <button onClick={this.props.updateSearch.bind(this, {'tutorial': undefined, 'current-tutoral': undefined, step: undefined})}>סגירה</button>
                </div>),
                position: 'center'
              },
            },
          ]
        },
        {
          name: 'Add Profiles',
          id: 'add-profile',
          steps: [
            {
              name: 'Watch Intro Video',
              description: '',
              video: null,
              type: 'action',
              selected_css_selectors: [
              ],
              selector_text: {
              },              
              selector_position: 'right',
              check_success: () => {
                return false
              },
              success_action: 'watch_intro_video',
              success: false,
              can_skip: true,
              warning_skip_message: '',

              helper_popup: {
                title: 'Welcome to Autopost',
                content: (<div>
                  <video 
                    controls="1"
                    onEnded={() => {
                      // this.set_watched_video()
                      // window.alert(this.props.tutorialEvent);
                      this.props.tutorialEvent({action: 'watch_intro_video'});
                    }}
                    class="giphy-video" 
                    width="100%" 
                    height="auto" 
                    autoplay=""
                    playsinline="" 
                    src="https://eoz.ams3.digitaloceanspaces.com/new-tutorials/%D7%A4%D7%AA%D7%99%D7%97%D7%94%201.mp4"></video>
                </div>),
                position: 'center'
              },
            },
            {
              name: 'Open Add Profile',
              description: '',
              video: null,
              type: 'action',
              selected_css_selectors: [
                '.add-new-user',
              ],
              selector_text: {
                '.add-new-user': this.props.t('Click here to add a new profile'),
              },              
              selector_position: 'right',
              check_success: () => {
                return false
              },
              success_action: 'add_new_user_button',
              success: false,
              can_skip: true,
              warning_skip_message: '',

              helper_popup: {
                title: 'Add profile',
                content: (<div>
                  <video 
                    controls="1"
                    onEnded={() => {
                      // this.set_watched_video()
                      // window.alert(this.props.tutorialEvent);
                      // this.props.tutorialEvent({action: 'watch_intro_video'});
                    }}
                    class="giphy-video" 
                    width="100%" 
                    height="auto" 
                    autoplay=""
                    playsinline="" 
                    src="https://eoz.ams3.digitaloceanspaces.com/new-tutorials/%D7%A4%D7%AA%D7%99%D7%97%D7%94%202.mp4"></video>
                </div>),
                position: 'center'
              },


            },
            {
              name: 'Insert facebook details',
              description: '',
              video: null,
              type: 'action',
              selected_css_selectors: [
                '.create-user-popup',
              ],
              selector_text: {
                '.create-user-popup': this.props.t('Insert facebook details'),
              },              
              selector_position: 'left',
              check_success: () => {
                return false
              },
              success_action: 'success_create_user',
              success: false,
              can_skip: true,
              warning_skip_message: '',
              must_watch_video: false,

              helper_popup: {
                title: 'Insert facebook details',
                content: (<div>
                  <video 
                    controls="1"
                    onEnded={() => {
                      this.set_watched_video()
                    }}
                    class="giphy-video" 
                    width="100%" 
                    height="auto" 
                    autoplay=""
                    playsinline="" 
                    src="https://eoz.ams3.digitaloceanspaces.com/new-tutorials/%D7%A4%D7%AA%D7%99%D7%97%D7%94%203.mp4"></video>
                </div>),
                position: 'left'
              },
            },
          ]
        },
        {
          name: 'Add Campaign',
          id: 'add-campaign',
          steps: [
            {
              name: 'Click add campaign button',
              description: '',
              video: null,
              type: 'action',

              selected_css_selectors: [
                '.new-campaign',
              ],
              selector_text: {
                '.new-campaign': this.props.t('Click here to add a new campaign'),
              },              
              selector_position: 'right',

              check_success: () => {
                return false
              },
              success_action: 'add_campaign_button_click',
              success: false,
              can_skip: true,
              warning_skip_message: '',


              helper_popup: {
                title: 'Add a new campaign / post directory',
                content: (<div>
                  <video 
                    controls="1"
                    onEnded={() => {
                      // this.set_watched_video()
                      // window.alert(this.props.tutorialEvent);
                      this.props.tutorialEvent({action: 'watch_intro_video'});
                    }}
                    class="giphy-video" 
                    width="100%" 
                    height="auto" 
                    autoplay=""
                    playsinline="" 
                    src="https://eoz.ams3.digitaloceanspaces.com/new-tutorials/%D7%94%D7%95%D7%A1%D7%A4%D7%AA%20%D7%A7%D7%9E%D7%A4%D7%99%D7%99%D7%9F%204.mp4"></video>
                </div>),
                position: 'center'
              },


            },
            {
              name: 'Add Campaign details',
              description: '',
              video: null,
              type: 'action',
              selected_css_selectors: [
                '.create-campaign-popup',
              ],
              selector_text: {
                '.create-campaign-popup': this.props.t('Add Campaign details'),
              },              
              selector_position: 'left',
              check_success: () => {
                return false
              },
              success_action: 'success_create_campaign',
              success: false,
              can_skip: false,
              warning_skip_message: '',
              must_watch_video: false,

              helper_popup: {
                title: 'Create Your Campaign',
                content: (<div>
                  <video 
                    controls="1"
                    onEnded={() => {
                      this.set_watched_video()
                    }}
                    class="giphy-video" 
                    width="100%" 
                    height="auto" 
                    autoplay=""
                    playsinline="" 
                    src="https://eoz.ams3.digitaloceanspaces.com/new-tutorials/%D7%94%D7%95%D7%A1%D7%A4%D7%AA%20%D7%A7%D7%9E%D7%A4%D7%99%D7%99%D7%9F%205.mp4"></video>
                </div>),
                position: 'left'
              },
            },

            {
              name: 'Success create campaign',
              description: '',
              video: null,
              type: 'action',
              selected_css_selectors: [
                '.campaigns .active'
              ],
              selector_text: {
                '.campaigns .active': this.props.t('New campaign'),
              },              
              selector_position: 'right',
              check_success: () => {
                return true
              },
              success_action: 'watch_video_success_campaign',
              success: false,
              can_skip: true,
              warning_skip_message: '', // 0548320636
              must_watch_video: false,
              helper_popup: {
                title: 'You have created a campaign successfully',
                content: (<div>

                  <video 
                    controls="1"
                    onEnded={() => {
                      // this.set_watched_video()
                      this.props.tutorialEvent({action: 'watch_video_success_campaign'});
                    }}
                    class="giphy-video" 
                    width="100%" 
                    height="auto" 
                    autoplay=""
                    playsinline="" 
                    src="https://eoz.ams3.digitaloceanspaces.com/new-tutorials/%D7%94%D7%95%D7%A1%D7%A4%D7%AA%20%D7%A7%D7%9E%D7%A4%D7%99%D7%99%D7%9F%206.mp4"></video>
                </div>),
                // position: 'center'
                v_position: 'bottom'
              },
              position_left: true,
            },


          ]
        },
        {
          name: 'Add Post',
          id: 'add-post',
          steps: [
            {
              name: 'Click add campaign button',
              description: '',
              video: null,
              type: 'action',

              selected_css_selectors: [
                '.create-new-post',
              ],
              selector_text: {
                '.create-new-post': this.props.t('Click here to add a new post'),
              },              
              selector_position: 'right',

              check_success: () => {
                return false
              },
              success_action: 'add_post_button_click',
              success: false,
              can_skip: true,
              warning_skip_message: '',

            },
            {
              name: 'Add Post details',
              description: '',
              video: null,
              type: 'action',
              selected_css_selectors: [
                '.create-post-popup',
              ],
              selector_text: {
                '.create-post-popup': this.props.t('Add post details'),
              },              
              selector_position: 'left',
              check_success: () => {
                return false
              },
              success_action: 'success_create_post',
              success: false,
              can_skip: false,
              warning_skip_message: '',
              must_watch_video: false,

              helper_popup: {
                title: 'Create Your Campaign',
                content: (<div>
                  <video 
                    controls="1"
                    onEnded={() => {
                      this.set_watched_video()
                    }}
                    class="giphy-video" 
                    width="100%" 
                    height="auto" 
                    autoplay=""
                    playsinline="" 
                    src="https://eoz.ams3.digitaloceanspaces.com/new-tutorials/%D7%A4%D7%95%D7%A1%D7%98%20%D7%97%D7%93%D7%A9%D7%A9%207.mp4"></video>
                </div>),
                position: 'left'
              },
            },
          ]
        },
        {









          name: 'Add Automatic Scheduling',
          id: 'Add-Automatic-Scheduling',
          steps: [
            {
              name: 'Click add schedule button',
              description: '',
              video: null,
              type: 'action',

              selected_css_selectors: [
                '.create-new-multi-schedule',
              ],
              selector_text: {
                '.create-new-multi-schedule': this.props.t('Click here to schedule posts'),
              },              
              selector_position: 'right',

              check_success: () => {
                return false
              },
              success_action: 'add_multi_schedule',
              success: false,
              can_skip: true,
              warning_skip_message: '',

              helper_popup: {
                title: 'Create Your Campaign',
                content: (<div>
                  <video 
                    controls="1"
                    onEnded={() => {
                      // this.set_watched_video()
                      // this.props.tutorialEvent({action: 'watch_video_success_campaign'});
                    }}
                    class="giphy-video" 
                    width="100%" 
                    height="auto" 
                    autoplay=""
                    playsinline="" 
                    src="https://eoz.ams3.digitaloceanspaces.com/new-tutorials/%D7%A4%D7%A8%D7%A1%D7%9D%20%D7%91%D7%A9%D7%91%D7%99%D7%9C%D7%99%208.mp4"></video>
                </div>),
                position: 'center'
              },


            },
            {
              name: 'Select Campaign',
              description: '',
              video: null,
              type: 'action',
              selected_css_selectors: [
                '.multiple-group-camaign-schedule',
              ],
              selector_text: {
                '.multiple-group-camaign-schedule': this.props.t('Add post details'),
              },              
              selector_position: 'left',
              check_success: () => {
                return false
              },
              success_action: 'finish_selected_campaign',
              success: false,
              can_skip: false,
              warning_skip_message: '',
              must_watch_video: false,

              helper_popup: {
                title: 'Add Schedule details',
                content: (<div>
                  <video 
                    controls="1"
                    onEnded={() => {
                      this.set_watched_video()
                    }}
                    class="giphy-video" 
                    width="100%" 
                    height="auto" 
                    autoplay=""
                    playsinline="" 
                    src="https://eoz.ams3.digitaloceanspaces.com/new-tutorials/%D7%A4%D7%A8%D7%A1%D7%9D%20%D7%91%D7%A9%D7%91%D7%99%D7%9C%D7%99%209.mp4"></video>
                </div>),
                position: 'left'
              },
            },
            {
              name: 'Select Posts',
              description: '',
              video: null,
              type: 'action',
              selected_css_selectors: [
                '.multiple-group-camaign-schedule',
              ],
              selector_text: {
                '.multiple-group-camaign-schedule': this.props.t('Add post details'),
              },              
              selector_position: 'left',
              check_success: () => {
                return false
              },
              success_action: 'finish_select_campaign',
              success: false,
              can_skip: false,
              warning_skip_message: '',
              must_watch_video: false,

              helper_popup: {
                title: 'Add Schedule details',
                content: (<div>
                  <video 
                    controls="1"
                    onEnded={() => {
                      this.set_watched_video()
                    }}
                    class="giphy-video" 
                    width="100%" 
                    height="auto" 
                    autoplay=""
                    playsinline="" 
                    src="https://eoz.ams3.digitaloceanspaces.com/new-tutorials/%D7%A4%D7%A8%D7%A1%D7%9D%20%D7%91%D7%A9%D7%91%D7%99%D7%9C%D7%99%2010.mp4"></video>
                </div>),
                position: 'left'
              },
            },
            {
              name: 'Select Profiles',
              description: '',
              video: null,
              type: 'action',
              selected_css_selectors: [
                '.multiple-group-camaign-schedule',
              ],
              selector_text: {
                '.multiple-group-camaign-schedule': this.props.t('Add post details'),
              },              
              selector_position: 'left',
              check_success: () => {
                return false
              },
              success_action: 'finish_profiles',
              success: false,
              can_skip: false,
              warning_skip_message: '',
              must_watch_video: false,

              helper_popup: {
                title: 'Add Schedule details',
                content: (<div>
                  <video 
                    controls="1"
                    onEnded={() => {
                      this.set_watched_video()
                    }}
                    class="giphy-video" 
                    width="100%" 
                    height="auto" 
                    autoplay=""
                    playsinline="" 
                    src="https://eoz.ams3.digitaloceanspaces.com/new-tutorials/%D7%A4%D7%A8%D7%A1%D7%9D%20%D7%91%D7%A9%D7%91%D7%99%D7%9C%D7%99%2011.mp4"></video>
                </div>),
                position: 'left'
              },
            },
            {
              name: 'Select Groups',
              description: '',
              video: null,
              type: 'action',
              selected_css_selectors: [
                '.multiple-group-camaign-schedule',
              ],
              selector_text: {
                '.multiple-group-camaign-schedule': this.props.t('Add post details'),
              },              
              selector_position: 'left',
              check_success: () => {
                return false
              },
              success_action: 'finish_groups',
              success: false,
              can_skip: false,
              warning_skip_message: '',
              must_watch_video: false,

              helper_popup: {
                title: 'Add Schedule details',
                content: (<div>
                  <video 
                    controls="1"
                    onEnded={() => {
                      this.set_watched_video()
                    }}
                    class="giphy-video" 
                    width="100%" 
                    height="auto" 
                    autoplay=""
                    playsinline="" 
                    src="https://eoz.ams3.digitaloceanspaces.com/new-tutorials/%D7%A4%D7%A8%D7%A1%D7%9D%20%D7%91%D7%A9%D7%91%D7%99%D7%9C%D7%99%2012.mp4"></video>
                </div>),
                position: 'left'
              },
            },
            {
              name: 'Select Custom Groups',
              description: '',
              video: null,
              type: 'action',
              selected_css_selectors: [
                '.multiple-group-camaign-schedule',
              ],
              selector_text: {
                '.multiple-group-camaign-schedule': this.props.t('Add post details'),
              },              
              selector_position: 'left',
              check_success: () => {
                return false
              },
              success_action: 'finish_custom_groups',
              success: false,
              can_skip: false,
              warning_skip_message: '',
              must_watch_video: false,

              helper_popup: {
                title: 'Add Schedule details',
                content: (<div>
                  <video 
                    controls="1"
                    onEnded={() => {
                      this.set_watched_video()
                    }}
                    class="giphy-video" 
                    width="100%" 
                    height="auto" 
                    autoplay=""
                    playsinline="" 
                    src="https://eoz.ams3.digitaloceanspaces.com/new-tutorials/%D7%A4%D7%A8%D7%A1%D7%9D%20%D7%91%D7%A9%D7%91%D7%99%D7%9C%D7%99%2013.mp4"></video>
                </div>),
                position: 'left'
              },
            },
            {
              name: 'Select Settings',
              description: '',
              video: null,
              type: 'action',
              selected_css_selectors: [
                '.multiple-group-camaign-schedule',
              ],
              selector_text: {
                '.multiple-group-camaign-schedule': this.props.t('Add post details'),
              },              
              selector_position: 'left',
              check_success: () => {
                return false
              },
              success_action: 'finish_settings',
              success: false,
              can_skip: false,
              warning_skip_message: '',
              must_watch_video: false,

              helper_popup: {
                title: 'Add Schedule details',
                content: (<div>
                  <video 
                    controls="1"
                    onEnded={() => {
                      this.set_watched_video()
                    }}
                    class="giphy-video" 
                    width="100%" 
                    height="auto" 
                    autoplay=""
                    playsinline="" 
                    src="https://eoz.ams3.digitaloceanspaces.com/new-tutorials/%D7%A4%D7%A8%D7%A1%D7%9D%20%D7%91%D7%A9%D7%91%D7%99%D7%9C%D7%99%2014.mp4"></video>
                </div>),
                position: 'left'
              },
            },
            {
              name: 'Summary',
              description: '',
              video: null,
              type: 'action',
              selected_css_selectors: [
                '.multiple-group-camaign-schedule',
              ],
              selector_text: {
                '.multiple-group-camaign-schedule': this.props.t('Add post details'),
              },              
              selector_position: 'left',
              check_success: () => {
                return false
              },
              success_action: 'save_multi_campaign_automatic_schedule',
              success: false,
              can_skip: false,
              warning_skip_message: '',
              must_watch_video: false,

              helper_popup: {
                title: 'Add Schedule details',
                content: (<div>
                  <video 
                    controls="1"
                    onEnded={() => {
                      this.set_watched_video()
                    }}
                    class="giphy-video" 
                    width="100%" 
                    height="auto" 
                    autoplay=""
                    playsinline="" 
                    src="https://eoz.ams3.digitaloceanspaces.com/new-tutorials/%D7%A4%D7%A8%D7%A1%D7%9D%20%D7%91%D7%A9%D7%91%D7%99%D7%9C%D7%99%20%D7%A1%D7%99%D7%9B%D7%95%D7%9D%2015.mp4"></video>
                </div>),
                position: 'left'
              },
            },
            {
              name: 'aaaaaa',
              description: '',
              video: null,
              type: 'action',
              selected_css_selectors: [
                '.multiple-group-camaign-schedule',
              ],
              selector_text: {
                '.multiple-group-camaign-schedule': this.props.t('Add post details'),
              },              
              selector_position: 'left',
              check_success: () => {
                return false
              },
              success_action: 'save_multi_campaign_automatic_schedule',
              success: false,
              can_skip: false,
              warning_skip_message: '',
              must_watch_video: false,

              helper_popup: {
                title: 'Add Schedule details',
                content: (<div>
                  <video 
                    controls="1"
                    onEnded={() => {
                      this.set_watched_video()
                    }}
                    class="giphy-video" 
                    width="100%" 
                    height="auto" 
                    autoplay=""
                    playsinline="" 
                    src="https://eoz.ams3.digitaloceanspaces.com/new-tutorials/%D7%A4%D7%A8%D7%A1%D7%9D%20%D7%91%D7%A9%D7%91%D7%99%D7%9C%D7%99%2016.mp4"></video>
                
                    <button type="button" onClick={this.props.updateSearch.bind(this, {'tutorial': undefined, 'current-tutoral': undefined, step: undefined})}>{this.props.t('Exit Tutorial')}</button>
                </div>),
                position: 'right'
              },
            },
          ]
        },
        /*{
          name: 'Exisitng Post',
          id: 'exisiting-post',
          steps: []
        },        
        {
          name: 'Tour',
          id: 'tour',
          steps: [
            {
              name: 'Open Manage Groups Popup',
              description: '',
              video: null,
              type: 'action',
              selected_css_selectors: [
                '.manage-group-button',
              ],
              check_success: () => {
                return false
              },
              success_action: 'open_manage_group_popup',
              success: false,
              can_skip: true,
              warning_skip_message: '',
              position_left: true,
            },
            {
              name: 'Select your groups that you want to post in',
              description: '',
              video: null,
              type: 'action',
              selected_css_selectors: [
                '.manage-group-popup',
              ],
              check_success: () => {
                return true
              },
              success_action: 'success_create_user',
              success: false,
              can_skip: true,
              warning_skip_message: '',
              position_left: true,

              helper_popup: {
                title: 'Choose your groups',
                content: (<div>
                  <video controls loop crossorigin="anonymous" draggable="true" class="giphy-video" width="100%" height="auto" autoplay="" playsinline="" src="https://media1.giphy.com/media/WRWaWzjroqHHqriLkL/giphy480p.mp4?cid=ecf05e477egc4nmbfvlj2gpe0xxfis6cyeh8jap241tv4ay3&amp;rid=giphy480p.mp4&amp;ct=v&amp;cc=en"></video>
                </div>),
                position: 'right'
              },
            },
            {
              name: 'Summary',
              description: '',
              video: null,
              type: 'action',
              selected_css_selectors: [
                '.manage-group-popup',
              ],
              check_success: () => {
                return true
              },
              success_action: 'login_trigger',
              success: false,
              can_skip: true,
              warning_skip_message: '',
              position_left: true,

              helper_popup: {
                title: 'Login to User',
                content: (<div>
                  <video controls loop crossorigin="anonymous" draggable="true" class="giphy-video" width="100%" height="auto" autoplay="" playsinline="" src="https://media1.giphy.com/media/WRWaWzjroqHHqriLkL/giphy480p.mp4?cid=ecf05e477egc4nmbfvlj2gpe0xxfis6cyeh8jap241tv4ay3&amp;rid=giphy480p.mp4&amp;ct=v&amp;cc=en"></video>
                </div>),
                position: 'center'
              },
            },
          ]

        },*/
      ],
      selected_tutorial: null,
    }

    refresh() {
    }

    update_data(props) {
    }

    componentWillReceiveProps(props) {
    }
    
    componentWillMount(props) {

    }

    setSelectedTutorial(tutorial, index) {
      setTimeout(() => {
        tutorial.step_index = parseInt(index) || 0;

        this.props.updateSearch({'current-tutoral': tutorial.id, step: tutorial.step_index})
        this.setState({selected_tutorial: tutorial});

        setTimeout(() => {
          var current_step = this.get_current_step();

          if (current_step.init_function) {
            current_step.init_function();
          }
        }, 0)
      }, 0)
    }

    render() {
      const { t, i18n } = this.props;

      return (
        <div className={styles.Tutorial}>
          {this.get_current_step() && this.get_current_step().helper_popup && <div className={styles.popupOverlay + ` popup-position-${this.get_current_step().helper_popup.position} popup-position-v-${this.get_current_step().helper_popup.v_position} parent-${this.get_current_step().position_left ? 'left' : 'right'}`}>
            <div className={styles.popup}>
              <h2>{t(this.get_current_step().helper_popup.title)}</h2>
              <div className="content">
                {this.get_current_step() && this.get_current_step().must_watch_video && !this.get_current_step().watched_video ? <div style={{color: 'red', fontWeight: 'bold'}}><FaExclamationTriangle/> {t('You must watch the video in order to continue the guide. It is recommended to watch the video in full screen mode before performing actions, Volume is required.')} <FaExclamationTriangle/></div> : <div></div>}

                {this.get_current_step().helper_popup.content}

                <button type="button" onClick={this.props.updateSearch.bind(this, {'tutorial': undefined, 'current-tutoral': undefined, step: undefined})}>{this.props.t('Exit Tutorial')}</button>
              </div>
            </div>
          </div>}


          <div>
            {this.get_current_step() && this.get_current_step().selected_css_selectors && <style>
              {this.active_css_location(this.get_current_step())}

            </style>}

            {queryString.parse(this.props.location.search).admin && <style>
              {this.getCustomTutorialCss()}
              {`
                .full-page {
                  width: calc(100% - 240px) !important;
                }
                .ffffff {
                    margin-right: 240px;
                }
              `}
            </style>}

            <div style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              zIndex: 100,
            }}>

              {queryString.parse(this.props.location.search).admin && <div className={styles.sidenav + ` ${this.get_current_step()}`}>
                
                {!this.state.selected_tutorial && <div className="tutorial-header">
                  <FaTimes 
                    style={{color: '#fff'}}
                    onClick={this.props.updateSearch.bind(this, {tutorial: undefined, 'current-tutoral': undefined, step: undefined})}
                  />
                </div>}

                {this.state.selected_tutorial && <div className="tutorial-header">
                  <FaLongArrowAltRight 
                    style={{color: '#fff'}}
                    onClick={() => {
                      this.setState({selected_tutorial: null})
                    }}
                  />
                </div>}

                <h4 className="tutorial-title">{this.state.selected_tutorial && t(this.state.selected_tutorial.name)}</h4>

                {!this.state.selected_tutorial && <ul className={styles.events}>
                  {this.state.tutorials.map((tutorial) => <li className={``}>
                    <time dateTime="10:03"></time>
                    <span>
                      <strong>{t(tutorial.name)}</strong>
                        <button className="" onClick={this.setSelectedTutorial.bind(this, tutorial)}>{t('Start')}</button>
                    </span>
                  </li>)}
                </ul>}

                {this.state.selected_tutorial && <ul className={styles.events + ' flex-fill list-container'}>
                  {this.state.selected_tutorial.steps.map((step, index) => <li className={`${this.state.selected_tutorial.step_index === index ? (styles.active) : 'upcoming'} ${step.success ? (styles.success) : ''}`}>
                    <time dateTime="10:03"></time>
                    <span>
                      <strong>{t(step.name)}</strong> 

                      {!step.success && step.progress && step.progress !== 100 && <React.Fragment>
                        ({step.progress}%) <LoadingIcons.Circles style={{
                          width: '22px',
                          height: '22px',
                        }}/>
                      </React.Fragment>}

                      {!step.success && step.wait_for_action_animation && this.get_current_step() === step && <React.Fragment>
                        <LoadingIcons.Circles style={{
                          width: '22px',
                          height: '22px',
                        }}/>
                      </React.Fragment>}
                    </span>
                  </li>)}
                </ul>}

                {this.state.selected_tutorial && this.get_current_step() && <div className="text-center">
                  <button onClick={this.next_step.bind(this)} disabled={!this.get_current_step().check_success() || !(!this.get_current_step().must_watch_video || this.get_current_step().must_watch_video && this.get_current_step().watched_video)} className={"btn btn-primary"}>{t(this.get_current_step().next_action_text || 'Next')}</button>
                </div>}

                {this.get_current_step() && this.get_current_step().must_watch_video && !this.get_current_step().watched_video ? <div style={{color: 'red'}}>{t('You must watch the video')}</div> : <div></div>}

                {this.state.selected_tutorial && this.get_current_step() && this.get_current_step().extra_buttons && this.get_current_step().extra_buttons.map( (button) => (<div>
                  {<div className="text-center">
                    <hr/>
                    <button onClick={button.action.bind(this)} disabled={!this.get_current_step().check_success()} className={"btn btn-primary"}>{button.text}</button>
                    <div>
                      <small style={{
                        color: '#fff'
                      }}>{button.small_label}</small>
                    </div>
                  </div>}
                </div>) )}


              </div>}
            </div>

          </div>
        </div>

      );
    }
}


Tutorial.propTypes = {};

Tutorial.defaultProps = {};

export default Tutorial;
