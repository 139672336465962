import React from "react";
import { useDrag } from "react-dnd";
import clock from '../../assets/icons/clock.svg';
import { FaBan, FaClock, FaVolumeUp } from "react-icons/fa";
import queryString from 'query-string';


const style = {
  cursor: "move",
  // float: "left"
};
export const Campaign = ({query, name,selectCampaign, type, isDropped, campaign, updateSearch, state,t }) => {
  const [{ opacity }, drag] = useDrag({
    type: "CAMPAIGN",
    item: { name, type, campaign },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1
    })
  });
  return (
    <React.Fragment>

      <span className="position-relative campaign" ref={drag} style={{display: 'inline-block'}}>
        <span style={{backgroundColor: campaign.color}} onClick={selectCampaign.bind(this, campaign, true) && query.campaign !== campaign._id && selectCampaign.bind(this, campaign)} className={"campaign rounded position-relative button-click " + ((query.campaign === campaign._id) ? 'active' : '')} key={campaign._id}>
          {campaign.enabled === false && <FaBan/>}
          {campaign.name}
          {campaign.enabled === false && <FaBan/>}

          {<span className="badge bg-info text-white rounded-pill position-absolute top-0 start-100 translate-middle campaign-popup">
            {<FaClock/>} {campaign.today_count} 
            {campaign._multi_campaign.length === 0 && campaign.request_for_approval && <span class="badge badge-danger">{<FaVolumeUp/>}</span>}
            {campaign._multi_campaign.length === 0 && !campaign.request_for_approval && <span class="badge badge-warning">{<FaVolumeUp/>}</span>}



          </span>}

          {campaign.editable && (query.campaign === campaign._id) && <span onClick={updateSearch.bind(this, {path: [':lang', 'console', 'campaign', 'edit', campaign._id]})} className="button-click badge bg-info text-white rounded-pill position-absolute top-100 start-0 translate-middle" style={{
              left: '19px !important',
              bottom: '-12px !important'
          }}>

          {t('Edit')}
      </span>}
        
      </span>


      </span>





    </React.Fragment>
  );
};
