import React from 'react';
import PropTypes from 'prop-types';
import styles from './JoinGroupPopup.module.scss';
import fetch from 'node-fetch';

import settings from '../../assets/icons/settings.svg';
import logo from '../../assets/images/logo.png';
import help from '../../assets/icons/help.svg';
import alert from '../../assets/icons/alert.svg';
import signin from '../../assets/icons/signin.svg';
import clock from '../../assets/icons/clock.svg';
import add from '../../assets/icons/add.svg';
import user1 from '../../assets/images/user1.png';
import user2 from '../../assets/images/user2.png';
import user3 from '../../assets/images/user3.png';
import user4 from '../../assets/images/user4.png';
import post1 from '../../assets/images/post1.png';
import post2 from '../../assets/images/post2.png';
import chat from '../../assets/icons/chat.svg';
import monitor from '../../assets/icons/monitor.svg';
import bin from '../../assets/icons/bin.svg';
import group1 from '../../assets/images/group1.png';
import account from '../../assets/icons/account.svg';
import password from '../../assets/icons/password.svg';
import like from '../../assets/icons/like.svg';
import follower from '../../assets/icons/follower.svg';

import {Button} from 'react-bootstrap/Button';
import moment from 'moment';
import {DropdownButton, ButtonGroup, Dropdown} from 'react-bootstrap';

import {NotificationContainer, NotificationManager} from 'react-notifications';

import { useTranslation, initReactI18next, withTranslation } from 'react-i18next';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  withRouter,
  useLocation
} from "react-router-dom";

import queryString from 'query-string';

class JoinGroupPopup extends React.Component {

    state = {
    	post: null,
    	selected: null,
    }

    refresh() {

    }

    update_data(props) {
    	var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/get-join-group-slot?access_token=${token}&post=${this.state.selected}`, {})
        .then(response => response.json())
        .then(data => {
        	this.setState({
        		post: data,
        	});
        })
        .catch(err => {

        });
    }

    refresh_status(props) {
    	var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/refresh-status?access_token=${token}&post=${this.state.selected}`, {})
        .then(response => response.json())
        .then(data => {
        	NotificationManager.success('Yayyy..', 'Added to que');
        })
        .catch(err => {

        });
    }

    join_group(props) {
        var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/join-group?access_token=${token}&post=${this.state.selected}`, {})
        .then(response => response.json())
        .then(data => {
            NotificationManager.success('Yayyy..', 'Added to que');
        })
        .catch(err => {

        });
    }

    refresh_reply(props) {
    	var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/refresh-fresh?access_token=${token}&post=${this.state.selected}`, {})
        .then(response => response.json())
        .then(data => {
        	this.setState({
        		post: data,
        	});
        })
        .catch(err => {

        });
    }

    post_now(props) {
    	var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/post-now?access_token=${token}&post=${this.state.selected}`, {})
        .then(response => response.json())
        .then(data => {
        	NotificationManager.success('Yayyy..', 'Added to que');
        })
        .catch(err => {
        	NotificationManager.error('Yayyy..', 'Error addeding to que');
        });
    }

    reply_now(props) {
    	var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/reply-now?access_token=${token}&post=${this.state.selected}`, {})
        .then(response => response.json())
        .then(data => {
        	NotificationManager.success('Yayyy..', 'Added to que');
        })
        .catch(err => {
        	NotificationManager.error('Yayyy..', 'Error addeding to que');
        });
    }

    componentWillReceiveProps(props) {

        var id = this.props.location.pathname.split('/').pop()

    	
    	if (id !== this.state.selected) {
			setTimeout(() => {
				this.update_data(props);	
			}, 0)
    	}
    }

    componentWillMount(props) {
    	this.setState({
    		selected: this.props.location.pathname.split('/').pop(),
    	});
		
		setTimeout(() => {
			this.update_data(this.props);	
		}, 0)
    }

    check_if_pending(date) {
    	var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/check-pending?access_token=${token}&post=${this.state.selected}`, {})
        .then(response => response.json())
        .then(data => {
        	NotificationManager.success('Yayyy..', 'Added to que');
        })
        .catch(err => {
        	NotificationManager.error('Yayyy..', 'Error addeding to que');
        });
    }

    fromNow(date) {
    	// return moment(date).relativeTimeThreshold('m', 60);
    	return (moment(date)).fromNow();
    }

    render() {
    	const { t, i18n } = this.props;

        return (
		  <div className="overlay">
            <style>{'body {overflow: hidden;}'}</style>
              {this.state.post ? <div className="bg-primary p-5 text-white text-center rounded-3 " id="myForm">
                  <div className="pb-5 position-relative" id="signin_header" style={{minWidth: '700px'}}>
                    <h2 className="fs-5 fw-bolder text-uppercase text-center">{t('Join Group')}</h2>

					<Switch>
						<Route path='/:lang/schedule/automatic/:interval/:period?'>
						</Route>
						<Route path='/'>
							<a type="button" className="btn text-white close_btn fs-2 fw-bolder" onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console']})}>×</a>
						</Route>
					</Switch>

                  </div>

					{this.state.post.submitted_post && this.state.post.submitted_post._errors && this.state.post.submitted_post._errors.map((error) => ( <div className="alert alert-danger" role="alert">
						{error}
					</div>))}


					<hr/>

                    <div className="btn-group" role="group" aria-label="Basic example">
                      <button type="button" className="btn btn-warning" onClick={this.join_group.bind(this)}>{t('Join Group Now')}</button>
                    </div>
					
                    <hr/>



<div className="container timeline-container">
    <div className="row">
        <div className="col-md-12">
            <div className="card">
                <div className="card-body">
                    <h6 className="card-title">Timeline</h6>
                    <div id="content">
                        <ul className="timeline">
                            {this.state.post.logs.map((log) => (<li className="event" data-date={this.fromNow(log.created_at)}>
                                <h3>
                                	{log.user && <img width="60px" className="profile rounded rounded-circle border border-3  logged_in " src={"https://images.fbzipper.com/api/users/profile/" + log.user._id} g="img/user1.png" alt=""/>}
                                	{log.action}
                                </h3>
                                <p>{log.message}</p>
                                <p><a target="blank" href={log.image_url}><img src={log.image_url} style={{maxWidth: "100%"}}/></a></p>
                            </li>))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


                 </div> : <div className="loader">
                  <svg width="300px" version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                    <circle fill="none" stroke="#fff" strokeWidth={4} cx={50} cy={50} r={44} style={{opacity: '0.5'}} />
                    <circle fill="#fff" stroke="#e74c3c" strokeWidth={3} cx={8} cy={54} r={6}>
                      <animateTransform attributeName="transform" dur="2s" type="rotate" from="0 50 48" to="360 50 52" repeatCount="indefinite" />
                    </circle>
                  </svg>
                 </div>}
		  </div>
        );
    }
}

JoinGroupPopup.propTypes = {};

JoinGroupPopup.defaultProps = {};

export default JoinGroupPopup;



