import React from 'react';
import PropTypes from 'prop-types';
import styles from './NewUserCreatePopup.module.scss';
import fetch from 'node-fetch';
import moment from 'moment';
import authenticator from 'authenticator';
import { confirmAlert } from 'react-confirm-alert'; // Import


import settings from '../../assets/icons/settings.svg';
import logo from '../../assets/images/logo.png';
import help from '../../assets/icons/help.svg';
import alert from '../../assets/icons/alert.svg';
import signin from '../../assets/icons/signin.svg';
import clock from '../../assets/icons/clock.svg';
import add from '../../assets/icons/add.svg';
import user1 from '../../assets/images/user1.png';
import user2 from '../../assets/images/user2.png';
import user3 from '../../assets/images/user3.png';
import user4 from '../../assets/images/user4.png';
import post1 from '../../assets/images/post1.png';
import post2 from '../../assets/images/post2.png';
import chat from '../../assets/icons/chat.svg';
import monitor from '../../assets/icons/monitor.svg';
import bin from '../../assets/icons/bin.svg';
import group1 from '../../assets/images/group1.png';
import account from '../../assets/icons/account.svg';
import password from '../../assets/icons/password.svg';
import like from '../../assets/icons/like.svg';
import follower from '../../assets/icons/follower.svg';

import {Button} from 'react-bootstrap/Button';
import {DropdownButton, ButtonGroup, Dropdown} from 'react-bootstrap';

import {NotificationContainer, NotificationManager} from 'react-notifications';

import { useTranslation, initReactI18next, withTranslation } from 'react-i18next';

import { SketchPicker } from 'react-color'

import { FaExclamationTriangle, FaLock, FaFemale, FaMale, FaUsers, FaLightbulb } from "react-icons/fa";

import {
  BrowserRouter as Router,
  Switch as SwitchRouter,
  Route,
  Link,
  Redirect,
  withRouter,
  useParams,
  useLocation
} from "react-router-dom";

import queryString from 'query-string';

import Switch from "@material-ui/core/Switch";

class NewUserCreatePopup extends React.Component {

    state = {
      "users": [],
      "sim_server_status": [],
      "filter_by": null,
    }

    refresh() {
    }
    componentWillReceiveProps() {
    }
    componentWillMount() {
    	this.update_data(this.props);
      setInterval(this.update_data_live.bind(this, this.props), 3000)
    }

    fromNow(date) {
      // return moment(date).relativeTimeThreshold('m', 60);
      return (moment(date)).fromNow();
    }

    updateSimCid(user) {
      var token = localStorage.getItem('user_token');
      
      fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/update-sim-cid?access_token=${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(user)
      })
      .then(response => response.json())
      .then(data => {
          NotificationManager.success('success', 'update sim cid success');
      })
      .catch(err => {
          NotificationManager.error('error', 'update sim cid success');
      });

    }

    update_data(props) {
    	var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/get-new-profiles?access_token=${token}&id=${this.props.match.params.profile_id}`, {})
        .then(response => response.json())
        .then(data => {

          data.sort((a, b) => {
            return a.sim_id - b.sim_id;
          })

        	this.setState({
		    	 "users": data,
        	});
        })
        .catch(err => {

        });
    }

    update_data_live(props) {
      var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/sim-server-status?access_token=${token}`, {})
        .then(response => response.json())
        .then(data => {
          this.setState({
           "sim_server_status": data,
          });
        })
        .catch(err => {

        });
    }

    getSlot(user) {
      return this.state.sim_server_status.find((a) => a.iccid == user.sim_cid)
    }
    activatePort(user, port) {
      var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/activate-sim-port?access_token=${token}&port=${port}`, {})
        .then(response => response.json())
        .then(data => {
          NotificationManager.success('success', 'update sim port success, please wait');
        })
        .catch(err => {
          NotificationManager.error('error', 'cant activate sim port');
        });
    }

    CreateUser(user, port) {
      var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/create-facebook-user?access_token=${token}&port=${port}&user=${user._id}`, {})
        .then(response => response.json())
        .then(data => {
          NotificationManager.success('success', 'update sim port success, please wait');
        })
        .catch(err => {
          NotificationManager.error('error', 'cant activate sim port');
        });
    }

    assignNumber(user, port) {
      var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/asign-number?access_token=${token}&port=${port}&user=${user._id}`, {})
        .then(response => response.json())
        .then(data => {
          NotificationManager.success('success', 'update sim port success, please wait');
        })
        .catch(err => {
          NotificationManager.error('error', `can't assign number`);
        });
    }

    render() {
    	const { t, i18n } = this.props;

        return (
		  <div className="overlay">
        <style>{'body {overflow: hidden;}'}</style>

              <div style={{width: '800px'}} className="bg-primary p-5 text-white text-center rounded-3 " id="myForm">

                  <div className="pb-5 position-relative" id="signin_header">
                    <h2 className="fs-5 fw-bolder text-uppercase text-center">{this.props.edit ? t('Edit profile') : t('Locked Profiles')}</h2>
                    <a type="button" className="btn text-white close_btn fs-2 fw-bolder" onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console']})}>×</a>
                  </div>

                  <div className="btn-group small-btn" role="group" aria-label="Basic example">
                    <button type="button" className={`btn btn-secondary`} onClick={() => { this.setState({filter_by: ''}) }}>All</button>
                    <button type="button" className={`btn btn-secondary`} onClick={() => { this.setState({filter_by: 'locked'}) }}>Locked</button>
                    <button type="button" className={`btn btn-secondary`} onClick={() => { this.setState({filter_by: 'open'}) }}>Open</button>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <ul className="list-group" style={{color: "black"}}>
                        {this.state.users.filter((u) => {
                          if (!this.state.filter_by) {
                            return true;
                          }

                          if (this.state.filter_by === 'locked' && u.locked_account) {
                            return true;
                          }
                          if (this.state.filter_by === 'open' && !u.locked_account) {
                            return true;
                          }

                        }).map((user) => <div>
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                            {user.first_name} {user.last_name}
                            <span className="badge badge-primary badge-pill badge-blue">{user.birth_day}</span>
                            <span className="badge badge-primary badge-pill badge-blue">{user.sim_id}</span>
                            <span className={`badge badge-pill ${!user.locked_account ? 'badge-success' : 'badge-danger'}`}>{user.locked_account && 'locked'} {!user.locked_account && 'Open'}</span>

                            <span><input disabled={true} type="text" value={user.sim_cid}/></span>
                          
                            {this.getSlot(user) && <span>
                              {<FaLightbulb style={{
                                color: this.getSlot(user).active ? '#3ee724' : '#ff6b00',
                                fontSize: '24px',
                                lineHeight: '41px',
                              }}/>}

                              {this.getSlot(user).port}

                              {this.getSlot(user).active ? <button onClick={this.CreateUser.bind(this, user, this.getSlot(user).port)}>Create Account</button> : <button onClick={this.activatePort.bind(this, user, this.getSlot(user).port)}>Activate Port</button>}
                            </span>}
                            {!user.sim_cid && <button onClick={this.assignNumber.bind(this, user)}>Assign Number</button>}
                          </li>
                          {this.getSlot(user) && this.getSlot(user).sms_messages.map((m) => (<div>{m.message}</div>))}
                        </div>)}
                      </ul>
                    </div>
                  </div>
              </div>

		  </div>
        );
    }
}

NewUserCreatePopup.propTypes = {};

NewUserCreatePopup.defaultProps = {};

export default withRouter(NewUserCreatePopup);
















