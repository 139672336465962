import React from 'react';
import { ChartDonut } from '@patternfly/react-charts';

import {Button} from 'react-bootstrap/Button';
import {DropdownButton, ButtonGroup, Dropdown} from 'react-bootstrap';

import { useTranslation, initReactI18next, withTranslation } from 'react-i18next';

import { fasAngleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  withRouter,
  useLocation
} from "react-router-dom";

import queryString from 'query-string';

class Stats extends React.Component {

    state = {
      stats: {},
      show: false,
    }


    update_data(props) {
      var token = localStorage.getItem('user_token');
      var account_id = props.user ? props.user._id : null;


        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/stats?access_token=${token}&account=${account_id}`, {})
        .then(response => response.json())
        .then(data => {
          this.setState({
            stats: data,
          });
        })
        .catch(err => {

        });;
     }

    componentWillMount(props) {  
      this.update_data(this.props);  
    }




    render() {
      const { t, i18n } = this.props;
        return (
            <React.Fragment>
            <div style={{marginTop: '40px', dir: 'ltr'}} className={`stats ${this.state.show ? 'show' : 'hide'}`}>
              {this.state.stats.posts && <div style={{marginTop: '30px', paddingBottom: '10px', background: 'rgb(255 255 255)',}} className="col-12 d-flex g-0 justify-content-between flexbox text-center">
                <div style={{ height: '180px', width: '480px',margin: 'auto' }}>
                  <ChartDonut
                    ariaDesc="Average number of pets"
                    ariaTitle="Donut chart example"
                    constrainToVisibleArea={true}
                    data={Object.keys(this.state.stats.groups.by_user).map((key) => {
                      return {
                        x: key.split('_')[1],
                        y: this.state.stats.groups.by_user[key],
                      }
                    })}
                    labels={({ datum }) => `${datum.x}: ${datum.y}`}
                    subTitle="Groups"
                    title={this.state.stats.groups.count}
                  />
                </div>


                  <div style={{ height: '180px', width: '480px',margin: 'auto' }}>
                    <ChartDonut
                      ariaDesc="Posts"
                      ariaTitle="Posts"
                      constrainToVisibleArea={true}
                      data={[
                        ]}
                      legendOrientation="vertical"
                      legendPosition="right"

                      padding={{
                        bottom: 20,
                        left: 20,
                        right: 0, // Adjusted to accommodate legend
                        top: 20
                      }}

                      legendData={[
                        ]}
                      labels={({ datum }) => `${datum.x}: ${datum.y}`}
                      subTitle="Posts"
                      title={this.state.stats.posts.count + '/' + this.state.stats.daily_post_limit}
                    />
                  </div>
                </div>}
              </div>


              <div  style={{"text-align": 'center'}}>
                <svg onClick={() => {this.setState({show: !this.state.show})} } style={{width: "16px"}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">{/* Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) */}<path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z" /></svg>
              </div>

            </React.Fragment>
        );
    }
}

Stats.propTypes = {};

Stats.defaultProps = {};

export default Stats;

