import React from 'react';
import PropTypes from 'prop-types';
import styles from './Campaigns.module.scss';
import fetch from 'node-fetch';

import settings from '../../assets/icons/settings.svg';
import logo from '../../assets/images/logo.png';
import help from '../../assets/icons/help.svg';
import alert from '../../assets/icons/alert.svg';
import signin from '../../assets/icons/signin.svg';
import add from '../../assets/icons/add.svg';
import user1 from '../../assets/images/user1.png';
import user2 from '../../assets/images/user2.png';
import user3 from '../../assets/images/user3.png';
import user4 from '../../assets/images/user4.png';
import post1 from '../../assets/images/post1.png';
import post2 from '../../assets/images/post2.png';
import chat from '../../assets/icons/chat.svg';
import monitor from '../../assets/icons/monitor.svg';
import bin from '../../assets/icons/bin.svg';
import group1 from '../../assets/images/group1.png';
import account from '../../assets/icons/account.svg';
import password from '../../assets/icons/password.svg';
import like from '../../assets/icons/like.svg';
import follower from '../../assets/icons/follower.svg';
import $ from 'jquery';


import {Button} from 'react-bootstrap/Button';
import {DropdownButton, ButtonGroup, Dropdown} from 'react-bootstrap';

import { FaExclamationTriangle, FaLock, FaBell, FaPlus } from "react-icons/fa";

import { useTranslation, initReactI18next, withTranslation } from 'react-i18next';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  withRouter,
  useLocation
} from "react-router-dom";

import queryString from 'query-string';

import { Campaign } from "./Campaign";

class Campaigns extends React.Component {

    state = {
    	campaigns: [],
    	selected: null,
        search_text: '',
        latest_new: null,
    }

    refresh() {

    }

    update_data(props) {
    	var token = localStorage.getItem('user_token');
    	var account_id = props.user ? props.user._id : null;

    	if (!account_id) {

        	this.setState({
        		campaigns: [],
        	});

    		return;
    	}

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/campaigns?access_token=${token}&account=${account_id}`, {})
        .then(response => response.json())
        .then(data => {
        	this.setState({
        		campaigns: data,
        	});
            setTimeout(() => {

                if ($('.campaigns .active')) {
                    if ($('.campaigns .active')[0]) {
                        $('.campaigns')[0].scroll($('.campaigns .active')[0].getBoundingClientRect().x+$('.campaigns')[0].scrollLeft-600, 0)
                    }
                }
            }, 0)



        })
        .catch(err => {

        });
    }

    componentWillReceiveProps(props) {

        var query = queryString.parse(this.props.location.search);
    	this.setState({
    		selected: query.campaign,
    	});

    	
    	if (props.user !== this.props.user) {
    		this.update_data(props);
    	}
        if (query['new-campaign'] && this.state.latest_new !== query['new-campaign']) {
            this.setState({
                latest_new: query['new-campaign'],
            });

            this.update_data(props);
        }
    }

    componentWillMount(props) {
        var query = queryString.parse(this.props.location.search);
    	this.setState({
    		selected: query.campaign,
    	});
	
		this.update_data(this.props);	
    }

    selectCampaign(campaign, do_not_update_search){
        this.props.tutorialEvent({action: 'select_campaign'});

        if (do_not_update_search) {
            this.props.updateSearch({campaign: campaign._id});
        }
    }

    render() {
    	const { t, i18n } = this.props;
        return (
            <React.Fragment>
                <div className="campaign-search" style={{textAlign: 'center'}} >
                  {/*<input style={{maxWidth: '80%', marginRight: '10px'}} placeholder={t('Search')} type="text" value={this.state.search_text} onChange={(e) => {this.setState({search_text: e.target.value})}}/>*/}
                  {/*<a className="btn btn-warning" onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console','campaign', 'add']})}>
                    {t('Add Campaign')}
                  </a>*/}
                </div>

                <div class="campaign-section">
                    <div class="campaigns">
                        <section id="info_campaign" className>
                          {this.state.campaigns.filter((c) => {
                              return c.name && c.name.indexOf(this.state.search_text) > -1;
                          }).map((campaign) => (<React.Fragment>

                                <React.Fragment>

                                    <Campaign
                                        query={queryString.parse(this.props.location.search)}
                                        name={"campaign"}
                                        t={t}
                                        selectCampaign={this.selectCampaign.bind(this)}
                                        type={"campaign"}
                                        key={"ggggggggg"}
                                        state={this.state}
                                        campaign={campaign}
                                        updateSearch={this.props.updateSearch}
                                    />

                                </React.Fragment>

                          </React.Fragment>))}
                        </section>
                    </div>
                    <div class="new-campaign">
                        <section id="add_campaign" className>
                        <button onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console','campaign', 'add']}, 'add_campaign_button_click')} className="btn btn-primary w-100" >{t('New Campaign')}</button>
                            {/*<span className="position-relative campaign" style={{display: 'inline-block'}}>
                                <span style={{backgroundColor: 'red'}} className={"campaign rounded position-relative button-click"} onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console','campaign', 'add']}, 'add_campaign_button_click')}>
                                   {t('New Campaign')}
                                   {<FaPlus/>}
                                </span>
                            </span>*/}
                        </section>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

Campaigns.propTypes = {};

Campaigns.defaultProps = {};

export default Campaigns;

