import React from 'react';
import PropTypes from 'prop-types';
import styles from './Header.module.scss';
import moment from 'moment';


import settings from '../../assets/icons/settings.svg';
import logo from '../../assets/images/logo.png';
import help from '../../assets/icons/help.svg';
import alert from '../../assets/icons/alert.svg';
import signin from '../../assets/icons/signin.svg';
import clock from '../../assets/icons/clock.svg';
import add from '../../assets/icons/add.svg';
import user1 from '../../assets/images/user1.png';
import user2 from '../../assets/images/user2.png';
import user3 from '../../assets/images/user3.png';
import user4 from '../../assets/images/user4.png';
import post1 from '../../assets/images/post1.png';
import post2 from '../../assets/images/post2.png';
import chat from '../../assets/icons/chat.svg';
import monitor from '../../assets/icons/monitor.svg';
import bin from '../../assets/icons/bin.svg';
import group1 from '../../assets/images/group1.png';
import account from '../../assets/icons/account.svg';
import password from '../../assets/icons/password.svg';
import like from '../../assets/icons/like.svg';
import follower from '../../assets/icons/follower.svg';

import {Button} from 'react-bootstrap/Button';
import {DropdownButton, ButtonGroup, Dropdown} from 'react-bootstrap';

import { useTranslation, initReactI18next, withTranslation } from 'react-i18next';

import { FaLightbulb } from "react-icons/fa";



import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  withRouter,
  useLocation
} from "react-router-dom";

import queryString from 'query-string';

var fromNow = (date) => {
  // return moment(date).relativeTimeThreshold('m', 60);
  return (moment(date)).fromNow();
}

class Header extends React.Component {

    state = {
    	health_check: {},
    	intervalId: null,
    }

    refresh() {

    }

    update_data(props) {
    	var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/health-check?access_token=${token}`, {})
        .then(response => response.json())
        .then(data => {
        	this.setState({
        		health_check: data,
        	});
        })
        .catch(err => {

        });;
    }

    componentWillReceiveProps(props) {
    }
    
    componentWillMount(props) {
    	var intervalId = setInterval(() => {
			this.update_data(this.props);	
    	}, 10000);
    	
    	this.setState({ intervalId: intervalId })

		this.update_data(this.props);	
    }
	componentWillUnmount(){
		clearInterval(this.state.intervalId)
	}



    render() {
    	const { t, i18n } = this.props;

    	// console.log(this.props, 77777)
    	var page = 'home';

    	if (this.props.location.pathname.indexOf('/schedule/automatic') > -1) {
    		var page = 'automatic';
    	} else if (this.props.location.pathname.indexOf('/guides') > -1) {
    		var page = 'guides';
    	}

        return (
		  <div className={styles.Header}>
		      <header className="bg-primary px-5 text-white row g-0">
		        <div className="col-2 py-3">
		          <h1 className="fs-5 fw-normal"><a className="text-decoration-none text-white" href="/">Fbzipper.com</a></h1>
		        </div>
		        <div className="col-6 d-flex g-0 justify-content-between">
		          <li className={`list-unstyled py-3 ${page === 'home' ? 'active' : ''}`}><a className="d-block text-decoration-none text-white" onClick={this.props.updateSearch.bind(this, {path: [':lang']})}>{t('Schedulor')}</a></li>
		          <li className={`list-unstyled py-3 ${page === 'automatic' ? 'active' : ''}`}><a className="d-block text-decoration-none text-white" onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console','schedule', 'automatic', 'day']})}>{t('Automatic schudling')}</a></li>
		          <li className={`list-unstyled py-3 ${page === 'guides' ? 'active' : ''}`}><a className="d-block text-decoration-none text-white" href="#">{t('Guides')}</a></li>
		        </div>
		        {/*<div className="col-5 d-flex g-0 justify-content-between flexbox text-center">
		        	
					<div className="sq circle" style={{margin: 'auto'}}>100/500</div>
					<div className="sq circle" style={{margin: 'auto'}}>asfasf</div>
					<div className="sq circle" style={{margin: 'auto'}}>asfasf</div>
					<div className="sq circle" style={{margin: 'auto'}}>asfasf</div>
					<div className="sq circle" style={{margin: 'auto'}}>asfasf</div>
					<div className="sq circle" style={{margin: 'auto'}}>asfasf</div>
					<div className="sq circle" style={{margin: 'auto'}}>asfasf</div>
					<div className="sq circle" style={{margin: 'auto'}}>asfasf</div>
					<div className="sq circle" style={{margin: 'auto'}}>asfasf</div>
					<div className="sq circle" style={{margin: 'auto'}}>asfasf</div>

		        </div>*/}
		        <div className="col-2 py-3 d-flex g-0 justify-content-center">
		          <a onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console','settings']})}><img className="mx-2" width="20px" src={settings} g="icons/settings.svg" alt="" /></a>
		          <a href="#"><img className="mx-2" width="20px" src={help} g="icons/help.svg" alt="" /></a>
		          <a href="#"><img className="mx-2" width="20px" src={alert} g="icons/alert.svg" alt="" /></a>

		          <a data-tip={this.state.health_check.version ? `${this.state.health_check.version} - ${this.state.health_check.simultaneous} browsers - ${this.state.health_check.restart_count}` : `OFF` } onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console','program-settings']})}><FaLightbulb style={{
                    color: this.state.health_check.started ? '#3ee724' : 'red',
                    fontSize: '24px',
                    lineHeight: '41px',
                  }}/></a>

		          <DropdownButton
		            as={ButtonGroup}
		            key={'default'}
		            title={this.props.match.params.lang ? this.props.match.params.lang.toUpperCase() : ''}
		          >
		            <Dropdown.Item as={Link} to='/en'>English</Dropdown.Item>
		            <Dropdown.Item as={Link} to='/he'>עברית</Dropdown.Item>
		          </DropdownButton>


		        </div>
		        <div className="col-2 border-start border-white text-center py-2">
		          {!this.props.logged_in && <a className="btn px-3 border border-white rounded-pill text-white" to={`/${this.props.match.params.lang}/login`}>
		          	<React.Fragment>{t('Sign in')} <span><img className="mx-1" width="20px" src={signin} g="icons/signin.svg" alt="" /></span></React.Fragment>
		          </a> }

		          {this.props.logged_in && <a className="btn px-3 border border-white rounded-pill text-white" onClick={this.props.logout}>
		          	<React.Fragment>{t('Hello')} {this.props.user && this.props.user.username} {t('logout')} <span><img className="mx-1" width="20px" src={signin} g="icons/signin.svg" alt="" /></span></React.Fragment>
		          </a> }
		        </div>
		      </header>
		  </div>
        );
    }
}

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
