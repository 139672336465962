import React from 'react';
import PropTypes from 'prop-types';
import styles from './Management.module.scss';

import {FaTimes, FaLongArrowAltRight, FaExclamationTriangle} from "react-icons/fa";

import LoadingIcons from 'react-loading-icons'
import queryString from 'query-string';

const fff_css = `
  .add-new-user {
      z-index: 999999;
      position: relative;
  }
  .add-new-user button {
      z-index: 999999;
      position: relative;
  }
`;

// baloon aniamtion -> https://codepen.io/Jemimaabu/pen/vYEYdOy

class Management extends React.Component {

    getCustomManagementCss = () => {
      return `.overlay {
        background: none !important;
      }`;
    }

    componentDidMount() {
      var query = queryString.parse(this.props.location.search);
    }

    componentWillUnmount() {
    }

    get_current_step() {
      if (this.state.selected_Management && this.state.selected_Management.steps && this.state.selected_Management.steps[this.state.selected_Management.step_index] && this.state.selected_Management.steps[this.state.selected_Management.step_index]) {
        return this.state.selected_Management.steps[this.state.selected_Management.step_index];
      }
    }

    set_watched_video () {
      var event = this.get_current_step();
      event.watched_video = true;

      if (event.action_triggered) {
        this.next_step();
      }

      this.setState({selected_Management: this.state.selected_Management})
    }



// this.setSelectedManagement.bind(this, Management)
//     setSelectedManagement(Management, index) {
//       // window.alert(123);
//       Management.step_index = parseInt(index) || 0;

//       this.props.updateSearch({'current-tutoral': Management.id, step: Management.step_index})
//       this.setState({selected_Management: Management});

//       setTimeout(() => {
//         var current_step = this.get_current_step();

//         if (current_step.init_function) {
//           current_step.init_function();
//         }
//       }, 0)
//     }




    next_Management () {
    }
    next_step() {
      var event = this.get_current_step();

      event.success = true;

      this.state.selected_Management.step_index++;
      this.setState({selected_Management: this.state.selected_Management})

      var current_step = this.get_current_step();
      
      if (!current_step) {
        this.next_Management();
        return;
      }

      if (current_step && current_step.init_function) {
        current_step.init_function();
      }

      setTimeout(() => {
        this.props.updateSearch({'current-tutoral': this.state.selected_Management.id, step: this.state.selected_Management.step_index})
      }, 0)
    }

    state = {
      selected_Management: null,
    }

    refresh() {
    }

    update_data(props) {
    }

    componentWillReceiveProps(props) {
    }
    
    componentWillMount(props) {

    }

    setSelectedManagement(Management, index) {
      // window.alert(123);
      Management.step_index = parseInt(index) || 0;

      this.props.updateSearch({'current-tutoral': Management.id, step: Management.step_index})
      this.setState({selected_Management: Management});

      setTimeout(() => {
        var current_step = this.get_current_step();

        if (current_step.init_function) {
          current_step.init_function();
        }
      }, 0)
    }

    render() {
      const { t, i18n } = this.props;

      return (
        <div className={styles.Management}>




          <div>
            {this.get_current_step() && this.get_current_step().selected_css_selectors && <style>

            </style>}

            {queryString.parse(this.props.location.search).admin && <style>
              {this.getCustomManagementCss()}
              {`
                .full-page {
                  width: calc(100% - 240px) !important;
                }
                .ffffff {
                    margin-right: 240px;
                }
              `}
            </style>}

            <div style={{
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              zIndex: 100,
            }}>

              <div className={styles.sidenav}>
                Admin Panel
              </div>
            </div>

          </div>
        </div>

      );
    }
}


Management.propTypes = {};

Management.defaultProps = {};

export default Management;
