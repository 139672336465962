import React from 'react';
import PropTypes from 'prop-types';
import styles from './ManageGroupsPopup.module.scss';
import fetch from 'node-fetch';
import _ from 'lodash';
import Select from 'react-select';

import settings from '../../assets/icons/settings.svg';
import logo from '../../assets/images/logo.png';
import help from '../../assets/icons/help.svg';
import alert from '../../assets/icons/alert.svg';
import signin from '../../assets/icons/signin.svg';
import clock from '../../assets/icons/clock.svg';
import add from '../../assets/icons/add.svg';
import user1 from '../../assets/images/user1.png';
import user2 from '../../assets/images/user2.png';
import user3 from '../../assets/images/user3.png';
import user4 from '../../assets/images/user4.png';
import post1 from '../../assets/images/post1.png';
import post2 from '../../assets/images/post2.png';
import chat from '../../assets/icons/chat.svg';
import monitor from '../../assets/icons/monitor.svg';
import bin from '../../assets/icons/bin.svg';
import group1 from '../../assets/images/group1.png';
import account from '../../assets/icons/account.svg';
import password from '../../assets/icons/password.svg';
import like from '../../assets/icons/like.svg';
import follower from '../../assets/icons/follower.svg';

import {Button} from 'react-bootstrap/Button';
import GroupSearch from './GroupSearch';
import {DropdownButton, ButtonGroup, Dropdown} from 'react-bootstrap';
import {FaSpinner,FaCheckCircle} from "react-icons/fa";

import {NotificationContainer, NotificationManager} from 'react-notifications';

import { useTranslation, initReactI18next, withTranslation } from 'react-i18next';

import { SketchPicker } from 'react-color'
import { confirmAlert } from 'react-confirm-alert'; // Import

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  withRouter,
  useParams,
  useLocation
} from "react-router-dom";

import {Radio, RadioGroup} from "@material-ui/core";

import queryString from 'query-string';

function nFormatter(num, digits) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function(item) {
    return num >= item.value;
  });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}


class ManageGroupsPopup extends React.Component {

    state = {
      "import_groups": ``,
      import_map: {
        "https://www.facebook.com/groups/1353113541478627/": {
          loading: false,
        },
        "https://www.facebook.com/groups/1470687743318992/": {
          loading: true,
        },
      },
      "master_users": [],
      "public_libraries": [],
      "libraries": [],
      "groups": [],
      "account": '',
      "group_library": {},
      "allow_permissions": [],
      "selected": null,
      "amount_to_display": 300,
      "search_text": '',
      // "sort_by": 'follow_count',
      "sort_by": null,
      // "sort_by": 'users_in_group_count',
      "profiles": [],
      "filter_by_profile": null,
      "profile_group": {},
      "loading": true,
      "filter_query": {
        by_selected: {
          value: 'all',
          query: {},
        },
      },
    }

    refresh() {
    }
    componentWillReceiveProps(props) {
      if (props.match.params.library_id !== this.props.match.params.library_id) {
        this.setState({
          _id: null,
          "logs": [],
          "log_stats": {},
          "profiles": [],
        });
        setTimeout(() => {
          this.update_data(this.props);
        }, 0)
      }
    }

    componentWillMount() {
      this.props.tutorialEvent({action: 'add_new_group_library_popup'})

      this.update_data(this.props);
      this.get_groups(this.props);
    }


    get_groups(props) {
      var token = localStorage.getItem('user_token');

          this.setState({
            loading: true,
            groups: [],
          });

          try {
            if (this.get_groups_conroller) {
              this.get_groups_conroller.abort();
            }
          } catch {
          }
        this.get_groups_conroller = new AbortController();
        let signal = this.get_groups_conroller.signal;

        setTimeout(async () => {
          try {
            var response = await fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/groups?all=true&access_token=${token}`, {
              method: 'POST',
              signal: signal,
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                search_text: this.state.search_text,
                selected_groups: this.state.selected,
                filter_by: this.state.filter_query.by_selected.value,
                search_text: this.state.search_text,
                sort_by: this.state.sort_by,
                filter_by_profile: this.state.filter_by_profile,
                filter_by_library: this.state.filter_by_library,
                page: 1,
                group_status: this.state.filter_query.group_status,
              })
            });
            let data = await response.json();

            this.state.groups = data
            this.setState({
              groups: this.state.groups,
              loading: false,
              total_group_count: await response.headers.get('total_group_count'),
            });
          } catch (e) {

          }
        })

    }
    update_data(props) {
    	var token = localStorage.getItem('user_token');

      (async () => {

        // var group_count = (await(await fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/groups?all=true&access_token=${token}&count=1`, {})).json()).count;
        

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/get-profile-group?access_token=${token}`, {})
        .then(response => response.json())
        .then(data => {
            this.setState({
                profile_group: data,
            });
        })
        .catch(err => {

        });

        
        // for (var i = 0; i < Math.ceil(group_count/1000); i++) {
        // }

        if (!this.props.groupLibrary) {
          fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/account-group-settings?access_token=${token}`, {})
          .then(response => response.json())
          .then(data => {
            this.setState({
              selected: data.facebook_groups,
            });
              this._sort();
          })
          .catch(err => {

          });
        } else {
          if (this.props.match.params.library_id) {

            fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/get-group-library?access_token=${token}&id=${this.props.match.params.library_id}`, {})
            .then(response => response.json())
            .then(data => {
              // window.alert(data.account);
              this.setState({
                selected: data.facebook_groups,
                name: data.name,
                account: data.account,
                allow_permissions: data.allow_permissions,
              });
              
              this._sort();
            })
            .catch(err => {

            });


          } else {
            this.setState({
              selected: [],
            });
          }
        }

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/profiles?access_token=${token}`, {})
        .then(response => response.json())
        .then(data => {
            this.setState({
                profiles: data,
            });
        })
        .catch(err => {

        });


        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/group-libraries?all=true&access_token=${token}`, {})
        .then(response => response.json())
        .then(data => {
          this.setState({
            libraries: data,
          });
        })
        .catch(err => {

        });

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/group-libraries?public=true&access_token=${token}`, {})
        .then(response => response.json())
        .then(data => {
          this.setState({
            public_libraries: data,
          });
        })
        .catch(err => {

        });

        fetch(`${process.env.NODE_ENV !== 'development' ? "/admin" : "/admin"}/master?access_token=${token}`, {})
          .then(response => response.json())
          .then(data => {
            this.setState({
              master_users: data,
            });
          })
          .catch(err => {

          });


      })()

    }

    handleManageGroupsPopupClicked() {
        var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/create-group?access_token=${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            group: this.state.group,
            id: this.props.match.params.group_id,
          })
        })
        .then(response => response.json())
        .then(data => {
          NotificationManager.success('Added group successfully');
          this.props.updateSearch({path: [':lang', 'console'], "new-group": Math.random()})
        })
        .catch(err => {
          NotificationManager.error('Error adding group');

          this.setState({
            error: "Error adding group",
            success: "",
            password: "",
          });
        })
    }

    toggleAddGroup(group_id) {    	
    	if (this.state.selected.indexOf(group_id) > -1) {
    		this.state.selected.splice(this.state.selected.indexOf(group_id), 1);
    	} else {
    		this.state.selected.push(group_id);
    	}

    	this.setState({
    		selected: this.state.selected,
    	});

      if (this.props.groupLibrary) {
        return;
      }

      var token = localStorage.getItem('user_token');

      fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/set-group-settings?access_token=${token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          selected: this.state.selected,
        })
        })
        .then(response => response.json())
        .then(data => {
          NotificationManager.success('success', 'saved settings');
        })
        .catch(err => {
          NotificationManager.error('error', 'error saving settings');
        });



    }

    handleDeleteGroupLibrary() {
    var token = localStorage.getItem('user_token');

        confirmAlert({
          title: 'Confirm to remove slot',
          message: 'Are you sure to do this.',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/delete-group-library?access_token=${token}`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                    id: this.props.match.params.library_id,
                  })
                })
                .then(response => response.json())
                .then(data => {
                  NotificationManager.success('Deleted group successfully');
                  this.props.updateSearch({path: [':lang', 'console', 'group-library']})
                })
                .catch(err => {
                  NotificationManager.error('Error deleting group');

                  this.setState({
                    error: "Error deleting group",
                    success: "",
                    password: "",
                  });
                })

              }
            },
            {
              label: 'No',
              onClick: () => {

              }
            }
          ]
        });



    }

    getDisplayGroups(sort_by, search_text, amount_to_display, filter_by_profile, filter_query, selected) {



      this.state.groups.map((g) => {
        g.follow_count = g.follow_count | 0;
      })

      var groups = _.orderBy(this.state.groups, [sort_by, 'follow_count', 'today_slot_count', 'users_in_group_count'],['desc'])

      if (filter_query.by_selected.value !== 'all') {

        groups = groups.filter((group) => {
          if (filter_query.by_selected.value === 'unselected') {
            return selected.indexOf(group._id) === -1;
          } else {
            return selected.indexOf(group._id) > -1;
          }
        })
      }

      if (filter_by_profile) {
        groups = groups.filter((group) => {
          return Object.keys(this.state.profile_group[filter_by_profile] || {}).indexOf(group._id) > -1
        })
      }

      return groups.filter((group) => {
        group.name = group.name || '';
          return group.name.toLowerCase().indexOf(search_text.toLowerCase()) > -1;
        })
        .slice(0,amount_to_display)

    }
    // dynamicSort(property) {
    //     var sortOrder = 1;
    //     if(property[0] === "-") {
    //         sortOrder = -1;
    //         property = property.substr(1);
    //     }
    //     return function (a,b) {
    //          next line works with strings and numbers, 
    //          * and you may want to customize it to your needs
             
    //         var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
    //         return result * sortOrder;
    //     }
    // }

    import_new_groups() {
      var urls = this.state.import_groups.split("\n").map((a) => a.trim()).filter((a) => a);

      this.state.import_map = {};
      for (var i = 0; i < urls.length; i++) {
          this.state.import_map[urls[i]] = {
            loading: true,
          }
      }

      this.setState({
        import_map: this.state.import_map
      });


      var token = localStorage.getItem('user_token');

      (async () => {

      for (var i = 0; i < urls.length; i++) {

          var result = await fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/get-facebook-data?access_token=${token}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              link: urls[i]
            })
          });
          var result = await result.json();
          if (result._group_id) {
            this.state.import_map[urls[i]].loading = false;
            this.state.selected.push(result._group_id);
            this.state.selected = [...new Set(this.state.selected)];
          } else {
            this.state.import_map[urls[i]].loading = false;
            this.state.import_map[urls[i]].error = true;
          }

          this.setState({
            import_map: this.state.import_map,
            selected: this.state.selected,
          })
      }





      })()



      window.alert(urls);
    }
    save_group_library() {

        var token = localStorage.getItem('user_token');

        if (!this.state.name) {
          NotificationManager.error(this.props.t('Library name is required'));
          this.setState({validate_form: true});
          return 'not valid';
        }

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/create-group-library?access_token=${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: this.props.match.params.library_id || null,
            selected: this.state.selected || [],
            allow_permissions: this.state.allow_permissions || [],
            name: this.state.name,
            account: this.state.account,
          })
        })
        .then(response => response.json())
        .then(data => {
          NotificationManager.success('Added group successfully');

          this.props.tutorialEvent({action: 'save_group_library'})

          this.props.updateSearch({path: [':lang', 'console', 'group-library'], 'added-library': data._id})
        })
        .catch(err => {
          NotificationManager.error('Error adding group');

          this.setState({
            error: "Error adding group",
            success: "",
            password: "",
          });
        })


    }


    handleDuplicateLibrary() {

      confirmAlert({
        title: 'Confirm to submit',
        message: 'Are you sure to do this.',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              var token = localStorage.getItem('user_token');
              let new_name = prompt("What do you want to call the new campaign?", "aaa");
              
              // return alert(new_name)

              fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/deplicate-group-library?access_token=${token}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              id: this.props.match.params.library_id,
              new_name
            })
          })
          .then(response => response.json())
          .then(data => {
            NotificationManager.success('Duplicate campaign successfully');
            this.props.updateSearch({path: [':lang', 'console', 'group-library', 'edit', data._id], "new-campaign": Math.random()})
          })
          .catch(err => {
            NotificationManager.error('Error duplicating library');
          })

            }
          },
          {
            label: 'No',
            onClick: () => {

            }
          }
        ]
      });


    }

    buttons = [
      {
        label: 'Delete',
        tool_tip: 'Delete',
        class: 'btn-danger',
        action: () => {
          this.handleDeleteGroupLibrary()
        },
        should_display: () => {
          return this.props.match.params.library_id;
        }
      },
      {
        label: 'Duplicate',
        tool_tip: 'Duplicate',
        class: 'btn-danger',
        action: () => {
          // window.alert(333);
          this.handleDuplicateLibrary()
        },
        should_display: () => {
          return !this.props.groupSettings;
        }
      },





      // {
      //   label: 'Delete',
      //   tool_tip: 'Delete',
      //   class: 'btn-danger',
      //   action: () => {

      //   },
      //   should_display: () => {
      //     return this.props.match.params.library_id;
      //   }
      // },
    ];

    selectAll() {
      this.setState({
        selected: [...new Set(this.state.selected.concat(this.state.groups.map((a) => a._id)))]
      })
    }
    extra_options = [
      // {
      //   label: 'Stop Profile Activity',
      //   tool_tip: 'Stop Profile Activity',
      //   action: () => {
      //     this.stopProfileActivity();
      //   },
      //   should_display: () => {
      //     return !this.state.locked_account && this.props.match.params.profile_id;
      //   }
      // },
    ];

    close_path = [':lang', 'console', 'group-library'];
    tabs = [
      {
        label: 'Settings',
        active: true,
        should_display: () => {
          return true;
        },
        content: () => <div>
          <div className={`needs-validation ${this.state.validate_form ? 'was-validated' : ''}`} noValidate="">
          <div className="container">
            <div className="row">
                {this.props.groupLibrary && <div className="form-group">
                  <label>{this.props.t('Library name')} <span style={{color: ''}}>({this.props.t('Required')})</span></label>
                  <input
                    required={true}
                    type="text"
                    placeholder={this.props.t('Library name')}
                    className="form-control"
                    value={this.state.name}
                    onChange={event => {this.state.name = event.target.value;this.setState({ group_library: this.state.name })}}
                  />
                </div>}
            </div>

                {this.state.account && this.state.master_users.length > 0 && <div className="form-group dddd">
                  <label htmlFor="exampleInputEmail1">{this.props.t('Account')}</label>
                  
                  <Select
                   className="my-react-select-container"
                   classNamePrefix="my-react-select"


                    value={this.state.master_users.map((a) => { return {label: a.username, value: a._id}; }).find((a) => a.value == this.state.account)}
                    name="colors"
                    options={this.state.master_users.map((a) => { return {label: a.username, value: a._id}; })}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => { this.setState({account: e.value}) }}
                  />
                </div>}





            <div className="group-search">
              <GroupSearch {... this.props} selected={this.state.selected} onChange={(selected) => this.setState({selected: selected})}/>
            </div>
          </div>
          </div>
        </div>,
        footer: () => <div>

          {this.props.groupLibrary && <div className="container">
            <div className="row">
              <button type="submit" className="btn btn-warning" onClick={this.save_group_library.bind(this)}>
                {this.props.t('Save')} ({this.state.selected.length} {this.props.t('groups')})
              </button>
            </div>
          </div>}


        </div>,
      },
      {
        label: 'Add New Groups',
        active: true,
        should_display: () => {
          return !this.props.groupSettings;
        },
        content: () => <div>
          <div className={`needs-validation ${this.state.validate_form ? 'was-validated' : ''}`} noValidate="">
            <div className="container">
              <div className="row">
                  <label>{this.props.t('Add your links here')} </label>
                  <textarea
                    required={true}
                    type="text"
                    rows={10}
                    placeholder={this.props.t('Library name')}
                    className="form-control links-area"
                    value={this.state.import_groups}
                    onChange={event => {this.state.import_groups = event.target.value;this.setState({ import_groups: this.state.import_groups })}}
                  />
              </div>
              <div className="row">
                import_map

                <ul>
                  {Object.keys(this.state.import_map).map((a) => (<li>
                    {this.state.import_map[a].loading && <FaSpinner/>}
                    {!this.state.import_map[a].loading && <FaCheckCircle/>}
                    {a}
                  </li>))}
                </ul>
              </div>
            </div>
          </div>
        </div>,
        footer: () => <div>

          {this.props.groupLibrary && <div className="container">
            <div className="row">
              <button type="submit" className="btn btn-warning" onClick={this.import_new_groups.bind(this)}>
                {this.props.t('Import')}
              </button>
            </div>
          </div>}


        </div>,
      },
    ];


    render() {
      const { t, i18n } = this.props;

      this.props.sidePaneEvent({
        id: `add-edit-group-library`,
        buttons: this.buttons,
        extra_options: this.extra_options,
        close_path: this.close_path,
        tabs: this.tabs,
        name: 'add-edit-group-library',
        loading: !this.state.selected,
        title: this.props.match.params.library_id ? 'Edit Group Library' : (this.props.groupSettings ? 'Manage Group Settings' : 'Add Group Library'),
        sub_title: null,
      });
      return (<div></div>);

        return (
      <div className="overlay">
        <style>{'body {overflow: hidden;}'}</style>
              {this.state.selected && <div className="bg-primary p-5 text-white text-center rounded-3 manage-group-popup" id="myForm">
                  <div className="pb-5 position-relative" id="signin_header">
                    <h2 className="fs-5 fw-bolder text-uppercase text-center">{this.props.t('Manage Groups')} ({this.state.selected.length})</h2>
                    
                    {!this.props.groupLibrary && <a type="button" className="btn text-white close_btn fs-2 fw-bolder" onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console']})}>×</a>}
                    {this.props.groupLibrary && <a type="button" className="btn text-white close_btn fs-2 fw-bolder" onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console', 'group-library']})}>×</a>}
                  
                  </div>

                  {this.props.groupLibrary && <div className="form-group">
                    <h4 for="exampleFormControlTextarea1">{this.props.t('Library name')}</h4>
                    <input className="form-control" value={this.state.name} onChange={event => {this.state.name = event.target.value;this.setState({ group_library: this.state.name })}} id="exampleFormControlTextarea1"/>
                  </div>}
                  {/*this.props.groupLibrary && <div className="form-group">
                    <h2>{this.props.t('Permissions')}</h2>
                    <div>
                      {this.state.allow_permissions.map((username, index) => <span className="permission-user">
                        {username}
                        <span className="close" onClick={() => {
                          this.state.allow_permissions.splice(index, 1);
                          this.setState({ permissions: this.state.allow_permissions})
                        }}>X</span>
                      </span>)}
                    </div>
                  </div>*/}

                    {this.props.groupLibrary && <div id="email" className="bg-blue py-3 my-3 rounded-3 d-flex">
                      <input className="m_input" type="text" placeholder={this.props.t('Username')} onChange={event => {this.setState({ new_permission: event.target.value })}} value={this.state.new_permission}/>
                      <button onClick={event => {this.state.allow_permissions.push(this.state.new_permission); this.setState({ permissions: this.state.allow_permissions, new_permission: '' })}} className="btn bg-white py-3 px-5 text-primary rounded rounded-pill">{this.props.t('Add')}</button>
                    </div>}


                  <div>
                    <div style={{width: '50%', float: 'left'}}>
                      <h3>{this.props.t('Sort By')}</h3>

                      <select value={this.state.sort_by} onChange={(e) => { this.setState({sort_by: e.target.value}); this.get_groups() }} className="form-select bg-primary  rounded-3 text-white py-3 mb-4 px-1" aria-label="Default select example">
                        <option>{this.props.t('Sort By')}</option>
                        <option value={'today_slot_count'}>{this.props.t('Slot Count')}</option>
                        <option value={'users_in_group_count'}>{this.props.t('User in group')}</option>
                        <option value={'follow_count'}>{this.props.t('Followers')}</option>
                      </select>

                    </div>
                    <div style={{width: '50%', float: 'left'}}>
                      <h3>{this.props.t('Filter By')}</h3>

                      <div className="btn-group">
                        <a onClick={() => { this.state.filter_query.by_selected.value = 'all'; this.setState({filter_query: this.state.filter_query}) }}  className={`btn ${this.state.filter_query.by_selected.value === 'all' ? 'btn-success' : 'btn-danger'}`}>{this.props.t('All')}</a>
                        <a onClick={() => { this.state.filter_query.by_selected.value = 'selected'; this.setState({filter_query: this.state.filter_query}) }} className={`btn ${this.state.filter_query.by_selected.value === 'selected' ? 'btn-success' : 'btn-danger'}`}>{this.props.t('Selected')}</a>
                        <a onClick={() => { this.state.filter_query.by_selected.value = 'unselected'; this.setState({filter_query: this.state.filter_query}) }}  className={`btn ${this.state.filter_query.by_selected.value === 'unselected' ? 'btn-success' : 'btn-danger'}`}>{this.props.t('Unselected')}</a>
                      </div>
                    </div>
                  </div>

                  <div style={{clear: 'both'}}></div>

                  <div>
                    <div style={{width: '50%', float: 'left'}}>
                      <h3>{this.props.t('Profile')}</h3>
                      <select value={this.state.filter_by_profile} onChange={(e) => { this.setState({filter_by_profile: e.target.value}); this.get_groups() }} className="form-select bg-primary  rounded-3 text-white py-3 mb-4 px-1" aria-label="Default select example">
                        <option value={""}>{this.props.t('Select a Profile')}</option>
                        {this.state.profiles.map((profile) => <option value={profile._id}>{profile.first_name + ' ' + profile.last_name}</option> )}
                      </select>

                    </div>
                    <div style={{width: '50%', float: 'left'}}>
                    </div>
                  </div>

                  <div style={{clear: 'both'}}></div>

                  {this.props.groupLibrary && <div>
                    <button onClick={this.save_group_library.bind(this)} className="btn btn-warning">{this.props.t('Save')} ({this.state.selected.length} {this.props.t('groups')}) </button>
                  </div>}





                  <div className="search-section">
                    <input placeholder={this.props.t('Search Groups')} type="text" value={this.state.search_text} onChange={(e) => {this.setState({search_text: e.target.value})}}/>
                  </div>

                  <div className="group-info"><strong>{this.props.t(`Showing`)}:</strong> {this.state.groups.filter((group) => {group.name = group.name || ''; return group.name.toLowerCase().indexOf(this.state.search_text.toLowerCase()) > -1;}).length} {this.props.t(`groups`)} {this.props.t('of')} {this.state.groups && this.state.groups.length}</div>

                  <div style={{width: '800px'}} className="manage-group-container">
                  	{this.state.loading ? <div className="loader">
                  <svg width="300px" version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                    <circle fill="none" stroke="#fff" strokeWidth={4} cx={50} cy={50} r={44} style={{opacity: '0.5'}} />
                    <circle fill="#fff" stroke="#e74c3c" strokeWidth={3} cx={8} cy={54} r={6}>
                      <animateTransform attributeName="transform" dur="2s" type="rotate" from="0 50 48" to="360 50 52" repeatCount="indefinite" />
                    </circle>
                  </svg>
                 </div> : this.getDisplayGroups(this.state.sort_by, this.state.search_text, this.state.amount_to_display, this.state.filter_by_profile, this.state.filter_query, this.state.selected).map((group) => (<div style={{padding: '13px', width: '25%', float: 'left'}}>
				      
                    <span dir="ltr" className={"text-decoration-none my-1 d-block square group " + (this.state.selected.indexOf(group._id) > -1 ? ' active': '') }>
      				        <div className="rounded rounded-circle ">
      				          <img style={{left: 0}} className="group-image img img-thumbnail img-fluid rounded rounded-circle " src={group.image_url} g="img/group1.png" alt="" />
      				          <span className="position-absolute top-50 start-50 translate-middle bg-dark-75 rounded rounded-circle w-75 h-75">
      				            <p className=" position-absolute top-50 start-50 translate-middle w-100 text-center text-white fw-bolder"><a target="_blank" href={`https://facebook.com/groups/${group.group_id}`}>{group.name}</a></p>
      				          </span>
      				        </div>
      				        <span className="badge bg-info px-2 py-2 small text-white rounded-pill position-25"><img className="d-inline mx-1 h-icon" width="5px" src="/static/media/follower.e4d49cda.svg" g="icons/follower.svg" />{nFormatter(group.follow_count, 2)}</span><span className="badge bg-info px-2 py-2 small text-white rounded-pill position-75"><img className="d-inline mx-1 h-icon" width="5px" src="/static/media/clock.66a84231.svg" g="icons/clock.svg" />{group.today_slot_count}<img className="d-inline mx-1 h-icon" width="5px" src="/static/media/account.9ec423dc.svg" g="icons/account.svg" />{group.users_in_group_count}</span>
      				      </span>
                      <button onClick={this.toggleAddGroup.bind(this, group._id)} className={`btn ${(this.state.selected.indexOf(group._id) > -1 ? 'btn-danger': 'btn-success')}`}>{(this.state.selected.indexOf(group._id) > -1 ? t('Unselect'): t('Select'))}ddd</button>
                  	</div>))}
                  </div>
              </div>}





      </div>
        );
    }
}

ManageGroupsPopup.propTypes = {};

ManageGroupsPopup.defaultProps = {};

export default withRouter(ManageGroupsPopup);









