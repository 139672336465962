import React from 'react';
import PropTypes from 'prop-types';
import styles from './Faq.module.scss';

class Faq extends React.Component {
    state = {
      selected_question: {
        section_index: 0,
        question_index: 0,
      },
      sections: [
//         {
//           title: 'פתיחת משתמש והתחברות',
//           questions: [
//             {
//               q: `איך פותחים משתמש באתר?`,
//               a: `<span style="font-weight: 400;">לוחצים על הכפתור </span><b>"התחבר/הירשם" </b><span style="font-weight: 400;">שנמצא בצידו השמאלי של הסרגל העליון באתר, בוחרים בהרשמה, ונרשמים לאתר דרך חלון ההרשמה הרגיל (כמו בתמונה המצורפת), מכניסים שם משתמש, סיסמא רצויה, מייל ומספר טלפון ליצירת קשר. לאחר מכן יש לקרוא בעיון את תנאי השימוש ולאשר אותם. </span><span style="font-weight: 400;">
// </span><span style="font-weight: 400;">מיד לאחר ההרשמה תקבלו מייל עם סיסמא לאימות, הזינו את הסיסמא לאימות בחלון ההתחברות ומיד לאחר מכן תתחברו אוטומטית למערכת האתר. (כמו בתמונה המצורפת).</span><span style="font-weight: 400;">
// </span><b>עליכם יהיה לבחור חבילת שירות על פי הצורך שלכם.</b>`,
//             },
//             {
//               q: `איך מתחברים לאתר?`,
//               a: `<span style="font-weight: 400;">לוחצים על הכפתור </span><b>"התחבר/הירשם" </b><span style="font-weight: 400;">שנמצא בצידו השמאלי של הסרגל העליון באתר, בוחרים בהתחברות, ומתחברים לאתר דרך חלון ההתחברות הרגיל (כמו בתמונה המצורפת), מכניסים שם משתמש, סיסמא, ואז לוחצים על "התחבר".</span><span style="font-weight: 400;">
// </span><span style="font-weight: 400;"><br><br>
// </span><span style="font-weight: 400;">במידה ויש לכם שאלה שלא ענינו עליה מוזמנים ליצור איתנו קשר דרך </span><b>טופס יצירת הקשר</b><span style="font-weight: 400;"> שבאתר ונשמח לעזור.</span>`,
//             },
//           ]
//         },
//         {
//           title: 'חבילות שירות',
//           questions: [
//             {
//               q: `כמה ימי ניסיון מגיע לי?`,
//               a: `<span style="font-weight: 400;">אנו לא מציעים ימי ניסיון, אך במקום זאת אנו נותנים במתנה לכל </span><span style="font-weight: 400;">משתמש חדש 14 ימי שימוש במערכת בחודש הראשון בלבד וזה כלול בחבילה שבחר (אלא אם צוין אחרת) על מנת שיוכל להתמצא בתוכנה / במערכת של </span><b>fbzipper, </b><span style="font-weight: 400;">ללמוד את התוכנה, להכין קמפיינים, ו</span><span style="font-weight: 400;">להיות שלם עם החלטתו. המשתמש ישלם מראש על החבילה והימים שקיבל במתנה יתווספו באופן אוטומטי עם תחילת הפעילות. החיוב הבא יחל בסיום 6 שבועות מרגע התשלום הראשון ותחילת הפעילות וכך ימשך בהוראת קבע מידי חודש.</span>
// <br>
// <span style="font-weight: 400;">בנוסף, צירפנו עבורכם סרטון הדרכה בדף הבית שמסביר על התוכנה לעומק.</span>`,
//             },
//             {
//               q: `איך בוחרים חבילת שירות?`,
//               a: `<span style="font-weight: 400;">מאחר והתחברתם לאתר עליכם יהיה לבחור חבילת שירות על פי הצורך שלכם.</span><span style="font-weight: 400;">
// </span><span style="font-weight: 400;">יפתח לכם באופן אוטומטי חלון בו תצטרכו לבחור חבילת שירות ע"פ כמות הפוסטים היומיים שאתם צריכים, בוחרים חבילה רצויה, משלמים וממשיכים. (כמו בתמונה המצורפת).</span>`,
//             },
//             {
//               q: `מה החבילה הכי מומלצת למתחילים?`,
//               a: `<span style="font-weight: 400;">על מנת לנסות את התוכנה וללמוד איך היא פועלת, אנו ממליצים להתחיל עם החבילה הקטנה ביותר של 100</span><span style="font-weight: 400;"> פוסטים ביום בעלות חודשית של 1000 ש"ח,</span><span style="font-weight: 400;"> תוכלו לנסות את התוכנה במחיר הכי זול ולאחר מכן תמיד ניתן יהיה לשדרג את החבילה בהתאם לצורך.</span>`,
//             },
//             {
//               q: `איך משדרגים את החבילה במידה וכבר השתמשתי בתוכנה חודש שלם ואני מרוצה ורוצה להמשיך?`,
//               a: `<span style="font-weight: 400;">בחשבון שלכם באתר יופיע בסרגל למעלה כפתור של </span><b>"<span style="color: #3366ff;">חבילות שירות</span>"</b><span style="font-weight: 400;">, לוחצים על הכפתור ויפתח חלון בו יוצגו מספר חבילות שונות ובנוסף גם את המסלול שאתם נמצאים עליו כרגע. (כמו בתמונה המצורפת). בוחרים את החבילה הרצויה לשדרוג לדוגמא </span><b>"</b><span style="color: #3366ff;"><b>חבילה של 200 פוסטים ביום</b></span><b>"</b><span style="font-weight: 400;">, מאשרים את תנאי השימוש וזה הכל. </span><span style="font-weight: 400;">
// </span><span style="color: #ff0000;"><br><br><b>*שימו לב - החבילה תשודרג מחודש השימוש הבא, ולכן מומלץ להזמין את השדרוג לפני סיום החודש הראשון.* </b></span>`,
//             },
//           ]
//         },
//         {
//           title: 'תשלום והחזר כספי',
//           questions: [
//             {
//               q: `האם חייבים לשלם מההתחלה?`,
//               a: `<span style="font-weight: 400;">התשלום הוא חובה, אנו ממליצים לקחת תחילה חבילה קטנה על מנת לנסות את התוכנה וללמוד איך היא פועלת,</span><span style="font-weight: 400;"> החבילה הקטנה ביותר היא חבילה של 100 פוסטים ביום בעלות חודשית של 1000 ש"ח,</span><span style="font-weight: 400;"> תוכלו לנסות את התוכנה במחיר הכי זול ולאחר מכן תמיד ניתן יהיה לשדרג את החבילה בהתאם לצורך.</span>`,
//             },
//             {
//               q: `איך משלמים על החבילה?`,
//               a: `<span style="font-weight: 400;">במידה וזו הפעם הראשונה שהתחברתם לאתר עליכם יהיה לבחור חבילת שירות על פי הצורך שלכם. יפתח לכם באופן אוטומטי חלון בו תצטרכו לבחור חבילת שירות ע"פ כמות הפוסטים היומיים שאתם צריכים, בוחרים חבילה רצויה, משלמים וממשיכים. </span><span style="color: #ff0000;"><b>(מידע נוסף בשדה "</b><b>חבילות שירות</b></span><b><span style="color: #ff0000;">")</span>.</b>`,
//             },
//             {
//               q: `באיזה דרכים ניתן לשלם?`,
//               a: `<span style="font-weight: 400;">התשלום יהיה באמצעות פלטפורמת </span><b>"פייפל - PayPal"</b><span style="font-weight: 400;"> הידועה. התשלום הוא מאובטח ביותר ואנו מכבדים את כל סוגי האשראי. החיוב בעבור החבילות יהיה תשלום בהוראת קבע חודשית ע"פ החבילה שבחרתם.</span>`,
//             },
//             {
//               q: `האם אתם מפיקים חשבונית?`,
//               a: `<span style="font-weight: 400;">כמובן, אנו חברה ישראלית ופועלים ע"פ החוק. לאחר התשלום בפלטפורמת </span><b>"פייפל - PayPal"</b><span style="font-weight: 400;"> וקבלת אישור תשלום תקין, תופק חשבונית באופן אוטומטי ותשלח למייל של הלקוח.</span><span style="font-weight: 400;">
// </span><span style="color: #ff0000;"><b>*שימו לב - יש להכניס בתהליך ההרשמה מייל תקין על מנת שנוכל לשלוח לשם את החשבונית, במידה ואתם בעלי עסקים יש לציין את שמכם המלא באופן תקין במהלך ההרשמה לאתר*</b></span>

// <b>*במידה ולא קיבלתם חשבונית מסיבה כלשהי עד 24 שעות מרגע התשלום, באחריותכם לעדכן את מערכת האתר דרך </b><b>טופס יצירת הקשר</b><b> על מנת שנוכל לטפל בכך*</b>`,
//             },
//             {
//               q: `איך אני יכול לקבל החזר כספי כמו שהובטח?`,
//               a: `<span style="font-weight: 400;">אנו מבטיחים החזר כספי מלא לכל מי שהשתמש בתוכנה עד 48 שעות בלבד ולא היה מרוצה.</span><span style="font-weight: 400;">
// </span><span style="font-weight: 400;">על מנת לקבל את ההחזר הכספי יש לפתוח פנייה ב</span><b>טופס יצירת הקשר</b><span style="font-weight: 400;"> שבאתר, או לחלופין להיכנס לחלון של </span><b>"חבילות שירות"</b><span style="font-weight: 400;"> ומיד לאחר מכן ללחוץ על הכפתור </span><b>"ביטול והפסקת הפעילות"</b><b>, במידה והשתמשתם בתוכנה במשך 48 שעות או פחות תקבלו את כספכם בחזרה תוך 14 ימי עסקים כחוק ותופק חשבונית זיכוי.</b>`,
//             },
//             {
//               q: `איך מפסיקים את השירות / מבטלים מנוי?`,
//               a: `<span style="font-weight: 400;">על מנת לבטל את המנוי יש לפתוח פנייה ב</span><b>טופס יצירת הקשר</b><span style="font-weight: 400;"> שבאתר, או לחלופין להיכנס לחלון של </span><b>"חבילות שירות"</b><span style="font-weight: 400;"> ומיד לאחר מכן ללחוץ על הכפתור </span><b>"ביטול והפסקת הפעילות"</b><b>, במידה והשתמשתם בתוכנה במשך 48 שעות או פחות תקבלו את כספכם בחזרה תוך 14 ימי עסקים כחוק ותופק חשבונית זיכוי,</b><b> במידה ועברו יותר מ 48 שעות שימוש בתוכנה לא יהיה ניתן לקבל החזר כספי ותחוייבו באופן מלא בעבור השירות בהתאם לחבילה שבחרתם ובכפוף לתנאי השימוש ותופסק הפעילות באופן מיידי בתום ניצול החבילה.</b><b>
// </b>`,
//             },
//             {
//               q: `האם ניתן לשלם במזומן או דרך אפליקצית ביט/פייבוקס?`,
//               a: `<b><span style="font-weight: 400;">כרגע לא ניתן לשלם בדרכים אלו, ניתן לשלם באמצעות פלטפורמת </span>"פייפל - PayPal"<span style="font-weight: 400;"> הידועה. התשלום הוא מאובטח ביותר ואנו מכבדים את כל סוגי האשראי.</span><span style="font-weight: 400;">
// </span><span style="font-weight: 400;">למידע נוסף ומורחב מוזמנים לקרוא את התקנון המלא של האתר/תוכנה.</span></b>`,
//             },
//           ]
//         },
//         {
//           title: 'יצירת קשר',
//           questions: [
//             {
//               q: `איך אני יכול ליצור אתכם קשר?`,
//               a: `<span style="font-weight: 400;">על מנת ליצור איתנו קשר יש לפתוח פנייה ב</span><b>טופס יצירת הקשר</b><span style="font-weight: 400;"> שבאתר ולבחור את סיבת הפנייה מתוך רשימת הסיבות הקיימות.</span>`,
//             },
//             {
//               q: `האם אפשר לפנות אליכם במסנג'ר/ווצאפ?`,
//               a: `בקרוב…`,
//             },
//             {
//               q: `האם יש לכם אפליקציה?`,
//               a: `<span style="font-weight: 400;">כרגע אין אפליקציה, וגם אין אפשרות להשתמש בפלטפורמה דרך הטלפון, אלא רק דרך המחשב.</span><span style="font-weight: 400;">
// </span><span style="font-weight: 400;">כשהיה אפליקציה בעתיד נדאג לעדכן.</span>`,
//             },
//           ]
//         },
//         {
//           title: 'התקנת התוכנה',
//           questions: [
//             {
//               q: `האם צריך להוריד את התוכנה על המחשב?`,
//               a: `<span style="font-weight: 400;">כן בוודאי, ישנו קובץ שצריך להתקין על המחשב על מנת להשלים את ההתקנה, התוכנה פועלת על המחשב שלכם ברקע, ואת כל הפעולות והפוסטים אנו נתזמן דרך האתר - FBZipper.com.</span>`,
//             },
//             {
//               q: `האם התוכנה תפריע לי להשתמש במחשב שלי?`,
//               a: `<span style="font-weight: 400;">- כמובן שלא, התוכנה עובדת ברקע וכל הפעולות מתבצעות מאחורי הקלעים ואתם לא תרגישו אותה כלל. אל דאגה תוכלו להמשיך להשתמש במחשב כרגיל.</span>`,
//             },
//             {
//               q: `האם התוכנה מתאימה לכל מחשב?`,
//               a: `<span style="font-weight: 400;">התוכנה מתאימה לכל מחשב נייח/נייד שמערכת ההפעלה שלו מבוססת ווינדוס - Windows.</span><span style="font-weight: 400;">
// </span><span style="font-weight: 400;">למען הסר ספק, התוכנה מתאימה לכל גרסאות הווינדוס - Windows </span><span style="font-weight: 400;">
// </span><span style="font-weight: 400;">לדוגמא - (Windows 7 / 8 / 10, Windows 11, Windows XP).</span>`,
//             },
//             {
//               q: `האם אני יכול להתקין את התוכנה על מחשב Mac של אפל?`,
//               a: `<span style="font-weight: 400;">כרגע אין לנו גרסה שתומכת במערכת הפעלה זו, בעתיד יהיה מוזמנים לעקוב ולהתעדכן.</span>`,
//             },
//             {
//               q: `איך מתקינים את התוכנה על המחשב?`,
//               a: `<span style="font-weight: 400;">מאחר ויש לנו חשבון, ואנחנו מחוברים לאתר - FBZipper.com ויש לנו חבילת שירות פעילה אפשר להמשיך ולהתקין את התוכנה על המחשב.</span>

// <b>שלב 1</b><span style="font-weight: 400;"> - לוחצים על כפתור ההגדרות (כפתור שנראה כמו "מפתח ברגים" שנמצא בין המדריכים לסימן שאלה בצד ימין למעלה).</span>

// <b>שלב 2</b><span style="font-weight: 400;"> - יפתח לנו חלון עם מספר אופציות: אופציה של שומר שבת לשומרי שבת במידה ולא מעוניינים שיעלו פוסטים בשבת אנחנו נלחץ על הכפתור שישתנה לצבע האדום ונלחץ על השמירה. מתחתיו מופיע לנו המספר סידורי שלנו שאותו אנחנו נעתיק ונחזור אליו בקרוב..</span>

// <b>שלב 3</b><span style="font-weight: 400;"> - באותו החלון מופיע כפתור ההורדה אנחנו לוחצים על כפתור ההורדה וברגעים אלו התוכנה יורדת למחשב (יש אפשרות שהאנטי וירוס ישלח לכם הודעה במצב כזה לוחצים על מידע נוסף או more info באנגלית) ואז יפתח אופציה של "התקן בכל מקרה" ולוחצים על ההתקן בכל מקרה.</span>

// <span style="font-weight: 400;">בזמן ההתקנה אמור לעלות לנו מסך ירוק שרץ כמו סנייק זה אומר שהכל תקין והתוכנה ברגעים אלה מותקנת על המחשב ובעוד כחצי דקה יופיע לנו מסך הדשבורד של התוכנה. (כמו בתמונה המצורפת).</span>

// <b>שלב 4</b><span style="font-weight: 400;"> - מדביקים את המפתח הסידורי שהעתקנו (מההגדרות שבאתר) במקום המיוחד לכך ולוחצים על כפתור </span><b>הפעל</b><span style="font-weight: 400;">. (כמו בתמונה המצורפת).</span>

// <b>שלב 5</b><span style="font-weight: 400;"> - </span><b>בדשבורד של התוכנה ממש מתחת לתיבה של המספר הסידורי יפתחו לנו 2 אופציות: </b><b>האחת - "משימות בו זמנית"</b><span style="font-weight: 400;"> - שזה אומר באיזה מהירות אתם מעוניינים שהתוכנה תעבוד. </span><b>בהתחלה בלבד!</b><span style="font-weight: 400;"> נגדיר על משימה אחת בו זמנית על מנת שנוכל לחבר את הפרופילים בבטחה ונלחץ </span><b>"התחל"</b><span style="font-weight: 400;">, ולאחר מכן, אחרי שהתחברנו לכל הפרופילים </span><b>(מידע נוסף בשדה "</b><b>הוספת פרופילים</b><b>"), </b><span style="font-weight: 400;">נעבור לדשבורד, נלחץ על כפתור </span><b>"הפסק"</b><span style="font-weight: 400;"> ונעלה את כמות המשימות בו זמנית לבין 3 - 5 משימות תלוי בחוזק המחשב ונפעיל בחזרה. (לא מומלץ לשים יותר מ-7 משימות בו זמנית).</span>

// <b>השנייה - "סטטוס"</b><span style="font-weight: 400;"> - הכוונה היא לסטטוס התוכנה האם היא פועלת או כבויה. </span><span style="font-weight: 400;">
// </span><span style="font-weight: 400;">בשדה הסטטוס אנו נלחץ על כפתור </span><b>"התחל"</b><span style="font-weight: 400;"> וברגע זה התוכנה פועלת או </span><b>"הפסק"</b><span style="font-weight: 400;"> על מנת להפסיק את התוכנה.</span>

// <b>חשוב מאוד!</b><span style="font-weight: 400;"> לוודא שבזמן שהתוכנה מעלה את הפוסטים שהמחשב יהיה דלוק עם חיבור לאינטרנט מהיר והתוכנה תהיה פתוחה ושתישאר דלוקה על המחשב.</span>

// <b>(מומלץ לשנות את הגדרות המחשב שלא יילך לישון כמו בתמונה המצורפת)</b>

// <span style="font-weight: 400;">לאחר מכן ניתן למזער את הדשבורד של התוכנה שתפעל ברקע וחוזרים לאתר console.fbzipper.com על מנת להמשיך את ההדרכה.</span><span style="font-weight: 400;">
// </span>`,
//             },
//           ]
//         },
//         {
//           title: 'הוספה / רכישת פרופילים',
//           questions: [
//             {
//               q: `האם ניתן להוסיף פרופילים אישיים לתוכנה?`,
//               a: `כמובן, ניתן להוסיף כל פרופיל.`,
//             },
//             {
//               q: `במידה ואין לי פרופילים, איך אני יכול לעבוד אתכם?`,
//               a: `<span style="font-weight: 400;">אנו מציעים פרופילים לרכישה, במידה ותרצו להשתמש בתוכנה ואין לכם פרופילים, תוכלו ליצור איתנו קשר דרך </span><b>טופס יצירת הקשר</b><span style="font-weight: 400;"> שבאתר / </span><span style="font-weight: 400;">בצ'אט מסנג'ר</span><span style="font-weight: 400;"> ונציגינו יפרטו על התהליך ויעזרו לכם לרכוש פרופילים.</span>`,
//             },
//             {
//               q: `עד כמה פרופילים אפשר להוסיף לתוכנה?`,
//               a: `<span style="font-weight: 400;">אנו לא מגבילים את כמות הפרופילים שניתן להוסיף לתוכנה, אך אנו לא ממליצים להכניס ולחבר יותר מ- 25 פרופילים במקביל.</span>`,
//             },
//             {
//               q: `כמה פוסטים כל פרופיל יכול לפרסם ביום?`,
//               a: `<b>כמה פוסטים כל פרופיל יכול לפרסם ביום?</b><span style="font-weight: 400;">
// </span><span style="font-weight: 400;">- במידה ומדובר בפרופילים אמיתיים ניתן לפרסם יותר פוסטים, אנו ממליצים לפרסם בין 25 - 30 פוסטים ביום מפרופיל אמיתי. במידה ומדובר בפרופיל פיקטיבי או כזה שנרכש דרכנו מומלץ לפרסם בין 5 - 10 פוסטים ביום לפחות בתחילת הפעילות. (ניתן להגביל את כמות הפוסטים לפי כל פרופיל כמו בתמונה המצורפת).</span>`,
//             },
//             {
//               q: `איך מוסיפים פרופילים לתוכנה?`,
//               a: `<span style="font-weight: 400;">מאחר ויש לנו חשבון, ואנחנו מחוברים לאתר - FBZipper.com, התקנו את התוכנה על המחשב, ויש לנו חבילת שירות פעילה אפשר להמשיך ולהוסיף פרופילים לתוכנה.</span><span style="font-weight: 400;">
// </span><b>שלב 1</b><span style="font-weight: 400;"> - </span><span style="font-weight: 400;">לוחצים על הכפתור </span><b>"הוסף פרופיל"</b><span style="font-weight: 400;"> שנמצא</span><span style="font-weight: 400;"> בסרגל העליון שבאתר מצד שמאל מתחת ללוגו הראשי של האתר ויפתח לנו החלון של הוספת פרופיל. (כמו בתמונה המצורפת).</span><span style="font-weight: 400;">
// </span><span style="font-weight: 400;">
// </span><b>שלב 2</b> <b>- נמלא את השדות הנדרשים:</b>

// <b>בשדה הראשון</b><span style="font-weight: 400;"> אנו נזין את השם פרטי של חשבון הפייסבוק.</span>

// <b>בשדה השני</b><span style="font-weight: 400;"> אנו נזין את שם המשפחה שמופיע בחשבון הפייסבוק.</span>

// <b>בשדה השלישי</b><span style="font-weight: 400;"> אנו נזין את שם המשתמש שהוא בדרך כלל מספר הנייד / המייל שאתם מזינים על מנת להיכנס לפייסבוק שלכם.</span>

// <b>בשדה הרביעי</b><span style="font-weight: 400;"> מזינים את סיסמת הפייסבוק של הפרופיל (חשוב לכתוב את הסיסמא במדויק - אות גדולה / קטנה וכו'...).</span>

// <b>בשדה החמישי</b><span style="font-weight: 400;"> מופיע ה- </span><b>seed</b><span style="font-weight: 400;"> שזה אומר שאם הפעלתם בהגדרות הפרופיל בפייסבוק אימות דו שלבי על החשבון הזינו אותו כאן. (אם אין לכם אימות דו שלבי ניתן לדלג על שלב זה ולהשאיר את השדה ריק).</span>

// <b>בשדה השישי</b><span style="font-weight: 400;"> מופיע הפרוקסי (</span><b>proxy</b><span style="font-weight: 400;">) (מדלגים על שלב זה השאירו את השדה ריק).</span>

// <b>מתחת לשדות יופיעו לנו 2 כפתורים שונים:</b>

// <span style="font-weight: 400;">כפתור </span><b>Allow Comment</b><span style="font-weight: 400;"> שזה אומר האם אתם מעוניינים שהפרופיל גם יגיב על הפוסטים.</span>

// <span style="font-weight: 400;">כפתור </span><b>Allow Publish</b><span style="font-weight: 400;"> שזה אומר האם אתם מעוניינים שהפרופיל יפרסם פוסטים. (במידה והפעלתם את הכפתור הזה יפתח לכם אופציה להחליט כמה פוסטים יפרסם אותו הפרופיל בכל יום).</span>

// <span style="font-weight: 400;">לאחר מכן לוחצים על כפתור ה- </span><b>"צור"</b><span style="font-weight: 400;"> ולמעלה התווסף לנו פרופיל חדש בעיגול (כרגע בלי תמונה).</span><span style="font-weight: 400;">
// </span><span style="color: #ff0000;"><b>שימו לב! הפרופיל עדיין לא מחובר, את זה נלמד לעשות בשאלה הבאה.</b></span>`,
//             },
//             {
//               q: `איך מחברים את הפרופיל?`,
//               a: `<span style="font-weight: 400;">על מנת לחבר את הפרופיל, אנו נלחץ על הפרופיל שנוצר לנו בתוכנה (זה שבלי תמונה כרגע) ויפתח לנו חלון חדש שבו נראה מספר אופציות. לאחר מכן נלחץ על כפתור ה- </span><b>Login</b><span style="font-weight: 400;"> (הירוק) על מנת לחבר את הפרופיל למערכת (אין צורך לחכות עד שהפרופיל יתחבר אפשר לסגור את הדף בזמן שהוא טוען ולהמשיך לעשות פעולות כרגיל) ולאחר דקה בערך נרענן את הדף ונוכל לראות שהפרופיל מחובר. אם מופיע לכם תמונה על הפרופיל ובלי סימני התראה מיוחדים אז הפרופיל מחובר.</span>

// <span style="font-weight: 400;">אם מופיע לכם סימן של </span><b>פעמון</b><span style="font-weight: 400;"> על הפרופיל זה אומר שהזנתם פרטים שגויים, זה יכול להיות הסיסמא של הפייסבוק או מייל / נייד לא תקינים. במצב כזה יש לעבור על הפרטים לתקן ולאחר מכן ללחוץ על כפתור ה- </span><b>Update</b><span style="font-weight: 400;"> ולאחר מכן לפתוח את החלון שוב וללחוץ על כפתור ה- </span><b>Login</b><span style="font-weight: 400;">.</span>

// <span style="font-weight: 400;">אם מופיע לכם סימן של </span><b>מנעול</b><span style="font-weight: 400;"> על הפרופיל (מאוד נדיר), צריך להתחבר לאותו פרופיל פייסבוק מהנייד / המחשב ולעקוב אחר ההוראות ולאשר התחברות ממקום שונה / להחליף סיסמא.</span>

// <b>חשוב לשים לב!</b><span style="font-weight: 400;"> בכל פעם שאנחנו מחליפים סיסמא לפרופיל בפייסבוק, אנו נחזור לתוכנה ונעדכן גם שם את הסיסמא לאותו הפרופיל ונלחץ על כפתור ה- </span><b>Update</b><span style="font-weight: 400;"> (הלבן) לאחר מכן נחזור שוב לפרופיל ונלחץ על כפתור ה- </span><b>Login </b><span style="font-weight: 400;">(הירוק) ונתחבר מחדש.</span>

// <b>לאחר ההתחברות התוכנה שואבת באופן אוטומטי את כל הקבוצות שאותו פרופיל נמצא בהם.</b><span style="font-weight: 400;">
// </span>`,
//             }
//           ]
//         },
//         {
//           title: 'הוספת קבוצות',
//           questions: [
//             {
//               q: `איך מוסיפים קבוצות לתוכנה?`,
//               a: `<span style="font-weight: 400;">- מאחר ויש לנו חשבון, ואנחנו מחוברים לאתר - FBZipper.com, התקנו את התוכנה על המחשב, הוספנו פרופילים, ויש לנו חבילת שירות פעילה אפשר להמשיך ולהוסיף קבוצות לתוכנה.</span><span style="font-weight: 400;">
// </span><b>שלב 1</b><span style="font-weight: 400;"> - לוחצים על </span><span style="font-weight: 400;">הכפתור </span><b>"נהל קבוצות" </b><span style="font-weight: 400;">שנמצא בסרגל הימני של האתר בתוך עיגול גדול,</span><span style="font-weight: 400;"> אחרי שנלחץ עליו יפתח לנו חלון, נמתין מספר שניות עד שיטענו כלל הקבוצות. (כמו בתמונה המצורפת).</span>

// <b>שלב 2</b><span style="font-weight: 400;"> - בחלון שנפתח לפניכם מוצג מאגר ענק ומגוון של קבוצות מכלל הנושאים שיש בארץ ובעולם ביניהם גם הקבוצות שהפרופילים שלכם נמצאים בהן.</span><span style="font-weight: 400;">
// </span><b>ניתן למיין את הקבוצות על פי מבחר אופציות שונות כגון:</b> <b>"עוקבים"</b><span style="font-weight: 400;"> - גודל הקבוצה (מהגדול לקטן), </span><b>"כמות פרופילים בקבוצה"</b><span style="font-weight: 400;"> (תראו תחילה קבוצות שיש בהן הכי הרבה פרופילים מצורפים), </span><b>"כמות תזמונים היום"</b><span style="font-weight: 400;"> - סינון זה רלוונטי לשלב מתקדם יותר (תראו תחילה קבוצות שתוזמנו בהן הכי הרבה פוסטים באותו היום).</span><span style="font-weight: 400;">
// </span><span style="font-weight: 400;">בנוסף, ניתן למיין את הקבוצות </span><b>על פי פרופיל מסוים</b><span style="font-weight: 400;"> (כלומר, במידה ובחרתם פרופיל אז יוצגו רק הקבוצות שאותו פרופיל נמצא בהן).</span><span style="font-weight: 400;">
// </span><b>ניתן גם לחפש קבוצות מסוימות דרך שורת החיפוש בהתאם לתחום שלכם ועל פי מילות מפתח שונות, לדוגמא: דרושים / רכב / נדל"ן / מכירה / השכרה / באר שבע / תל אביב / נופש וכו'...</b>

// <span style="font-weight: 400;">נבחר את הקבוצות שאנו מעוניינים לפרסם בהן ונוסיף אותם לרשימת הקבוצות שלנו בתוכנה. </span><span style="font-weight: 400;">
// </span><span style="font-weight: 400;">על ידי לחיצה על הכפתור </span><b>"סמן"</b><span style="font-weight: 400;"> שנמצא מתחת לכל קבוצה, אנו נוסיף את הקבוצות שבחרנו לרשימת הקבוצות שלנו לפרסום.</span><span style="font-weight: 400;">
// </span><span style="font-weight: 400;">במידה ואנו רוצים להסיר קבוצה שבחרנו מרשימת הקבוצות לפרסום נלחץ על הכפתור </span><b>"הורד"</b><span style="font-weight: 400;"> שנמצא מתחת לכל קבוצה שסימנו בעבר, והקבוצה תוסר מהרשימה.</span>

// <b>שלב 3</b><span style="font-weight: 400;"> - מאחר ובחרנו את הקבוצות שאנו מעוניינים לפרסם בהן, נצא ממנהל הקבוצות ונעשה רענון לדף הראשי והקבוצות יופיעו לנו מיד ברשימת גלילה מסודרת בסרגל שבצד ימין באתר.</span><span style="font-weight: 400;">
// </span><span style="font-weight: 400;">את הקבוצות ניתן למיין לפי מספר אופציות שונות, ומעל כל קבוצה נראה את כמות העוקבים שיש בה, כמות הפוסטים המתוזמנים באותו היום ומספר הפרופילים שיש לנו בכל קבוצה. </span>

// <b>*מאחר ובחרנו קבוצות שאנו רוצים לפרסם בהן, התוכנה תתחיל לצרף מיום למחרת באופן אוטומטי לחלוטין את הפרופילים שלנו לקבוצות שהם לא נמצאים בהן מתוך הרשימה שבחרנו על מנת להגדיל את החשיפה (כל פרופיל ינסה להצטרף מקסימום ל- 10 קבוצות ביום).*</b>`,
//             },
//           ]
//         },
//         {
//           title: 'הוספת קמפיין',
//           questions: [
//             {
//               q: `איך מייצרים קמפיין בתוכנה?`,
//               a: `<span style="font-weight: 400;">- מאחר ויש לנו חשבון, ואנחנו מחוברים לאתר - FBZipper.com, התקנו את התוכנה על המחשב, הוספנו פרופילים, בחרנו קבוצות לפרסום, ויש לנו חבילת שירות פעילה אפשר להמשיך ולהוסיף קמפיינים בתוכנה.</span><span style="font-weight: 400;">
// </span><b>שלב 1</b><span style="font-weight: 400;"> - לוחצים על</span><span style="font-weight: 400;"> הכפתור </span><b>"הוסף קמפיין"</b><span style="font-weight: 400;"> שנמצא</span><span style="font-weight: 400;"> בסרגל העליון שבאתר מצד שמאל מתחת לכפתור של "הוספת פרופילים" ויפתח לנו החלון של הוספת קמפיין. (כמו בתמונה המצורפת).</span><span style="font-weight: 400;">
// </span><span style="font-weight: 400;">
// </span><b>שלב 2</b> <b>- נמלא את השדות הנדרשים:</b>

// <b>בשדה הראשון</b><span style="font-weight: 400;"> אנו נזין את שם הקמפיין. לדוגמא: רכבים/נדל"ן/החזרי מס.</span>

// <b>בשדה השני</b><span style="font-weight: 400;"> אנו נבחר צבע לקמפיין (לא משנה איזה, זה למען הנוחות).</span>

// <b>בשדה השלישי</b><span style="font-weight: 400;"> מתחת לצבע, מופיע שם משתמש - שדה זה נועד בשביל לשתף את הקמפיין עם משתמשים אחרים בתוכנה (אפשר לדלג עליו).</span>

// <b>מתחת לשדות יופיעו לנו 2 כפתורים שונים:</b>

// <span style="font-weight: 400;">כפתור </span><b>Immediate Pump</b><span style="font-weight: 400;"> - שזה אומר האם אתם מעוניינים שבפוסטים שבקמפיין זה כל התגובות יהיו באופן מיידי. במידה והכפתור דלוק אז כל התגובות יינתנו באופן מיידי ובמידה והכפתור אינו דלוק </span><b>יפתחו לכם מספר אופציות נוספות להגדרה:</b><b>
// </b><span style="font-weight: 400;">
// </span><span style="font-weight: 400;">- </span><b>מקסימום תגובות</b><span style="font-weight: 400;"> - כלומר, כמה תגובות מקסימום תרצו לקבל על כל פוסט שבקמפיין זה.</span><span style="font-weight: 400;">
// </span><span style="font-weight: 400;">- </span><b>מקסימום פוסטים ביום</b><span style="font-weight: 400;"> - כמה פוסטים ביום תרצו במקסימום בקמפיין זה (ניתן להשאיר על 1000).</span><span style="font-weight: 400;">
// </span><span style="font-weight: 400;">- </span><b>מקסימום תגובות מיידיות</b><span style="font-weight: 400;"> - כלומר, כמה תגובות מיידיות תרצו לקבל כשפוסט מקמפיין זה מתפרסם בפייסבוק.</span><span style="font-weight: 400;">
// </span><span style="font-weight: 400;">- </span><b>משך חיי התגובות לפי שעות</b><span style="font-weight: 400;"> - כלומר, מרגע פרסום הפוסט, לכמה שעות לחלק את שאר התגובות שנותרו (אחרי שניתנו התגובות המיידיות). </span><span style="font-weight: 400;">
// </span><b>לדוגמא:</b><span style="font-weight: 400;"> אם יש לי 10 תגובות (פרופילים) וביקשנו שהמערכת תגיב בדקות הראשונות 4 תגובות באופן מיידי, אז נשאר לנו 6 תגובות שאותם נחלק למספר השעות שאנחנו מעוניינים שהמערכת תגיב בהמשך.</span>

// <span style="font-weight: 400;">כפתור </span><b>Enabled</b><span style="font-weight: 400;"> - שזה אומר האם אתם מעוניינים שהקמפיין יהיה פעיל.</span><span style="font-weight: 400;">
// </span><span style="font-weight: 400;">במידה והכפתור דלוק אז הקמפיין פעיל, כפתור זה נועד בשביל שתוכלו לבטל את הקמפיין במקום למחוק אותו.</span><span style="font-weight: 400;">
// </span><span style="font-weight: 400;">לאחר מכן לוחצים על כפתור ה- </span><b>"צור"</b><span style="font-weight: 400;"> ויפתח לנו הקמפיין החדש.</span><span style="font-weight: 400;">
// </span>`,
//             },
//           ]
//         },
//         {
//           title: 'הוספת פוסט לקמפיין',
//           questions: [
//             {
//               q: `איך מוסיפים פוסט לקמפיין?`,
//               a: `<span style="font-weight: 400;">- מאחר ויש לנו חשבון, ואנחנו מחוברים לאתר - FBZipper.com, התקנו את התוכנה על המחשב, הוספנו פרופילים, בחרנו קבוצות לפרסום, יצרנו קמפיין, ויש לנו חבילת שירות פעילה אפשר להמשיך ולהוסיף פוסטים לקמפיין בתוכנה.</span><span style="font-weight: 400;">
// </span><b>שלב 1</b><span style="font-weight: 400;"> - בוחרים קמפיין שאליו אנו רוצים לשייך פוסטים, לוחצים על הקמפיין ומיד לאחר מכן נלחץ על הכפתור </span><b>"הוסף פוסט חדש"</b><span style="font-weight: 400;"> שנמצא בסרגל השמאלי באתר בתוך ריבוע, ויפתח לנו החלון של הוספת פוסט. (כמו בתמונה המצורפת).</span><span style="font-weight: 400;">
// </span><span style="font-weight: 400;">
// </span><b>שלב 2</b> <b>- בחלון שנפתח לנו נבחר בלחיצה את סוג הפוסט שאנו מעוניינים לפרסם מתוך הרשימה שמופיעה (תמונה/אלבום/סרטון/רקע+טקסט ועוד…) ונמלא את השדות הנדרשים:</b>

// <b>בשדה הראשון</b><span style="font-weight: 400;"> אנו נזין את תוכן הפוסט. כלומר, את המלל (התוכן שאתם מעוניינים שיופיע מעל הסרטון/התמונה או על רקע צבעוני של פייסבוק).</span>

// <b>בשדה השני</b><span style="font-weight: 400;"> אנו נגדיר את התגובות שאנחנו רוצים לקבל על אותו הפוסט - בלחיצה על הכפתור </span><b>"הוסף תגובה"</b><span style="font-weight: 400;"> (כפתור בצבע ירוק) יפתח לנו תיבה שבה נצטרך להזין את התגובה שנרצה על אותו הפוסט, </span><b>לדוגמא:</b><span style="font-weight: 400;"> אשמח לפרטים נוספים / מספר 1 בתחום / מה המחיר? / מומלץ בחום / אני וכו'...</span><span style="font-weight: 400;">
// </span><span style="font-weight: 400;">בכל פעם שנרצה להוסיף תגובה נוספת נלחץ שוב פעם על הכפתור </span><b>"הוסף תגובה" </b><span style="font-weight: 400;">ונזין את התגובה הרצויה (בכל שדה נכניס תגובה אחת בלבד).</span><span style="font-weight: 400;">
// </span><b>בשדה התגובות יופיעו לנו מספר אופציות נוספות:</b>

// <span style="font-weight: 400;">- </span><b>"הוסף תשובה"</b><span style="font-weight: 400;"> - כלומר, "תגובה בתוך תגובה", בלחיצה על הכפתור הזה יפתח לנו שדה נוסף בו נוכל להזין תגובה שתהיה בעצם תשובה על התגובה הקודמת. </span><span style="font-weight: 400;">
// </span><b>לדוגמא:</b><span style="font-weight: 400;"> אם רשמתי בתגובה הראשית "מומלץ בחום" אז יש לנו אפשרות להוסיף תשובה על התגובה הנ"ל כגון - "מצטרפת להמלצה" וכו'... (התשובה תינתן מפרופיל שונה כמובן).</span>

// <span style="font-weight: 400;">- </span><b>"מחיקה"</b><span style="font-weight: 400;"> - כפתור זה נועד כדי למחוק תגובה מסוימת מהפוסט.</span>

// <span style="font-weight: 400;">- </span><b>"הוספת רגשונים"</b><span style="font-weight: 400;"> - לצד כל שדה של התגובות יופיעו הרגשונים / האימוג'ים. </span><span style="font-weight: 400;">
// </span><span style="font-weight: 400;">ניתן לבחור ולהוסיף לכל תגובה רגשונים / אימוג'ים שונים (לבבות, סמיילי צוחק, סמיילי עצוב וכו'...).</span><span style="font-weight: 400;">
// </span><span style="font-weight: 400;">בלחיצה על האימוג'י באמצעות הלחצן השמאלי של העכבר אנו נגדיר את הכמות שנרצה מאותו האימוג'י על אותה התגובה (אפשר יותר מאחד).</span><span style="font-weight: 400;">
// </span><span style="font-weight: 400;">במידה ונרצה להפחית את הכמות או לבטל את האימוג'י נלחץ על אותו האימוג'י באמצעות הלחצן הימני של העכבר עד שיופיע המספר 0 מתחת לאותו אימוג'י.</span>

// <span style="font-weight: 400;">- </span><b>"תיוג פרופילים"</b><span style="font-weight: 400;"> - מתחת לכל תגובה בשדה התגובות ישנה האופציה לתייג פרופילים בתגובה.</span><span style="font-weight: 400;">
// </span><span style="font-weight: 400;">על מנת לתייג פרופיל בתגובה </span><b>נצטרך לבצע את השלבים הבאים:</b><span style="font-weight: 400;">
// </span><span style="font-weight: 400;">במידה ואנו רוצים להוסיף תיוג של פרופיל רנדומלי מתוך רשימת הפרופילים שיש לנו, נעתיק את השורה הבאה - @[[RANDOM_PROFILE]] - נזין אותה בשדה התגובות והתוכנה תתייג את אחד הפרופילים בפוסט הזה.</span><span style="font-weight: 400;">
// </span><span style="font-weight: 400;">במידה ואנו רוצים להוסיף תיוג של פרופיל ספציפי, נלך לאותו פרופיל בתוכנה נלחץ עליו ויפתח לנו חלון שבו מספר פרטים ושדות. באחת השדות יופיע לנו ה- </span><b>Tag Code</b><span style="font-weight: 400;"> -</span><span style="font-weight: 400;"> של הפרופיל שזה בעצם הקוד בשביל לתייג אותו, שאותו אנחנו מעתיקים ומוסיפים לשדה של התגובה הרצויה. </span>

// <b>בשדה השלישי</b><span style="font-weight: 400;"> אנו נגדיר "טקסט תגובה" - כלומר, איזה תגובה אני מעוניין לתת לכל הלקוחות שיגיבו על הפוסט. התוכנה תתייג את כל מי שיגיב על הפוסט ותשלח לו את מה שנכתוב ונגדיר מראש בשדה זה. </span><span style="font-weight: 400;">
// </span><b>לדוגמא:</b><span style="font-weight: 400;"> קישור לדף נחיתה / לווצאפ / מספר נייד להשארת פרטים ועוד… </span><span style="font-weight: 400;">
// </span><b>דוגמא נוספת:</b><span style="font-weight: 400;"> "היי בשמחה, צירפתי קישור לווצאפ, לאתר, מספר נייד ועוד…"</span>

// <b>בשדה הרביעי</b><span style="font-weight: 400;"> אנו נגדיר את "אחוז השיתוף" - כלומר, כמה שיתופים אנחנו מעוניינים לקבל על אותו הפוסט באחוזים. לא מומלץ יותר מ - 15% כי זה לא יראה אמין, אלה אם כן זה פוסט ויראלי שאמור לקבל הרבה שיתופים (במידה ולא תגדירו כלום בשדה זה, התוכנה תבחר באופן אוטומטי ב- 15%).</span>

// <span style="font-weight: 400;">לאחר שהגדרנו את הכל נלחץ על כפתור ה- </span><b>"צור"</b><span style="font-weight: 400;"> והפוסט שיצרנו יתווסף לרשימת הפוסטים של אותו הקמפיין בסרגל השמאלי. (תמיד ניתן לחזור ולשנות את הפוסט / להוסיף פוסטים חדשים).</span><span style="font-weight: 400;">
// </span>`,
//             },
//           ]
//         },
//         {
//           title: 'תזמון ופרסום פוסטים',
//           questions: [
//             {
//               q: `איך מפרסמים פוסטים בתוכנה?`,
//               a: `<span style="font-weight: 400;">מאחר ויש לנו חשבון, ואנחנו מחוברים לאתר - FBZipper.com, התקנו את התוכנה על המחשב, הוספנו פרופילים, בחרנו קבוצות לפרסום, יצרנו קמפיין, הוספנו פוסטים ויש לנו חבילת שירות פעילה אפשר להמשיך ולתזמן פוסטים בתוכנה.</span><span style="font-weight: 400;">
// </span><b>שלב 1</b><span style="font-weight: 400;"> - בוחרים בלחיצה את הקבוצה שבה אנו רוצים לפרסם (אפשר להיעזר בחיפוש).</span><span style="font-weight: 400;">
// </span><b>שלב 2</b><span style="font-weight: 400;"> - בוחרים בלחיצה את הפרופיל שממנו אנחנו מעוניינים שיעלה הפוסט / סרטון.</span><span style="font-weight: 400;">
// </span><b>שלב 3</b><span style="font-weight: 400;"> - לוחצים על הקמפיין שאנחנו מעוניינים לפרסם (ומיד יופיעו הפוסטים שבקמפיין).</span><span style="font-weight: 400;">
// </span><b>שלב 4</b><span style="font-weight: 400;"> - גוררים / מושכים את הפוסט הרצוי מהקמפיין לשעה שאנחנו מעוניינים שהפוסט יעלה (כמו בתמונה המצורפת).</span><span style="font-weight: 400;">
// </span><b>שלב 5</b><span style="font-weight: 400;"> - אחרי ששמנו את הפוסט בשעה הרצויה יופיע לנו סימון של עיגול שקוף באותה שעה עם תמונת הפרופיל של המפרסם, ברגע שהפוסט יעלה הוא יהפוך מצבע בהיר (שקוף) למודגש.</span><span style="font-weight: 400;">
// </span><b>ניתן ללחוץ על העיגול המודגש ואז יפתחו לנו מספר סימנים: מסך / מפתח ברגים / פח זבל. </b><b>
// </b><span style="font-weight: 400;">במידה ונלחץ על ה</span><b>"מסך"</b><span style="font-weight: 400;"> הקטן זה ישלח אותנו לפוסט שהתפרסם בתוך הקבוצה עצמה בפייסבוק. במידה ונלחץ על ה</span><b>"מפתח ברגים"</b><span style="font-weight: 400;"> יפתח לנו חלון שבו אנחנו יכולים לראות יותר נתונים על הפוסט כמו - סוג הפוסט, מתי הפוסט עלה, מאיזה פרופיל, באיזה קבוצה ועוד… ולבצע פעולות נוספות.</span><span style="font-weight: 400;">
// </span><span style="font-weight: 400;">במידה ונלחץ על</span><span style="font-weight: 400;"> ה</span><b>"פח"</b><span style="font-weight: 400;"> זה ימחק את הפוסט מהתוכנה.</span><span style="font-weight: 400;">
// </span><span style="font-weight: 400;">(בקבוצות מסוימות אם אנחנו לא מחוברים לפייסבוק מהמחשב או שאין לנו את הקבוצה בפרופיל אז לא נוכל לראות את הפוסט דרך המסך).</span>`,
//             },
//             {
//               q: `האם יש אפשרות שלא יתפרסמו לי פוסטים ביום שבת?`,
//               a: `בוודאי, לוחצים על כפתור ההגדרות (כפתור שנראה כמו "מפתח ברגים" שנמצא בין המדריכים לסימן שאלה בצד ימין בסרגל שלמעלה באתר). יפתח לנו חלון עם מספר אופציות: אופציה של שומר שבת לשומרי שבת במידה ולא מעוניינים שיעלו פוסטים בשבת אנחנו נלחץ על הכפתור שישתנה לצבע האדום ונלחץ על השמירה.`,
//             },
//           ]
//         },
//         {
//           title: 'הגדרת תזמון אוטומטי',
//           questions: [
//             {
//               q: `איך מגדירים תזמון אוטומטי קבוע של פוסטים?`,
//               a: `<span style="font-weight: 400;">מאחר ויש לנו חשבון, ואנחנו מחוברים לאתר - FBZipper.com, התקנו את התוכנה על המחשב, הוספנו פרופילים, בחרנו קבוצות לפרסום, יצרנו קמפיין, הוספנו פוסטים ויש לנו חבילת שירות פעילה אפשר להמשיך ולתזמן פוסטים בצורה אוטומטית וקבועה בתוכנה.</span><span style="font-weight: 400;">
// </span><b>שלב 1</b><span style="font-weight: 400;"> - לוחצים על הכפתור </span><b>"תזמון אוטומטי"</b><span style="font-weight: 400;"> שנמצא בסרגל העליון באתר.</span><span style="font-weight: 400;">
// </span><b>שלב 2</b><span style="font-weight: 400;"> - בטבלה שנפתחה בפנינו ישנם מספר שיטות לתזמון אוטומטי: תזמון יומי / שבועי / חודשי. אנו ממליצים לעבוד עם התזמון היומי, ניתן גם לשלב בין שיטות התזמון (כמו בתמונה המצורפת).</span><span style="font-weight: 400;">
// </span><b>שלב 3</b><span style="font-weight: 400;"> - במידה ואנו מעוניינים שהפוסט יעלה באותה קבוצה ובאותה שעה ומאותו פרופיל ולכל החיים אז אני פשוט יבחר קמפיין יבחר קבוצה ויבחר פרופיל שאני מעוניין לפרסם ממנו ומושך / גורר את הפוסט הרצוי בדיוק כמו בתזמון הרגיל.</span><span style="font-weight: 400;">
// </span><span style="font-weight: 400;">בנוסף, ישנה אופציה לפרסם גם בתזמון השבועי או בחודשי אם אנו רוצים פוסטים שונים בימים שונים או תאריכים מסוימים.</span>

// <span style="font-weight: 400;">יש אפשרות להקל עוד יותר על העבודה ולתזמן קמפיין שלם לצורך הדוגמא יש לכם 10 פוסטים בקמפיין ואתם מעוניינים לפרסם את כולם או חלקם, אז מה שנעשה אנחנו נמשוך / נגרור את כל הקמפיין לשעה שממנה אנחנו מעוניינים להתחיל לפרסם. (כמו בתמונה המצורפת).</span><span style="font-weight: 400;">
// </span><span style="font-weight: 400;">
// </span><span style="font-weight: 400;">לאחר מכן נלחץ על הקמפיין שתזמנו בלוח התזמונים, ויופיע לנו בקטן שתי סימונים: </span><b>"מסך"</b><span style="font-weight: 400;"> ו</span><b>"פח"</b><span style="font-weight: 400;">, נלחץ על המסך ויפתח לנו חלון של תזמון קמפיין (כמו בתמונה המצורפת).</span>

// <span style="font-weight: 400;">יופיעו לנו הפרופילים שלנו עם כפתור של </span><b>"סמן הכל"</b><span style="font-weight: 400;"> (הפרופילים שנסמן אלו הפרופילים שאנו רוצים שיפרסמו עבורנו את הפוסטים באותה הקבוצה, התוכנה תבחר באופן רנדומלי מי יפרסם כל פוסט).</span>

// <b>מתחת לפרופילים יופיעו לנו 3 שדות נוספים שעלינו למלא:</b>

// <b>בשדה הראשון</b><span style="font-weight: 400;"> אנו נגדיר "מקסימום פוסטים בקמפיין" - כלומר, כמה פוסטים בסך הכל אני מעוניין שיעלו לי באותו יום בקבוצה מתוך הקמפיין (רנדומלי). </span><span style="font-weight: 400;">
// </span><b>לדוגמא:</b><span style="font-weight: 400;"> אם אני מעוניין שכל היום יעלו בסך הכל 5 פוסטים בקבוצה שנבחרה מאותו הקמפיין אז אני יסמן 5 ואם אני מעוניין 8 אז אני ילחץ על 8 וכן הלאה..</span>

// <b>בשדה השני</b><span style="font-weight: 400;"> אנו נגדיר "מקסימום פוסטים בשעה" - כלומר, כמה פוסטים אנו מעוניינים לתזמן בכל שעה. </span><b>לצורך הדוגמא:</b><span style="font-weight: 400;"> אם אתם מעוניינים שיעלה לכם 2 פוסטים בשעה באותה קבוצה אז נסמן 2 ואם אנחנו מעוניינים בפוסט 1 לשעה (הכי מומלץ) אז נסמן 1.</span>

// <b>בשדה השלישי</b><span style="font-weight: 400;"> אנו נגדיר "כמות דילוג בשעות" - כלומר, כמה אתם מעוניינים לדלג (בשעות) בין זמני הפרסום (בין הפרסום הראשון לשני באותה הקבוצה). </span><span style="font-weight: 400;">
// </span><b>לצורך הדוגמא:</b><span style="font-weight: 400;"> אם תזמנו את תחילת הקמפיין לשעה 6 בבוקר ונגדיר דילוג של 1, אז בשעה 6 התוכנה תפרסם וב- 7 לא (היא תדלג) ובשעה 8 היא תמשיך לפרסם שוב וב- 9 לא וכן הלאה…</span><span style="font-weight: 400;">
// </span><span style="font-weight: 400;">אם נגדיר את הדילוג בין הפוסטים על 0 התוכנה תפרסם לנו בכל שעה ברצף ובלי עצירות.</span>

// <b>מתחת לשלושת השדות האלו יופיעו לנו מספר אופציות נוספות:</b>

// <span style="font-weight: 400;">- </span><b>"תזמון מחיקה"</b><span style="font-weight: 400;"> - שזה אומר שאם אתם מעוניינים שהפוסט בפייסבוק ימחק בשעה מסויימת.</span>

// <span style="font-weight: 400;">- </span><b>"תזמון הקפצה"</b><span style="font-weight: 400;"> - שזה אומר כל כמה זמן (בדקות) אתם מעוניינים להקפיץ את הפוסט מעבר לתגובות שיש לכם. (מומלץ לשים על 100 ומעלה או לא לגעת בכלל).</span>

// <span style="font-weight: 400;">- </span><b>"תזמון עריכה"</b><span style="font-weight: 400;"> - שזה אומר שיש אופציה לערוך את הפוסטים שבקמפיין ולשנות אותם כעבור זמן מסוים לפוסט אחר מאותו הסוג.</span><span style="font-weight: 400;">
// </span><span style="font-weight: 400;">לצורך העניין, אפשר להעלות פוסט ויראלי שיקבל חשיפה ואז לערוך אותו לפוסט שיווקי. </span><span style="font-weight: 400;">
// </span><b>לדוגמא:</b><span style="font-weight: 400;"> אפשר לפרסם פוסט כזה - "תנו לי שם של בת באות א", ואז להגדיר שהפוסט ישתנה אוטומטית אחרי 10 דקות לפוסט אחר מקמפיין אחר (יכול להיות פוסט שיווקי). </span><span style="font-weight: 400;">
// </span><b>במידה ונבחר באופציה הזאת נצטרך להגדיר 2 שדות נוספים:</b> <span style="font-weight: 400;">
// </span><span style="font-weight: 400;">האחת אחרי כמה זמן (בדקות) אנו רוצים שהתוכנה תערוך לנו את הפוסט. לדוגמא - אחרי 15 דקות.</span><span style="font-weight: 400;">
// </span><span style="font-weight: 400;">השניה "קמפיגן" - כלומר, לאיזה קמפיין אנחנו מעוניינים להחליף את הפוסט. בוחרים את הקמפיין והפוסט הרצוי מתוך הקמפיין.</span>

// <b>ונלחץ שמירה לאחר כל פעולה.</b>`,
//             },
//           ]
//         },
      ],
    }

    update_data(props) {

    }

    refresh() {

    }

    componentWillReceiveProps(props) {
    }
    
    componentWillMount(props) {
      var token = localStorage.getItem('user_token');

      fetch(`${process.env.NODE_ENV !== 'development' ? "" : ""}/faq-list?access_token=${token}`, {})
      .then(response => response.json())
      .then(data => {
        this.setState({
          sections: data,
        });
      })
      .catch(err => {

      });;


    }

    componentWillUnmount(){
    }
    getCurrentQuetion(sections){
      var question = sections.find((a) => {
        return a._id == this.props.match.params.rand1
      }) || sections[0];

      var answer = question.questions.find((a) => {
        return a._id == this.props.match.params.rand2
      }) || question.questions[0];

      return answer;
    }

    // 

    render() {
      const { t, i18n } = this.props;

      return (
        <React.Fragment>
      <div id="main-wrapper" className="page-wrapper faq">
        <div className="page-header section-padding section-padding-extra dc-two pb-0" style={{paddingTop: '0px'}}>
        </div>
      </div>

      <div className="section-padding light-bg">
        <div className="container">
          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="heading-wrapper with-separator">
                <h2 className="h1">{t('FAQ')}</h2>
              </div>
              {/* End Heading */}
            </div>{/* End Col */}
          </div>{/* End Row */}

          {this.state.sections.length ? <div className="row clearfix">
            <div className="col-lg-12">
              <div className="tab-wrapper vertical-tab">
                <div className="st-tab-btn">
                  <ul className="nav nav-tabs" role="tablist">
                    {this.state.sections.map((section, section_index) => <li className="nav-item">
                      <a onClick={this.props.updateSearch.bind(this, {path: [':lang', 'faq', section._id, section.questions[0]._id]})} className="nav-link" data-toggle="tab" role="tab" aria-selected="false">{section.title}</a>
                        <ul className={`collapse child-menu ${this.props.match.params.rand1 === section._id || (!this.props.match.params.rand1 && this.state.selected_question.section_index === section_index) ? 'show' : ''}`}>
                          {section.questions.map((question, question_index) => <li className="nav-item">
                            <a onClick={this.props.updateSearch.bind(this, {path: [':lang', 'faq', section._id, question._id]})} className={`nav-link ${this.props.match.params.rand2 == question._id || (!this.props.match.params.rand1 && section_index === this.state.selected_question.section_index && question_index === this.state.selected_question.question_index) ? 'active' : ''}`}>{question.q}</a>
                          </li>)}
                        </ul>
                    </li>)}
                  </ul>
                </div>
                <div className="st-tab-content vertical-tab-content">     
                  <div className="tab-content">
                    <div className="tab-pane active" id="EasyCustomize" role="tabpanel">
                      <div className="text-wrapper">
                        <h4>{this.getCurrentQuetion(this.state.sections).q}</h4>
                        <div dangerouslySetInnerHTML={{__html: this.getCurrentQuetion(this.state.sections).a}} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>{/* End Tabs */}
            </div>
          </div> : <div className="loader">
                  <svg width="300px" version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                    <circle fill="none" stroke="#fff" strokeWidth={4} cx={50} cy={50} r={44} style={{opacity: '0.5'}} />
                    <circle fill="#fff" stroke="#e74c3c" strokeWidth={3} cx={8} cy={54} r={6}>
                      <animateTransform attributeName="transform" dur="2s" type="rotate" from="0 50 48" to="360 50 52" repeatCount="indefinite" />
                    </circle>
                  </svg>
                 </div>}
        </div>
      </div>


      </React.Fragment>
      );
    }
}

Faq.propTypes = {};

Faq.defaultProps = {};

export default Faq;

