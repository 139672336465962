import React from 'react';
import { ChartDonut } from '@patternfly/react-charts';

import {Button} from 'react-bootstrap/Button';
import {DropdownButton, ButtonGroup, Dropdown} from 'react-bootstrap';
import moment from 'moment';

import { useTranslation, initReactI18next, withTranslation } from 'react-i18next';

import { fasAngleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {NotificationContainer, NotificationManager} from 'react-notifications';
import { Puff } from 'react-loading-icons'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  withRouter,
  useLocation
} from "react-router-dom";

import { FaLightbulb } from "react-icons/fa";

import queryString from 'query-string';

class Plans extends React.Component {
    state = {
      plans: [],
      new_order: {
      },
      // payment_url: `https://app.upay.co.il/BANKRESOURCES/UPAY/redirectpages/dWJQb3Q1OWNETFpWdWdZVnlEUU9aMmM4Z0kzbHdVUTRPV0JMUXFaTXFzRT0equal.html`,
    }

    update_data(props) {

    }

    refresh() {

    }

    componentWillReceiveProps(props) {
      this.setState({payment_url:''});

      if (props.user && !this.state.new_order.first_name) {
        this.setState({new_order:props.user});
      }
    }
    
    componentWillMount(props) {
      this.getPlans();
    }

    componentWillUnmount(){
    }

    startPlan(plan_id) {
      var token = localStorage.getItem('user_token');
      var payment_url = `${process.env.NODE_ENV !== 'development' ? "/" : "http://localhost:8888/"}payment/test?access_token=${token}&plan=${plan_id}`;
      // console.log(payment_url);
      fetch(payment_url, {})
      .then(response => response.json())
      .then(data => {
        window.location.replace(data.approval_url);
        // this.setState({
        //   plans: data,
        // });
      })
      .catch(err => {
        // alert(request_url)
      });


      
    }

    handleFreeTrial (){
      var token = localStorage.getItem('user_token');
      var request_url = `${process.env.NODE_ENV !== 'development' ? "/" : "http://localhost:8888/"}start-free-trial?access_token=${token}`;
      var query = queryString.parse(this.props.location.search);

      if (!this.state.new_order.first_name) {
        NotificationManager.error(this.props.t(`First Name required`));
        return;
      }
      if (!this.state.new_order.last_name) {
        NotificationManager.error(this.props.t(`Last Name required`));
        return;
      }
      if (!this.state.new_order.phone) {
        NotificationManager.error(this.props.t(`Phone required`));
        return;
      }
      if (!this.state.new_order.email) {
        NotificationManager.error(this.props.t(`Email required`));
        return;
      }

      // this.setState({payment_url: ''})
      this.setState({loading_payment_screen: true})

      fetch(request_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          new_order: this.state.new_order,
          user: this.props.user,
          sum: query.sum || undefined,
          payments: query.payments || undefined,
        })
      })
      .then(response => response.json())
      .then(data => {
        this.setState({payment_url: data.payment_url})
        this.setState({loading_payment_screen: false})

        if (data.token) {
          localStorage.setItem('user_token', data.token);
          this.props.checkLoggedIn();
          this.props.updateSearch({path: [':lang', 'console'], "fl": true, tutorial: '1', 'current-tutoral': 'add-profile', step: 0})
          
          NotificationManager.success(this.props.t(`Success register`));
        }

      })
      .catch(err => {
        NotificationManager.error(this.props.t(`error generating order`));
        this.setState({loading_payment_screen: false})
      });


    }
    handlePersonalDetails(plan_id){
      // this.state.new_order

      var token = localStorage.getItem('user_token');
      var request_url = `${process.env.NODE_ENV !== 'development' ? "/" : "http://localhost:8888/"}create-new-order?access_token=${token}`;
      var query = queryString.parse(this.props.location.search);

      if (!this.state.new_order.company_name) {
        NotificationManager.error(this.props.t(`Company name is required`));
        return;
      }

      this.setState({payment_url: ''})
      this.setState({loading_payment_screen: true})

      fetch(request_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          new_order: this.state.new_order,
          plan_id: plan_id,
          user: this.props.user,
          sum: query.sum || undefined,
          payments: query.payments || undefined,
        })
      })
      .then(response => response.json())
      .then(data => {
        this.setState({payment_url: data.payment_url})
        this.setState({loading_payment_screen: false})
        NotificationManager.success(this.props.t(`Success changed email`));
      })
      .catch(err => {
        NotificationManager.error(this.props.t(`error generating order`));
      });
    }
    getPlans(){
      var token = localStorage.getItem('user_token');
      var request_url = `${process.env.NODE_ENV !== 'development' ? "/" : "http://localhost:8888/"}payment/plans?access_token=${token}`;
      
      fetch(request_url, {})
      .then(response => response.json())
      .then(data => {
        this.setState({
          plans: data.filter((p) => p.daily_post_amount > 35),
        });
      })
      .catch(err => {
        // alert(request_url)
      });


      // window.location.replace(payment_url);
    }

    format(date) {
      return moment(date).format('DD/MM/YYYY')
    }

    render() {
      const { t, i18n } = this.props;
        return (
            <React.Fragment>
      {<div id="main-wrapper" className="page-wrapper">
        <div className="page-header section-padding style-dark full-height dc-two pb-0">
          <div className="container">

        <div className="pricing-section section-padding">
          <div className="container">

            <div className="row justify-content-center clearfix">



              <div className="col-lg-8">
                <div className="heading-wrapper text-center with-separator">
                  <h2 className="h1">{t("Our packages")}</h2>
                  <div className="lead-text">
                    <p>{t("We guarantee you the cheapest and most affordable service packages in the market!")}</p>
                  </div>
                  {this.props.user && !this.props.user.active_plan && <div className="lead-text">
                    <p>על מנת להמשיך יש לבחור חבילת שירות מתאימה, ניתן גם לבחור חבילה בהתאמה אישית, לחבילות בהתאמה אישית <a style={{color: '#06fd98'}} onClick={this.props.updateSearch.bind(this, {path: [':lang', 'support']})}>{t("Contact us")}</a> 🙂</p>
                  </div>}
                  {this.props.user && this.props.user.active_plan && <div className="lead-text">
                    <p>יש לכם חבילה פעילה, ניתן לשדרג אותה או להמשיך לעבוד עם התוכנה דרך <a style={{color: '#06fd98'}} onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console']})}>"ממשק התזמון"</a>. לחבילות בהתאמה אישית <a style={{color: '#06fd98'}} onClick={this.props.updateSearch.bind(this, {path: [':lang', 'support']})}>{t("Contact us")}</a> 🙂</p>
                  </div>}
                </div>


                {/*this.props.user && <div className="row">
                    <div className="col-md-12">
                        <iframe style={{width: '100%', height: '700px'}} src={this.props.user.payment_url}></iframe>
                    </div>
                </div>*/}





                {this.props.user && this.props.user.active_plan && <div class="alert alert-success" role="alert">
                  {t('You are subscribed')}! {t('Next billing period')}: {this.format(this.props.user.active_plan.valid_payment_until)}
                </div>}
                {this.props.user && this.props.user.upcoming_plan && this.props.user.active_plan && <div class="alert alert-warning" role="alert">
                  {t('Your plan will be changed')} on {moment(this.props.user.active_plan.valid_payment_until).format('dd-mm-yyyy')}
                </div>}
              </div>
            </div>
            <div className="row pricing-plans-two clearfix">
              

              {/*Basic plan*/}
              {/*<div className={`col-lg-4 col-12 active2 mx-auto`}>
                <div className="price-card text-center wow fadeInLeft">
                  <div className="plan-cost-wrapper">
                    <div className="plan-cost">
                      <span className="plan-cost-prefix">{'₪'}</span>{450}
                    </div>
                    <div className="plan-validity">עבור חודש</div>
                    <div className="plan-validity">לא כולל מע"מ</div>
                  </div>
                  <div className="card-header">
                    <h3 className="card-title">{'פרופיל אישי'}</h3>
                  </div>
                  <div className="card-body">
                    <div className="desc">
                      <p></p>
                    </div>
                    <ul className="pricing-feature-list text-left">
                      <li>בין {30}-{60} פוסטים ביום</li>
                      <li>תזמון אוטומטי מלא</li>
                      <li>תיוג והחזרת תגובה על הפוסטים</li>
                      
                      <li>הטמעת המערכת והתקנה ללא עלות</li>
                      <li>תמיכה טכנית ושירות לקוחות בעברית</li>
                      <li>מדריכים אינטראקטיביים בעברית לשימוש בתוכנה</li>
                    </ul>

                    {!this.props.user && <a onClick={this.props.updateSearch.bind(this, {path: [':lang', 'plans', 'start', 'free']})} className="btn btn-secondary">{t('Start Free 14 Day Trial')}</a>}
                  </div>
                </div>
              </div>*/}









              {this.state.plans.filter((plan) => plan.price >= 1000).map((plan, index) => (<div className={`col-lg-4 col-12 active2 mx-auto ${this.props.user && this.props.user.active_plan && this.props.user.active_plan.plan && this.props.user.active_plan && plan._id == this.props.user.active_plan.plan._id && 'active'}`}>
                <div className="price-card text-center wow fadeInLeft">
                  <div className="plan-cost-wrapper">
                    <div className="plan-cost">
                      <span className="plan-cost-prefix">{plan.currency === 'ILS' && '₪'}{plan.currency === 'USD' && '$'}</span>{plan.price}
                    </div>
                    <div className="plan-validity">{t("for a month")}</div>
                    {/*<div className="plan-validity">(~{parseFloat(plan.price / (plan.daily_post_amount * 30)).toFixed(2)} ש"ח לפוסט)</div>*/}
                    <div className="plan-validity">{t("Excluding VAT")}</div>
                  </div>
                  <div className="card-header">
                    <h3 className="card-title">{t(plan.name)}</h3>
                  </div>
                  <div className="card-body">
                    <div className="desc">
                      <p>{plan.description}</p>
                    </div>
                    <ul className="pricing-feature-list text-left">
{plan.price < 2001 && <span>חבילה זו תקפה למתווכים,<br/> חברות כ"א/השמה, סוכנויות רכב

ולכל מי שמעוניין לפרסם את העסק/מוצר/שירותים שלו.

ושנפרסם עבורו את התכנים שיש ברשותו בלבד.

חבילה זו ללא פוסטים סמויים וקריאייטיב.</span>}
                      <li>{t("Until")} {plan.daily_post_amount} {t("Posts per day")}</li>
                      <li>{t("Fully automatic timing")}</li>
                      <li>{t("Comments on the posts")}</li>
                      <li>{t("Tagging and returning a comment on the posts")}</li>
                      <li>{t("Until")} {plan.daily_post_amount * 30} {t("Posts per month")}</li>
                      {plan.daily_post_amount !== 40 && <li>{plan.daily_post_amount === 50 && Math.ceil(50/10) - 1}{plan.daily_post_amount === 200 && Math.ceil(200/10) + 2}{plan.daily_post_amount === 500 && Math.ceil(500/10) + 0}{plan.daily_post_amount === 120 && Math.ceil(100/10) + 3}{plan.daily_post_amount === 300 && Math.ceil(300/10) + 0}{plan.daily_post_amount === 700 && Math.ceil(700/10) + 0}{plan.daily_post_amount === 1000 && Math.ceil(1000/10) + 0}{plan.daily_post_amount === 2500 && Math.ceil(2500/10) + 0} {t("Profiles for use as a gift including comprehensive insurance")}</li>}
                      {plan.daily_post_amount !== 40 && <li>{t("Until")} {plan.daily_post_amount === 2500 && Math.ceil(2500/10) - 1}{plan.daily_post_amount === 50 && Math.ceil(50/10) - 2}{plan.daily_post_amount === 120 && Math.ceil(100/10) + 2}{plan.daily_post_amount === 200 && Math.ceil(200/10) + 1}{plan.daily_post_amount === 300 && Math.ceil(300/10) - 1}{plan.daily_post_amount === 700 && Math.ceil(700/10) - 1}{plan.daily_post_amount === 1000 && Math.ceil(1000/10) - 1}{plan.daily_post_amount === 500 && Math.ceil(500/10) - 1} {t("Comments on the posts")} (1 {t("Advertiser")} {plan.daily_post_amount === 50 && Math.ceil(50/10) - 2}{plan.daily_post_amount === 120 && Math.ceil(100/10) + 2}{plan.daily_post_amount === 200 && Math.ceil(200/10) + 1}{plan.daily_post_amount === 300 && Math.ceil(300/10) - 1}{plan.daily_post_amount === 700 && Math.ceil(700/10) - 1}{plan.daily_post_amount === 1000 && Math.ceil(1000/10) - 1}{plan.daily_post_amount === 500 && Math.ceil(500/10) - 1}{plan.daily_post_amount === 2500 && Math.ceil(2500/10) - 1} {t("commenters")})</li>}
                      {/*<li className="disabled">תוכנית שותפים</li>*/}
                      <li>{t("Personal Facebook profiles/users can be attached free of charge")}</li>
                      {plan.price > 1000 && <li>{plan.price === 10000 ? 10 : index+2} {t("Hours with a close campaigner who does all the work for you.")}</li>}
                      
                      {plan.price > 1000 && <li>{t("Including writing posts and creative")}</li>}
                      {plan.price > 1000 && <li>{t("Coordinated comments on posts")}</li>}
                      {plan.price > 1000 && <li>{t("Selecting relevant groups")}</li>}
                      {plan.price > 1000 && <li>{t("Uploading posts to groups")}</li>}
                      
                      <li>{t("Another week as a gift / organization time")}</li>
                      <li>{t("Implementation of the system and installation at no cost")}</li>
                      <li>{t("Technical support and customer service in Hebrew")}</li>
                      <li>{t("Interactive guides in Hebrew for using the software")}</li>
                    </ul>
                    {this.props.user && <a onClick={this.props.updateSearch.bind(this, {path: [':lang', 'plans', 'start', plan._id]})} className="btn btn-secondary">{t('Start Now')}</a>}
                    {!this.props.user && <a onClick={this.props.updateSearch.bind(this, {path: [':lang', 'plans', 'start', plan._id]})} className="btn btn-secondary">{t('Start Now')}</a>}
                    {this.props.user && this.props.user.active_plan && this.props.user.active_plan && this.props.user.active_plan.plan && plan._id !== this.props.user.active_plan.plan._id && (!this.props.user.upcoming_plan || (this.props.user.upcoming_plan && plan._id !== this.props.user.upcoming_plan.plan._id)) && <a onClick={this.startPlan.bind(this, plan._id)} className="btn btn-secondary">{t('Change Plan')}</a>}
                    
                    {this.props.user && this.props.user.active_plan && (this.props.user.upcoming_plan && plan._id === this.props.user.upcoming_plan.plan._id) && <a className="btn btn-warning">{t('Will change on')} ${this.props.user.active_plan.valid_payment_until}</a>}
                  </div>
                </div>
              </div>))}


              {/*<div className={`col-lg-3 col-12 active2 mx-auto`}>
                <div className="price-card text-center wow fadeInLeft">
                  <div className="card-header">
                    <h3 className="card-title">התאמה אישית</h3>
                  </div>
                  <div className="card-body">
                    <div className="desc">
                      <p>לחבילות בהתאמה אישית צרו איתנו קשר :)</p>
                    </div>
                    <ul className="pricing-feature-list">
                      <li>תזמון אוטומטי מלא</li>
                      <li>כולל תגובות על הפוסטים</li>
                      <li>כולל ותיוג והחזרת תגובה על הפוסטים</li>
                    </ul>                    
                    <a className="btn btn-warning" onClick={this.props.updateSearch.bind(this, {path: [':lang', 'support']})}>צור קשר</a>
                  </div>
                </div>
              </div>*/}

            </div>
          </div>
        </div>
          </div>
        </div>

      {this.props.match.params.rand2 && <div style={{display: 'block'}} id="myModal" className="modal">
        <div className="modal-content">
          <span className="close" onClick={this.props.updateSearch.bind(this, {path: [':lang', 'plans']}) }>×</span>

          {this.state.loading_payment_screen && <Puff stroke="#3446cd" fill="#3446cd" style={{
            fontSize: '70px',
            lineHeight: '78px',
            opacity: 1,
            width: '100%',
            height: '200px',
          }}/>}

          {!this.state.payment_url && !this.state.loading_payment_screen && <div>
          <p>{t('Personal Details')}</p>

                      
                        <div className="form-group">
                          <label>{t('First Name')}{this.props.match.params.rand2 === 'free' && <span style={{color: 'red'}}>*</span>}</label>
                          <input name="first_name" onChange={event => { this.state.new_order.first_name = event.target.value; this.setState({ new_order: this.state.new_order })}} value={this.state.new_order.first_name} type="text" className="form-control" placeholder={t('First Name')} />
                        </div>
                        <div className="form-group">
                          <label>{t('Last Name')}{this.props.match.params.rand2 === 'free' && <span style={{color: 'red'}}>*</span>}</label>
                          <input name="last_name" onChange={event => { this.state.new_order.last_name = event.target.value; this.setState({ new_order: this.state.new_order })}} value={this.state.new_order.last_name} type="text" className="form-control" placeholder={t('Last Name')} />
                        </div>
                        <div className="form-group">
                          <label>{t('Phone')}{this.props.match.params.rand2 === 'free' && <span style={{color: 'red'}}>*</span>}</label>
                          <input name="phone" onChange={event => { this.state.new_order.phone = event.target.value; this.setState({ new_order: this.state.new_order })}} value={this.state.new_order.phone} type="text" className="form-control" placeholder={t('Phone')} />
                        </div>
                        <div className="form-group">
                          <label>{t('Email')} {this.props.match.params.rand2 === 'free' && <span style={{color: 'red'}}>*</span>}</label>
                          <input name="email" onChange={event => { this.state.new_order.email = event.target.value; this.setState({ new_order: this.state.new_order })}} value={this.state.new_order.email} type="email" className="form-control" placeholder={t('Email')} />
                        </div>
                        {this.props.match.params.rand2 !== 'free' && <div className="form-group">
                          <label>{t('Company Name For Invoice')} <span style={{color: 'red'}}>*</span></label>
                          <input name="email" onChange={event => { this.state.new_order.company_name = event.target.value; this.setState({ new_order: this.state.new_order })}} value={this.state.new_order.comapny_name} type="email" className="form-control" placeholder={t('Company Name For Invoice')} />
                        </div>}
                        <div className="form-group">
                          {this.props.match.params.rand2 !== 'free' && <button type="button" className="btn btn-primary btn-full" onClick={this.handlePersonalDetails.bind(this, this.props.match.params.rand2)}>{t('Continue To Payment')}</button>}
                          {this.props.match.params.rand2 === 'free' && <button type="button" className="btn btn-primary btn-full" onClick={this.handleFreeTrial.bind(this)}>{t('Start Free Trial')}</button>}
                        </div>
          </div>}
          {this.state.payment_url && <iframe height={700} src={this.state.payment_url}></iframe>}

        </div>
      </div>}



      </div>}
            </React.Fragment>
        );
    }
}

Plans.propTypes = {};

Plans.defaultProps = {};

export default Plans;

