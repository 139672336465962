import React from 'react';
import PropTypes from 'prop-types';
import styles from './CreateGroupPopup.module.scss';
import fetch from 'node-fetch';
import { confirmAlert } from 'react-confirm-alert'; // Import

import settings from '../../assets/icons/settings.svg';
import logo from '../../assets/images/logo.png';
import help from '../../assets/icons/help.svg';
import alert from '../../assets/icons/alert.svg';
import signin from '../../assets/icons/signin.svg';
import clock from '../../assets/icons/clock.svg';
import add from '../../assets/icons/add.svg';
import user1 from '../../assets/images/user1.png';
import user2 from '../../assets/images/user2.png';
import user3 from '../../assets/images/user3.png';
import user4 from '../../assets/images/user4.png';
import post1 from '../../assets/images/post1.png';
import post2 from '../../assets/images/post2.png';
import chat from '../../assets/icons/chat.svg';
import monitor from '../../assets/icons/monitor.svg';
import bin from '../../assets/icons/bin.svg';
import group1 from '../../assets/images/group1.png';
import account from '../../assets/icons/account.svg';
import password from '../../assets/icons/password.svg';
import like from '../../assets/icons/like.svg';
import follower from '../../assets/icons/follower.svg';

import {Button} from 'react-bootstrap/Button';
import {DropdownButton, ButtonGroup, Dropdown} from 'react-bootstrap';

import {NotificationContainer, NotificationManager} from 'react-notifications';

import { useTranslation, initReactI18next, withTranslation } from 'react-i18next';

import { SketchPicker } from 'react-color'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  withRouter,
  useParams,
  useLocation
} from "react-router-dom";

import queryString from 'query-string';

class CreateGroupPopup extends React.Component {

    state = {
      "group": {},
      "error": "",
      "success": "",
      "selected": null,
    }

    refresh() {
    }
    componentWillReceiveProps() {
    }
    componentWillMount() {
      this.update_data(this.props);
    }


    update_data(props) {
      var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/get-group?access_token=${token}&id=${this.props.match.params.group_id}`, {})
        .then(response => response.json())
        .then(data => {
          this.setState({
            group: data,
          });
        })
        .catch(err => {

        });
    }

    handleCreateGroupPopupClicked() {
    var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/create-group?access_token=${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            group: this.state.group,
            id: this.props.match.params.group_id,
          })
        })
        .then(response => response.json())
        .then(data => {
          NotificationManager.success('Added group successfully');
          this.props.updateSearch({path: [':lang', 'console'], "new-group": Math.random()})
        })
        .catch(err => {
          NotificationManager.error('Error adding group');

          this.setState({
            error: "Error adding group",
            success: "",
            password: "",
          });
        })
    }

    handleDeleteCampaign() {

    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
    var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/delete-group?access_token=${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: this.props.match.params.group_id,
          })
        })
        .then(response => response.json())
        .then(data => {
          NotificationManager.success('Deleted group successfully');
          this.props.updateSearch({path: [':lang', 'console'], "new-group": Math.random()})
        })
        .catch(err => {
          NotificationManager.error('Error deleting group');

          this.setState({
            error: "Error deleting group",
            success: "",
            password: "",
          });
        })

          }
        },
        {
          label: 'No',
          onClick: () => {
            
          }
        }
      ]
    });

      
    }

    render() {
      const { t, i18n } = this.props;

        return (
      <div className="overlay">
        <style>{'body {overflow: hidden;}'}</style>
              <div className="bg-primary p-5 text-white text-center rounded-3 " id="myForm">
                  <div className="pb-5 position-relative" id="signin_header">
                    <h2 className="fs-5 fw-bolder text-uppercase text-center">{this.props.edit ? t('Edit group') : t('Create group')}</h2>
                    <a type="button" className="btn text-white close_btn fs-2 fw-bolder" onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console']})}>×</a>
                  </div>

                  <div className="error">{t(this.state.error)}</div>
                  <div className="success">{t(this.state.success)}</div>


                  <div id="email" className="bg-blue py-3 my-3 rounded-3 d-flex">
                    <img className="mx-4" width="35px" src={account} g="icons/account.svg" />
                    <input className="m_input" type="text" placeholder={t('group Name')} onChange={event => {this.state.group.name = event.target.value; this.setState({ post: this.state.group })}} value={this.state.group.name}/>
                  </div>
                  <div id="email" className="bg-blue py-3 my-3 rounded-3 d-flex">
                    <img className="mx-4" width="35px" src={account} g="icons/account.svg" />
                    <input className="m_input" type="text" placeholder={t('group_url')}  onChange={event => {this.state.group.group_url = event.target.value; this.setState({ post: this.state.group })}} value={this.state.group.group_url}/>
                  </div>
                  <div id="email" className="bg-blue py-3 my-3 rounded-3 d-flex">
                    <img className="mx-4" width="35px" src={account} g="icons/account.svg" />
                    <input className="m_input" type="text" placeholder={t('group_id')}  onChange={event => {this.state.group.group_id = event.target.value; this.setState({ post: this.state.group })}} value={this.state.group.group_id}/>
                  </div>
                  <div id="email" className="bg-blue py-3 my-3 rounded-3 d-flex">
                    <img className="mx-4" width="35px" src={account} g="icons/account.svg" />
                    <input className="m_input" type="text" placeholder={t('group_username')}  onChange={event => {this.state.group.group_username = event.target.value; this.setState({ post: this.state.group })}} value={this.state.group.group_username}/>
                  </div>


                  <button onClick={this.handleCreateGroupPopupClicked.bind(this)} type="submit" className="btn w-75 fw-bold bg-white py-3 px-5 mt-4 text-primary rounded rounded-pill">{this.props.edit ? t('Update') : t('Create')}</button>
                  {this.props.edit && <button onClick={this.handleDeleteCampaign.bind(this)} type="submit" className="btn w-75 fw-bold bg-danger py-3 px-5 mt-4 text-primary rounded rounded-pill">{t('Delete')}</button>}
              </div>
      </div>
        );
    }
}

CreateGroupPopup.propTypes = {};

CreateGroupPopup.defaultProps = {};

export default withRouter(CreateGroupPopup);









