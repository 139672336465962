import React from 'react';
import PropTypes from 'prop-types';
import styles from './ManageCommentsPopup.module.scss';
import fetch from 'node-fetch';
import _ from 'lodash';

import settings from '../../assets/icons/settings.svg';
import logo from '../../assets/images/logo.png';
import help from '../../assets/icons/help.svg';
import alert from '../../assets/icons/alert.svg';
import signin from '../../assets/icons/signin.svg';
import clock from '../../assets/icons/clock.svg';
import add from '../../assets/icons/add.svg';
import user1 from '../../assets/images/user1.png';
import user2 from '../../assets/images/user2.png';
import user3 from '../../assets/images/user3.png';
import user4 from '../../assets/images/user4.png';
import post1 from '../../assets/images/post1.png';
import post2 from '../../assets/images/post2.png';
import chat from '../../assets/icons/chat.svg';
import monitor from '../../assets/icons/monitor.svg';
import bin from '../../assets/icons/bin.svg';
import group1 from '../../assets/images/group1.png';
import account from '../../assets/icons/account.svg';
import password from '../../assets/icons/password.svg';
import like from '../../assets/icons/like.svg';
import follower from '../../assets/icons/follower.svg';

import {Button} from 'react-bootstrap/Button';
import {DropdownButton, ButtonGroup, Dropdown} from 'react-bootstrap';

import {NotificationContainer, NotificationManager} from 'react-notifications';

import { useTranslation, initReactI18next, withTranslation } from 'react-i18next';

import { SketchPicker } from 'react-color'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  withRouter,
  useParams,
  useLocation
} from "react-router-dom";

import queryString from 'query-string';

function nFormatter(num, digits) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function(item) {
    return num >= item.value;
  });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}


class ManageCommentsPopup extends React.Component {

    state = {
      "groups": [],
      "selected": null,
      "amount_to_display": 300,
      "search_text": '',
      // "sort_by": 'follow_count',
      "sort_by": 'users_in_group_count',
      "profiles": [],
      "selected_campaign": queryString.parse(this.props.location.search).campaign,
      "profile_group": {},
      "loading": true,
      "filter_query": {
        by_selected: {
          value: 'all',
          query: {},
        },
      },
      "campaigns": null,
    }

    refresh() {
    }
    componentWillReceiveProps() {
    }
    componentWillMount() {
      this.update_data(this.props);
    }


    update_data(props) {
    	var token = localStorage.getItem('user_token');

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/comment-groups?all=true&access_token=${token}`, {})
        .then(response => response.json())
        .then(data => {
        	this.setState({
        		comment_groups: data,
        	});
            this._sort();
        })
        .catch(err => {

        });

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/campaigns?access_token=${token}`, {})
        .then(response => response.json())
        .then(data => {


          this.setState({
            campaigns: data,
          });
        })
        .catch(err => {

        });


    }

    getDisplayComments(sort_by, search_text, amount_to_display, filter_by_profile, filter_query, selected) {

      search_text = search_text || '';

      this.state.comment_groups.map((g) => {
        g.follow_count = g.follow_count | 0;
      })

      var groups = _.orderBy(this.state.comment_groups, [sort_by, 'follow_count', 'today_slot_count', 'users_in_group_count'],['desc'])

      return groups.filter((group) => {
        group.name = group.name || "";
          return group.name.toLowerCase().indexOf(search_text.toLowerCase()) > -1;
        }).filter((group) => {
          if (this.state.selected_campaign) {
            return group.campaigns.find((c) => c._id == this.state.selected_campaign);
          }
          return true;
        })
        .slice(0,amount_to_display)

    }


    render() {
      const { t, i18n } = this.props;

        return (
      <div className="overlay">
        <style>{'body {overflow: hidden;}'}</style>
              {this.state.campaigns && this.state.comment_groups ? <div className="bg-primary p-5 text-white text-center " id="myForm">
                  <div className="pb-5 position-relative" id="signin_header">
                    <h2 className="fs-5 fw-bolder text-uppercase text-center">{t('Manage Comment Groups')}</h2>
                    <a type="button" className="btn text-white close_btn fs-2 fw-bolder" onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console']})}>×</a>
                  </div>

                  <div style={{clear: 'both'}}></div>

                  <div>
                    <div>
                      <h3>{t('Campaign')}</h3>
                      <select value={this.state.selected_campaign} onChange={(e) => { this.setState({selected_campaign: e.target.value}); }} className="form-select bg-primary  text-white py-3 mb-4 px-1" aria-label="Default select example">
                        <option value={""}>{t('Select a Campaign')}</option>
                        {this.state.campaigns.map((campaign) => <option value={campaign._id}>{campaign.name}</option> )}
                      </select>

                    </div>
                    <div style={{width: '50%', float: 'left'}}>
                    </div>
                  </div>

                  <div style={{clear: 'both'}}></div>
                  <div className="search-section">
                    <input placeholder={t('Search Groups')} type="text" value={this.state.search_text} onChange={(e) => {this.setState({search_text: e.target.value})}}/>
                  </div>

                  <div className="group-info"><strong>{t(`Showing`)}:</strong> {this.state.comment_groups.filter((group) => {group.name = group.name || ''; return group.name.toLowerCase().indexOf(this.state.search_text.toLowerCase()) > -1;}).length} {t(`groups`)} {t('of')} {this.state.comment_groups && this.state.comment_groups.length}</div>

                  <button className={`btn btn-primary`} onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console','comment-library', 'add']})} >{t('Add New')}</button>

                  <div style={{width: '800px'}} className="manage-group-container">
                  	{this.getDisplayComments(this.state.sort_by, this.state.search_text, this.state.amount_to_display, this.state.filter_by_profile, this.state.filter_query, this.state.selected).map((group) => (<div style={{padding: '13px', width: '25%', float: 'left'}}>
				      
                    <span dir="ltr" className={"text-decoration-none my-1 d-block square group " }>
      				        <div className="">
      				          <span className="position-absolute top-50 start-50 translate-middle bg-dark-75 w-75 h-75">
      				            <p className=" position-absolute top-50 start-50 translate-middle w-100 text-center text-white fw-bolder"><a target="_blank" href={`https://facebook.com/groups/${group.group_id}`}>{group.name}</a></p>
      				          </span>
      				        </div>
      				        <span className="badge bg-info px-2 py-2 small text-white rounded-pill position-25"><img className="d-inline mx-1 h-icon" width="5px" src="/static/media/follower.e4d49cda.svg" g="icons/follower.svg" />{nFormatter(group.follow_count, 2)}</span><span className="badge bg-info px-2 py-2 small text-white rounded-pill position-75"><img className="d-inline mx-1 h-icon" width="5px" src="/static/media/clock.66a84231.svg" g="icons/clock.svg" />{group.today_slot_count}<img className="d-inline mx-1 h-icon" width="5px" src="/static/media/account.9ec423dc.svg" g="icons/account.svg" />{group.users_in_group_count}</span>
      				      </span>
                      <button className={`btn btn-primary`} onClick={this.props.updateSearch.bind(this, {path: [':lang', 'console','comment-library', 'edit', group._id]})} >{t('Edit')}</button>
                  	</div>))}
                  </div>
              </div> : <div className="loader">
                  <svg width="300px" version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                    <circle fill="none" stroke="#fff" strokeWidth={4} cx={50} cy={50} r={44} style={{opacity: '0.5'}} />
                    <circle fill="#fff" stroke="#e74c3c" strokeWidth={3} cx={8} cy={54} r={6}>
                      <animateTransform attributeName="transform" dur="2s" type="rotate" from="0 50 48" to="360 50 52" repeatCount="indefinite" />
                    </circle>
                  </svg>
                 </div>}
      </div>
        );
    }
}

ManageCommentsPopup.propTypes = {};

ManageCommentsPopup.defaultProps = {};

export default withRouter(ManageCommentsPopup);









