import React from 'react';
import PropTypes from 'prop-types';
import styles from './Login.module.scss';
import fetch from 'node-fetch';

import settings from '../../assets/icons/settings.svg';
import logo from '../../assets/images/logo.png';
import help from '../../assets/icons/help.svg';
import alert from '../../assets/icons/alert.svg';
import signin from '../../assets/icons/signin.svg';
import clock from '../../assets/icons/clock.svg';
import add from '../../assets/icons/add.svg';
import user1 from '../../assets/images/user1.png';
import user2 from '../../assets/images/user2.png';
import user3 from '../../assets/images/user3.png';
import user4 from '../../assets/images/user4.png';
import post1 from '../../assets/images/post1.png';
import post2 from '../../assets/images/post2.png';
import chat from '../../assets/icons/chat.svg';
import monitor from '../../assets/icons/monitor.svg';
import bin from '../../assets/icons/bin.svg';
import group1 from '../../assets/images/group1.png';
import account from '../../assets/icons/account.svg';
import password from '../../assets/icons/password.svg';
import like from '../../assets/icons/like.svg';
import follower from '../../assets/icons/follower.svg';

import {Button} from 'react-bootstrap/Button';
import {DropdownButton, ButtonGroup, Dropdown} from 'react-bootstrap';

import {NotificationContainer, NotificationManager} from 'react-notifications';

import { useTranslation, initReactI18next, withTranslation } from 'react-i18next';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  withRouter,
  useLocation
} from "react-router-dom";

import queryString from 'query-string';

class Login extends React.Component {

    state = {
    	"username": "",
    	"password": "",
    	"error": "",
    	"success": "",
      new_account: {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        password: '',
        agree_terms_and_conditions: false,
      },
      register_error: null,
      login_error: null,
    }

    refresh() {
    }
    componentWillReceiveProps() {
    }
    componentWillMount() {
    }

    handleRegister() {

      fetch(`${process.env.NODE_ENV !== 'development' ? "" : ""}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.state.new_account)
      })
      .then(response => response.json())
      .then(data => {
        if (!data.success) {
          // NotificationManager.error(data.message);
          this.setState({register_error: data.message})
          return;
        }

        NotificationManager.success('Success Register');
        this.props.updateSearch({path: [':lang', 'login']})
      })
      .catch(err => {
        
      })

    }

    handleLoginClicked() {
        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/login`, {
  		    method: 'POST',
  		    headers: {
  		      'Content-Type': 'application/json'
  		    },
  		    body: JSON.stringify({
  		    	username: this.state.username,
  		    	password: this.state.password,
  		    })
        })
        .then(response => response.json())
        .then(data => {
          if (data.success === false) {
            this.setState({
              error: "Ivalid username or password",
              success: "",
              password: "",
            });

            localStorage.setItem('user_token', "");
            return;
          }
        	this.setState({
        		error: "",
        		success: "Logged in successfully",
        		password: "",
        	});
        	localStorage.setItem('user_token', data.token);

          NotificationManager.success('Logged in successfully');

        	// this.props.history.push('/');
        	this.props.checkLoggedIn();
          this.props.updateSearch({path: [':lang', 'console']})
        })
        .catch(err => {
        	this.setState({
        		error: "Ivalid username or password",
        		success: "",
        		password: "",
        	});

        	localStorage.setItem('user_token', "");
        })
    }
    handleResetClicked() {
        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/reset-password`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            username: this.state.username,
          })
        })
        .then(response => {
          if (response.status !== 200 ) {
            return new Promise((resolve, reject) => {
              reject();
            })
          }
          return response.json();
        })
        .then(data => {
          NotificationManager.success('Send Reset Link');
          this.props.updateSearch({path: [':lang', 'sent-email']});
        })
        .catch(err => {
          NotificationManager.error('Reset password error');
        })
    }
    handleResetPasswordClicked() {
      var query = queryString.parse(this.props.location.search);

        fetch(`${process.env.NODE_ENV !== 'development' ? "/api2" : "/api2"}/reset-password-change`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            key: query.key,
            password: this.state.password,
          })
        })
        .then(response => {
          if (response.status !== 200 ) {
            return new Promise((resolve, reject) => {
              reject();
            })
          }
          return response.json();
        })
        .then(data => {
          NotificationManager.success('password reset success');
          this.props.updateSearch({path: [':lang', 'login']});
        })
        .catch(err => {
          NotificationManager.error('error');
        })
    }
    render() {
    	const { t, i18n } = this.props;

        return (

      <div className="box-wrapper">
        {/* Main Wrapper */}
        <div id="main-wrapper" className="page-wrapper">
          <div className="dc-signin theme-two">
            <div className="signin-wrapper">
              <div className="intro-box">
                <div className="intro-content style-dark">
                  <img src="/images/d-code-logo-light.svg" className="logo" alt="DCode" />
                  <div className="heading-wrapper">
                    <h2 className="h1">{t("Wellcome")}</h2>
                  </div>
                  <div className="text-wrapper">
                    <p>{t("The best automatic software in the world for advertising on Facebook that will close the corner for you!")}</p>
                  </div>
                  <div className="btn-wrapper">
                    <a className="btn btn-primary btn-light" href="/">{t("Learn More")}</a>
                  </div>
                </div>
                <div className="st-tab-btn">
                  <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                      <Link className={`nav-link ${this.props.page === 'login' && 'active'}`} data-toggle="tab" href="#SignUp" role="tab" to={`/${this.props.match.params.lang}/login`}>{t('Login')}</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={`nav-link ${this.props.page === 'register' && 'active'}`} data-toggle="tab" role="tab" to={`/${this.props.match.params.lang}/register`}>{t('Register')}</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="form-box">
                <div className="st-tab-content">
                  <div className="tab-content">
                    <div className={`tab-pane ${this.props.page === 'login' && 'active'}`} id="SignIn" role="tabpanel">

                      <div className="error">{t(this.state.error)}</div>
                      <div className="success">{t(this.state.success)}</div>

                        <div className="form-group">
                          <input onChange={event => {this.setState({ username: event.target.value })}} value={this.state.username} type="text" name="username" className="form-control" placeholder={t('Username')} />
                        </div>
                        <div className="form-group">
                          <input onChange={event => {this.setState({ password: event.target.value })}} value={this.state.password} type="password" name="password" className="form-control" placeholder={t('Password')} />
                        </div>
                        <div className="form-group text-center">
                          <Link to={`/${this.props.match.params.lang}/forgot-password`} className="btn link-btn forgot-link">{t('Forgot password?')}</Link>
                        </div>
                        <div className="form-group">
                          <button onClick={this.handleLoginClicked.bind(this)} className="btn btn-primary btn-full">{t("Login")}</button>
                        </div>
                        {/*
                                            <div class="or"><span>or</span></div>

                                            <div class="form-group">
                                                <button class="btn btn-primary btn-full email-btn">Continue with Email</button>
                                            </div>
                                            <div class="form-group">
                                                <button class="btn btn-primary btn-full facebook-btn">Continue with Facebook</button>
                                            </div>
                                            */}
                    </div>
                    <div className={`tab-pane ${this.props.page === 'forgot-password' && 'active'}`} id="SignIn" role="tabpanel">

                      <div className="error">{t(this.state.error)}</div>
                      <div className="success">{t(this.state.success)}</div>

                        <div className="form-group">
                          <input onChange={event => {this.setState({ username: event.target.value })}} value={this.state.username} type="text" name="username" className="form-control" placeholder={t("Username")} />
                        </div>
                        <div className="form-group">
                          <button onClick={this.handleResetClicked.bind(this)} className="btn btn-primary btn-full">{t("Reset password")}</button>
                        </div>
                    </div>
                    <div className={`tab-pane ${this.props.page === 'sent-email' && 'active'}`} id="SignIn" role="tabpanel">

                      <div className="error">{t('Reset Password link has been sent to your email address at this moment. Please follow instructions there.')}</div>

                    </div>
                    <div className={`tab-pane ${this.props.page === 'forgot-password-confirm' && 'active'}`} id="SignIn" role="tabpanel">

                      <div className="error">{t(this.state.error)}</div>
                      <div className="success">{t(this.state.success)}</div>

                        <div className="form-group">
                          <input onChange={event => {this.setState({ password: event.target.value })}} value={this.state.password} type="password" name="password" className="form-control" placeholder="סיסמה חדשה" />
                        </div>
                        <div className="form-group">
                          <button onClick={this.handleResetPasswordClicked.bind(this)} className="btn btn-primary btn-full">{t("Reset password")}</button>
                        </div>
                    </div>
                    <div className={`tab-pane ${this.props.page === 'register' && 'active'}`} id="SignUp" role="tabpanel">
                      <div className="error">{t(this.state.register_error)}</div>
                      <form action="/register" method="POST">
                        <div className="form-group">
                          <input name="first_name" onChange={event => { this.state.new_account.first_name = event.target.value; this.setState({ new_account: this.state.new_account })}} value={this.state.new_account.first_name} type="text" className="form-control" placeholder={t('First Name')} />
                        </div>
                        <div className="form-group">
                          <input name="last_name" onChange={event => { this.state.new_account.last_name = event.target.value; this.setState({ new_account: this.state.new_account })}} value={this.state.new_account.last_name} type="text" className="form-control" placeholder={t('Last Name')} />
                        </div>
                        <div className="form-group">
                          <input name="phone" onChange={event => { this.state.new_account.phone = event.target.value; this.setState({ new_account: this.state.new_account })}} value={this.state.new_account.phone} type="text" className="form-control" placeholder={t('Phone')} />
                        </div>
                        <div className="form-group">
                          <input name="email" onChange={event => { this.state.new_account.email = event.target.value; this.setState({ new_account: this.state.new_account })}} value={this.state.new_account.email} type="email" className="form-control" placeholder={t('Email')} />
                        </div>
                        <div className="form-group">
                          <input name="password" onChange={event => { this.state.new_account.password = event.target.value; this.setState({ new_account: this.state.new_account })}} value={this.state.new_account.password} type="password" className="form-control" placeholder={t('Password')} />
                        </div>
                        <div className="form-group text-center">
                          <label><input checked={this.state.new_account.agree_terms_and_conditions && "checked"} type="checkbox" onChange={event => {this.state.new_account.agree_terms_and_conditions = event.target.checked; this.setState({ new_account: this.state.new_account })}} required="required" /> {t('I agree to terms and conditions')}</label>
                          <div>
                            <a href="/he/terms-of-service" target="_blank">{t("Regulations and terms of use")}</a>
                          </div>
                        </div>
                        <div className="form-group">
                          <button type="button" className="btn btn-primary btn-full" onClick={this.handleRegister.bind(this)}>{t('Register Action')}</button>
                        </div>
{/*
<div class="or"><span>or</span></div>

<div class="form-group">
<button class="btn btn-primary btn-full email-btn">Continue with Email</button>
</div>
<div class="form-group">
<button class="btn btn-primary btn-full facebook-btn">Continue with Facebook</button>
</div>
*/}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Page Header */}
        </div>
      </div>



		  // <div className="overlay">
    //           <div className="bg-primary p-5 text-white text-center rounded-3 " id="myForm">
    //               <div className="pb-5 position-relative" id="signin_header">
    //                 <h2 className="fs-5 fw-bolder text-uppercase text-center">{t('Sign in to account')}</h2>
    //                 <a type="button" className="btn text-white close_btn fs-2 fw-bolder" to={`/${this.props.match.params.lang}`}>×</a>
    //               </div>

    //               <div className="error">{t(this.state.error)}</div>
    //               <div className="success">{t(this.state.success)}</div>

    //               <div id="email" className="bg-blue py-3 my-3 rounded-3 d-flex">
    //                 <img className="mx-4" width="35px" src={account} g="icons/account.svg" />
    //                 <input className="m_input" type="text" placeholder={t('username')} onChange={event => {this.setState({ username: event.target.value })}} value={this.state.username}/>
    //               </div>
    //               <div id="password" className="bg-blue py-3 my-3 rounded-3 d-flex">
    //                 <img className="mx-4" width="35px" src={password} g="icons/password.svg" />
    //                 <input className="m_input" type="password" placeholder={t('password')}  onChange={event => {this.setState({ password: event.target.value })}} value={this.state.password} />
    //               </div>
    //               <p className="small text-white-50">{t('Forget login or password?')} <a className="text-info text-decoration-none" href="#">{t('Click here to reset')}</a></p>
    //               <button onClick={this.handleLoginClicked.bind(this)} type="submit" className="btn w-75 fw-bold bg-white py-3 px-5 mt-4 text-primary rounded rounded-pill">{t('Sign In')}</button>
    //               <p className="my-1 small text-white-50">{t('Dont have an account?')} <a className="text-info text-decoration-none" href="#">{t('Register now.')}</a></p>
    //           </div>
		  // </div>
        );
    }
}

Login.propTypes = {};

Login.defaultProps = {};

export default Login;



