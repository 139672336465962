import React from 'react';
import PropTypes from 'prop-types';
import styles from './Home.module.scss';

import {NotificationContainer, NotificationManager} from 'react-notifications';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  withRouter,
  useLocation
} from "react-router-dom";

import YouTube from 'react-youtube';

class Home extends React.Component {
    state = {
      changing_images: [
        "/images/ss-1.jpg",
        "/images/ss-2.jpg",
        "/images/ss-3.jpg"
      ],
      current_rotation_image_index: 0,
      contact: {
        name: '',
        phone: '',
        about: '',
      },
      testimonials: [

{url: "https://autopost.co.il/embed?v=eUA69aILHhk", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=qw--5vWLomM", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=_mFpUjygcQ0", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=_9PkH9NjpFE", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=BlbIoThmxwQ", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=D-77ROjC4R8", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=QOv4WFdWadY", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=WLebQzwEEaI", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=jIWKHBizKEg", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=dhWQ3FLu50c", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=ZKbEyrxFY48", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=1BqJNZrFjB8", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=DodFDnWLz6w", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=4AoBYbigjIU", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=KWI50zK-YRY", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=HJfGXN-Ydmc", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=S0FUKoF9X14", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=MFMZxWXBr3k", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=Yl7SRegIflw", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=YuUx8zcUoV0", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=sRKqvICZadI", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=StE4w8r6Qak", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=MzDFpm8BT70", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=EY4Gv1yB9-k", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=g0hwSDp4BuQ", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=0HeGvvtRCNo", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=ao6MyItVtYA", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=RY_5WAGL3mE", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=wZ6sXP1wu0Y", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=POF7-WG7PZ0", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=F5bew0c8WGw", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=c2wjLoEvm-Y", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=tsptJv9GemE", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=RoPDABOH6mU", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=tkgU6Fwv9VU", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=0X40qyH57RA", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=ccbiufW2tKY", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=kgn2n5PV_34", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=I8lyTAmUD38", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=ugxHSjlHwLs", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=jcq6tJr9qlA", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=ADDJE4HtfVc", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=lAq0QOjnQMM", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=SfNFkToIKGw", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=8tUidvqp1-E", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=o6woX4SooEY", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=zNjY-8VuN8w", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=AuZ21MVbJ_E", name: "", job: "", youtube_id: ""},
{url: "https://autopost.co.il/embed?v=EgDep7R0dTU", name: "", job: "", youtube_id: ""},
      ],
    }

    update_data(props) {

    }

    handleContactClicked(props) {
      fetch(`${process.env.NODE_ENV !== 'development' ? "" : ""}/contact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.state.contact)
      })
      .then(response => response.json())
      .then(data => {
        if (!data.success) {
          NotificationManager.error(data.message);
          return;
        }

        this.setState({contact: {
          name: '',
          phone: '',
          about: '',
        }})

        NotificationManager.success('Success Sent Message');
      })
      .catch(err => {
        NotificationManager.error(this.props.t('Error sending message'));
      })

    }

    refresh() {

    }

    componentWillReceiveProps(props) {
    }
    
    componentWillMount(props) {
      // setInterval(() => {
      //   if (this.state.changing_images[this.state.current_rotation_image_index + 1]) {
      //     this.setState({
      //       current_rotation_image_index: this.state.current_rotation_image_index + 1,
      //     })
      //   } else {
      //     this.setState({
      //       current_rotation_image_index: 0,
      //     })
      //   }
      // }, 3000);
    }

    componentWillUnmount(){
    }

    render() {
      const { t, i18n } = this.props;

      console.log(6666666);

      return (
      <div id="main-wrapper" className="page-wrapper home">
        <div className="page-header section-padding style-dark full-height dc-two pb-0">
          <div className="container">
            <div className="row justify-content-center text-center">
              <div className="col-lg-7 col-md-12">
                <div className="heading-wrapper wow fadeInDown" data-wow-delay="0.8s">
                  <h1>{t("Welcome to")} - FBzipper</h1>



                  <div className="lead-text">
                    <p>אנחנו חברת fbzipper ופיתחנו את התוכנה האוטומטית והמתקדמת בעולם לפרסום בפייסבוק
שאותה אנחנו מוכרים לכל בעלי העסקים שמעוניינים בפרסום בעלות הנמוכה בעולם, ובצורה מתקדמת ואפקטיבית ביותר.
</p>
                  </div>

                <div className="video-wrapper">
                <h3>סרטון תדמית</h3>
                  <div className="video-player">

        <iframe
            src={"https://tube.rvere.com/embed?v=eUA69aILHhk"}
            title={"YouTube video player"}
            frameBorder={"0"}
            allow={"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"}
            allowFullScreen={"allowFullScreen"}/>
                  </div>
                </div>



                <div className="video-wrapper">
                <h3>הסבר על התוכנה + הסבר על החבילות
</h3>
                  <div className="video-player">

        <iframe
            src={"https://autopost.co.il/embed?v=qw--5vWLomM"}
            title={"YouTube video player"}
            frameBorder={"0"}
            allow={"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"}
            allowFullScreen={"allowFullScreen"}/>
                  </div>
                </div>


                </div>
                {/*<div className="btn-wrapper wow fadeIn" data-wow-delay="1.2s">
                  <a className="btn btn-primary btn-light " href="#contact">{t('Contact')}</a>
                  <a className="btn btn-primary " href="#fullguide">{t("for the full tutorial\n")}</a>
                  <a style={{display: 'none'}} className="btn btn-outline-primary btn-light" href="#contact"><i className="fas fa-play-circle" />צפו בהמלצות</a>
                </div>*/}
                <div className="empty-space-30 clearfix" />
              </div>{/* End Col */}
              <div style={{display: 'none'}} className="col-lg-12">
                <div className="ss-wrapper wow fadeInUp" data-wow-delay="1.5s">
                  <img className="laptop-img-bg" src="/images/laptop-screen.png" alt="" />
                  <div className="screenshot-slider text-center">
                    <div className="item">
                      {/*<img src={this.state.changing_images[this.state.current_rotation_image_index]} alt="" />*/}
                <div className="video-wrapper">
                  <div className="video-player">

        <iframe
            src={"https://tube.rvere.com/embed?v=eUA69aILHhk"}
            width='560'
            height='315'

            title={"YouTube video player"}
            frameBorder={"0"}
            allow={"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"}
            allowFullScreen={"allowFullScreen"}/>


                  </div>
                </div>


                    </div>
                  </div>
                </div>
                <div id="video" />
              </div>{/* End Col */}
            </div>{/* End Row */}
          </div>
        </div>





        <div className="services-section light-gradient-bg section-padding">
          <div className="container">
            <div className="row clearfix justify-content-center">
              <div className="col-lg-10">
                <div className="heading-wrapper text-center with-separator">
                  <h2 className="h1">מה הלקוח מקבל?</h2>
                </div>
                {/* End Heading */}
              </div>{/* End Col */}
            </div>{/* End Row */}
            <div className="row clearfix">


              <div className="col-lg-6">
                <div className="icon-box theme-one wow fadeInLeft">
                  <h4>1. שם משתמש וגישה לתוכנה כולל ניהול מלא</h4>
                  <img src="https://autopost.co.il/wp-content/uploads/2023/02/%D7%9E%D7%94-%D7%94%D7%9C%D7%A7%D7%95%D7%97-%D7%9E%D7%A7%D7%91%D7%9C-1.png" alt="" />
                  <div className="text">
                    
<p>יש ללקוח אפשרות שאנחנו נבצע עבורו את כל העבודה מא' ועד ת'.<br/>
והחל מחבילה של 1500 ש"ח אתם תקבלו 3 שעות עם קמפיינר צמוד <br/>
 שיערוך שיחת הכרות לאפיון העסק, יכתוב לכם פוסטים וקריאייטיב, יכתוב  תגובות מתאימות על הפוסטים, יבחר עבורכם את הקבוצות הרלוונטיות לפרסום, יעלה לכם את כל הפוסטים לקבוצות<br/>
 ולמעשה הקמפיינר יבצע את כל מה שצריך כדי שתתחילו לעבוד.<br/>
בנוסף, יש אפשרות שהלקוח יעשה את כל העבודה לבד<br/>
וכמובן שתמיד תינתן תמיכה ועזרה מהצד שלנו ובכל שלב ❤<br/>
לפרטים נוספים על החבילות </p>
                </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="icon-box theme-one wow fadeInLeft">
                  <h4>2. תוכנה לפרסום אוטומטי המתקדמת בעולם</h4>
                  <img src="https://autopost.co.il/wp-content/uploads/2023/02/מה-הלקוח-מקבל-2-544x450.png" alt="" />
                  <div className="text">
                    
  <p className="elementor-image-box-description">
    שתפרסם לו את כל הפוסטים, התמונות, והסרטונים
    <br />
    ובכל הקבוצות שיבקש.
    <br />
    (ניתן לפרסם את הפוסטים מהפרופיל האישי שלכם או מהפרופילים שלנו)
  </p>

                </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="icon-box theme-one wow fadeInLeft">
                  <h4>3. תגובות על הפוסטים מפרופילים שונים</h4>
                  <img src="https://autopost.co.il/wp-content/uploads/2023/02/2%D7%90%D7%9E%D7%AA%D7%95%D7%A7%D7%9F-1-540x450.jpg" alt="" />
                  <div className="text">
<p className="elementor-image-box-description">
  מעבר לכך, הלקוח יקבל תגובות מפרופילים שונים על כל פוסט שיפורסם.
  <br />
  לדוגמא – "אשמח לפרטים", "מחיר", "מומלץ בחום", "מעוניין בפרטים נוספים".
  <br />
  בנוסף, ניתן לרשום תגובות לפוסטים בהתאמה אישית, לפי בקשת הלקוח.
  <br />
  גם ניתן להוסיף לייקים וללבות על התגובות
  <br />
  והכל יעלה באופן אוטומטי.
</p>
                </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="icon-box theme-one wow fadeInLeft">
                  <h4>4. התוכנה תתייג ותגיב בחזרה בהתאם לבקשת הלקוח</h4>
                  <img src="https://autopost.co.il/wp-content/uploads/2023/02/2%D7%91.jpg" alt="" />
                  <div className="text">
<p className="elementor-image-box-description">
  בנוסף, כל מי שיגיב על הפוסט ויגלה עניין בעוד פרטים
  <br />
  כמו, אשמח לפרטים נוספים, מחיר, וכו', אז הפרופיל שפרסם את הפוסט יתייג את
  הפרופיל שהגיב וישלח לו בחזרה את הפרטים שלנו, והכל יתנהל בצורה אוטומטית. <br />
  לדוגמא: לקוח הגיב על הפוסט שלנו "אשמח לקבל פרטים"
  <br />
  אז הפרופיל שפרסם את הפוסט יתייג אותו ויגיב לו בחזרה באופן אוטומטי – "היי
  בשמחה, לפרטים נוספים ולכל שאלה מצורף מספר נייד/ לינק לווטסאפ או לינק לאתר".
</p>
                </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="icon-box theme-one wow fadeInLeft">
                  <h4>5. תגובות ותיוגים על כל פוסט שקיים בפייסבוק</h4>
                  <img src="https://autopost.co.il/wp-content/uploads/2023/02/%D7%AA%D7%9E%D7%95%D7%A0%D7%94-%D7%97%D7%91%D7%99%D7%9C%D7%95%D7%AA-2.png" alt="" />
                  <div className="text">
<p className="elementor-image-box-description">
  התוכנה יודעת להגיב על כל פוסט שקיים בפייסבוק
  <br />
  בין אם הוא ממומן, לבין אם זה פוסט שעלה בקבוצה, בדף או בפרופיל אישי.
  <br /> בנוסף, התוכנה גם יודעת לתייג את כל מי שהגיב על הפוסט ולשלוח להם את
  הפרטים שלכם.
</p>
                </div>
                </div>
              </div>


              <div className="col-lg-6">
                <div className="icon-box theme-one wow fadeInLeft">
                  <h4>6. מאגר קבוצות הגדול ביותר בשוק</h4>
                  <img src="https://autopost.co.il/wp-content/uploads/2023/02/%D7%9E%D7%90%D7%92%D7%A8-%D7%A7%D7%91%D7%95%D7%A6%D7%95%D7%AA-%D7%94%D7%A9%D7%9B%D7%A8%D7%94-%D7%91%D7%90%D7%A8-%D7%A9%D7%91%D7%A2.png" alt="" />
                  <div className="text">
                    <p className="elementor-image-box-description">
  עם פילוחי קטגוריות מתקדמים. הקבוצות פייסבוק מסודרות לפי מספר העוקבים מהגדולה
  לקטנה
  <br />
  לפי קבוצות פרסום חופשי שהפוסטים ישר מתפרסמים בקבוצות ולא נשלחים לאישור מנהל
  <br /> ובאיזה קבוצות פייסבוק הפוסטים שנפרסם ישלחו להמתנה לאישור ממנהל הקבוצה.
  ועוד..
  <br />
  בנוסף, תוכלו לצרף למאגר כל קבוצה שרק תרצו.
</p>

                </div>
                </div>
              </div>


              <div className="col-lg-6">
                <div className="icon-box theme-one wow fadeInLeft">
                  <h4>7. התחייבות למחיר הזול בעולם.</h4>
                  <div className="text">
                    <p className="elementor-image-box-description">
  אנו מתחייבים למחיר הזול בשוק, וגם בפי 10 מהמחיר שלנו אנו עדיין מתחייבים להישאר
  הזולים בעולם!!! <br />
  ואין חברת פרסום, כלי זהה לשלנו, או פיליפיני, שיעשה לכם בדיוק את אותה העבודה
  <br />
  וגם לא בכפול 10 מהמחיר שלנו בהתחייבות.
  <br />
  קראתם נכון... ואני ממש מתרגש לכתוב את השורות האלה, מהסיבה שזו התחייבות מטורפת
  שפשוט אי אפשר לסרב לה, ולא מאמין שמישהוא איי פעם קיבל התחייבות מדהימה שכזאת.
  <br />
  ונמשיך לדוגמא: אם רכשתם דרכנו חבילה של 1000 ש"ח ומצאתם מישהוא בעולם שמוכן
  לעשות לכם בדיוק את אותה העבודה, ב-10,000 ש"ח
  <br />
  קיבלתם את החבילה בחינם בהתחייבות.
  <br /> .וזה נוגע לכל החבילות{" "}
  🙂
</p>

                </div>
                </div>
              </div>


              <div className="col-lg-6">
                <div className="icon-box theme-one wow fadeInLeft">
                  <h4>8. פרופילים מרשימים ביותר</h4>
                  <div className="text">
                     <div className="elementor-image-box-title">8. פרופילים מרשימים ביותר</div>
  <p className="elementor-image-box-description">
    פרופילים מרשימים ביותר, שיש להם חברים ותגובות מפרופילים שונים.
    <br />
    למשל - על פרופיל של בחורה יהיו תגובות
    <br />
    כגון: "חיים שלי, התגעגעתי", "בת דודה יקרה", "יפה שלי" ועוד...
  </p>
  <p>
    <u>
      <strong>
        <a
          href="https://autopost.co.il/wp-content/uploads/2023/03/תגובות-על-תמונת-פרופיל.jpg"
          onclick="window.open(this.href, '', 'resizable=no,status=no,location=no,toolbar=no,menubar=no,fullscreen=no,scrollbars=no,dependent=no'); return false;"
        >
          <span style={{ color: "#f43336" }}>ראה דוגמא כאן</span>
        </a>
      </strong>
    </u>
  </p>
  .
  <br />
  בנוסף, הפרופילים עם 100% ביטוח מקיף
  <br />
  כלומר, שאם אחד מהם נחסם או ננעל, הלקוח מקבל פרופיל חדש במתנה או שפותחים לו את
  הפרופיל הנעול.
  <p />
                </div>
                </div>
              </div>


              <div className="col-lg-6">
                <div className="icon-box theme-one wow fadeInLeft">
                  <h4>9. הצטרפות אוטומטית לקבוצות</h4>
                  <div className="text">
                    <p className="elementor-image-box-description">
  הפרופילים מבקשים בקשות חברות, ויצטרפו לכל הקבוצות שתבחרו לפרסם בהם בצורה
  אוטומטית.
</p>

                </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="icon-box theme-one wow fadeInLeft">
                  <h4>10. פרופילים בהתאמה אישית</h4>
                  <div className="text">
                    <p className="elementor-image-box-description">
  אנו מספקים פרופילים בהתאמה אישית ללא עלות נוספת
  <br />
  כמו - פרופילים של גברים, נשים, ישראלים, אמריקאים, אזור מגורים מסוים, תחום
  עבודה וכד'.
</p>

                </div>
                </div>
              </div>


              <div className="col-lg-6">
                <div className="icon-box theme-one wow fadeInLeft">
                  <h4>11. צירוף פרופילים אישיים</h4>
                  <div className="text">
                    <p className="elementor-image-box-description">
  ניתן לצרף את הפרופילים האישיים שלכם
  <br />
  שיפרסמו פוסטים ויגיבו לפוסטים ולכל לקוחות שהתעניינו בפרטים.
</p>

                </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="icon-box theme-one wow fadeInLeft">
                  <h4>12. שירות לקוחות ותמיכה</h4>
                  <div className="text">
                    <p className="elementor-image-box-description">
  שירות לקוחות ותמיכה טכנית בעברית
  <br />
  זמינים בימים ראשון – חמישי
  <br />
  בין השעות 9:00 בבוקר עד 19:00 בערב.
</p>

                </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="icon-box theme-one wow fadeInLeft">
                  <h4>13. אין התחייבות לאחר החודש ראשון</h4>
                  <div className="text">
                    <p className="elementor-image-box-description">
  לאחר החודש הראשון, הלקוח לא מחויב להמשיך לחודש הבא.
  <br />
  אנחנו יודעים שברגע שתתחילו לעבוד עם המערכת, וכשתראו את התוצאות בשטח
  <br />
  אתם לא רק תמשיכו איתנו אתם גם תגדילו חבילות וגם תמליצו עלינו הלאה בעזרת השם
  יתברך.
  <br />
  כמו כן, אנו נספק שבוע התארגנות מהסיבה שלוקח מספר ימים עד שכל הפרופילים מצטרפים
  לקבוצות
  <br />
  ועד שנכין עבורכם את כל החומרים והתכנים לפרסום
  <br />
  שזה אומר שבחודש הראשון תקבלו חודש + שבוע.
</p>

                </div>
                </div>
              </div>


              <div className="col-lg-6">
                <div className="icon-box theme-one wow fadeInLeft">
                  <h4>14. שקיפות מלאה</h4>
                  <div className="text">
                    <p className="elementor-image-box-description">
  הכל פועל ומתנהל בשקיפות מלאה וכל פוסט מתועד עם שעה תאריך וכתובת
  <br />
  ותמיד ניתן להיכנס למערכת שלנו ולאתר את כל הפוסטים שפורסמו בקבוצות
  <br />
  לראות את כל התגובות שקיבלנו על הפוסטים,
  <br />
  ואת התיוגים והמענה האוטומטי שקיבלו כל הלקוחות שביקשו פרטים נוספים בפוסטים.
</p>

                </div>
                </div>
              </div>


            </div>
            <div className="row clearfix">
              <div className="col-lg-12 text-center">
                <div className="btn-wrapper">
                  <a href="#contact" className="btn btn-primary btn-large">{t("Try now")}</a>
                </div>
              </div>{/* End Col */}
            </div>{/* End Row */}
          </div>
        </div>









        <div className="services-section light-gradient-bg section-padding">
          <div className="container">
            <div className="row clearfix justify-content-center">
              <div className="col-lg-10">
                <div className="heading-wrapper text-center with-separator">
                  <h2 className="h1">למי מתאימה התוכנה?</h2>
                  <p>התוכנה מתאימה כפי שציינו לכל בעל עסק.
למשל - אם בעלים של חנות שווארמה מקומי מעוניין לפרסם את העסק, אז נוכל לפרסם אותו במספר דרכים:
</p>
                </div>
                {/* End Heading */}
              </div>{/* End Col */}
            </div>{/* End Row */}
            <div className="row clearfix">


              <div className="col-lg-6">
                <div className="icon-box theme-one wow fadeInLeft">
                  <h4>1. מפרסמים את העסק בכל הקבוצות, כולל תגובות וכולל מענה ללקוחות</h4>
                  <img src="https://autopost.co.il/wp-content/uploads/2023/02/1-850x450.jpg" alt="" />
                  <div className="text">
                    
<p className="elementor-image-box-description">
  התוכנה תפרסם פוסטים של העסק שלו בכל הקבוצות אוכל והשווארמה, ובקבוצות האזוריות
  <br />
  מעבר לכך, אנו דואגים להגיב על הפוסטים תגובות מפרופילים שונים.
  <br />
  תגובות של אנשים שמתעניינים במקום, וגם תגובות של המון לקוחות שממליצים על אותה
  שווארמה
  <br />
  ואם לקוחות נוספים יגיבו על הפוסטים ויהיו מעוניינים בפרטים
  <br />
  אנו נתייג אותם ונגיב להם בחזרה את הפרטים של העסק.
</p>

                </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="icon-box theme-one wow fadeInLeft">
                  <h4>2. פרסום סמוי בסגנון שאלות שמכוונות לבית העסק</h4>
                  <img src="https://autopost.co.il/wp-content/uploads/2023/03/2new-850x450.jpg" alt="" />
                  <div className="text">
                    
<p className="elementor-image-box-description">
  נפרסם פוסטים בסגנון "מי השווארמה הכי טובה שאתם מכירים באזור?"
  <br />
  או "מחפשת המלצות על שווארמה טובה בסביבה".
  <br />
  ואז התוכנה תגיב עם כמות מסיבית של המלצות חמות על אותה שווארמה ממגוון פרופילים
  שונים.{" "}
</p>


                </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="icon-box theme-one wow fadeInLeft">
                  <h4>3. פרסום סמוי, המלצות של לקוחות על בית העסק</h4>
                  <img src="https://autopost.co.il/wp-content/uploads/2023/02/3.jpg" alt="" />
                  <div className="text">
                    
<p className="elementor-image-box-description">
  פרסום המלצות של חווית לקוח, לדוגמא: מעלים פוסט בסגנון:
  <br />
  "אני חייבת לשתף אתכם בחוויה מיוחדת, הגעתי לשווארמה של דניאל, דרך המלצה של חברה
  ואני מוכרחה להגיד שהתאהבתי בה מהביס הראשון, אמאל'ה! זאת השווארמה הכי טעימה
  שאכלתי בחיים!!
  <br />
  הבשר עסיסי, הלאפה טרייה, רטבים סוףףף והכל מתאסף לשלמות!
  <br />
  וגם בתגובות לפוסט נוסיף עוד המון המלצות.
  <br />
  "גם אני מצטרף להמלצה על השווארמה של דניאל מספר 1 בארץ".
  <br />
  או - "לאחר שטעמתי המון שווארמות אין ספק שהשווארמה של דניאל עולה על כולם" וכד'.{" "}
</p>



                </div>
                </div>
              </div>


              <div className="col-lg-6">
                <div className="icon-box theme-one wow fadeInLeft">
                  <h4>4. פרסום סמוי בסגנון שאלות וחוות דעת על העסק עצמו.</h4>
                  <img src="https://autopost.co.il/wp-content/uploads/2023/03/111222.jpg" alt="" />
                  <div className="text">
                    
<p className="elementor-image-box-description">
  אנו נפרסם פוסטים מפרופילים שרוצים לקבל חוות דעת, ושואלים על העסק עצמו.
  <br />
  לדוגמא : "חברים יקרים, אשמח לקבל חוות דעת על השווארמה של דניאל מניסיון בלבד.
  תודה רבה"
  <br />
  וכמובן שאנחנו נעזור לו וניתן לו חוות דעת בתגובות מהמון פרופילים שונים שממליצים
  על המקום.
  <br />
  לדוגמא: "אין על השווארמה של דניאל בעולם תטעם ואז תבין"
  <br />
  או "מי שלא אכל בשווארמה של דניאל לא אכל שווארמה&nbsp;מימיו"&nbsp;ועוד...
</p>



                </div>
                </div>
              </div>




            </div>
            <div className="row clearfix">
              <div className="col-lg-12 text-center">
                <div className="btn-wrapper">
                  <a href="#contact" className="btn btn-primary btn-large">{t("Try now")}</a>
                </div>
              </div>{/* End Col */}
            </div>{/* End Row */}
          </div>
        </div>


















        {/* Page Header */}
        <div id="video" className="about-section section-padding dc-two">
          <div className="container">
            {<div style={{display: 'none'}} className="row clearfix align-items-center flex-row-reverse justify-content-between">
              <div className="col-lg-6">
                <h2 className="h3">כתבה שעשו עלינו בחדשות 12</h2>
                <div className="video-wrapper">
                  <div className="video-player">

        <iframe
            src={"https://tube.rvere.com/embed?v=ZiZ-uSxhcaY"}
            width='560'
            height='400'

            title={"YouTube video player"}
            frameBorder={"0"}
            allow={"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"}
            allowFullScreen={"allowFullScreen"}/>


                  </div>
                </div>
              </div>{/* End Col */}
              <div className="col-lg-5">
                <div className="heading-wrapper with-separator">
                  <h2 className="h1">הפוך את העסק שלך בפייסבוק לאוטומטי עכשיו!</h2>
                </div>
                {/* End Heading */}
                <div className="text-wrapper">
                  <p className="lead-text">למה צריך תוכנה אוטומטית לפרסום בפייסבוק?</p>
                  {/*
                                    <p>אם אתם בעלי עסקים או עוסקים בתחום השיווק בטח שמתם לב כמה קשה לפרסם בפייסבוק, לבסוף אתם נאלצים לשלם אלפי שקלים בכדי לפרסם את המוצרים או השירותים שלכם.
                  באמצעות המערכת שלנו תוכלו ללמוד איך להוזיל את עלויות הפרסום שלכם עד 80% (בהתאם לתחום), לחסוך המון זמן יקר ואף ליצור מערכת פרסום אוטומטית שתנהל עבורכם את הפרסום של העסק מידי יום ותביא לכם תוצאות טובות גם כשאתם ישנים, בטיול, או מתעסקים בדברים אחרים. הפרסום הוא <strong>100% אוטומטי!!!</strong> ובצורה הטובה והנוחה ביותר שיש!!! <strong>רוצים לדעת עוד? צפו עכשיו בסרטון! או לחצו על הכתפור "למד עוד".</strong></p>
                  */}
                  <p>
                    אם אתם בעלי עסקים או עוסקים בתחום השיווק בטח שמתם לב כמה קשה לפרסם בפייסבוק, לבסוף אתם נאלצים לשלם אלפי שקלים בכדי לפרסם את המוצרים או השירותים שלכם. באמצעות המערכת שלנו תוכלו ללמוד איך להוזיל את עלויות הפרסום שלכם עד 80% (בהתאם לתחום), לחסוך המון זמן יקר ואף ליצור מערכת פרסום אוטומטית שתנהל עבורכם את הפרסום של העסק מידי יום ותביא לכם תוצאות טובות גם כשאתם ישנים, בטיול, או מתעסקים בדברים אחרים. הפרסום הוא 100% אוטומטי!!! ובצורה הטובה והנוחה ביותר שיש!!! רוצים לדעת עוד? <br/> צפו עכשיו בסרטון! או השאירו פרטים בטופס יצירת הקשר שלמטה.
                  </p>
                </div>
                <div id="contact34534" style={{paddingBottom: '60px'}} />
                {/*                                <div class="btn-wrapper">
                                    <a class="btn btn-primary" href="#" id="contact">למד עוד</a>
                                </div>*/}
              </div>{/* End Col */}
            </div>}{/* End Row */}
          </div>
        </div>


        <div className="testimonial-section style-two section-padding">
          <div className="container">
            <div className="row clearfix style-dark">
              <div className="col-lg-8">
                <div className="heading-wrapper">
                  <h2>{t("Opinions of satisfied customers")}</h2>
                  <div className="lead-text">
                    <p>{t("If you are still in doubt then the customers who have already tried the software want to give you a personal opinion")} <br /> {t("and tell you about the experience of using the software, about their type of business and the results they received, you are welcome to review")}:</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <Carousel
                  showArrows={true}
                  infiniteLoop={true}
                  showThumbs={false}
                  showStatus={false}
                  autoPlay={false}
                  interval={6100}
                >
                  {this.state.testimonials.map((te) => (<div key={te.youtube_id}>
                    <div className="myCarousel" >
                      <div style={{paddingTop: '20px'}}>
        <iframe
            src={te.url}
            title={"YouTube video player"}
            frameBorder={"0"}
            allow={"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"}
            allowFullScreen={"allowFullScreen"}/>


                        {/*<video 
                          controls="1"
                          class="giphy-video" 
                          width="100%" 
                          height="auto" 
                          autoplay={false}
                          playsinline="" 
                          src={te.url}></video>*/}


                        <h3>{te.name}</h3>
                        <h4>{te.job}</h4>
                      </div>
                    </div>
                  </div>))}
                </Carousel>
              </div>



                 </div>

                </div>
              </div>


            <div className="clearfix">
              <div className="col-lg-12">
                <div className="client-testimonial theme-two">


        <div className="about-section dc-two">
          <div className="container">
            <div className="row clearfix align-items-center justify-content-center">
              <div className="col-lg-6">
                <div className="heading-wrapper with-separator">
                  <span id="contact" className="sub-title">{t("Want to hear more?")}</span>
                  <h2>{t("Contact us")}</h2>
                </div>{/* End Heading */}
                <div className="text-wrapper">
                  <p>{t("In order to receive service and additional information about us and the features of the system and any other topic, leave details and we will be happy to get back to you and help!")}</p>
                </div>
                <div className="contact-form">
                  <form>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <input onChange={event => { this.state.contact.name = event.target.value; this.setState({ contact: this.state.contact })}} value={this.state.contact.name} type="text" className="form-control" id="FiratName" placeholder={t("Full name *")} required />
                      </div>
                    </div>
                    <div className="form-group">
                      <input type="text" onChange={event => { this.state.contact.phone = event.target.value; this.setState({ contact: this.state.contact })}} value={this.state.contact.phone} className="form-control" id="subject" placeholder={t("Phone Number *")} required />
                    </div>
                    <div className="form-group">
                      <textarea onChange={event => { this.state.contact.about = event.target.value; this.setState({ contact: this.state.contact })}} value={this.state.contact.about} className="form-control" name="about" placeholder={t("Tell us briefly about your line of work...")} defaultValue={""} />
                    </div>
                    <div className="form-group" style={{textAlign: 'center'}}>
                      <button type="button" onClick={this.handleContactClicked.bind(this)} className="btn btn-primary" defaultValue="" >{t("Send Message")}</button>
                    </div>
                  </form>
                </div>
              </div>{/* End Col */}
            </div>{/* End Row */}
          </div>
        </div>





        {/* About Section */}
        <div id="fullguide" className="services-section light-gradient-bg section-padding">
          <div className="container">
            {/*<div className="row clearfix justify-content-center">
              <div className="col-lg-10">
                <div className="heading-wrapper text-center with-separator">
                  <h2 className="h1">הדרכה מלאה</h2>
                </div>
              </div>
            </div>
            <div className="row clearfix">
              <div className="col-lg-12">

                <div className="video-wrapper">
                  <div className="video-player">

        <iframe
            src={"https://tube.rvere.com/embed?v=Gs7ysr7aPjw"}
                      width='560'
                      height='315'

            title={"YouTube video player"}
            frameBorder={"0"}
            allow={"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"}
            allowFullScreen={"allowFullScreen"}/>

                  </div>
                </div>

              </div>
            </div>*/}
          </div>
        </div>
        <div className="services-section light-gradient-bg section-padding">
          <div className="container">
            <div className="row clearfix justify-content-center">
              <div className="col-lg-10">
                <div className="heading-wrapper text-center with-separator">
                  <h2 className="h1">{t("Why should you purchase a robot for automatic Facebook advertising?")}</h2>
                  <div className="lead-text">
                    <p>{t("If your field of business is digital marketing or if you are a business owner who wants to save precious time and unnecessary costs, and learn how to automate your business advertising and bring good results, you should try our robot! We are sure that you have noticed the fact that advertising on Facebook is not particularly cheap and also requires a lot of investment and precious time that not everyone has... Our system is suitable for all business owners, saves time and is proven to reduce advertising costs")} <strong>{t("Up to 80% less than what you are used to paying!!!")}</strong> {t("Brings you peace and makes you less busy! worth trying!")}</p>
                  </div>
                </div>
                {/* End Heading */}
              </div>{/* End Col */}
            </div>{/* End Row */}
            <div className="row clearfix">
              <div className="col-lg-4">
                <div className="icon-box theme-one wow fadeInLeft">
                  <div className="icon">
                    <img src="/images/default-color/icon-5-light.svg" alt="" />
                  </div>
                  <div className="text">
                    <h4>{t("100% Automatic")}</h4>
                    <p>{t("We are the only company in Israel that sells an automated robotic system for marketing and distribution purposes on Facebook. The robot will advertise according to your choice in dedicated groups, to the audiences you choose, for the people you want while you are at work, sleeping or on a trip.")}</p>
                  </div>
                </div>
              </div>{/* End Col */}
              <div className="col-lg-4">
                <div className="icon-box theme-one wow fadeInUp">
                  <div className="icon">
                    <img src="/images/default-color/icon-6-light.svg" alt="" />
                  </div>
                  <div className="text">
                    <h4>{t("High circulation")}</h4>
                    <p>{t("The system will publish for you in hundreds of groups posts of any type - text, image, link, marketplace or marketing video, will join you in groups, will send you friend requests and many other amazing options that will make your life much easier!")}</p>
                  </div>
                </div>
              </div>{/* End Col */}
              <div className="col-lg-4">
                <div className="icon-box theme-one wow fadeInRight">
                  <div className="icon">
                    <img src="/images/default-color/icon-7-light.svg" alt="" />
                  </div>
                  <div className="text">
                    <h4>{t("Easy to operate")}</h4>
                    <p>{t("FBzipper is the only company in the market that provides broad support, and beyond digital trainings provides frontal sales and operation trainings that will expand your knowledge and make you bring much better results. Purchasing use of the software will give you the opportunity to participate in them at no additional cost!!!")}</p>
                  </div>
                </div>
              </div>{/* End Col */}
            </div>{/* End Row */}
            <div className="row clearfix">
              <div className="col-lg-12 text-center">
                <div className="btn-wrapper">
                  <a href="#contact" className="btn btn-primary btn-large">{t("Try now")}</a>
                </div>
              </div>{/* End Col */}
            </div>{/* End Row */}
          </div>
        </div>
        {/* Services Section */}
        <div className="features-section section-padding style-dark dark-bg gradient-heading-bg dc-two">
          <div className="container">
            <div className="row justify-content-center clearfix">
              <div className="col-lg-8">
                <div className="heading-wrapper text-center">
                  <h2>{t("So what are the options that the system is able to perform")}</h2>
                  <div className="lead-text">
                    <p>{t("Our system is the most innovative and convenient on the market! And the most updated!")} <br />{t("And you will find a lot of different options in it that will help you make the advertising of your business automatic and most efficient!")}</p>
                  </div>
                </div>{/* End Heading */}
                <div className="empty-space-60 clearfix" />
              </div>{/* End Col */}
            </div>{/* End Row */}
            <div className="row clearfix">
              <div className="col-lg-4 col-md-6">
                <div className="features-block theme-two wow fadeInUp">
                  <div className="inner-box">
                    <div className="text">
                      <h4>{t("Joining groups")}</h4>
                      <p>המערכת תצרף עבורכם את הפרופילים שתבחרו לקבוצות פייסבוק שתרצו באופן אוטומטי על מנת שתוכלו לפרסם בהם פוסטים. בנוסף במערכת יש מאגר רחב של קבוצות מפולחות לפי נושאים ועוד... לבחירתכם.</p>
                    </div>
                  </div>
                </div>
              </div>{/* End Col */}
              <div className="col-lg-4 col-md-6">
                <div className="features-block theme-two wow fadeInUp" data-wow-delay="0.2s">
                  <div className="inner-box">
                    <div className="text">
                      <h4>{t("Automatic posting")}</h4>
                      <p>{t("The system allows scheduling and publishing automatic posts in the most convenient way in different Facebook groups. Posts of all types containing text, video, image / albums, marketplace, link and more...")}</p>
                    </div>
                  </div>
                </div>
              </div>{/* End Col */}
              <div className="col-lg-4 col-md-6">
                <div className="features-block theme-two wow fadeInUp" data-wow-delay="0.4s">
                  <div className="inner-box">
                    <div className="text">
                      <h4>{t("Bouncing posts")}</h4>
                      <p>{t("After the post is published, the system will record random comments of your choice from a list of profiles and comments that you define in advance on existing posts to boost them and increase exposure and all automatically!")}</p>
                    </div>
                  </div>
                </div>
              </div>{/* End Col */}
              <div className="col-lg-4 col-md-6">
                <div className="features-block theme-two wow fadeInUp">
                  <div className="inner-box">
                    <div className="text">
                      <h4>{t("Automatic timing")}</h4>
                      <p>{t("For each post that will be published through the system in each group, you can schedule a fixed date and time to perform the action according to a fixed daily / weekly / monthly schedule that will run automatically for life. In addition to defining from which profiles each post will be published, what to write in pop-ups, what to reply to commenters interested in the service and a host of cool options!")}</p>
                    </div>
                  </div>
                </div>
              </div>{/* End Col */}
              <div className="col-lg-4 col-md-6">
                <div className="features-block theme-two wow fadeInUp" data-wow-delay="0.2s">
                  <div className="inner-box">
                    <div className="text">
                      <h4>{t("Data export (coming soon)")}</h4>
                      <p>{t("This interface is currently under development, but very soon our system will be able to export for you a focused and orderly list of the profile names you want from certain Facebook groups that you choose to file in an orderly manner so that you can keep the data with you and use it for more targeted advertising purposes.")}</p>
                    </div>
                  </div>
                </div>
              </div>{/* End Col */}
              <div className="col-lg-4 col-md-6">
                <div className="features-block theme-two wow fadeInUp" data-wow-delay="0.4s">
                  <div className="inner-box">
                    <div className="text">
                      <h4>{t("Human simulation")}</h4>
                      <p>{t("Our software is one of the most advanced and developed and our development team worked on it for a little over a year until they reached a situation where the profiles are not at risk and everything is conducted in a very calculated manner. The system simulates human actions in a very calculated way and everything happens in real time in your Facebook accounts to prevent blocks and spam.")}</p>
                    </div>
                  </div>
                </div>
              </div>{/* End Col */}
              <div className="col-lg-4 col-md-6">
                <div className="features-block theme-two wow fadeInUp">
                  <div className="inner-box">
                    <div className="text">
                      <h4>{t("Sharing posts")}</h4>
                      <p>{t("In addition to publishing the posts automatically, bouncing the posts from different profiles and random comments, tagging people and replying links or information about your service, our system will share your posts for you from the profiles you define in advance and according to the desired number of shares!")}</p>
                    </div>
                  </div>
                </div>
              </div>{/* End Col */}
              <div className="col-lg-4 col-md-6">
                <div className="features-block theme-two wow fadeInUp" data-wow-delay="0.2s">
                  <div className="inner-box">
                    <div className="text">
                      <h4>{t("Search groups")}</h4>
                      <p>{t("The system contains a database of thousands of Facebook groups segmented by categories (free advertising, jobs, tinkering, real estate, cars, and more...) You can search for groups by keywords in free text, in addition, the system will scan your profiles and update the list of your groups in the software .")}</p>
                    </div>
                  </div>
                </div>
              </div>{/* End Col */}
              <div className="col-lg-4 col-md-6">
                <div className="features-block theme-two wow fadeInUp" data-wow-delay="0.4s">
                  <div className="inner-box">
                    <div className="text">
                      <h4>{t("Market Place")}</h4>
                      <p>{t("Through our system there is the possibility to upload different products, to advertise vehicles or real estate of any kind in appropriate Facebook groups and it will also publish them in the Facebook Market Place automatically in order to make you receive more inquiries from those interested!")}</p>
                      <div id="contact2" />
                    </div>
                  </div>
                </div>
              </div>{/* End Col */}
            </div>{/* End Row */}
          </div>
        </div>
        {/* Features Section */}


        {/* Pricing Section */}

            </div>
          </div>
        </div>

        <div className="subscribe-section section-padding pt-0">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="image-wrapper">
                  <img src="/images/default-color/newsletter-img.png" alt="" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="heading-wrapper with-separator">
                  <h2>{t("Sign up now for our newsletter")}</h2>
                  <div className="lead-text">
                    <p>{t("Want to be the first to receive notifications about updates to the system? new features?")} <br /> {t("And tools that will help you optimize your business?!")} <strong>{t("Sign up for our newsletter now!")}</strong></p>
                  </div>
                </div>
                <div className="subscribe-form-wrapper">
                  <form method="post">
                    <div className="form-group">
                      <input type="email" name="EmailInput" className="form-control" placeholder={t("What is your email?")} required />
                    </div>
                    <input type="submit" className="btn btn-primary" defaultValue="הירשם כעת!" />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Subscribe Section */}
      </div>

      );
    }
}

Home.propTypes = {};

Home.defaultProps = {};

export default Home;
